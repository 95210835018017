import React from 'react';
import PropTypes from 'prop-types';

const SkipLink = ({ targetId = 'maincontent', linkText = 'Skip to main content', customAction = null }) => {
  const onClick =
    customAction != null
      ? event => {
          event.preventDefault();
          customAction();
        }
      : null;
  return (
    <a href={`#${targetId}`} className="skip-to" onClick={onClick}>
      {linkText}
    </a>
  );
};

SkipLink.propTypes = {
  targetId: PropTypes.string,
  linkText: PropTypes.string,
  customAction: PropTypes.func,
};

export default SkipLink;
