import i18next from 'i18next';
import { isEmpty, last, sortBy } from 'lodash';

import { formatDateDMYYYY } from 'utils/datetime-utils';
import { GRANTEE_ROLES } from 'realEstateSite/constants/Roles';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { FETCH_RESOLVED_CADASTRAL_ACTIVITIES } from 'realEstateSite/containers/Authorization/privileges';
import {
  ACTIVITY_TYPE_REGISTRATION_ISSUE,
  ACTIVITY_TYPE_CADASTRAL_SURVEY,
  APPROVED_SOLUTION_TYPE,
  LEGALLY_BINDING_SOLUTION_TRACKING_INFO,
  NOT_LEGALLY_BINDING_SOLUTION_TRACKING_INFO,
  PENDING_ACTIVITY_STATE,
  RESTING_SOLUTION_TYPE,
  REJECTED_SOLUTION_TYPE,
  NOT_INVESTIGATED_SOLUTION_TYPE,
  PROCESSING_PETITION_TYPE,
  SUPPLEMENT_PROCESSING_TYPE,
  SUPPLEMENT_RECEIVED_TYPE,
} from './ActivityConstants';
import {
  SUPPLEMENT_REQUEST_TYPE,
  SUPPLEMENT_REMINDER_TYPE,
  RESOLUTION_REQUEST_TYPE,
  CONSENT_REQUEST_TYPE,
  DELIVERY_METHOD_EMAIL,
  DELIVERY_METHOD_PHONE,
} from './RegistrationActivity/SupplementRegistrationActivity/SupplementRegistrationActivityConstants';
import {
  EVENT_TYPE_PROCEEDING,
  EVENT_TYPE_REGISTER_DECISION,
  PHASE_TYPE_ACCOMPLISHED,
  PHASE_TYPE_APPEAL_PENDING,
  PHASE_TYPE_APPEAL_SOLVED,
  PHASE_TYPE_CANCELLED,
  PHASE_TYPE_LAPSED,
  PHASE_TYPE_REGISTERED,
  CADASTRAL_ACTIVITY_NOTIFICATION_PHASES,
  CADASTRAL_ACTIVITY_REGISTER_DECISION_PHASES,
  CADASTRAL_ACTIVITY_PROCEEDING_PHASES,
  PHASE_CANCELLED,
  PHASE_REGISTERED,
  ALL_CADASTRAL_PHASES,
  PHASE_TYPE_COMBINED,
  PHASE_TYPE_PREPROCESSING,
} from './CadastralActivityConstants';

export const getActivityStateText = (
  { activityState, activityStartDate, solutionType, solutionTrackingDate, solutionTrackingInfo, solutionTypeText },
  isActivityListItem = false
) => {
  if (activityState === PENDING_ACTIVITY_STATE) {
    return activityStartDate
      ? `${i18next.t('activity.pending.text')} ${formatDateDMYYYY(new Date(activityStartDate))}`
      : `${i18next.t('activity.title.pending')} `;
  }
  if (solutionType === RESTING_SOLUTION_TYPE) {
    const postfix =
      i18next.t('activity.resting.text.postfix') === 'activity.resting.text.postfix'
        ? ''
        : i18next.t('activity.resting.text.postfix');
    return `${i18next.t('activity.resting.text.prefix')} ${formatDateDMYYYY(
      new Date(solutionTrackingDate)
    )} ${postfix}`;
  }
  if (
    isActivityListItem &&
    [REJECTED_SOLUTION_TYPE, NOT_INVESTIGATED_SOLUTION_TYPE, APPROVED_SOLUTION_TYPE].includes(solutionType) &&
    solutionTrackingInfo !== LEGALLY_BINDING_SOLUTION_TRACKING_INFO
  ) {
    if (solutionTrackingInfo === NOT_LEGALLY_BINDING_SOLUTION_TRACKING_INFO) {
      if (i18next.language === 'fi') {
        return `${i18next.format(i18next.t('activity.nonProbated.text'), 'capitalize')} ${formatDateDMYYYY(
          new Date(solutionTrackingDate)
        )} ${i18next.t('activity.state.postfix')}`;
      }
      return `${i18next.format(i18next.t('activity.nonProbated.text'), 'capitalize')} ${formatDateDMYYYY(
        new Date(solutionTrackingDate)
      )}`;
    }
    return i18next.format(i18next.t('activity.probated.text'), 'capitalize');
  }
  if (
    !isActivityListItem &&
    (solutionType !== APPROVED_SOLUTION_TYPE ||
      (solutionType === APPROVED_SOLUTION_TYPE && solutionTrackingInfo !== LEGALLY_BINDING_SOLUTION_TRACKING_INFO))
  ) {
    if (solutionTrackingInfo === NOT_LEGALLY_BINDING_SOLUTION_TRACKING_INFO) {
      if (i18next.language === 'fi') {
        return `${solutionTypeText ? solutionTypeText[i18next.language] : ''}, ${i18next.t(
          'activity.nonProbated.text'
        )} ${formatDateDMYYYY(new Date(solutionTrackingDate))} ${i18next.t('activity.state.postfix')}`;
      }
      return `${solutionTypeText ? solutionTypeText[i18next.language] : ''}, ${i18next.t(
        'activity.nonProbated.text'
      )} ${formatDateDMYYYY(new Date(solutionTrackingDate))}`;
    }
    return `${solutionTypeText ? solutionTypeText[i18next.language] : ''}, ${i18next.t('activity.probated.text')}`;
  }
  return '';
};

const getSurveyStateText = ({ activityStartDate }) => {
  return activityStartDate
    ? `${i18next.t('activity.inProgress.date')} ${formatDateDMYYYY(new Date(activityStartDate))}`
    : i18next.t('activity.inProgress.text');
};

/** Rekisterinpitäjän päätös  */
const getDecisionPhaseProperties = phaseTypeCode => {
  switch (phaseTypeCode) {
    case '1':
    case '3':
    case '70':
    case '71':
      return CADASTRAL_ACTIVITY_REGISTER_DECISION_PHASES.pending;
    case '15':
    case '60':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.cancelled;
    case '61':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.combined;
    case '23':
    case '24':
    case '26':
      return CADASTRAL_ACTIVITY_REGISTER_DECISION_PHASES.finished;
    case '99':
      return CADASTRAL_ACTIVITY_REGISTER_DECISION_PHASES.preprocessing;
    default:
      return undefined;
  }
};

/** Toimitus  */
const getProcedurePhaseProperties = phaseTypeCode => {
  switch (phaseTypeCode) {
    case '1':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.pending;
    case '15':
    case '60':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.cancelled;
    case '61':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.combined;
    case '18':
    case '21':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.reOpened;
    case '3':
    case '70':
    case '71':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.informed;
    case '26':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.finished;
    case '23':
    case '24':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.registered;
    case '99':
      return CADASTRAL_ACTIVITY_PROCEEDING_PHASES.preprocessing;
    default:
      return undefined;
  }
};

const combinePhases = (phases, appealPhases) => {
  const currentPhase = { ...phases[0] };
  const aDate = new Date(currentPhase.date);

  const appealsForPhase = appealPhases.filter(b => {
    const bDate = new Date(b.date);

    return bDate >= aDate;
  });

  if (appealsForPhase.length > 0) {
    const correctAppeal =
      appealsForPhase.find(appeal => appeal.phaseTypeCode === PHASE_TYPE_APPEAL_SOLVED) || appealsForPhase[0];
    currentPhase.appealPhase = correctAppeal;
  }
  return [currentPhase, ...phases.slice(1)];
};

export const isInPreprocessing = phases => {
  return phases != null && phases.length === 1 && phases[0].phaseTypeCode === PHASE_TYPE_PREPROCESSING;
};

export const filterCadastralSurveyPhases = (eventTypeCode, phases) => {
  const filteredPhases = sortBy(
    phases.map(phase => ({
      ...phase,
      /** Add phase properties */
      ...(eventTypeCode === EVENT_TYPE_PROCEEDING
        ? getProcedurePhaseProperties(phase.phaseTypeCode)
        : getDecisionPhaseProperties(phase.phaseTypeCode)),
    })),
    'date'
  )
    .filter((phase, index, array) => {
      /** Filter out phases without properties */
      if (!phase.phaseTitle) return false;
      /** Filter out same consecutive phases */
      if (index > 0 && array[index - 1].phaseTitle === phase.phaseTitle) return false;
      return true;
    })
    .reverse();

  const appealPhases = phases.filter(
    phase => phase.phaseTypeCode === PHASE_TYPE_APPEAL_PENDING || phase.phaseTypeCode === PHASE_TYPE_APPEAL_SOLVED
  );

  const finalPhases = !isEmpty(appealPhases) ? combinePhases(filteredPhases, appealPhases) : filteredPhases;

  /** Find the index of cancelled or registered phase; phases that come after these should be ignored */
  const index = finalPhases.findLastIndex(
    phase => phase.phaseNumber === PHASE_CANCELLED || phase.phaseNumber === PHASE_REGISTERED
  );
  return index > 0 ? finalPhases.slice(index) : finalPhases;
};

export const getFuturePhases = (eventTypeCode, currentPhase) => {
  const futurePhaseList = [
    CADASTRAL_ACTIVITY_PROCEEDING_PHASES.registered,
    CADASTRAL_ACTIVITY_PROCEEDING_PHASES.finished,
    CADASTRAL_ACTIVITY_PROCEEDING_PHASES.informed,
  ];

  const registerDecisionFuturePhases = [CADASTRAL_ACTIVITY_REGISTER_DECISION_PHASES.finished];

  if (
    isEmpty(currentPhase) ||
    currentPhase.phaseNumber === PHASE_REGISTERED ||
    currentPhase.phaseNumber === PHASE_CANCELLED
  )
    return false;
  if (eventTypeCode === EVENT_TYPE_REGISTER_DECISION) return registerDecisionFuturePhases;
  return futurePhaseList.slice(0, 4 - currentPhase.phaseNumber);
};

export const getActivityTitle = activity => {
  if (activity.isPending)
    return activity.petition?.stateType === PROCESSING_PETITION_TYPE
      ? `${activity.title[i18next.language]} ${i18next.t('activity.title.resting')}`
      : `${activity.title[i18next.language]} ${i18next.t('activity.title.pending')}`;
  if (activity.solutionType === RESTING_SOLUTION_TYPE)
    return `${activity.title[i18next.language]} ${i18next.t('activity.title.resting')}`;
  if ([REJECTED_SOLUTION_TYPE, NOT_INVESTIGATED_SOLUTION_TYPE, APPROVED_SOLUTION_TYPE].includes(activity.solutionType))
    return `${activity.title[i18next.language]} ${activity.solutionTypeText[i18next.language]?.toLowerCase()}`;
  return activity.title ? activity.title[i18next.language] : '';
};

export const getCorrectLastPhaseTypeCode = phases => {
  const stopPhaseCodes = [PHASE_TYPE_CANCELLED, PHASE_TYPE_LAPSED, PHASE_TYPE_REGISTERED, PHASE_TYPE_ACCOMPLISHED];
  let stopPhaseFound = false;

  const filteredPhases = phases?.reduce((phaseList, phase) => {
    if (stopPhaseFound || !ALL_CADASTRAL_PHASES.includes(phase.phaseTypeCode)) {
      return phaseList;
    }
    if (stopPhaseCodes.includes(phase.phaseTypeCode)) {
      stopPhaseFound = true;
    }
    return [...phaseList, phase];
  }, []);

  return last(filteredPhases)?.phaseTypeCode;
};

export const getCadastralSurveyText = currentPhase => {
  if (isEmpty(currentPhase)) return '';
  if (currentPhase.phaseTypeCode === PHASE_TYPE_COMBINED)
    return i18next.t('activity.cadastralSurvey.description.combined');
  return i18next.t(currentPhase.phaseTitle);
};

const getCorrectDescription = activity => {
  if (activity.type === ACTIVITY_TYPE_REGISTRATION_ISSUE) return getActivityStateText(activity, true);
  if (activity.type === ACTIVITY_TYPE_CADASTRAL_SURVEY && isAuthorized([FETCH_RESOLVED_CADASTRAL_ACTIVITIES])) {
    return getCadastralSurveyText(activity.surveyDetails?.phases[0]);
  }
  return getSurveyStateText(activity);
};

export const getFormattedActivity = activity => ({
  ...activity,
  title: getActivityTitle(activity),
  description: getCorrectDescription(activity),
});

const fetchSupplementRequests = activity =>
  activity &&
  GRANTEE_ROLES.includes(activity.roleIdentifier) &&
  [SUPPLEMENT_PROCESSING_TYPE, SUPPLEMENT_RECEIVED_TYPE].includes(activity.petition?.processingStateType) &&
  activity.petition.stateType === PROCESSING_PETITION_TYPE &&
  (PENDING_ACTIVITY_STATE === activity.activityState || RESTING_SOLUTION_TYPE === activity.solutionType);

export const showSupplementRequest = activity =>
  fetchSupplementRequests(activity) && !isEmpty(activity.supplementRequests?.newRequests);

export const showSupplementRequestExceptions = activity =>
  fetchSupplementRequests(activity) && activity.supplementRequestExceptions;

export const showCadastralNotification = activity =>
  isEmpty(activity.surveyDetails?.phases[0]?.appealPhase) &&
  CADASTRAL_ACTIVITY_NOTIFICATION_PHASES.includes(activity.surveyDetails?.phases[0]?.phaseTypeCode);

export const getSupplementRequestTitle = (supplementRequestType, deliveryMethod, isPrint = false) => {
  switch (supplementRequestType) {
    case SUPPLEMENT_REQUEST_TYPE: {
      if (isPrint) {
        if ([DELIVERY_METHOD_EMAIL, DELIVERY_METHOD_PHONE].includes(deliveryMethod))
          return i18next.t('supplementActivity.form.print.contact');
        return i18next.t('supplementActivity.form.print.supplementRequest');
      }
      if ([DELIVERY_METHOD_EMAIL, DELIVERY_METHOD_PHONE].includes(deliveryMethod))
        return i18next.t('activity.supplementRequest.type.contact');
      return i18next.t('activity.supplementRequest.type.supplementRequest');
    }
    case SUPPLEMENT_REMINDER_TYPE: {
      return isPrint
        ? i18next.t('supplementActivity.form.print.supplementReminder')
        : i18next.t('activity.supplementRequest.type.supplementReminder');
    }
    case RESOLUTION_REQUEST_TYPE: {
      return isPrint
        ? i18next.t('supplementActivity.form.print.resolutionRequest')
        : i18next.t('activity.supplementRequest.type.resolutionRequest');
    }
    case CONSENT_REQUEST_TYPE: {
      return isPrint
        ? i18next.t('supplementActivity.form.print.consentRequest')
        : i18next.t('activity.supplementRequest.type.consentRequest');
    }
    default:
      return '';
  }
};
