import merge from 'deepmerge';
import { t } from 'i18next';
import * as types from './AppNotificationsActionTypes';

function addNotificationAction({ message, type, id = Date.now(), autoHide = true, isValidationMessage = false } = {}) {
  return {
    type: types.ADD_NOTIFICATION,
    notification: {
      id,
      link: '',
      date: '',
      disableCloseButton: false,
      type,
      message,
      visible: true,
      timestamp: Date.now(),
      isBulletinMessage: false,
      autoHide,
      isValidationMessage,
    },
  };
}

export function addInfoNotificationAction(message) {
  return addNotificationAction({ message, type: types.NOTIFICATION_TYPE_INFO });
}

export function addWarningNotificationAction(message, id) {
  return addNotificationAction({ message, type: types.NOTIFICATION_TYPE_WARNING, id });
}

export function addErrorNotificationAction(message) {
  return addNotificationAction({ message, type: types.NOTIFICATION_TYPE_ERROR, autoHide: false });
}

export function addValidationNotificationAction(message, id) {
  return addNotificationAction({ message, type: types.NOTIFICATION_TYPE_ERROR, id, isValidationMessage: true });
}

export function addMmlBulletinNotificationAction(message, date, url) {
  return merge(addWarningNotificationAction(message), {
    notification: {
      isBulletinMessage: true,
      autoHide: false,
      link: url,
      date,
    },
  });
}

const AVAILABILITY_NOTIFICATION_ID = 'AVAILABILITY_NOTIFICATION';

export function addAvailabilityNotificationAction(message) {
  return merge(addWarningNotificationAction(message), {
    notification: {
      id: AVAILABILITY_NOTIFICATION_ID,
      autoHide: false,
    },
  });
}

export function addBoundaryInaccuracyNotification() {
  // Static ID given here ensures an identical notification about the inaccuracies of boundaries is not shown/added
  return addWarningNotificationAction(t('notifications.boundaryInaccuracy'), types.BOUNDARY_INACCURACY_WARNING);
}

export function hideNotificationAction(id) {
  return {
    type: types.HIDE_NOTIFICATION,
    id,
  };
}

export function deleteFormValidationNotification(id) {
  return {
    type: types.DELETE_FORM_VALIDATION_NOTIFICATION,
    id,
  };
}

export function hideAvailabilityNotificationAction() {
  return hideNotificationAction(AVAILABILITY_NOTIFICATION_ID);
}
