import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cls from 'classnames';

import { Calculator, FocusableHeading, Glyphicon, Ruler } from 'common/components';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import MapButton from 'realEstateSite/containers/Realty/RealEstate/common/MapButtonComponent';
import ListItem from './ScrollerPanelListItem';

const TitleWithIcon = ({ glyph, title, longTitle = false, className = '' }) => {
  const dispatch = useDispatch();
  const Heading = longTitle ? 'h3' : 'h2';

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <div className={cls('panel-list-item-title__with-icon', className)}>
      <Glyphicon glyph={glyph} />
      <div>
        <Heading onKeyDown={onKeyDown}>{title}</Heading>
        <Ruler className="margin-t-1" />
      </div>
    </div>
  );
};

TitleWithIcon.propTypes = {
  glyph: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  longTitle: PropTypes.bool,
  className: PropTypes.string,
};

const ListTitle = ({ title, h3 = false, className = '', isFocusable = false }) => {
  const dispatch = useDispatch();
  const Heading = h3 ? 'h3' : 'h2';

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  if (isFocusable) {
    return (
      <ListItem>
        <FocusableHeading
          level={Heading}
          visualLevel={Heading}
          onKeyDown={onKeyDown}
          className={cls('panel-list-item__title', className)}
          excludeDefaultClass
        >
          {title}
        </FocusableHeading>
      </ListItem>
    );
  }

  return (
    <ListItem>
      <Heading onKeyDown={onKeyDown} className={cls('panel-list-item__title', className)}>
        {title}
      </Heading>
    </ListItem>
  );
};

ListTitle.propTypes = {
  title: PropTypes.string.isRequired,
  h3: PropTypes.bool,
  className: PropTypes.string,
  isFocusable: PropTypes.bool,
};

export const ListTitleWithCalculator = ({
  title,
  h3 = false,
  className = '',
  isFocusable = false,
  fullListLength = 0,
  shownItemsLength = 0,
  isLoading = false,
  isLongList = false,
  dataTestId,
}) => {
  const dispatch = useDispatch();
  const Heading = h3 ? 'h3' : 'h2';

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  if (isFocusable) {
    return (
      <ListItem>
        <FocusableHeading
          level={Heading}
          visualLevel={Heading}
          onKeyDown={onKeyDown}
          className={cls('panel-list-item__title', className)}
          excludeDefaultClass
        >
          {title}
        </FocusableHeading>
        {!isLoading && isLongList && (
          <Calculator
            className="margin-b-2"
            shownItemsLength={shownItemsLength}
            fullListLength={fullListLength}
            dataTestId={dataTestId}
          />
        )}
      </ListItem>
    );
  }

  return (
    <ListItem>
      <Heading onKeyDown={onKeyDown} className={cls('panel-list-item__title', className)}>
        {title}
      </Heading>
      {!isLoading && isLongList && (
        <Calculator
          className="margin-b-2"
          shownItemsLength={shownItemsLength}
          fullListLength={fullListLength}
          dataTestId={dataTestId}
        />
      )}
    </ListItem>
  );
};

ListTitleWithCalculator.propTypes = {
  title: PropTypes.string.isRequired,
  h3: PropTypes.bool,
  className: PropTypes.string,
  isFocusable: PropTypes.bool,
  fullListLength: PropTypes.number,
  shownItemsLength: PropTypes.number,
  isLoading: PropTypes.bool,
  isLongList: PropTypes.bool,
  dataTestId: PropTypes.string,
};

const ListSubTitle = ({ title, h4 = false }) => {
  const Heading = h4 ? 'h4' : 'h3';
  return (
    <ListItem>
      <Heading className="panel-list-item__title panel-list-item__title--sub">{title}</Heading>
    </ListItem>
  );
};

ListSubTitle.propTypes = {
  title: PropTypes.string.isRequired,
  h4: PropTypes.bool,
};

const ListTitleWithMapButton = ({
  title,
  onMobile = false,
  showButton = true,
  hidden = false,
  withLifeline = false,
}) => (
  <ListItem className={cls({ 'lifeline-title': withLifeline })}>
    <div className="panel-list-item-title__wrapper">
      <h2 className="panel-list-item__title">{title}</h2>
      {onMobile && showButton && <MapButton tabIndex={hidden ? -1 : 0} />}
    </div>
  </ListItem>
);

ListTitleWithMapButton.propTypes = {
  title: PropTypes.string.isRequired,
  onMobile: PropTypes.bool,
  showButton: PropTypes.bool,
  hidden: PropTypes.bool,
  withLifeline: PropTypes.bool,
};

const ListTitleWithControls = ({
  title,
  onMobile = false,
  showButton = true,
  hidden = false,
  withLifeline = false,
  checkBoxMargin = false,
  children,
}) => {
  return (
    <ListItem className={cls({ 'lifeline-title': withLifeline })} checkBoxMargin={checkBoxMargin}>
      <div className="panel-list-item-title__wrapper">
        <h2 className="panel-list-item__title">{title}</h2>
        {onMobile && showButton && <MapButton tabIndex={hidden ? -1 : 0} />}
      </div>
      <div>{children}</div>
    </ListItem>
  );
};

ListTitleWithControls.propTypes = {
  title: PropTypes.string.isRequired,
  onMobile: PropTypes.bool,
  showButton: PropTypes.bool,
  hidden: PropTypes.bool,
  withLifeline: PropTypes.bool,
  checkBoxMargin: PropTypes.bool,
  children: PropTypes.node,
};

export default TitleWithIcon;
export { ListTitle, ListSubTitle, ListTitleWithMapButton, ListTitleWithControls };
