import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Block } from 'common/components/Grid';
import { Glyphicon } from 'common/components/Icon';
import { ROUTE_MAP_SITE_ID } from 'common/constants/Routes';
import { SHOPPING_CART_TITLE } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';

const CartButton = ({ shoppingCartUrl, cartItemCount, children = null, rootUrl = '' }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const itemCount = parseInt(cartItemCount, 10);

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      dispatch(requestKeyboardFocusAction({ selector: SHOPPING_CART_TITLE }));
    }
  };

  return (
    <div className={cls('full-height', { 'user-menu--right': rootUrl.includes(ROUTE_MAP_SITE_ID) })}>
      <Link
        className={cls('button', 'button--flexible', 'user-menu__item-block')}
        to={shoppingCartUrl}
        onKeyDown={onKeyDown}
        title={
          itemCount
            ? `${t('usermenu.shoppingcart.prefix')} ${cartItemCount} ${t('usermenu.shoppingcart.postfix')}`
            : t('usermenu.shoppingcart.empty')
        }
      >
        <Block className="item-block__content user-menu__cart-button">
          <Glyphicon glyph="ostoskarry" />
          <span aria-hidden className={cls('cart-button__count', { hidden: itemCount <= 0 })}>
            {cartItemCount}
          </span>
        </Block>
      </Link>
      {children}
    </div>
  );
};

CartButton.propTypes = {
  cartItemCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  shoppingCartUrl: PropTypes.string.isRequired,
  children: PropTypes.node,
  rootUrl: PropTypes.string,
};

export default CartButton;
