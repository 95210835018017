import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { groupBy, isEmpty, flatten } from 'lodash';
import { useTranslation } from 'react-i18next';

import { FEATURE_TYPES } from 'common/constants/MapConstants';
import { EVENTS } from 'common/helpers/Analytics';

import { shownOnMapResetted } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import { removeUsufructFeaturesThunk } from 'realEstateSite/containers/Realty/RealEstate/UsufructsAndRestrictions/UsufructUnits/UsufructUnitsActions';
import { hideInfobox } from 'oskari/OskariMap';
import { SERVICES_FETCH_REAL_ESTATES } from 'realEstateSite/constants/Routes';
import { removeCustomMarkerThunk } from '../MapToolbar/CustomMarkerActions';
import {
  removeAllNonLaturiGeometriesFromMapThunk,
  removeFeaturesByTypeThunk,
  removeRealEstateSiteFeaturesThunk,
  setLayerVisibilityThunk,
  setMeasurementsLayerVisibility,
} from '../../geometries/geometriesActions';
import { REAL_ESTATE_SITE_FEATURE_TYPES } from '../../geometries/geometriesActionTypes';
import MapFeatureGroup from './MapFeatureGroup';
import { cleanMeasurementsThunk, hideMeasurementsThunk, showMeasurementsThunk } from '../MapToolbar/MapToolbarActions';
import MainToolbarHeaderComponent from '../Sidebar/MainToolbarHeaderComponent';
import { getCorrectGeometriesForType } from '../../../realEstateSite/containers/Realty/common/geometries/geometriesHelpers';
import { OWN, SEARCHED } from '../../../realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import { clearInfoBox } from '../MapToolbar/SelectorActions';
import {
  LAYER_ADDRESSES,
  LAYER_APARTMENTS,
  LAYER_BOUNDARY_MARKERS,
  LAYER_CUSTOM_MARKERS,
  LAYER_MEASUREMENTS,
  LAYER_PARCELS,
  LAYER_PLACE_NAMES,
  LAYER_REAL_ESTATE_BORDERS,
  LAYER_REAL_ESTATE_IDS,
  LAYER_REAL_ESTATE_SEARCH,
  LAYER_USUFRUCTS,
} from '../../../oskari/layers/VectorLayers';
import { clearDrawnRealEstateMarkers } from '../SidebarSearch/SearchActions';
import useDrawPrevRealtyMarkers from '../../hooks/useDrawPrevRealtyMarkers';

const {
  ADDRESS_MARKER,
  BOUNDARY_MARKER,
  SEARCH_MARKER,
  CUSTOM_MARKER,
  REAL_ESTATE_SEARCH_RESULT_MARKER,
  DISTANCE_MEASUREMENT,
  AREA_MEASUREMENT,
  USUFRUCT_UNIT,
} = FEATURE_TYPES;
const { APARTMENTS, BOUNDARY_MARKERS, REAL_ESTATES } = REAL_ESTATE_SITE_FEATURE_TYPES;

const extractBoundaryMarkersFromRealEstates = realEstates =>
  realEstates.reduce(
    (markers, { features }) => [
      ...markers,
      ...features.filter(({ properties }) => properties.name === BOUNDARY_MARKER),
    ],
    []
  );

const MapFeatureToggleComponent = ({ onRemoveAll = () => null }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const disabled = useSelector(state => state.mapToolbar.disableMapTool.toolsDisabled);
  const mapFeatures = useSelector(state => state.geometries.features);
  const appContext = useSelector(state => state.application.applicationContext);
  const { infoBoxId, layerId } = useSelector(state => state.mapToolbar.selector);

  const {
    [LAYER_BOUNDARY_MARKERS]: boundaryMarkersVisible = true,
    [LAYER_PARCELS]: realEstatesVisible = true,
    [LAYER_APARTMENTS]: apartmentsVisible = true,
    [LAYER_PLACE_NAMES]: searchResultsVisible = true,
    [LAYER_CUSTOM_MARKERS]: customMarkersVisible = true,
    [LAYER_ADDRESSES]: addressesVisible = true,
    [LAYER_MEASUREMENTS]: measurementsVisible = true,
    [LAYER_USUFRUCTS]: usufructUnitsVisible = true,
  } = useSelector(state => state.geometries?.layerVisibility || {});

  const geometries = useSelector(state => state.geometry || {});
  const ownGeometries = getCorrectGeometriesForType(OWN, geometries);
  const searchedGeometries = getCorrectGeometriesForType(SEARCHED, geometries);

  useDrawPrevRealtyMarkers();

  const {
    [REAL_ESTATES]: realEstatesRemoved = false,
    [APARTMENTS]: apartmentsRemoved = false,
    [BOUNDARY_MARKERS]: boundaryMarkersRemoved = false,
  } = useSelector(state => state.geometries?.removedRealEstateSiteFeatures || {});

  const {
    [ADDRESS_MARKER]: nearestAddress = [],
    [CUSTOM_MARKER]: customMarkers = [],
    [SEARCH_MARKER]: searchResults = [],
    [REAL_ESTATE_SEARCH_RESULT_MARKER]: searchedRealEstates = [],
    [AREA_MEASUREMENT]: areaMeasurements = [],
    [DISTANCE_MEASUREMENT]: distanceMeasurements = [],
    [USUFRUCT_UNIT]: usufructUnits = [],
  } = groupBy(mapFeatures, 'featureType');

  const correctGeometries =
    pathname.includes(SERVICES_FETCH_REAL_ESTATES) || appContext === 'mapSite' ? searchedGeometries : ownGeometries;

  const { registerUnit = [], apartment = [], property = [], facility = [] } = groupBy(correctGeometries, 'type');

  const ownRealEstateFeatures = [...registerUnit, ...property, ...facility];
  const boundaryMarkers = extractBoundaryMarkersFromRealEstates(ownRealEstateFeatures);
  const realEstateFeatures = [...flatten(ownRealEstateFeatures.map(unit => unit.features)), ...searchedRealEstates];

  const removeRealEstateInfoBox = () => {
    if ([LAYER_PARCELS, LAYER_REAL_ESTATE_IDS, LAYER_REAL_ESTATE_BORDERS, LAYER_REAL_ESTATE_SEARCH].includes(layerId)) {
      hideInfobox([infoBoxId]);
      dispatch(clearInfoBox());
    }
  };

  const removeUsufructUnitsInfoBox = () => {
    if (layerId === LAYER_USUFRUCTS) {
      hideInfobox([infoBoxId]);
      dispatch(clearInfoBox());
    }
  };

  const removePlaceMarkers = () => dispatch(removeFeaturesByTypeThunk(SEARCH_MARKER));
  const removeAddressMarkers = () => dispatch(removeFeaturesByTypeThunk(ADDRESS_MARKER));
  const removeCustomMarkers = () => customMarkers.forEach(marker => dispatch(removeCustomMarkerThunk(marker)));
  const removeBoundaryMarkers = () => {
    dispatch(shownOnMapResetted());
    dispatch(removeRealEstateSiteFeaturesThunk(BOUNDARY_MARKERS));
  };
  const removeApartments = () => {
    dispatch(shownOnMapResetted());
    dispatch(removeRealEstateSiteFeaturesThunk(APARTMENTS));
  };
  const removeRealEstates = () => {
    dispatch(shownOnMapResetted());
    dispatch(removeRealEstateSiteFeaturesThunk(REAL_ESTATES));
    dispatch(removeFeaturesByTypeThunk(REAL_ESTATE_SEARCH_RESULT_MARKER));
    removeRealEstateInfoBox();
    if (appContext === 'mapSite') dispatch(clearDrawnRealEstateMarkers());
  };
  const removeUsufructUnits = () => {
    dispatch(shownOnMapResetted());
    dispatch(removeUsufructFeaturesThunk());
    removeUsufructUnitsInfoBox();
  };

  const toggleMeasurements = () => {
    if (measurementsVisible) {
      dispatch(hideMeasurementsThunk());
      dispatch(setMeasurementsLayerVisibility(false));
    } else {
      dispatch(showMeasurementsThunk());
    }
  };

  const toggleSearchMarkers = () => dispatch(setLayerVisibilityThunk(LAYER_PLACE_NAMES, !searchResultsVisible));

  const toggleCustomMarkers = () => dispatch(setLayerVisibilityThunk(LAYER_CUSTOM_MARKERS, !customMarkersVisible));

  const toggleAddressMarkers = () => dispatch(setLayerVisibilityThunk(LAYER_ADDRESSES, !addressesVisible));

  const clearMeasurements = () => dispatch(cleanMeasurementsThunk());

  const toggleBoundaryMarkers = () =>
    dispatch(setLayerVisibilityThunk(LAYER_BOUNDARY_MARKERS, !boundaryMarkersVisible));
  const toggleApartments = () => dispatch(setLayerVisibilityThunk(LAYER_APARTMENTS, !apartmentsVisible));

  const toggleRealEstates = () => {
    dispatch(setLayerVisibilityThunk(LAYER_PARCELS, !realEstatesVisible));
    dispatch(setLayerVisibilityThunk(LAYER_REAL_ESTATE_IDS, !realEstatesVisible));
    dispatch(setLayerVisibilityThunk(LAYER_REAL_ESTATE_SEARCH, !realEstatesVisible));
    dispatch(setLayerVisibilityThunk(LAYER_REAL_ESTATE_BORDERS, !realEstatesVisible));
    removeRealEstateInfoBox();
  };

  const toggleUsufructUnits = () => {
    dispatch(setLayerVisibilityThunk(LAYER_USUFRUCTS, !usufructUnitsVisible));
    removeUsufructUnitsInfoBox();
  };

  const removeAll = () => {
    EVENTS.trackRemoveAllClick();
    dispatch(cleanMeasurementsThunk());
    removeRealEstates();
    removeApartments();
    dispatch(removeRealEstateSiteFeaturesThunk(BOUNDARY_MARKERS));
    dispatch(removeAllNonLaturiGeometriesFromMapThunk());
    hideInfobox([infoBoxId]);
    dispatch(clearInfoBox());
    onRemoveAll();
  };

  const isApartmentsEmpty = apartmentsRemoved || isEmpty(apartment);
  const isBoundaryMarkersEmpty = boundaryMarkersRemoved || isEmpty(boundaryMarkers);
  const isCustomMarkersEmpty = isEmpty(customMarkers);
  const isMeasurementsEmpty = isEmpty(distanceMeasurements) && isEmpty(areaMeasurements);
  const isSearchResultsEmpty = isEmpty(searchResults);
  const isAddressesEmpty = isEmpty(nearestAddress);
  const isRealEstatesEmpty = isEmpty(searchedRealEstates) && (realEstatesRemoved || isEmpty(realEstateFeatures));
  const isUsufructUnitsEmpty = isEmpty(usufructUnits);

  const mapHasNoFeatures = [
    isApartmentsEmpty,
    isBoundaryMarkersEmpty,
    isCustomMarkersEmpty,
    isMeasurementsEmpty,
    isSearchResultsEmpty,
    isRealEstatesEmpty,
    isAddressesEmpty,
    isUsufructUnitsEmpty,
  ].every(isEmptyLayer => isEmptyLayer === true);

  return (
    <div className="map-feature-toggle">
      <div className="main-toolbar-block">
        <MainToolbarHeaderComponent title={t('sidebar.features.tooltip')} />
        {mapHasNoFeatures && <div className="map-feature-toggle__no-features">{t('sidebar.features.noFeatures')}</div>}
      </div>
      <ul className="map-feature-toggle__ul">
        <MapFeatureGroup
          isEmptyLayer={isCustomMarkersEmpty}
          deleteFeatures={removeCustomMarkers}
          toggleFeatures={toggleCustomMarkers}
          isHidden={!customMarkersVisible}
          name={t('sidebar.features.customMarkers')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isMeasurementsEmpty}
          deleteFeatures={clearMeasurements}
          toggleFeatures={toggleMeasurements}
          isHidden={!measurementsVisible}
          name={t('sidebar.features.measurements')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isSearchResultsEmpty}
          deleteFeatures={removePlaceMarkers}
          toggleFeatures={toggleSearchMarkers}
          isHidden={!searchResultsVisible}
          name={t('sidebar.features.searchResults')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isAddressesEmpty}
          deleteFeatures={removeAddressMarkers}
          toggleFeatures={toggleAddressMarkers}
          isHidden={!addressesVisible}
          name={t('sidebar.features.addresses')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isRealEstatesEmpty}
          deleteFeatures={removeRealEstates}
          toggleFeatures={toggleRealEstates}
          isHidden={!realEstatesVisible}
          name={t('sidebar.features.realEstates')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isBoundaryMarkersEmpty}
          deleteFeatures={removeBoundaryMarkers}
          toggleFeatures={toggleBoundaryMarkers}
          isHidden={!boundaryMarkersVisible}
          name={t('sidebar.features.boundaryMarkers')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isApartmentsEmpty}
          deleteFeatures={removeApartments}
          toggleFeatures={toggleApartments}
          isHidden={!apartmentsVisible}
          name={t('sidebar.features.apartments')}
          disabled={disabled}
        />
        <MapFeatureGroup
          isEmptyLayer={isUsufructUnitsEmpty}
          deleteFeatures={removeUsufructUnits}
          toggleFeatures={toggleUsufructUnits}
          isHidden={!usufructUnitsVisible}
          name={t('sidebar.features.usufructUnits')}
          disabled={disabled}
        />
      </ul>
      <div>
        {!mapHasNoFeatures && (
          <button
            className="button map-feature-toggle__remove-all-button"
            type="button"
            onClick={removeAll}
            disabled={disabled}
          >
            {t('sidebar.features.removeAll')}
          </button>
        )}
      </div>
    </div>
  );
};

MapFeatureToggleComponent.propTypes = {
  onRemoveAll: PropTypes.func,
};

export default MapFeatureToggleComponent;
