import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { EVENTS } from 'common/helpers/Analytics';
import MapTool from './MapToolComponent';
import CustomMarkerEditorComponent from './CustomMarkerEditorComponent';
import { switchMapToolThunk } from './MapToolbarActions';

import { MAP_TOOL_DISTANCE, MAP_TOOL_ADDRESS, MAP_TOOL_AREA, MAP_TOOL_CUSTOM_MARKER } from './MapToolNames';

const MapToolbarComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addressActive = useSelector(state => state.mapToolbar.address.active);
  const distanceActive = useSelector(state => state.mapToolbar.distance.active);
  const areaActive = useSelector(state => state.mapToolbar.area.active);
  const customMarkerActive = useSelector(state => state.mapToolbar.customMarker.active);
  const customMarkerDisabled = useSelector(state => state.mapToolbar.disableMapTool.customMarker);
  const distanceDisabled = useSelector(state => state.mapToolbar.disableMapTool.distance);
  const areaDisabled = useSelector(state => state.mapToolbar.disableMapTool.area);
  const addressDisabled = useSelector(state => state.mapToolbar.disableMapTool.address);
  const customMarkerInEditor = useSelector(state => state.mapToolbar.customMarker.itemInEditor);

  const handleCustomMarkerToolClick = () => EVENTS.trackCustomMarkerToolClick(customMarkerActive);
  const handleDistanceToolClick = () => EVENTS.trackDistanceToolClick(distanceActive);
  const handleAreaToolClick = () => EVENTS.trackAreaToolClick(areaActive);
  const handleAddressToolClick = () => EVENTS.trackAddressToolClick(addressActive);

  const makeMapToolSwitcher = toolId => enable => dispatch(switchMapToolThunk(toolId, enable));

  return (
    <div className="maptools__toolbar">
      <MapTool
        active={customMarkerActive}
        disabled={customMarkerDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_CUSTOM_MARKER)}
        title={t('maptoolbar.marker.title')}
        description={t('maptoolbar.marker.description')}
        glyph="oma-merkinta"
        onClick={handleCustomMarkerToolClick}
      />

      <MapTool
        active={distanceActive}
        disabled={distanceDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_DISTANCE)}
        title={t('maptoolbar.distance.title')}
        description={t('maptoolbar.distance.description')}
        glyph="etaisyys"
        onClick={handleDistanceToolClick}
      />

      <MapTool
        active={areaActive}
        disabled={areaDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_AREA)}
        title={t('maptoolbar.area.title')}
        description={t('maptoolbar.area.description')}
        glyph="pinta-ala"
        onClick={handleAreaToolClick}
      />

      <MapTool
        active={addressActive}
        disabled={addressDisabled}
        enable={makeMapToolSwitcher(MAP_TOOL_ADDRESS)}
        title={t('maptoolbar.address.title')}
        description={t('maptoolbar.address.description')}
        glyph="lahin-osoite"
        onClick={handleAddressToolClick}
      />

      <CustomMarkerEditorComponent item={customMarkerInEditor} />
    </div>
  );
};

export default MapToolbarComponent;
