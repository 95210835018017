/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import firstBy from 'thenby';

import { getRealEstateRoute, SERVICES_FETCH_REAL_ESTATES } from 'realEstateSite/constants/Routes';
import {
  asyncValidateHousingCompanySearch,
  asyncValidateRealEstateSearch,
} from 'realEstateSite/containers/Forms/FormValidation/AsyncValidation';
import ServicesApi from 'realEstateSite/api/ServicesApi';
import { setRegisterState } from 'realEstateSite/containers/Realty/RealEstate/RealEstateList/realEstateListSlice';
import * as OskariMap from 'oskari/OskariMap';
import { fetchGeometries, zoomToGeometriesThunk } from '../../common/geometries/geometriesSlice';
import { SEARCHED } from '../../common/geometries/geometriesConstants';
import { formatRealEstateId } from '../RealEstateHelpers';
import shareGroupNameComparison from '../../Apartment/shareGroupNameComparison';
import { apartmentType, sortByAddress } from '../../Apartment/ApartmentHelpers';
import { PAGE_UNIT_COUNT } from '../../RealtyConstants';

const searchRealestatesSlice = createSlice({
  name: 'searchRealestates',
  initialState: {
    searchRealEstatesInProgress: false,
    searchHousingCompaniesInProgress: false,
    apartments: [],
    realEstates: [],
    searchRealEstatesLoaded: false,
    searchHousingCompaniesLoaded: false,
    onClickError: false,
    showAllApartments: true,
    apartmentPaginationLength: undefined,
  },
  reducers: {
    searchRealEstatesInProgress(state, action) {
      state.searchRealEstatesInProgress = action.payload;
    },
    searchRealEstatesResultsCreated(state, action) {
      state.realEstates = [...action.payload, ...state.realEstates];
      state.searchRealEstatesLoaded = true;
    },
    searchRealEstatesOnClickErrorToggled(state, action) {
      state.onClickError = action.payload;
    },
    searchRealEstatesResultsCleared(state) {
      state.realEstates = [];
    },
    searchHousingCompaniesInProgress(state, action) {
      state.searchHousingCompaniesInProgress = action.payload;
    },
    searchHousingCompaniesResultsCreated(state, action) {
      state.apartments = action.payload.sort(
        firstBy(apartmentType)
          .thenBy(a => (a.address ? sortByAddress(a.address) : ''))
          .thenBy(shareGroupNameComparison)
      );
      state.searchHousingCompaniesLoaded = true;
    },
    searchHousingCompaniesResultsCleared(state) {
      state.apartments = [];
    },
    showAllApartmentsToggled(state, action) {
      state.showAllApartments = action.payload;
    },
    apartmentPaginationLengthUpdated(state, action) {
      state.apartmentPaginationLength = action.payload;
    },
    resetSearchedApartmentListPagination(state) {
      state.apartmentPaginationLength = undefined;
      state.showAllApartments = true;
    },
  },
});

export const {
  searchRealEstatesInProgress,
  searchRealEstatesResultsCreated,
  searchRealEstatesOnClickErrorToggled,
  searchRealEstatesResultsCleared,
  searchHousingCompaniesInProgress,
  searchHousingCompaniesResultsCreated,
  searchHousingCompaniesResultsCleared,
  showAllApartmentsToggled,
  apartmentPaginationLengthUpdated,
  resetSearchedApartmentListPagination,
} = searchRealestatesSlice.actions;

const navigateIfNecessary = (navigate, currentPath) => {
  if (currentPath.split('/').length > 3) {
    navigate(getRealEstateRoute('', SERVICES_FETCH_REAL_ESTATES));
  }
};

const handleRealEstateSearch = async (dispatch, fetcher, registerStateDate) => {
  try {
    dispatch(searchRealEstatesInProgress(true));
    const realties = await fetcher();
    if (realties != null && realties.length > 0) {
      dispatch(setRegisterState(realties[0].registerStateDate, registerStateDate));
      await dispatch(fetchGeometries(realties, SEARCHED));
      dispatch(searchRealEstatesResultsCreated(realties));
      dispatch(zoomToGeometriesThunk(SEARCHED, realties[0].id));
    }
  } finally {
    dispatch(searchRealEstatesInProgress(false));
  }
};

export const searchRealEstatesThunk = (identifierInput, navigate, currentPath) => async (dispatch, getState) => {
  const identifier = formatRealEstateId(identifierInput);
  const isAlreadySearched = getState()
    .searchRealEstates.realEstates.map(({ id }) => id)
    .includes(identifier);

  if (!isAlreadySearched) {
    navigateIfNecessary(navigate, currentPath);
    const fetchRealEstate = () => asyncValidateRealEstateSearch(identifier);
    await handleRealEstateSearch(dispatch, fetchRealEstate, getState().realEstateList?.registerStateDate);
  } else {
    navigate(getRealEstateRoute(identifier, SERVICES_FETCH_REAL_ESTATES));
  }
};

export const searchRealEstateFromMapClickThunk = (lat, lon, registerStateDate) => async dispatch => {
  const fetchRealEstate = async () => {
    const registerUnit = await ServicesApi.fetchRegisterUnitForPoint(lat, lon);
    if (registerUnit != null) {
      dispatch(setRegisterState(registerUnit.registerStateDate, registerStateDate));
      return [registerUnit];
    }
    return null;
  };
  await handleRealEstateSearch(dispatch, fetchRealEstate);
};

const handleHousingCompanySearch = async (dispatch, state, fetcher) => {
  try {
    dispatch(searchHousingCompaniesInProgress(true));
    const apartments = await fetcher();
    if (apartments != null) {
      if (apartments.length > PAGE_UNIT_COUNT) {
        dispatch(showAllApartmentsToggled(false));
        if (!state.searchRealEstates.apartmentPaginationLength) {
          dispatch(apartmentPaginationLengthUpdated(PAGE_UNIT_COUNT));
        }
        if (state.searchRealEstates.apartmentPaginationLength >= apartments.length) {
          dispatch(showAllApartmentsToggled(true));
        }
      } else {
        dispatch(showAllApartmentsToggled(true));
      }

      OskariMap.clearMapApartmentsLayer();
      try {
        await dispatch(fetchGeometries([apartments[0]], SEARCHED, true));
        dispatch(searchHousingCompaniesResultsCreated(apartments));
        dispatch(zoomToGeometriesThunk(SEARCHED, null));
      } catch (geometryError) {
        dispatch(searchHousingCompaniesResultsCreated(apartments));
        console.error('fetching housing company geometries failed');
      }
    }
  } finally {
    dispatch(searchHousingCompaniesInProgress(false));
  }
};

export const searchHousingCompaniesThunk = (identifier, navigate, currentPath) => async (dispatch, getState) => {
  const isAlreadySearched = getState()
    .searchRealEstates.apartments.map(({ companyId }) => companyId)
    .includes(identifier);
  if (!isAlreadySearched) {
    navigateIfNecessary(navigate, currentPath);
    const fetchHousingCompany = () => asyncValidateHousingCompanySearch(identifier);
    await handleHousingCompanySearch(dispatch, getState(), fetchHousingCompany);
  }
};

export default searchRealestatesSlice.reducer;
