import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

const AutomaticSkipComponent = ({ selector = ACTIVE_MENU_ITEM }) => {
  const dispatch = useDispatch();
  const onFocus = event => {
    if (event.relatedTarget == null) {
      dispatch(requestKeyboardFocusAction({ selector }));
    }
  };
  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return <div tabIndex="0" onFocus={onFocus} />;
};

AutomaticSkipComponent.propTypes = {
  selector: PropTypes.string,
};

export default AutomaticSkipComponent;
