import React, { useState, useEffect, useContext, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { SearchBar, Uploader, FormWrapper } from 'common/components';
import { Glyphicon } from 'common/components/Icon';
import {
  addUploadedFile,
  setUploadComplete,
  setUploadPending,
} from 'realEstateSite/containers/Forms/FormHelpers/FormHelpers';
import INPUT_ERROR from 'common/constants/InputValidationConstants';
import { fieldHasErrorOnBlur } from 'common/containers/Forms/InputValidation/inputValidationHelpers';
import DatePicker from 'common/components/DatePicker';
import PortionInput from 'common/containers/Forms/Components/PortionInput';
import { searchRealEstatesOnClickErrorToggled } from 'realEstateSite/containers/Realty/RealEstate/Search/searchRealEstatesSlice';
import { ErrorMessage } from 'common/components/Form/components/Error';

/* eslint-disable react/prop-types */
export const ValidatedSearchBar = React.forwardRef(
  (
    {
      id,
      'aria-describedby': ariaDescribedby,
      title,
      search,
      reset,
      noSearchOnBlur,
      meta,
      input: { onBlur, onChange, value, name },
      required,
      maxLength,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const errorId = useId();
    const dispatch = useDispatch();
    const onClickError = useSelector(state => state.searchRealEstates.onClickError);

    const isError = () => !isSubmitting && meta.touched && !!meta.error;

    useEffect(() => {
      let mounted = true;
      async function asyncSearch(val) {
        onBlur();
        await search(val);
        if (mounted) {
          setIsSubmitting(false);
        }
      }
      if (isSubmitting) {
        dispatch(searchRealEstatesOnClickErrorToggled(false));
        asyncSearch(value);
      }
      return () => {
        mounted = false;
        dispatch(searchRealEstatesOnClickErrorToggled(false));
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting]);

    const onSubmitFN = e => {
      e.preventDefault();
      setIsSubmitting(true);
    };

    return (
      <>
        {onClickError && <ErrorMessage message={t('error.general.notResponding')} />}
        {!isSubmitting && <ErrorBox meta={meta} elementId={errorId} />}
        <SearchBar
          {...{ ref, id, ariaDescribedby, title, onChange, value, name, noSearchOnBlur, required, maxLength }}
          onReset={(...args) => {
            if (reset) reset(...args);
            else onChange('');
          }}
          onSubmit={onSubmitFN}
          error={isError()}
          ariaDescribedby={isError() ? errorId : null}
        />
      </>
    );
  }
);

export const ValidatedPortionInput = React.forwardRef(
  (
    {
      id,
      label,
      meta,
      input,
      required,
      isFirst,
      numeratorMaxLength,
      denominatorMaxLength,
      setShouldValidateTotalSumOfPortions,
    },
    ref
  ) => {
    const errorId = useId();

    const isError = () =>
      meta?.error &&
      ((isFirst && meta.modified) ||
        (!isFirst && (meta.touched || (meta.submitFailed && meta.touched) || (meta.dirty && meta.modified))));

    return (
      <>
        {isError() && <ErrorBox elementId={errorId} meta={meta} ignoreTouch />}
        <PortionInput
          {...{
            ref,
            id,
            label,
            meta,
            input,
            required,
            isFirst,
            numeratorMaxLength,
            denominatorMaxLength,
            setShouldValidateTotalSumOfPortions,
          }}
          ariaDescribedby={isError() ? errorId : null}
        />
      </>
    );
  }
);

export const ValidatedUploader = React.forwardRef(
  (
    {
      id,
      accept,
      errorText,
      attachmentType,
      input,
      formId,
      ariaDescribedby,
      required,
      disabled,
      meta,
      withTypeSelector,
      fileRequired,
      isOrwa,
    },
    ref
  ) => {
    const { sendA11yNotification, clearA11yNotification, setFileUploads } = useContext(FormWrapper.FormContext);
    const errorId = useId();
    const isError = meta?.error && meta?.touched;

    return (
      <>
        <ErrorBox elementId={errorId} meta={meta} />
        <Uploader
          {...{ id, ariaDescribedby, required, ...input }}
          onBeforeSend={(data, settings, upload) => setUploadPending(upload.id, setFileUploads)}
          onDone={data => setUploadComplete(data.id, setFileUploads)}
          onDoneFinalForm={addUploadedFile(input)}
          onUploadError={fileId => setUploadComplete(fileId, setFileUploads)}
          onRemove={index => input.onChange(input.value.filter((upload, i) => i !== index))}
          formId={formId}
          ref={ref}
          uploadedFiles={input.value}
          isError={isError}
          type={attachmentType}
          accept={accept}
          required={required}
          disabled={disabled}
          withTypeSelector={withTypeSelector}
          errorText={errorText}
          sendA11yNotification={sendA11yNotification}
          clearA11yNotification={clearA11yNotification}
          errorId={isError ? errorId : null}
          fileRequired={fileRequired}
          isOrwa={isOrwa}
        />
      </>
    );
  }
);

export const ValidatedDatePicker = React.forwardRef((field, ref) => {
  const errorId = useId();
  const { meta } = field;
  return (
    <>
      <ErrorBox meta={meta} elementId={errorId} />
      <DatePicker
        {...field}
        ref={ref}
        isError={meta?.touched && !!meta?.error}
        ariaDescribedby={errorId}
        language={i18next.language}
      />
    </>
  );
});

/* eslint-disable react/prop-types */
export const ErrorBox = ({ ignoreTouch, className, meta, elementId }) => {
  if ((ignoreTouch ? meta?.error : fieldHasErrorOnBlur(meta)) && meta?.error !== INPUT_ERROR) {
    return (
      <div className={cls('validation-message', 'validation-message--error', 'flex-container', className)}>
        <Glyphicon glyph="huomio" />
        <div id={elementId} className="indent-block-1">
          {meta?.error && i18next.t(meta.error)}
        </div>
      </div>
    );
  }
  return false;
};
/* eslint-enable react/prop-types */
