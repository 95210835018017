import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearKeyboardFocusRequestAction } from './AutomaticKeyboardFocusActions';

const useAutomaticKeyboardFocus = (tentativeClassName, focusHandler, options = {}) => {
  const { delayFocus = false, focusPending = null, ignore = false, clearFocusRequest = false } = options;

  const dispatch = useDispatch();
  const targetClassName = useSelector(state => state.automaticKeyboardFocus?.selector || null);

  useEffect(() => {
    if (
      !ignore &&
      tentativeClassName.includes(targetClassName) &&
      (!delayFocus || (focusPending != null && focusPending === false)) &&
      !window.Cypress
    ) {
      setTimeout(() => {
        focusHandler();
        if (clearFocusRequest) {
          dispatch(clearKeyboardFocusRequestAction());
        }
      }, 500); // We need to wait for the scroller animation to finish.
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tentativeClassName, targetClassName, delayFocus, focusPending, ignore]);
};

export default useAutomaticKeyboardFocus;
