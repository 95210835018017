import React, { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import * as Routes from 'realEstateSite/constants/Routes';

import { POLITE } from 'common/containers/AccessibleNotifications/AccessibleNotificationsConstants';
import { OTHER_ATTACHMENTS_TYPE } from 'common/constants/FieldNames';
import UploadProgress from './UploadProgress';
import UploaderInput from './UploaderInput';
import { ACCEPTED_FILES_JPG_PDF } from './UploaderConstants';
import UploadedFilesList from './UploadedFilesList';
import { UploaderProvider } from './UploaderContext';

const noop = () => null;

const Uploader = ({
  url = Routes.buildServicesSiteRoute(Routes.API_PATH_UPLOADER),
  type = '',
  accept = ACCEPTED_FILES_JPG_PDF,
  disabled = false,
  name = 'file',
  onDone = undefined,
  onDoneFinalForm = undefined,
  onRemove = noop,
  onBeforeSend = noop,
  onUploadError = noop,
  maxSize = 20000000,
  uploadedFiles = [],
  isError = false,
  errorText = 'uploader.defaultFilesError',
  ariaDescribedby = null,
  required = false,
  sendA11yNotification = undefined,
  clearA11yNotification = undefined,
  errorId = null,
  withTypeSelector = false,
  fileRequired = false,
  id,
  formId,
  isOrwa = false,
}) => {
  const [uploadsInProgress, setUploadsInProgress] = useState([]);
  const [doneUploads, setDoneUploads] = useState([]);

  const { change, resetFieldState } = useForm();

  const delayedClearA11yNotifications = () => {
    setTimeout(() => {
      clearA11yNotification(POLITE);
    }, 7000);
  };

  const handleUpload = newDoneUpload => {
    if (newDoneUpload.filename) {
      setDoneUploads(previous => [...previous, newDoneUpload]);
      if (sendA11yNotification != null) {
        sendA11yNotification(t('attachment.added'), POLITE);
        delayedClearA11yNotifications();
      }
    }
  };

  useEffect(() => {
    if (onDoneFinalForm) onDoneFinalForm(doneUploads);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doneUploads]);

  const removeUploadProgressIndicator = upload => {
    setUploadsInProgress(prevUploads => prevUploads.filter(u => u.id !== upload.id));
  };

  const onRemoveFile = (index, filename) => {
    onRemove(index);
    setDoneUploads(prevDoneUploads => prevDoneUploads.filter(u => u.filename !== filename));
    if (sendA11yNotification != null) {
      sendA11yNotification(t('attachment.removed'), POLITE);
      delayedClearA11yNotifications();
    }
  };

  const handleDone = data => {
    const { responseFilename, filename, attachmentType, mimetype } = data;

    if (onDoneFinalForm) {
      handleUpload({ responseFilename, filename, attachmentType, mimetype });
    }

    if (onDone) {
      onDone(data);
      if (withTypeSelector) {
        change(OTHER_ATTACHMENTS_TYPE, '');
        resetFieldState(name);
      }
    }
  };

  return (
    <UploaderProvider>
      {(required || fileRequired) && <span className="visually-hidden">{t('form.required.title')}</span>}
      <UploaderInput
        setUploads={setUploadsInProgress}
        {...{ id, name, ariaDescribedby, accept, disabled, maxSize, isError, errorText, errorId, fileRequired }}
      />
      <UploadProgress
        onDone={handleDone}
        removeUpload={removeUploadProgressIndicator}
        uploads={uploadsInProgress}
        {...{ onBeforeSend, onUploadError, type, formId, url, errorText }}
      />
      <UploadedFilesList onRemove={onRemoveFile} showType={withTypeSelector} {...{ uploadedFiles }} isOrwa={isOrwa} />
    </UploaderProvider>
  );
};

Uploader.propTypes = {
  id: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  type: PropTypes.string,
  url: PropTypes.string,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onBeforeSend: PropTypes.func,
  onDone: PropTypes.func,
  onDoneFinalForm: PropTypes.func,
  onUploadError: PropTypes.func,
  onRemove: PropTypes.func,
  maxSize: PropTypes.number,
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({ responseFilename: PropTypes.string.isRequired, filename: PropTypes.string.isRequired })
  ),
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  required: PropTypes.bool,
  sendA11yNotification: PropTypes.func,
  clearA11yNotification: PropTypes.func,
  errorId: PropTypes.string,
  withTypeSelector: PropTypes.bool,
  fileRequired: PropTypes.bool,
  isOrwa: PropTypes.bool,
};

export default Uploader;
