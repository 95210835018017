import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';

const RouterLink = ({ children, to, className = '', selector = undefined }) => {
  const dispatch = useDispatch();

  const onKeyDown = event => {
    if (event.key === 'Enter' && selector) {
      dispatch(requestKeyboardFocusAction({ selector }));
    }
  };

  return (
    <Link to={to} tabIndex={0} onKeyDown={onKeyDown} className={cls('router-link', className)}>
      {children}
    </Link>
  );
};

RouterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  selector: PropTypes.string,
};

export default RouterLink;
