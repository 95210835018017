import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'i18next';

import { toggleMapBackButtonVisibility } from 'common/containers/OskariMap/OskariMapActions';
import { businessPanelToggleAction } from 'common/containers/BusinessPanel/BusinessPanelActions';
import changeMobileViewAction from 'common/containers/MobileTools/MobileViewActions';
import { MOBILE_MAP_VIEW } from 'common/containers/MobileTools/MobileViewNames';
import { ShowMapButton } from 'common/components/Button/index';

export const MapButtonComponent = ({
  className = '',
  style = {},
  title = undefined,
  withText = false,
  disabled = false,
  tabIndex = 0,
}) => {
  const dispatch = useDispatch();

  const toggleMap = () => {
    dispatch(toggleMapBackButtonVisibility(true));
    dispatch(businessPanelToggleAction(false));
    dispatch(changeMobileViewAction(MOBILE_MAP_VIEW));
  };

  return (
    <ShowMapButton
      className={className}
      style={style}
      title={title || t('button.showOnMap')}
      onClick={toggleMap}
      withText={withText}
      disabled={disabled}
      tabIndex={tabIndex}
    />
  );
};

MapButtonComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  title: PropTypes.string,
  withText: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.number,
};

export default MapButtonComponent;
