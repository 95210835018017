import React from 'react';
import PropTypes from 'prop-types';

const Badge = ({ value = '?' }) => {
  return <span className="nls-badge">{value}</span>;
};

Badge.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Badge;
