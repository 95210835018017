import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { useSelector, useDispatch } from 'react-redux';

import { ExternalLink, VerticalScrollerComponent as VerticalScroller } from 'common/components';
import MainToolbarHeaderComponent from 'common/containers/Sidebar/MainToolbarHeaderComponent';
import { isMobile } from 'common/constants/Layout';
import { getSelectableLayers } from 'oskari/OskariMap';
import LayerControls from './LayerControlsComponent';
import { toggleMapLayerOpacityThunk } from './MapLayerSelectorActions';

const LegendLink = () => (
  <li className="map-layer-selector__legend-link">
    <ExternalLink href={t('mapLayerSelector.mapLegend.link.url')}>
      {t('mapLayerSelector.mapLegend.link.text')}
    </ExternalLink>
  </li>
);

const WrappedWithScroller = ({ isWrapped, children }) =>
  isWrapped ? <VerticalScroller>{children}</VerticalScroller> : children;

WrappedWithScroller.propTypes = {
  isWrapped: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

const MapLayerSelectorComponent = () => {
  const dispatch = useDispatch();
  const allowedLayerIds = getSelectableLayers();

  const layoutMode = useSelector(state => state.layout.mode);
  const mapLayers = useSelector(state => state.mapLayers.filter(layer => allowedLayerIds?.includes(layer.id)));
  const zoomLevel = useSelector(state => state.oskariIframe.zoom);

  const layerOpacityHandler = layerId => opacity => {
    dispatch(toggleMapLayerOpacityThunk(layerId, opacity));
  };

  return (
    <>
      <div className="main-toolbar-block">
        <MainToolbarHeaderComponent title={t('mapLayerSelector.title')} />
      </div>
      <WrappedWithScroller isWrapped={isMobile(layoutMode)}>
        <ul className="layer-controls__ul">
          {mapLayers.map(({ id, ...layerProps }) => (
            <LayerControls
              id={id}
              key={`lid_${id}`}
              onMapLayerOpacityChange={layerOpacityHandler(id)}
              zoomLevel={zoomLevel}
              {...layerProps}
            />
          ))}
          <LegendLink />
        </ul>
      </WrappedWithScroller>
    </>
  );
};

export default MapLayerSelectorComponent;
