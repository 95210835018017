export const ADDRESSEE = 'addressee';
export const ATTACHMENTS = 'attachments';
export const BUSINESS_ID = 'organizationId';
export const ORGANIZATION_ID_ASYNC_ERRORFIELD = 'organizationIdAsyncError';
export const BUSINESS_NAME = 'organizationName';
export const CITIZENSHIP = 'citizenship';
export const CITY = 'city';
export const CONTACT_PERSON = 'contactPerson';
export const CONTRACT_TYPE = 'contractType';
export const COUNTRY = 'country';
export const DATE_OF_BIRTH = 'dateOfBirth';
export const DESCRIPTION = 'description';
export const EMAIL = 'email';
export const EMAIL_AGAIN = 'emailAgain';
export const CHECKOUT_TERMS = 'checkoutTerms';
export const CHECKOUT_TERMS_LICENCE = 'checkoutTermsLicence';
export const CHECKOUT_TERMS_LASER5P = 'checkoutTermsLaser5p';
export const COMPANY_SEARCH = 'companySearch';
export const FILTER_PARAMETER = 'filterParameter';
export const FIRST_NAME = 'firstName';
export const FOREIGN_BUSINESS_ID = 'foreignBusinessId';
export const FULL_NAME = 'fullName';
export const IS_ENERGY_COMPANY = 'isEnergyCompany';
export const LANGUAGE = 'language';
export const LAST_NAME = 'lastName';
export const ORGANIZATION = 'organization';
export const ORGANIZATION_NAME = 'organizationName';
export const ORGANIZATION_ID = 'organizationId';
export const OTHER_ATTACHMENTS_TYPE = 'otherAttachmentsType';
export const PHONE_NUMBER = 'phoneNumber';
export const PORTION = 'portion';
export const POSTAL_CODE = 'postalCode';
export const SPECIFIER = 'specifier';
export const STREET_ADDRESS = 'streetAddress';
export const TARGET_SEARCH = 'targetSearch';
export const FACILITY_SEARCH = 'facilitySearch';
export const SSN = 'socialSecurityNumber';
export const SSN_ASYNC_ERRORFIELD = 'socialSecurityNumberAsyncError';
export const USE = 'use';

export const CONTACT_INFO = 'contactInfo';
