import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-final-form';
import PropTypes from 'prop-types';

import Modal from 'common/components/Modal';
import { FlexGrid, Row, Block } from 'common/components/Grid';
import { updateIsModalApproved } from 'realEstateSite/containers/Realty/RealEstate/OtherRegistration/OSRA/OSRAActions';
import { Paragraph } from '../index';

const FormModal = ({ closeModal, setOpenModal, form, submitPage }) => {
  const { t } = useTranslation();
  const { getState } = useForm();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setOpenModal(false);
    await new Promise(resolve => {
      dispatch(updateIsModalApproved(true));
      resolve();
    });
    submitPage(getState().values, form);
  };

  return (
    <Modal isOpen closeHandler={closeModal} title={t('osra.step3.modal.title')}>
      <FlexGrid className="text-center">
        <Row className="margin-t-2">
          <Block size="6">
            <Paragraph className="margin-t-1">{t('osra.step3.modal.description')}</Paragraph>
          </Block>
        </Row>
        <Row className="margin-t-2 margin-b-3">
          <Block size="6">
            <button type="button" className="button button--light" onClick={closeModal}>
              {t('osra.step3.modal.button.no')}
            </button>

            <button type="submit" className="button button--orange indent-block-2" onClick={handleSubmit}>
              {t('osra.step3.modal.button.yes')}
            </button>
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  );
};

FormModal.propTypes = {
  closeModal: PropTypes.func,
  setOpenModal: PropTypes.func,
  form: PropTypes.shape({}),
  submitPage: PropTypes.func,
};

export default FormModal;
