import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components';

const Chip = ({ label, remove = null }) => {
  const { t } = useTranslation();

  return (
    <span className="nls-chip">
      <span className="nls-chip-label" title={label}>
        {label}
      </span>
      {remove && (
        <button
          type="button"
          aria-label={`${t('button.remove')} ${label}`}
          className="nls-chip-button"
          onClick={() => remove(label)}
        >
          <Glyphicon glyph="times" />
        </button>
      )}
    </span>
  );
};

Chip.propTypes = {
  label: PropTypes.string,
  remove: PropTypes.func,
};

export default Chip;
