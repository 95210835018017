import React, { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';

import * as Routes from 'common/constants/Routes';
import MapSiteIcons from 'mapSite/components/MapSiteIcons/MapsiteIcons';
import MobileTopButtonsComponent from 'common/containers/MobileTools/MobileTopButtonsComponent';
import SearchMobileComponent from 'common/containers/SidebarSearch/SearchMobileComponent';
import MobileMainView from 'common/containers/MobileTools/MobileMainViewComponent';
import AccessibleNotificationsComponent from 'common/containers/AccessibleNotifications/AccessibleNotificationsComponent';
import CookieNotice from 'common/components/CookieNotice';
import SessionModal from 'common/components/SessionModal/SessionModal';
import * as MobileViewNames from 'common/containers/MobileTools/MobileViewNames';
import { Root, MobileTop, MobileBottom, DesktopLeft as Left, DesktopRight as Right, MapView } from 'common/components';
import { isMobile as isMobileMode, isDesktop as isDesktopMode } from 'common/constants/Layout';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { AutomaticSkipComponent } from 'common/components/SkipLink/index';
import { OSKARI_CHANNEL_STATUS_IDS } from 'oskari/OskariMap';
import { SERVICES_APPLICATION_REQUEST_PRINT } from 'realEstateSite/constants/Routes';
import { logoutThunk } from 'common/helpers/Authentication/authenticationSlice';
import MapLayerSelectorComponent from '../MapLayerSelector/MapLayerSelectorComponent';
import ShareComponent from '../Share/ShareComponent';
import MobileBottomToolbarComponent from '../MobileBottomToolbar/MobileBottomToolbarComponent';
import MapToolbarComponent from '../MapToolbar/MapToolbarComponent';
import ServiceClosedModal from '../ServiceClosedModal/ServiceClosedModal';
import DesktopMapControlsComponent from '../DesktopMapControls/DesktopMapControlsComponent';
import BusinessPanelControlsComponent from '../BusinessPanel/BusinessPanelControlsComponent';
import SidebarComponent from '../Sidebar/SidebarComponent';
import OskariMap from '../OskariMap/OskariMapComponent';
import LayoutErrorBoundary from './LayoutErrorBoundary';
import MainContentErrorBoundary from './MainContentErrorBoundary';
import SidebarErrorBoundary from './SidebarErrorBoundary';
import MapFeatureToggleComponent from '../MapFeatureToggle/MapFeatureToggleComponent';
import LayoutVeilComponent from './LayoutVeilComponent';

const LayoutComponent = ({ children }) => {
  const dispatch = useDispatch();
  const { mode, desktop, mobile } = useSelector(state => state.layout);
  const session = useSelector(state => state.layout.session);
  const showCurtain = useSelector(state => state.layout.showCurtain);
  const applicationContext = useSelector(state => state.application.applicationContext);
  const shoppingCartItems = useSelector(state => state.shoppingCart?.items?.length || 0);
  const oskariChannelStatus = useSelector(state => state.layout.oskariChannelStatus);

  const isMobile = isMobileMode(mode);
  const isDesktop = isDesktopMode(mode);
  const isMapSite = applicationContext === 'mapSite';
  const startingChannel = oskariChannelStatus === OSKARI_CHANNEL_STATUS_IDS.startingChannel;
  const channelReady = oskariChannelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady;
  const location = useLocation();
  const navigate = useNavigate();
  const isDeskTopMapPrintPage = location.pathname.split('/').pop() === Routes.MAP_PRINT_PAGE;
  const isSupplementRequestPrint = location.pathname.split('/').pop() === SERVICES_APPLICATION_REQUEST_PRINT;

  const isHidden = ({ visibleView, hiddenView }) => {
    if (isDesktop) return true;
    if (visibleView) return mobile.view !== visibleView;
    return mobile.view === hiddenView;
  };

  const goToShoppingCart = useCallback(() => {
    navigate(Routes.getRoute(Routes.SHOPPING_CART));
  }, [navigate]);

  const rootProps = {
    mode,
    desktop,
    mobile,
    className: cls(
      `sidebar-${desktop.sidebar.isOpen ? 'open' : 'closed'}`,
      `business-panel-${desktop.businessPanel.isOpen ? 'open' : 'closed'}`,
      {
        desktop: isDesktop,
        mobile: isMobile,
        [desktop.view]: isDesktop,
      }
    ),
  };

  if (isDeskTopMapPrintPage) return <div className="printmap-page__body-wrapper">{children}</div>;
  if (isSupplementRequestPrint) return <div className="supplement-request-print">{children}</div>;

  return (
    <LayoutErrorBoundary>
      <Root {...rootProps}>
        {isMobile && (
          <MobileTop>
            <MobileTopButtonsComponent />
          </MobileTop>
        )}

        {isDesktop && (
          // Elements before MapView
          <SidebarErrorBoundary>
            {!isMapSite && <AutomaticSkipComponent selector={ACTIVE_MENU_ITEM} />}
            {channelReady && (
              <Left
                className={cls('sidebar', {
                  open: desktop.sidebar.isOpen,
                  closed: !desktop.sidebar.isOpen,
                })}
                id="leftSidebar"
              >
                <SidebarComponent />
              </Left>
            )}
          </SidebarErrorBoundary>
        )}
        {/* Map View.
         * This must be shared between layout modes or OskariMap JSChannel will fail
         * when layout is changed. Can be reinitiated but doesn't work in IE
         */}
        <MapView>
          <OskariMap />
          {isDesktop && <ShareComponent />}
          {isDesktop && <CookieNotice />}
          {isDesktop && session && (
            <SessionModal
              logout={() => dispatch(logoutThunk())}
              shoppingCartItems={shoppingCartItems}
              goToShoppingCart={goToShoppingCart}
            />
          )}
          {isDesktop && <ServiceClosedModal />}
        </MapView>
        {/* Elements after MapView */}
        {isDesktop && (
          <>
            {channelReady && <DesktopMapControlsComponent />}
            <Right
              className={cls('business-panel__wrapper', 'mobile-main-view', {
                open: desktop.businessPanel.isOpen,
                closed: !desktop.businessPanel.isOpen,
              })}
            >
              <BusinessPanelControlsComponent>{isMapSite && <MapSiteIcons />}</BusinessPanelControlsComponent>
              {/*
               * Desktop Page content
               */}
              <MainContentErrorBoundary>{children}</MainContentErrorBoundary>
            </Right>
          </>
        )}

        {/* MOBILE MAIN VIEWS */}
        {isMobile && (
          <MobileMainView
            className={MobileViewNames.MOBILE_MAP_VIEW}
            isHidden={isHidden({ visibleView: MobileViewNames.MOBILE_MAP_VIEW })}
          >
            {/* Needs an empty child for reasons now forgotten. */}
            <div />
          </MobileMainView>
        )}
        {isMobile && (
          <MobileMainView isHidden={isHidden({ visibleView: MobileViewNames.MOBILE_TOOLS_VIEW })}>
            <MapToolbarComponent />
          </MobileMainView>
        )}
        {isMobile && (
          <MobileMainView
            className={MobileViewNames.MOBILE_MAP_LAYERS_VIEW}
            isHidden={isHidden({ visibleView: MobileViewNames.MOBILE_MAP_LAYERS_VIEW })}
          >
            <MapLayerSelectorComponent />
          </MobileMainView>
        )}
        {isMobile && (
          <MobileMainView
            className={MobileViewNames.MOBILE_SEARCH_VIEW}
            isHidden={isHidden({ visibleView: MobileViewNames.MOBILE_SEARCH_VIEW })}
          >
            <SearchMobileComponent />
          </MobileMainView>
        )}
        {isMobile && (
          <MobileMainView
            className={MobileViewNames.MOBILE_FEATURES_VIEW}
            isHidden={isHidden({ visibleView: MobileViewNames.MOBILE_FEATURES_VIEW })}
          >
            <MapFeatureToggleComponent />
          </MobileMainView>
        )}
        {/*
         * Mobile Page content
         */}
        {isMobile && <MainContentErrorBoundary isMobile>{children}</MainContentErrorBoundary>}
        {isMobile && (
          <MobileBottom>
            <MobileBottomToolbarComponent
              hidden={isHidden({ hiddenView: MobileViewNames.MOBILE_BUSINESS_PANEL_VIEW }) && !showCurtain}
            />
          </MobileBottom>
        )}
        {isMobile && session && (
          <SessionModal
            logout={() => dispatch(logoutThunk())}
            shoppingCartItems={shoppingCartItems}
            goToShoppingCart={goToShoppingCart}
          />
        )}
        {isMobile && <ServiceClosedModal />}
        {isMobile && <CookieNotice />}
        {isMobile && <ShareComponent />}
        <AccessibleNotificationsComponent />
        {startingChannel && <LayoutVeilComponent />}
      </Root>
    </LayoutErrorBoundary>
  );
};

LayoutComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
