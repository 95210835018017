import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { t } from 'i18next';
import { Glyphicon } from 'common/components/Icon';

const CloseButtonComponent = ({ className = '', glyph = 'sulje', onClick = () => true, testId = undefined }) => (
  <button
    aria-label={t('button.close')}
    className={cls('button', 'button--icon', 'button--flexible', className)}
    onClick={onClick}
    type="button"
    title={t('button.close')}
    data-testid={testId}
  >
    <Glyphicon glyph={glyph} alt={t('button.close')} />
  </button>
);

CloseButtonComponent.propTypes = {
  glyph: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  testId: PropTypes.string,
};

export default CloseButtonComponent;
