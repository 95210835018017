import { uniqueId } from 'lodash';
import i18next from 'i18next';

import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { formatDateYMMDD } from 'utils/datetime-utils';
import { OSRA_FORM_ID } from 'realEstateSite/constants/FormIds';
import ServicesApi from 'realEstateSite/api/ServicesApi';
import { SUBMIT_LEASEHOLD_TRANSFER } from 'realEstateSite/containers/Authorization/privileges';
import { addOrUpdateProductThunk } from 'common/containers/ShoppingCart/ShoppingCartActions';
import { formatPrice } from 'common/helpers/moneyHelpers';
import { WHOLE_TARGET } from 'realEstateSite/constants/PortionTypes';
import { fetchTargetRealEstate, storeTargetRealEstate } from '../../CadastralSurveys/common/CadastralSurveyFormActions';
import { removeGeometryFromType, geometryTypeCleared } from '../../../common/geometries/geometriesSlice';
import { OSRA } from '../../../common/geometries/geometriesConstants';
import { OSRA_FIELDS, USAGE_CODE, LOGGING_CODE, SOIL_CODE, OSRA_FIXED_TERM_DURATION_CODE } from './OSRAConstants';
import { formatLCAParty, formattedLCACommissioner } from '../../LegalConfirmation/common/LCAGenericFormatter';
import { formatAttachments } from '../../../../../helpers/applicationHelpers';

const {
  OTHER_ATTACHMENTS,
  TARGETS,
  OSRA_ATTACHMENTS,
  FIXED_TERM_ENDING_DATE,
  TRANSFER_RIGHT,
  TRANSFER_RIGHT_CHOICE,
  PREMISE_DATE,
  BUILDING_IN_AREA,
  ALLOWED_TO_TRANSFER,
  PREMISE,
  STARTING_DATE,
  DURATION,
  FOREST_USUFRUCT_CHOICE,
  SOIL_CHOICE,
} = OSRA_FIELDS;

export const SET_OSRA_FORM_ABORT_PATH = 'SET_OSRA_FORM_ABORT_PATH';
export const ADD_OSRA_TARGET_REAL_ESTATE = 'ADD_OSRA_TARGET_REAL_ESTATE';
export const RESET_OSRA = 'RESET_OSRA';
export const REMOVE_OSRA_TARGET_REAL_ESTATE = 'REMOVE_OSRA_TARGET_REAL_ESTATE';
export const SET_OSRA_ORDER_PRICE = 'SET_OSRA_ORDER_PRICE';
export const UPDATE_IS_SPLIT_APPLICATION = 'UPDATE_IS_SPLIT_APPLICATION';
export const UPDATE_IS_MODAL_APPROVED = 'UPDATE_IS_MODAL_APPROVED';

export const addOSRAToCart = ({ values, price, id, targetRealEstates, osraBody }) => {
  const augmentedTargets = values.targets.map(target => {
    return {
      ...target,
      ...targetRealEstates.find(e => e.id === target.id),
    };
  });
  return dispatch => {
    dispatch(
      addOrUpdateProductThunk(
        `${uniqueId('application')}-${new Date().getTime()}`,
        {
          ...values,
          targets: augmentedTargets,
          productId: id,
          productType: 'application',
          applicationProduct: {
            applicationType: OSRA_FORM_ID,
            applicationData: JSON.stringify(osraBody),
          },
          name: i18next.t('application.heading.registration'),
          description: i18next.t('osra.subtitle'),
        },
        formatPrice(price),
        1
      )
    );
  };
};

export const setOSRAFormAbortPath = formAbortPath => dispatch => {
  dispatch({ type: SET_OSRA_FORM_ABORT_PATH, formAbortPath });
};

export const addOSRATargetRealEstate = targetRealEstate => ({
  type: ADD_OSRA_TARGET_REAL_ESTATE,
  targetRealEstate,
});

export const fetchOSRATargetRealEstateThunk = (identifier, registerStateDate, searchedRealEstate) => async dispatch => {
  if (searchedRealEstate) {
    dispatch(storeTargetRealEstate(searchedRealEstate, OSRA));
    dispatch(addOSRATargetRealEstate(searchedRealEstate));
    return searchedRealEstate;
  }

  const fetchedRealty = await dispatch(fetchTargetRealEstate(identifier, OSRA, true));
  if (fetchedRealty) dispatch(addOSRATargetRealEstate(fetchedRealty));
  return fetchedRealty;
};

const removeOSRATargetRealEstate = realEstateId => ({
  type: REMOVE_OSRA_TARGET_REAL_ESTATE,
  realEstateId,
});

export const removeOSRAFromTargetRealEstatesThunk = id => dispatch => {
  dispatch(removeOSRATargetRealEstate(id));
  dispatch(removeGeometryFromType(id, OSRA));
};

export const resetOSRA = () => dispatch => {
  dispatch({ type: RESET_OSRA });
  dispatch(geometryTypeCleared(OSRA));
};

export const setOSRAOrderPrice = price => ({ type: SET_OSRA_ORDER_PRICE, price });

const formatOSRARealEstates = ({ targets, contactInfo }) => ({
  realEstateIds: targets.map(({ id }) => id),
  applicants: targets[0].recipients.map((recipient, index) => ({
    party: formatLCAParty(recipient),
    email: index === 0 ? recipient.email || contactInfo.email : null,
    ...((recipient.portion || recipient.targetPortion === WHOLE_TARGET) && { share: recipient.portion || '1/1' }),
  })),
  donors: targets[0].donors.map(donor => ({ party: formatLCAParty(donor) })),
});

const getLegalConfirmationType = ({ right, subRight }) => {
  if (right === USAGE_CODE) return subRight[TRANSFER_RIGHT_CHOICE];
  if (right === LOGGING_CODE) return subRight[FOREST_USUFRUCT_CHOICE];
  if (right === SOIL_CODE) return subRight[SOIL_CHOICE];
  return right;
};

const composeOSRABody = values => ({
  legalConfirmationType: getLegalConfirmationType({
    right: values[TRANSFER_RIGHT],
    subRight: {
      [TRANSFER_RIGHT_CHOICE]: values[TRANSFER_RIGHT_CHOICE],
      [FOREST_USUFRUCT_CHOICE]: values[FOREST_USUFRUCT_CHOICE],
      [SOIL_CHOICE]: values[SOIL_CHOICE],
    },
  }),
  verificationDate: formatDateYMMDD(values[PREMISE_DATE]),
  specialRight: formatOSRARealEstates({ targets: values[TARGETS], contactInfo: values.contactInfo }),
  attachments: [
    ...formatAttachments(values[OSRA_ATTACHMENTS], formatDateYMMDD(values[PREMISE_DATE])),
    ...formatAttachments(values[OTHER_ATTACHMENTS]),
  ],
  lang: i18next.language,
  commissioner: formattedLCACommissioner(values, false),
  source: 'SA',
  validityType: values[DURATION],
  validityStarts: formatDateYMMDD(values[STARTING_DATE]),
  validityEnds:
    values[DURATION] === OSRA_FIXED_TERM_DURATION_CODE ? formatDateYMMDD(values[FIXED_TERM_ENDING_DATE]) : undefined,
  buildingRight: values[BUILDING_IN_AREA] ? 'true' : 'false',
  transferRight: values[ALLOWED_TO_TRANSFER] ? 'true' : 'false',
  acquisitionType: values[PREMISE],
});

const validateOSRAForm = values => async () => {
  try {
    const body = composeOSRABody(values);
    await ServicesApi.validateSpecialRight(body);
    return body;
  } catch (e) {
    console.error(`Leasehold registration application (LRA) failed: `, e);
    throw OSRA_FORM_ID;
  }
};

const noopPromise = () => Promise.resolve();

export const validateOSRA = values => dispatch =>
  isAuthorized([SUBMIT_LEASEHOLD_TRANSFER]) ? dispatch(validateOSRAForm(values)) : dispatch(noopPromise);

export const updateIsSplitApplication = isSplitApplication => ({
  type: UPDATE_IS_SPLIT_APPLICATION,
  isSplitApplication,
});

export const updateIsModalApproved = isModalApproved => ({
  type: UPDATE_IS_MODAL_APPROVED,
  isModalApproved,
});
