import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  ScrollerPanelList,
  ScrollerPanelListItem,
  ScrollerColumn,
  Block,
  ExternalLink,
  FocusableHeading,
  HeadingWithIcon,
  Paragraph,
  Row,
} from 'common/components';
import {
  clearMapLayersThunk,
  fetchProductPropertiesThunk,
  removeBboxOfFinlandThunk,
} from 'common/containers/FileDownloadService/FileDownloadServiceAction';
import PRODUCT_LIST, { SpatialDataType } from 'common/containers/FileDownloadService/ProductList';
import { getLocalizedName } from 'common/containers/FileDownloadService/FileDownloadServiceHelpers';
import { ROUTE_MAP_SITE_ID } from 'common/constants/Routes';
import { START_PHASE_CHANNEL_READY } from 'common/containers/KasiAppMode/KasiAppModes';
import { closeSidePanel } from 'common/containers/KasiApp/KasiAppActions';
import disableAllMapToolsThunk from 'common/containers/MapToolbar/DisableMapToolActions';
import Loader from 'realEstateSite/containers/Realty/RealEstate/LoaderComponent';
import { trackLicencedDataFilesSelect } from 'common/helpers/Analytics/Analytics_eventmap';
import { GEOSPATIAL_DATA_TITLE } from '../AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import useFileDownloadServiceLayer from './useFileDownloadServiceLayer';

const sortedProductList = () =>
  sortBy(
    PRODUCT_LIST.filter(product => !product.hidden),
    getLocalizedName
  );

const ProductListItem = ({ product, onClick = () => null }) => (
  <ScrollerPanelListItem linkUrl={product.id} key={product.id} selector={`${product.id}-title`} frame onClick={onClick}>
    <div className="panel-list-item-content__title margin-t-1 margin-b-1 indent-block-2">
      {getLocalizedName(product)}
    </div>
  </ScrollerPanelListItem>
);

ProductListItem.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
};

const FileDownloadServiceComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mapReady = useSelector(state => state.appMode.startPhase >= START_PHASE_CHANNEL_READY);
  const { pathname } = useLocation();

  const columnNumber = pathname.includes(ROUTE_MAP_SITE_ID) ? 2 : 1;

  useFileDownloadServiceLayer();

  useEffect(() => {
    dispatch(fetchProductPropertiesThunk());
  }, [dispatch]);

  useEffect(() => {
    if (mapReady) {
      dispatch(disableAllMapToolsThunk(true));
      dispatch(closeSidePanel());
    }

    return () => {
      dispatch(disableAllMapToolsThunk(false));
      dispatch(removeBboxOfFinlandThunk());
      dispatch(clearMapLayersThunk());
    };
  }, [dispatch, mapReady]);

  return (
    <ScrollerColumn columnNumber={columnNumber}>
      {!mapReady ? (
        <Loader />
      ) : (
        <div className="flex-grid-container form__container-bg">
          <FocusableHeading level="h1" className={GEOSPATIAL_DATA_TITLE}>
            {t('fileDownloadService.title')}
          </FocusableHeading>
          <Row>
            <Block>
              <Paragraph>
                {t('fileDownloadService.info1.title')}{' '}
                <ExternalLink
                  href={t('fileDownloadService.info1.link.url')}
                  text={t('fileDownloadService.info1.link.title')}
                />
              </Paragraph>
              <Paragraph>
                {t('fileDownloadService.info2.1')}{' '}
                <ExternalLink
                  href={t('fileDownloadService.info2.link.url')}
                  text={t('fileDownloadService.info2.link.title')}
                />{' '}
                {t('fileDownloadService.info2.2')}
              </Paragraph>
              <Paragraph>
                {t('fileDownloadService.info4.1')}{' '}
                <ExternalLink
                  href={t('fileDownloadService.info4.link.url')}
                  text={t('fileDownloadService.info4.link.title')}
                />{' '}
                {t('fileDownloadService.info4.2')}
              </Paragraph>
            </Block>
          </Row>
          <Row>
            <Block size={6}>
              <div className="indent-block-3">
                <HeadingWithIcon level="h2" visualLevel="h3" glyph="karttapaikka-rasteri" noRuler>
                  {t('fileDownloadService.productList.raster')}
                </HeadingWithIcon>
              </div>
              <ScrollerPanelList odd>
                {sortedProductList()
                  .filter(product => product.category === SpatialDataType.RASTER)
                  .map(product => (
                    <ProductListItem product={product} key={product.id} />
                  ))}
              </ScrollerPanelList>
              <div className="indent-block-3">
                <HeadingWithIcon level="h2" visualLevel="h3" glyph="karttapaikka-vektori" noRuler>
                  {t('fileDownloadService.productList.vector')}
                </HeadingWithIcon>
              </div>
              <ScrollerPanelList odd>
                {sortedProductList()
                  .filter(product => product.category === SpatialDataType.VECTOR)
                  .map(product => (
                    <ProductListItem product={product} key={product.id} />
                  ))}
              </ScrollerPanelList>
              <>
                <div className="indent-block-3 margin-b-2">
                  <HeadingWithIcon level="h2" visualLevel="h3" glyph="karttapaikka-kayttorajoitettu" noRuler>
                    {t('fileDownloadService.productList.restricted')}
                  </HeadingWithIcon>
                  <Paragraph>{t('fileDownloadService.info5.1')}</Paragraph>
                  <Paragraph>{t('fileDownloadService.info5.2')}</Paragraph>
                </div>
                <ScrollerPanelList odd>
                  <ProductListItem
                    product={{ id: 'laserkeilausaineisto_5p' }}
                    key="laserkeilausaineisto_5p"
                    onClick={trackLicencedDataFilesSelect}
                  />
                </ScrollerPanelList>
              </>
            </Block>
          </Row>
        </div>
      )}
    </ScrollerColumn>
  );
};

export default FileDownloadServiceComponent;
