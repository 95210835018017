import React from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';

const duration = 1000;

const defaultStyle = {
  transition: `all ${duration}ms ease-in`,
  opacity: 0,
};

const transitionStyles = {
  entered: { opacity: 1 },
};

const CartBubbleTransition = ({ trigger = false, children }) => (
  <Transition in={trigger} timeout={duration} mountOnEnter unmountOnExit>
    {state => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

CartBubbleTransition.propTypes = {
  trigger: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CartBubbleTransition;
