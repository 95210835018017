import React from 'react';
import i18next from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Slider from 'rc-slider';

import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import * as Routes from 'common/constants/Routes';
import { SCREENSHOT_STORAGE_KEY } from 'common/constants/LocalStorageKeys';
import idb from 'common/helpers/idb';
import {
  enableIframeEvents,
  getScreenshotThunk,
  toggleCoordinateToolThunk,
  trackUserLocationThunk,
  zoomToThunk,
} from 'common/containers/OskariMap/OskariMapActions';

async function saveScreenshotAndPrint(data) {
  (await idb.print)
    .put('printmap', data, SCREENSHOT_STORAGE_KEY)
    .then(() => {
      sessionStorage.setItem('lang', i18next.language);
      window.open(Routes.getRoute(Routes.MAP_PRINT_PAGE));
    })
    .catch(err => {
      console.error('error: ', err);
    });
}

const btnClasses = 'button button--icon button--dark';

const DesktopMapControlsComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const trackingUser = useSelector(state => state.oskariIframe.trackingUser);
  const zoomLevel = useSelector(state => state.oskariIframe.coords.zoom);

  const trackUserLocation = () => {
    dispatch(trackUserLocationThunk(!trackingUser));
  };

  const handleCoordinateToolClick = () => {
    dispatch(toggleCoordinateToolThunk());
  };

  const handleZoomOut = () => {
    let parsedZoomLevel = parseInt(zoomLevel, 10);
    parsedZoomLevel = parsedZoomLevel > 0 ? parsedZoomLevel - 1 : parsedZoomLevel;
    dispatch(zoomToThunk(parsedZoomLevel));
  };

  const handleZoomIn = () => {
    let parsedZoomLevel = parseInt(zoomLevel, 10);
    parsedZoomLevel = parsedZoomLevel < 13 ? parsedZoomLevel + 1 : parsedZoomLevel;
    dispatch(zoomToThunk(parsedZoomLevel));
  };

  const handlePrint = () => {
    dispatch(getScreenshotThunk(saveScreenshotAndPrint));
  };

  const handleSliderChange = zoomToLevel => {
    dispatch(zoomToThunk(zoomToLevel));
    dispatch(enableIframeEvents(false));
  };

  const handleSliderAfterChange = () => {
    dispatch(enableIframeEvents(true));
  };

  const sliderProps = {
    min: 0,
    max: 13,
    vertical: true,
    value: zoomLevel,
    onChange: handleSliderChange,
    onAfterChange: handleSliderAfterChange,
    tipFormatter: null,
  };

  const currentLocationTooltip = t('map.controls.automaticTracking');
  const zoomInTooltip = t('map.controls.zoomin.tooltip');
  const zoomOutTooltip = t('map.controls.zoomout.tooltip');
  const coordinatesTooltip = t('map.controls.coordinates.tooltip');
  const printMapTooltip = t('map.controls.print.tooltip');
  const handleTooltip = t('map.controls.handle.tooltip');

  return (
    <div className="desktop-map-controls">
      <div className="controls-align">
        <button
          type="button"
          title={printMapTooltip}
          onClick={handlePrint}
          className={btnClasses}
          aria-label={printMapTooltip}
        >
          <Glyphicon glyph="tulosta" alt={printMapTooltip} />
        </button>
      </div>
      <div className="map-zoom-controls controls-align">
        <button
          type="button"
          title={zoomInTooltip}
          className={btnClasses}
          onClick={handleZoomIn}
          aria-label={zoomInTooltip}
        >
          <Glyphicon glyph="plus-pallo" alt={zoomInTooltip} />
        </button>
        <Slider className="zoom-slider" ariaLabelForHandle={handleTooltip} {...sliderProps} />
        <button
          type="button"
          title={zoomOutTooltip}
          onClick={handleZoomOut}
          className={btnClasses}
          aria-label={zoomOutTooltip}
        >
          <Glyphicon glyph="miinus" alt={zoomOutTooltip} />
        </button>
        <button
          type="button"
          title={currentLocationTooltip}
          className={cls(btnClasses, { 'dark-selected': trackingUser })}
          onClick={trackUserLocation}
          aria-label={currentLocationTooltip}
        >
          <Glyphicon glyph="gps-sijainti" alt={currentLocationTooltip} />
        </button>
      </div>
      <div className="controls-align">
        <button
          type="button"
          title={coordinatesTooltip}
          onClick={handleCoordinateToolClick}
          className={btnClasses}
          aria-label={coordinatesTooltip}
        >
          <Glyphicon glyph="koordinaatit" alt={coordinatesTooltip} />
        </button>
      </div>
    </div>
  );
};

export default DesktopMapControlsComponent;
