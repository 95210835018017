import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { useNavigate, useParams, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import merge from 'deepmerge';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'common/helpers/isEmpty';
import { START_PHASE_BG_TASKS_READY } from 'common/containers/KasiAppMode/KasiAppModes';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import {
  accessibleNotificationAdded,
  accessibleNotificationsCleared,
} from 'common/containers/AccessibleNotifications/accessibleNotificationsSlice';
import { FlexGrid, Row } from 'common/components/Grid';
import FormLoaderBlock from 'common/components/Loader/FormLoaderBlock';
import HeadingRow from 'common/containers/Forms/Blocks';
import { deleteFormValidationNotification } from 'common/containers/AppNotifications/AppNotificationsActions';
import usePageTitle from 'common/hooks/usePageTitle';
import { SERVICES_OSRA } from 'realEstateSite/constants/Routes';
import { FormNavigation, Error, FormSectionErrorWrapper, RedirectToFirstValidStep, Listener } from './components';
import {
  focusOnErrorOnSubmitListenerCreator,
  saveToStorageListenerCreator,
  getFormDataFromStorage,
  clearFormData,
  setAsyncErrors,
  setSubmitFailed,
  checkAndFocusOnSectionErrors,
  getErrorFields,
  resolveActivePage,
  isOnFailedPage,
  isOnSucceededPage,
  combineMerge,
} from './FormHelpers';
import useFormNavigationTools from './useFormNavigationTools';
import useFormRouting from './useFormRouting';
import { getRoute } from '../../constants/Routes';
import { updateFormErrorMsg, resetFormMessage } from './FormActions';

const FormContext = React.createContext();

// NOTE: these are to help Cypress testing skip to specific steps without having to fill every little thing
const resolveInitialValues = realInitialValues =>
  window.Cypress && window.formInitialValues ? merge(realInitialValues, window.formInitialValues) : realInitialValues;

const resolveHeadingText = (failedSubmit, activePage) =>
  failedSubmit && activePage?.props?.errorTitle ? activePage.props.errorTitle : activePage?.props?.title;

export const FormWrapperLoader = () => (
  <Row>
    <FormLoaderBlock size="m" className="margin-b-1" />
  </Row>
);

function shouldRenderForm({ submitting, dontUnmountWhenSubmitting, isFormReady }) {
  return dontUnmountWhenSubmitting ? isFormReady : isFormReady && !submitting;
}

const FormContextProvider = ({
  children,
  goBackToForm,
  sendA11yNotification,
  clearA11yNotification,
  setFileUploads,
  setNavigationDisabled,
  reset,
  form,
  formId,
  currentPageNumber,
}) => {
  const contextValues = useMemo(
    () => ({
      goBackToForm,
      sendA11yNotification,
      clearA11yNotification,
      setFileUploads,
      setNavigationDisabled,
      reset: reset(form),
      checkAndFocusOnSectionErrors: checkAndFocusOnSectionErrors(form, formId),
      isOnSubmitFailedPage: () => isOnFailedPage(currentPageNumber),
      isOnSubmitSucceededPage: () => isOnSucceededPage(currentPageNumber),
    }),
    [
      currentPageNumber,
      form,
      formId,
      goBackToForm,
      reset,
      setNavigationDisabled,
      setFileUploads,
      sendA11yNotification,
      clearA11yNotification,
    ]
  );

  return <FormContext.Provider value={contextValues}>{children}</FormContext.Provider>;
};

FormContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  goBackToForm: PropTypes.func.isRequired,
  sendA11yNotification: PropTypes.func.isRequired,
  clearA11yNotification: PropTypes.func.isRequired,
  setFileUploads: PropTypes.func.isRequired,
  setNavigationDisabled: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  currentPageNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  form: PropTypes.shape({}).isRequired,
  formId: PropTypes.string.isRequired,
};

// TODO: rethink the flow of initialValues so they can easily be dynamic while taking into account saved values
const FormWrapper = ({
  formId,
  children,
  onSubmit,
  initialValues = {},
  handlePreSubmitTasks = async () => ({ preSubmitTasksOk: true }),
  handlePreSubmitTasksOnPage = 1,
  onAbort = () => null,
  isLoaded = true,
  submitText = '',
  listener = null,
  restartable = false,
  dataWillNotBeSending = false,
  dontUnmountWhenSubmitting = false, // prevent form unmount when doing async error checking on submit
  hideNavigation = false,
  hasNoSucceededPage = false,
  singlePage = false,
  dataCy = '',
  showErrorNotifications = false,
  cameFromDifferentRealEstate = false,
  printSummaryPage = null,
  isSplitApplication = false,
  isModalApproved = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { step } = useParams();
  const dispatch = useDispatch();
  const hasAppStarted = useSelector(state => state.appMode.startPhase >= START_PHASE_BG_TASKS_READY);
  const headingRef = useRef(null);
  const [fileUploads, setFileUploads] = useState({});
  const [navigationDisabled, setNavigationDisabled] = useState(false);
  const [submittedWithEnter, setSubmittedWithEnter] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const focusOnErrorOnSubmitListener = useRef(focusOnErrorOnSubmitListenerCreator(formId));

  const saveToStorageUnsubscribe = useRef(null);
  const saveToStorageListener = useRef(form => {
    const unsubscribe = saveToStorageListenerCreator(formId)(form);
    saveToStorageUnsubscribe.current = unsubscribe;
    return unsubscribe;
  });
  const location = useLocation();

  const isOSRASplitApplicationStep3 =
    location.pathname.includes(SERVICES_OSRA) && /3$/.test(location.pathname) && isSplitApplication;

  const [upToDateInitValues, setUpToDateInitValues] = useState(
    merge(resolveInitialValues(initialValues), getFormDataFromStorage(formId), { arrayMerge: combineMerge })
  ); // NOTE: deep merge to account for nested form-value structures

  const { formStepCount, currentPageNumber, isFirstPage, isLastFormPage, isReceivedPage } = useFormRouting(
    children,
    step
  );

  const isFormReady = hasAppStarted && isLoaded;
  const activePage = resolveActivePage(currentPageNumber, children);
  const validate = vals => (activePage?.props?.validate ? activePage.props.validate(vals) : {});
  const goBackToForm = () => navigate('../1', { state: { form: { isLegitSubmitPage: false } } });

  const previousSelector = activePage?.props?.previousSelector;
  const abortSelector = activePage?.props?.abortSelector;
  const submitSelector = activePage?.props?.submitSelector;

  const sendA11yNotification = (msgText, msgType) => {
    dispatch(accessibleNotificationAdded({ text: msgText, type: msgType }));
  };

  const clearA11yNotification = msgType => {
    dispatch(accessibleNotificationsCleared(msgType));
  };

  const reset = form => (fieldName = '') => {
    form.batch(() => {
      form.pauseValidation();
      form.change(fieldName, undefined);
      form.restart(merge(initialValues, form.getState().values, { arrayMerge: combineMerge }));
      form.resumeValidation();
    });
  };

  const { previous, submitPage } = useFormNavigationTools({
    isLastFormPage,
    formStepCount,
    onSubmit,
    onNext: setUpToDateInitValues,
    headingRef,
    reset,
    hasNoSucceededPage,
  });

  const pageHeading = resolveHeadingText(isOnFailedPage(currentPageNumber), activePage);

  const pageTitle = () => {
    const title = activePage?.props?.subtitle || pageHeading;
    const stepTitle = activePage?.props?.steptitle ? `: ${activePage.props.steptitle}` : '';
    const stepText = `, ${t('application.step', {
      currentPage: currentPageNumber,
      formSteps: formStepCount,
    })}${stepTitle}`;
    const steps = formStepCount > 1 && typeof currentPageNumber === 'number' ? stepText : '';
    return `${title}${steps}`;
  };

  usePageTitle({
    title: pageTitle(),
    template: 'app.documentTitle.template',
    trackPageView: false,
  });

  useEffect(() => {
    if (isFormReady) {
      const storedFormData = getFormDataFromStorage(formId);
      if (!isEmpty(storedFormData) && !cameFromDifferentRealEstate) {
        setUpToDateInitValues(merge(initialValues, storedFormData, { arrayMerge: combineMerge }));
      } else setUpToDateInitValues(resolveInitialValues(initialValues));
    }
    if (singlePage) return () => clearFormData(formId);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormReady, formId, singlePage]);

  // NOTE: redirect to parsed step if is not on received page and step has changed recently
  useEffect(() => {
    if (!isReceivedPage && currentPageNumber !== parseInt(step, 10) && !singlePage)
      navigate(`../${currentPageNumber}`, { replace: true, state: { form: { isLegitSubmitPage: false } } });
  }, [currentPageNumber, isReceivedPage, navigate, singlePage, step]);

  if (!activePage) return null;
  if (isCompleted && restartable && isFirstPage) setIsCompleted(false);
  if (isCompleted && !isReceivedPage) return <Navigate replace to={getRoute()} />;

  return (
    <FlexGrid className="form__container-bg">
      <Form
        initialValues={upToDateInitValues}
        onSubmit={async (vals, form) => {
          if (submittedWithEnter && submitSelector) {
            dispatch(requestKeyboardFocusAction({ selector: submitSelector }));
            setSubmittedWithEnter(false);
          }

          const skipHandlePreSubmitTasks = currentPageNumber !== handlePreSubmitTasksOnPage;
          const { preSubmitTasksOk } = skipHandlePreSubmitTasks
            ? { preSubmitTasksOk: true }
            : await handlePreSubmitTasks({
                vals,
                checkAndFocusOnSectionErrors: checkAndFocusOnSectionErrors(form, formId),
                form,
                isFirstPage,
              });
          if (preSubmitTasksOk) {
            // OSRA Step3 verification moda
            if (isOSRASplitApplicationStep3 && !isModalApproved && vals?.targets[0]?.recipients?.length === 1) {
              return setOpenModal(true);
            }
            return submitPage(vals, form);
          }
          return null;
        }}
        validate={validate}
        decorators={[focusOnErrorOnSubmitListener.current, saveToStorageListener.current]}
        mutators={{ setAsyncErrors, getErrorFields, setSubmitFailed }}
      >
        {({ handleSubmit, form, submitting, values }) => (
          <>
            {!singlePage && Array.isArray(children) && (
              <RedirectToFirstValidStep page={currentPageNumber} pageComponents={children} />
            )}
            {pageHeading && (
              <HeadingRow
                ref={headingRef}
                text={pageHeading}
                subtitleText={activePage?.props?.subtitle}
                headingLevel={formId === 'file-download-form' ? 'h2' : 'h1'}
                step={formStepCount > 1 && !isReceivedPage && currentPageNumber}
                numberOfSteps={formStepCount > 1 && !isReceivedPage && formStepCount}
                className="margin-b-1"
                headerClassName={activePage?.props?.headerClassName}
              />
            )}
            <form
              onSubmit={async e => {
                const submitResult = await handleSubmit(e);
                if (isLastFormPage && form.getState().submitSucceeded) {
                  clearFormData(formId);
                  setIsCompleted(true);
                  reset(form);
                }
                return submitResult;
              }}
              name={formId}
              data-cy={dataCy}
            >
              {(!isFormReady || submitting) && <FormWrapperLoader />}
              {shouldRenderForm({ submitting, dontUnmountWhenSubmitting, isFormReady }) ? (
                <div style={{ display: !isFormReady || submitting ? 'none' : null }}>
                  {/* React Final Form made me do this Timeout as there is some issue with FormSpy https://github.com/final-form/react-final-form/issues/809#issuecomment-808942373 */}
                  <FormSpy
                    onChange={state =>
                      setTimeout(() => {
                        const selectedMapSheetError = Object.values(state.errors).filter(
                          m => m === t('fileDownloadService.areaSelection.selectSheets.error.tooMany')
                        );

                        if (showErrorNotifications) {
                          if (!isEmpty(selectedMapSheetError)) {
                            dispatch(updateFormErrorMsg(selectedMapSheetError, formId));
                          } else {
                            dispatch(resetFormMessage());
                            dispatch(deleteFormValidationNotification(formId));
                          }
                        }
                      })
                    }
                  />
                  <FormContextProvider
                    goBackToForm={goBackToForm}
                    sendA11yNotification={sendA11yNotification}
                    clearA11yNotification={clearA11yNotification}
                    form={form}
                    currentPageNumber={currentPageNumber}
                    setNavigationDisabled={setNavigationDisabled}
                    setFileUploads={setFileUploads}
                    reset={reset}
                    formId={formId}
                  >
                    {listener && <Listener values={values} onChange={listener} />}
                    {activePage}
                  </FormContextProvider>
                </div>
              ) : null}
              <Error name={FORM_ERROR} />
              {!isReceivedPage && (
                <FormNavigation
                  isFirst={isFirstPage}
                  isLast={isLastFormPage}
                  onPrevious={previous}
                  dataWillNotBeSending={dataWillNotBeSending}
                  submitText={submitText}
                  onAbort={() => {
                    if (saveToStorageUnsubscribe.current) {
                      saveToStorageUnsubscribe.current();
                    }
                    form.restart(initialValues);
                    onAbort();
                    clearFormData(formId);
                  }}
                  setSubmittedUsingEnter={submittedUsingEnter => {
                    setSubmittedWithEnter(submittedUsingEnter);
                  }}
                  hideNavigation={hideNavigation}
                  isFormReady={isFormReady}
                  fileUploads={fileUploads}
                  navigationDisabled={navigationDisabled}
                  previousSelector={previousSelector}
                  abortSelector={abortSelector}
                  printSummaryPage={printSummaryPage}
                  openModal={openModal}
                  closeModal={() => setOpenModal(false)}
                  setOpenModal={setOpenModal}
                  form={form}
                  submitPage={submitPage}
                />
              )}
            </form>
          </>
        )}
      </Form>
    </FlexGrid>
  );
};

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  formId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handlePreSubmitTasks: PropTypes.func,
  handlePreSubmitTasksOnPage: PropTypes.number,
  onAbort: PropTypes.func,
  initialValues: PropTypes.shape({}),
  isLoaded: PropTypes.bool,
  submitText: PropTypes.string,
  listener: PropTypes.func,
  restartable: PropTypes.bool,
  dataWillNotBeSending: PropTypes.bool,
  dontUnmountWhenSubmitting: PropTypes.bool,
  hideNavigation: PropTypes.bool,
  hasNoSucceededPage: PropTypes.bool,
  singlePage: PropTypes.bool,
  dataCy: PropTypes.string,
  showErrorNotifications: PropTypes.bool,
  cameFromDifferentRealEstate: PropTypes.bool,
  printSummaryPage: PropTypes.func,
  isSplitApplication: PropTypes.bool,
  isModalApproved: PropTypes.bool,
};

const withRouting = WrappedComponent => ({ ...props }) => {
  // eslint-disable-next-line react/prop-types
  const { singlePage } = props;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {singlePage ? (
        <WrappedComponent {...props} />
      ) : (
        <Routes>
          <Route path=":step/" element={<WrappedComponent {...props} />} />
          <Route path=":step/*" element={<Navigate to="." replace />} />
          <Route path="*" element={<Navigate to="1" replace />} />
        </Routes>
      )}
    </>
  );
};

const FormWrapperWithRouting = withRouting(FormWrapper);
const Page = ({ children }) => children;

FormWrapperWithRouting.Page = Page;
FormWrapperWithRouting.FormContext = FormContext;
FormWrapperWithRouting.FormSectionErrorWrapper = FormSectionErrorWrapper;

export default FormWrapperWithRouting;
export { Error };
