export const EVENT_TYPE_PROCEEDING = '1';
export const EVENT_TYPE_REGISTER_DECISION = '10';

export const CADASTRAL_ACTIVITY_URL_EVENT_TYPES = [EVENT_TYPE_PROCEEDING, EVENT_TYPE_REGISTER_DECISION];

export const PHASE_TYPE_PENDING = '1';
export const PHASE_TYPE_STARTED = '3';
export const PHASE_TYPE_LAPSED = '15';
export const PHASE_TYPE_RE_SCHEDULED = '18';
export const PHASE_TYPE_RE_OPENED = '21';
export const PHASE_TYPE_REGISTERED = '23';
export const PHASE_TYPE_ACCOMPLISHED = '24';
export const PHASE_TYPE_FINISHED = '26';
export const PHASE_TYPE_APPEAL_PENDING = '45';
export const PHASE_TYPE_APPEAL_SOLVED = '46';
export const PHASE_TYPE_CANCELLED = '60';
export const PHASE_TYPE_COMBINED = '61';
export const PHASE_TYPE_INFORMED = '70';
export const PHASE_TYPE_LANDSCAPE = '71';
export const PHASE_TYPE_PREPROCESSING = '99'; // Käsittely ennen toimitusmääräystä

export const CADASTRAL_ACTIVITY_DAYS_VISIBLE = 180;

export const TARGET_TYPE_REGISTER_UNIT_CODE = '1';
export const TARGET_TYPE_PROPERTY_CODE = '2';

export const ALL_CADASTRAL_PHASES = [
  PHASE_TYPE_PENDING,
  PHASE_TYPE_STARTED,
  PHASE_TYPE_LAPSED,
  PHASE_TYPE_RE_SCHEDULED,
  PHASE_TYPE_RE_OPENED,
  PHASE_TYPE_REGISTERED,
  PHASE_TYPE_ACCOMPLISHED,
  PHASE_TYPE_FINISHED,
  PHASE_TYPE_APPEAL_PENDING,
  PHASE_TYPE_APPEAL_SOLVED,
  PHASE_TYPE_CANCELLED,
  PHASE_TYPE_COMBINED,
  PHASE_TYPE_INFORMED,
  PHASE_TYPE_LANDSCAPE,
  PHASE_TYPE_PREPROCESSING,
];

export const CADASTRAL_ACTIVE_PHASES = [
  PHASE_TYPE_PENDING,
  PHASE_TYPE_STARTED,
  PHASE_TYPE_INFORMED,
  PHASE_TYPE_LANDSCAPE,
  PHASE_TYPE_RE_SCHEDULED,
  PHASE_TYPE_RE_OPENED,
  PHASE_TYPE_APPEAL_PENDING,
  PHASE_TYPE_APPEAL_SOLVED,
];

export const CADASTRAL_ACTIVITY_NOTIFICATION_PHASES = [
  PHASE_TYPE_INFORMED,
  PHASE_TYPE_FINISHED,
  PHASE_TYPE_LAPSED,
  PHASE_TYPE_CANCELLED,
  PHASE_TYPE_LANDSCAPE,
];

export const PHASE_PREPROCESSING = 0;
export const PHASE_STARTED = 1;
export const PHASE_PENDING = 2;
export const PHASE_FINISHED = 3;
export const PHASE_REGISTERED = 4;
export const PHASE_CANCELLED = 'cancelled';

/** Rekisterinpitäjän päätös  */
export const CADASTRAL_ACTIVITY_REGISTER_DECISION_PHASES = {
  preprocessing: {
    phaseNumber: PHASE_PREPROCESSING,
  },
  pending: {
    phaseNumber: PHASE_STARTED,
    phaseTitle: 'activity.cadastralSurvey.description.pending.registered',
    phaseInfoText: 'activity.cadastralSurvey.phases.pending.proceeding',
  },
  finished: {
    phaseNumber: PHASE_REGISTERED,
    phaseTitle: 'activity.cadastralSurvey.description.registered.registerDecision',
    phaseInfoText: 'activity.cadastralSurvey.phases.registered',
  },
};

/** Toimitus  */
export const CADASTRAL_ACTIVITY_PROCEEDING_PHASES = {
  preprocessing: {
    phaseNumber: PHASE_PREPROCESSING,
  },
  pending: {
    phaseNumber: PHASE_STARTED,
    phaseTitle: 'activity.cadastralSurvey.description.pending.proceeding',
    phaseInfoText: 'activity.cadastralSurvey.phases.pending.proceeding',
  },
  informed: {
    phaseNumber: PHASE_PENDING,
    phaseTitle: 'activity.cadastralSurvey.description.pending.registered',
    phaseInfoText: 'activity.cadastralSurvey.phases.pending.registered',
  },
  reOpened: {
    phaseNumber: PHASE_PENDING,
    phaseTitle: 'activity.cadastralSurvey.description.pending.registered',
    phaseInfoText: 'activity.cadastralSurvey.phases.re_opened',
  },
  finished: {
    phaseNumber: PHASE_FINISHED,
    phaseTitle: 'activity.cadastralSurvey.description.finished',
    phaseInfoText: 'activity.cadastralSurvey.phases.finished',
  },
  registered: {
    phaseNumber: PHASE_REGISTERED,
    phaseTitle: 'activity.cadastralSurvey.description.registered.cadastralSurvey',
    phaseInfoText: 'activity.cadastralSurvey.phases.registered',
  },
  cancelled: {
    phaseNumber: PHASE_CANCELLED,
    phaseTitle: 'activity.cadastralSurvey.description.cancelled',
    phaseInfoText: 'activity.cadastralSurvey.phases.cancelled',
  },
  combined: {
    phaseNumber: PHASE_CANCELLED,
    phaseTitle: 'activity.cadastralSurvey.description.cancelled',
  },
};

export const SECRET_DOCUMENT = 'Salassa pidettävä';
