import { last } from 'lodash/fp';
import {
  buildMapSiteRoute,
  MAP_STORE_INDEX,
  SHARE_CUSTOM_MARKER,
  TO_REAL_ESTATE_SITE,
  FILE_DOWNLOAD_SERVICE,
  getRoute,
  ROUTE_MAP_SITE_ID,
  FEEDBACK,
} from 'common/constants/Routes';
import {
  buildServicesSiteRoute,
  SERVICES_REAL_ESTATE_LIST,
  SERVICES_FETCH_REAL_ESTATES,
  SERVICES_REAL_ESTATE_LIST_MOBILE,
  SERVICES_REAL_ESTATE_LOCATIONS,
  SERVICES_REAL_ESTATE_PROPERTIES,
  LICENCED_DATA_INDEX,
  SERVICES_FETCH_APARTMENT,
} from 'realEstateSite/constants/Routes';

const resolveBackUrl = (pathname, isMobile) => {
  // File download service page starts with FormWrapper so can't go back to "zero" page.
  if (pathname === `${buildMapSiteRoute(FILE_DOWNLOAD_SERVICE)}/1`) {
    return getRoute();
  }
  const urlparts = pathname.split('/').filter(Boolean);

  if (isMobile && urlparts.length === 2 && !pathname.includes(ROUTE_MAP_SITE_ID)) {
    return SERVICES_REAL_ESTATE_LIST_MOBILE;
  }

  if (urlparts.length < 2) return pathname;
  urlparts.pop(); // remove last path part

  if (
    last(urlparts).includes(SERVICES_REAL_ESTATE_LOCATIONS) ||
    last(urlparts).includes(SERVICES_REAL_ESTATE_PROPERTIES)
  )
    urlparts.pop();

  if (last(urlparts).includes(SERVICES_FETCH_APARTMENT)) {
    urlparts.pop();
    urlparts.push(SERVICES_FETCH_REAL_ESTATES);
  }

  return urlparts.length < 2 ? `/${urlparts.join('/')}/` : `/${urlparts.join('/')}`;
};

const showBack = ({ pathname, onMobile }) =>
  pathname.includes(buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST).concat('/')) ||
  pathname.includes(buildServicesSiteRoute(SERVICES_FETCH_REAL_ESTATES).concat('/')) ||
  pathname.includes(buildServicesSiteRoute(SERVICES_FETCH_APARTMENT).concat('/')) ||
  pathname === buildMapSiteRoute(MAP_STORE_INDEX) ||
  pathname.includes(buildMapSiteRoute(TO_REAL_ESTATE_SITE)) ||
  pathname.includes(buildMapSiteRoute(SHARE_CUSTOM_MARKER)) ||
  pathname === buildMapSiteRoute(FEEDBACK) ||
  pathname.includes(buildMapSiteRoute(FILE_DOWNLOAD_SERVICE)) ||
  pathname.includes(buildServicesSiteRoute(LICENCED_DATA_INDEX).concat('/')) ||
  (onMobile && pathname.includes(buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST))) ||
  (onMobile && pathname.includes(buildServicesSiteRoute(SERVICES_FETCH_REAL_ESTATES))) ||
  (onMobile && pathname.includes(buildServicesSiteRoute(SERVICES_FETCH_APARTMENT))) ||
  (onMobile && pathname === buildServicesSiteRoute(MAP_STORE_INDEX));

export { showBack, resolveBackUrl };
