import React from 'react';

import { ScrollerColumn, FocusableHeading } from 'common/components';
import FeedbackIndex from 'common/containers/Feedback/FeedbackComponent';
import usePageTitle from 'common/hooks/usePageTitle';
import { useTranslation } from 'react-i18next';
import ScrollerPanelPage from 'common/components/ScrollerPanel/ScrollerPanelPage';
import { PROVIDE_FEEDBACK_TITLE } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

const FeedbackIndexPage = () => {
  usePageTitle({
    title: 'feedback.index.documentTitle',
    template: 'app.documentTitle.template',
  });

  const { t } = useTranslation();

  return (
    <ScrollerColumn columnNumber={2} lastColumn>
      <ScrollerPanelPage>
        <FocusableHeading level="h1" className={PROVIDE_FEEDBACK_TITLE}>
          {t('feedback.pageTitle')}
        </FocusableHeading>
        <FeedbackIndex />
      </ScrollerPanelPage>
    </ScrollerColumn>
  );
};

export default FeedbackIndexPage;
