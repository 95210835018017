import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import { API_PATH_CHANGE_ROLE, getRoute } from 'common/constants/Routes';
import useClickOutside from 'common/hooks/useClickOutside';
import { clearFormDataFromSessionStorage } from 'common/components/Form/FormHelpers';
import LogoutModal from '../LogoutModal/LogoutModal';
import RoleChangeModal from '../RoleChangeModal/RoleChangeModal';

const UserMenuDropDownContent = ({
  handleLogoutClick = () => null,
  handleRoleChangeClick = () => null,
  authentication,
  cartItemCount,
}) => {
  const { t } = useTranslation();

  return (
    <section
      id="userMenu"
      role="dialog"
      aria-labelledby="userMenuTitle"
      aria-describedby={authentication?.organizationRepresentative ? 'userMenuSubtitle' : null}
      className={cls('user-menu-dropdown__content')}
    >
      <header>
        <h2 id="userMenuTitle">
          {authentication?.organizationRepresentative
            ? authentication?.organizationName
            : `${authentication?.firstName} ${authentication?.lastName}`}
        </h2>
        {authentication?.organizationRepresentative && (
          <p id="userMenuSubtitle">{`${authentication?.firstName} ${authentication?.lastName}`}</p>
        )}
      </header>
      {(authentication?.organizationRepresentative || authentication?.publicPurchaseWitnessing) && (
        <p className="margin-b-1">
          <a
            href={getRoute(API_PATH_CHANGE_ROLE)}
            onClick={e => {
              if (cartItemCount > 0) {
                e.preventDefault();
                handleRoleChangeClick();
              } else {
                clearFormDataFromSessionStorage();
              }
            }}
          >
            {t('usermenu.changeOrganization')}
          </a>
        </p>
      )}
      <p>
        <button type="button" className="logout-button button button--blue" onClick={handleLogoutClick}>
          <Glyphicon className="glyphicon-lock" glyph="lukko-auki" />
          &nbsp;
          {t('usermenu.logout')}
        </button>
      </p>
    </section>
  );
};

UserMenuDropDownContent.propTypes = {
  authentication: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    organizationRepresentative: PropTypes.bool,
    publicPurchaseWitnessing: PropTypes.bool,
  }).isRequired,
  cartItemCount: PropTypes.number.isRequired,
  handleLogoutClick: PropTypes.func,
  handleRoleChangeClick: PropTypes.func,
};

const UserMenuDropDown = ({
  floatRight = true,
  authentication,
  onLogout = () => null,
  isMobile = false,
  goToShoppingCart,
  cartItemCount,
  changeRole = () => null,
}) => {
  const dropDownRef = useRef(null);
  const buttonRef = useRef(null);

  const [close, setClose] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openRoleModal, setOpenRoleModal] = useState(false);

  const { target } = useClickOutside();

  const handleLogoutClick = () => {
    setClose(true);
    setOpenModal(true);
  };

  const handleUniversalClick = () => {
    setClose(!close);
  };

  const handleRoleChangeClick = () => {
    setClose(true);
    setOpenRoleModal(true);
  };

  const handleModalClose = () => setOpenModal(false);

  const handleRoleModalClose = () => setOpenRoleModal(false);

  const handleShoppingCart = () => {
    handleModalClose();
    handleRoleModalClose();
    goToShoppingCart();
  };

  const handleBlur = e => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setClose(true);
      }
    }, 0);
  };

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      event.preventDefault();
      buttonRef?.current?.focus();
      setClose(true);
    }
  };

  useEffect(() => {
    if (target != null && dropDownRef.current && !dropDownRef.current.contains(target)) {
      setClose(true);
    }
  }, [target]);

  return (
    // The <div> element has child elements that allow keyboard interaction
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onBlur={handleBlur}
      ref={dropDownRef}
      onKeyDown={onKeyDown}
      className={cls('full-height', { relative: !isMobile }, { 'user-menu--right': floatRight })}
    >
      <button
        id="userMenuButton"
        type="button"
        ref={buttonRef}
        onClick={handleUniversalClick}
        className="user-menu__item-block button button--flexible user-menu__dropdown"
        aria-haspopup="dialog"
        aria-controls="userMenu"
        aria-expanded={!close}
      >
        <span className={cls('mobile-hide', 'user-menu___user-name')}>
          <span className="ellipsis">
            {authentication?.organizationRepresentative ? authentication?.organizationName : authentication?.firstName}
          </span>
        </span>
        <Glyphicon glyph="kayttaja2" className="indent-block-2" />
        <Glyphicon glyph={close ? 'nuoli-alas' : 'nuoli-ylos'} className="dropdown__arrow-icon" />
      </button>
      {!close && (
        <UserMenuDropDownContent
          handleLogoutClick={handleLogoutClick}
          handleRoleChangeClick={handleRoleChangeClick}
          changeRole={changeRole}
          authentication={authentication}
          cartItemCount={cartItemCount}
        />
      )}
      {openModal && (
        <LogoutModal
          logout={onLogout}
          cancelLogout={handleModalClose}
          cartItemCount={cartItemCount}
          goToShoppingCart={handleShoppingCart}
        />
      )}
      {openRoleModal && (
        <RoleChangeModal
          changeRole={changeRole}
          cancelRoleChange={handleRoleModalClose}
          cartItemCount={cartItemCount}
          goToShoppingCart={handleShoppingCart}
        />
      )}
    </div>
  );
};

UserMenuDropDown.propTypes = {
  authentication: PropTypes.shape({
    uid: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    organizationName: PropTypes.string,
    organizationRepresentative: PropTypes.bool,
  }).isRequired,
  cartItemCount: PropTypes.number.isRequired,
  goToShoppingCart: PropTypes.func.isRequired,
  onLogout: PropTypes.func,
  changeRole: PropTypes.func,
  floatRight: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export { UserMenuDropDown as UserMenuDropDownTest };
export default UserMenuDropDown;
