import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { DayPicker } from 'react-day-picker';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';

import * as DatetimeUtils from 'utils/datetime-utils';
import useClickOutside from 'common/hooks/useClickOutside';
import { Block, Row } from '../Grid';
import { Glyphicon } from '../Icon';
import { ariaLabels } from './DatePickerLocalization';
import getDateFnsLocale from '../../i18n/helpers/dateFnsLocale';

export const DATE_PICKER_CLASS_NAME = 'date-picker-input';

const yearRangeMin = 1800;
const yearRangeMax = new Date().getFullYear() + 100;

const validatedParseDate = (str, dateFormat) => {
  const parsed = DatetimeUtils.parseBase(str, dateFormat);
  return DatetimeUtils.isValid(parsed) && DatetimeUtils.getYear(parsed) >= yearRangeMin && parsed;
};

const DatePicker = React.forwardRef(
  (
    {
      id,
      input: { onChange, value, name },
      placeholder = '',
      isError = false,
      desktopSize = 6,
      nofutureDates = false,
      onlyfutureDates = false,
      birthDate = false,
      required = false,
      ariaDescribedby = null,
      language = '',
    },
    ref
  ) => {
    const [inputDate, setInputDate] = useState(
      DatetimeUtils.isDate(value)
        ? DatetimeUtils.format(DatetimeUtils.formatDateInEET(value), DatetimeUtils.DATE_HUMAN_DMYYYY)
        : ''
    );
    const [isPopperOpen, setIsPopperOpen] = useState(false);
    const [popperElement, setPopperElement] = useState(null);
    const popperRef = useRef(null);
    const dayPickerRef = useRef(null);

    const { target } = useClickOutside();

    const closePopper = () => setIsPopperOpen(false);
    const openPopper = () => setIsPopperOpen(true);

    useEffect(() => {
      if (target != null && dayPickerRef.current && !dayPickerRef.current.contains(target)) {
        closePopper();
      }
    }, [target]);

    const handleDayChange = day => {
      const date = DatetimeUtils.isDate(day) ? DatetimeUtils.format(day, DatetimeUtils.DATE_HUMAN_DMYYYY) : '';
      setInputDate(date);
      const validatedDate = validatedParseDate(date, DatetimeUtils.DATE_HUMAN_DMY);
      onChange(validatedDate || undefined);
      closePopper();
    };

    const handleInputChange = event => {
      setInputDate(event.currentTarget.value);
      const date = validatedParseDate(event.currentTarget.value, DatetimeUtils.DATE_HUMAN_DMY);
      if (date) {
        onChange(date);

        // Make date picker change month/year view after user input
        if (isPopperOpen) {
          closePopper();
          setTimeout(openPopper, 0);
        }
      } else {
        onChange(event.currentTarget.value || '');
      }
    };

    const handleDayKeyDown = (day, activeModifiers, event) => {
      const date = DatetimeUtils.format(day, DatetimeUtils.DATE_HUMAN_DMYYYY);
      const validatedDate = validatedParseDate(date, DatetimeUtils.DATE_HUMAN_DMY);
      if ((event.key === 'Enter' || event.key === ' ') && validatedDate == null) {
        event.preventDefault();
      }
    };

    const popper = usePopper(popperRef.current, popperElement, {
      placement: 'bottom-start',
    });

    const disabledDays = [nofutureDates && { after: new Date() }, onlyfutureDates && { before: new Date() }];
    const defaultMonthBirthDate = birthDate ? DatetimeUtils.formatDateInEET(new Date('1970-01-01')) : undefined;
    const defaultMonth = DatetimeUtils.isDate(value) ? value : defaultMonthBirthDate;

    const onKeyDown = event => {
      if (event.key === 'Escape') closePopper();
      if (event.key === 'Enter') {
        event.preventDefault();
        closePopper();
      }
      if (event.key === ' ') {
        event.preventDefault();
        if (isPopperOpen) {
          closePopper();
        } else {
          openPopper();
        }
      }
    };

    return (
      <Row>
        <Block desktop={desktopSize} mobile={6} onBlur={closePopper}>
          <div className="date-picker-input-field__wrapper" ref={popperRef}>
            <input
              id={id}
              ref={ref}
              name={name}
              autoComplete="off"
              className={cls('input-text', { 'input-text--error': isError }, DATE_PICKER_CLASS_NAME)}
              aria-invalid={isError}
              {...(required ? { 'aria-required': true } : {})}
              value={
                DatetimeUtils.isDate(inputDate)
                  ? DatetimeUtils.format(inputDate, DatetimeUtils.DATE_HUMAN_DMYYYY)
                  : inputDate
              }
              onChange={handleInputChange}
              placeholder={placeholder}
              onClick={openPopper}
              onKeyDown={onKeyDown}
              aria-describedby={ariaDescribedby}
            />
            <Glyphicon glyph="kalenteri" />
          </div>
          {isPopperOpen && (
            <FocusTrap
              active
              focusTrapOptions={{
                initialFocus: false,
                allowOutsideClick: true,
                clickOutsideDeactivates: true,
                onDeactivate: closePopper,
                returnFocusOnDeactivate: false,
              }}
            >
              <div
                className="DayPickerInput-Overlay"
                ref={setPopperElement}
                style={popper.styles.popper}
                role="dialog"
                tabIndex={-1}
                {...popper.attributes.popper}
                data-testid="datepicker"
              >
                <div ref={dayPickerRef}>
                  <DayPicker
                    mode="single"
                    selected={value}
                    defaultMonth={defaultMonth}
                    locale={getDateFnsLocale(language)}
                    captionLayout="dropdown"
                    fromYear={yearRangeMin}
                    toYear={yearRangeMax}
                    onSelect={handleDayChange}
                    onDayKeyDown={handleDayKeyDown}
                    disabled={disabledDays}
                    labels={ariaLabels}
                    required
                  />
                </div>
              </div>
            </FocusTrap>
          )}
        </Block>
      </Row>
    );
  }
);

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]), // empty value after reload is ''
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  desktopSize: PropTypes.number,
  nofutureDates: PropTypes.bool,
  onlyfutureDates: PropTypes.bool,
  birthDate: PropTypes.bool,
  required: PropTypes.bool,
  ariaDescribedby: PropTypes.string,
  language: PropTypes.string,
};

export default DatePicker;
