import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Paragraph = ({ className = '', children = null, dataTestId = null }) => (
  <p className={cls('nls-paragraph', className)} data-testid={dataTestId}>
    {children}
  </p>
);

Paragraph.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

export default Paragraph;
