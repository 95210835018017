import { validate } from 'email-validator';
import { r } from 'rational-arithmetic';
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { OTHER_ATTACHMENTS_TYPE } from 'common/constants/FieldNames';

const SOCIAL_SECURITY_CHECK_NUMBERS = '0123456789ABCDEFHJKLMNPRSTUVWXY'.split('');
const MAXIMUM_INTEGER = 2147483647;

const getSocialSecurityNumberBirthday = socialSecurityNumber => socialSecurityNumber.substring(0, 6);
const getSocialSecurityIdentificationNumber = socialSecurityNumber => socialSecurityNumber.substring(7, 10);

const verifySocialSecurityCheckNumber = socialSecurityNumber => {
  const checkNumberInput =
    getSocialSecurityNumberBirthday(socialSecurityNumber) + getSocialSecurityIdentificationNumber(socialSecurityNumber);
  const validPunctuationMark = /[+\-ABCDEFYXWVU]/.test(socialSecurityNumber.substring(6, 7));
  const checkNumber = socialSecurityNumber.substring(10, 11).toUpperCase();
  return validPunctuationMark && checkNumber === SOCIAL_SECURITY_CHECK_NUMBERS[checkNumberInput % 31];
};

export const validateSocialSecurityNumber = socialSecurityNumber =>
  socialSecurityNumber && socialSecurityNumber.length === 11 && verifySocialSecurityCheckNumber(socialSecurityNumber);

export const validateEmail = email => email && validate(email);

export const validateEmailFields = (values, required = true, hasEmailAgainField = true) => {
  const errors = {};
  if (hasEmailAgainField && (values.email || values.emailAgain) && values.email !== values.emailAgain) {
    errors.emailAgain = t('error.email.unidentical');
  }
  if (values.email && !validateEmail(values.email)) {
    errors.email = t('error.email.invalid');
  }
  if (hasEmailAgainField && values.emailAgain && !validateEmail(values.emailAgain)) {
    errors.emailAgain = t('error.email.invalid');
  }
  if (required && !values.email) {
    errors.email = t('error.email.missing');
  }
  if (hasEmailAgainField && required && !values.emailAgain) {
    errors.emailAgain = t('error.email.missing');
  }
  return errors;
};

export const validateFinnishPostalCode = postalCode => /\b\d{5}\b/.test(postalCode);

export const validateForeignPostalCode = postalCode => {
  const trimmedLength = postalCode && postalCode.trim && postalCode.trim().length;
  return trimmedLength > 0 && trimmedLength <= 10;
};

export const validatePostalCode = (postalCode, country) => {
  const countryCode = country?.value ?? 'FI';
  return countryCode === 'FI' ? validateFinnishPostalCode(postalCode) : validateForeignPostalCode(postalCode);
};

export const validatePhoneNumber = phoneNumber => phoneNumber && /^(?:(\+)?[\d])(?:-?[\d]+)+$/.test(phoneNumber.trim());

export const validatePhoneNumberField = (values, required = true) => {
  const errors = {};
  if (values.phoneNumber && !validatePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = t('error.phone.invalid');
  }
  if (required && !values.phoneNumber) {
    errors.phoneNumber = t('error.phone.missing');
  }
  return errors;
};

export const validateAttachmentsFields = (mandatoryAttachmentField, values) => {
  const errors = {};

  if (isEmpty(values[mandatoryAttachmentField])) errors[mandatoryAttachmentField] = t('attachment.error.missing');

  if (!isEmpty(values[OTHER_ATTACHMENTS_TYPE]) && isEmpty(values.otherAttachments))
    errors.otherAttachments = t('attachment.error.missing');

  return errors;
};

export const validateRealEstateIdentifier = (identifier, acceptFacility = true) => {
  if (identifier && identifier.length > 0) {
    const id = identifier.toUpperCase();
    if (acceptFacility || id.indexOf('L') < 0) {
      const extensionTrimmed = id ? id.replace('M', '').replace('L', '') : '';
      if (id.indexOf('M') > -1 || id.indexOf('L') > -1) {
        return (
          extensionTrimmed.match(/^\d{1,3}-\d{1,3}-\d{1,4}-\d{1,4}(-\d{1,3})$/) !== null ||
          extensionTrimmed.match(/^\d{15,18}$/) !== null
        );
      }
      return (
        extensionTrimmed.match(/^\d{1,3}-\d{1,3}-\d{1,4}-\d{1,4}$/) !== null ||
        extensionTrimmed.match(/^\d{14}$/) !== null
      );
    }
  }

  return false;
};

const organizationIdFactors = [7, 9, 10, 5, 8, 4, 2];
// Algorithm: http://tarkistusmerkit.teppovuori.fi/tarkmerk.htm#y-tunnus2
export const validateBusinessId = organizationId => {
  if (organizationId && organizationId.length === 9) {
    const idParts = organizationId.split('-');
    const checkSumData = [...idParts[0]];
    const checkSum = Number(idParts[1]);
    if (checkSumData.length === 7) {
      const calculatedCheckSumModulus =
        checkSumData.reduce((acc, value, index) => acc + value * organizationIdFactors[index], 0) % 11;
      const calculatedCheckSum = calculatedCheckSumModulus === 0 ? 0 : 11 - calculatedCheckSumModulus;
      return calculatedCheckSum === checkSum;
    }
  }
  return false;
};

export const validateForeignBusinessId = organizationId =>
  organizationId && organizationId.length >= 3 && organizationId.length <= 25;

const isNumber = value =>
  typeof value === 'number' && !Number.isNaN(value) && value !== Infinity && value !== -Infinity;

export const validateNumber = number => {
  const formattedNumber = number ? number.replace(/,/g, '.').replace(/ /g, '') : undefined;
  return isNumber(Number(formattedNumber));
};

const stringIsInteger = string => string.trim().match(/^[1-9]\d*$/);

export const trimFractionNumber = numberString => r`${numberString.replace(/ /g, '')}`;

export const parseShare = numberString => {
  if (numberString == null) return null;

  const fractions = numberString.replace(/ /g, '').split('/');
  try {
    if (!stringIsInteger(fractions[0]) || !stringIsInteger(fractions[1])) return null;
    return { numerator: parseInt(fractions[0], 10), denominator: parseInt(fractions[1], 10) };
  } catch (err) {
    return null;
  }
};

export const validatePortion = numberString => {
  const share = parseShare(numberString);
  return share != null && share.numerator > 0 && share.denominator > 0 && share.numerator < share.denominator;
};

export const validateOwnerShare = numberString => {
  const share = parseShare(numberString);
  return (
    share != null &&
    share.numerator > 0 &&
    share.numerator <= MAXIMUM_INTEGER &&
    share.denominator > 0 &&
    share.denominator <= MAXIMUM_INTEGER &&
    share.numerator <= share.denominator
  );
};

export const validateFullShare = numberString => {
  const share = parseShare(numberString);
  return share != null && share.numerator === 1 && share.denominator === 1;
};

export const validateShare = numberString => {
  return validatePortion(numberString) || validateFullShare(numberString);
};

export const validateReference = reference =>
  reference &&
  reference.match(/^(([a-zA-Z0-9/\-_&\\]+[a-zA-Z0-9/\-_&\\\s]+[a-zA-Z0-9/\-_&\\]+)|[a-zA-Z0-9/\-_&\\]+)+$/);
