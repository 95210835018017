import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Line = ({ className = '', children = null, dataTestId = '' }) => (
  <>
    <span className={cls('nls-line', className)} data-testid={dataTestId}>
      {children}
    </span>
    <br />
  </>
);

Line.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

export default Line;
