import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Glyphicon } from 'common/components/Icon';
import classNames from 'classnames';
import { t } from 'i18next';

import { AppLogo, Row, Block, FlexGrid } from 'common/components';
import * as mobileViews from 'common/containers/MobileTools/MobileViewNames';
import { businessPanelToggleAction } from 'common/containers/BusinessPanel/BusinessPanelActions';
import changeMobileViewAction from 'common/containers/MobileTools/MobileViewActions';

export const translationkeys = {
  maptoolsButtonTitle: 'mobiletools.maptoolsButtonTitle',
  searchButtonTitle: 'mobiletools.searchButtonTitle',
  mapLayersButtonTitle: 'mobiletools.mapLayersButtonTitle',
  mapButtonTitle: 'mobiletools.mapButtonTitle',
  menuButtonTitle: 'mobiletools.menuButtonTitle',
  featureToggleTitle: 'application.heading.targets',
};

function getButtonClass(isActive, classnames = []) {
  return classNames(
    'button--dark',
    'button',
    'button--icon',
    {
      'dark-selected': isActive,
    },
    ...classnames
  );
}

const MobileToolsComponent = () => {
  const dispatch = useDispatch();

  const appContext = useSelector(state => state.application.applicationContext);
  const currentMobileView = useSelector(state => state.mobileView.currentMobileView);
  const businessPanelIsOpen = useSelector(state => state.businessPanel.isOpen);
  const appLogoTitle = t('sidebar.appLogo.title');

  const isInSubPage = () => businessPanelIsOpen && currentMobileView === mobileViews.MOBILE_MAP_VIEW;
  const isBusinessPanelOpen = isInSubPage() || currentMobileView === mobileViews.MOBILE_BUSINESS_PANEL_VIEW;
  const selectButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_TOOLS_VIEW);
  const searchButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_SEARCH_VIEW);
  const layerButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_MAP_LAYERS_VIEW);
  const featuresButtonClasses = getButtonClass(currentMobileView === mobileViews.MOBILE_FEATURES_VIEW, [
    'mobile-feature-toggle-btn',
  ]);
  const glyphTitle = isBusinessPanelOpen ? t(translationkeys.mapButtonTitle) : t(translationkeys.menuButtonTitle);
  const glyph = isBusinessPanelOpen ? 'nayta-kartalla' : 'hampurilainen';
  const menuButtonClasses = getButtonClass(false);

  const handleMapLayersButtonClick = () => {
    dispatch(changeMobileViewAction(mobileViews.MOBILE_MAP_LAYERS_VIEW));
    if (businessPanelIsOpen) {
      dispatch(businessPanelToggleAction(false));
    }
  };

  const handleSearchButtonClick = () => {
    dispatch(changeMobileViewAction(mobileViews.MOBILE_SEARCH_VIEW));
    if (businessPanelIsOpen) {
      dispatch(businessPanelToggleAction(false));
    }
  };

  const handleMapToolsButtonClick = () => {
    dispatch(changeMobileViewAction(mobileViews.MOBILE_TOOLS_VIEW));
    if (businessPanelIsOpen) {
      dispatch(businessPanelToggleAction(false));
    }
  };

  const handleMapFeatureToggleButtonClick = () => {
    dispatch(changeMobileViewAction(mobileViews.MOBILE_FEATURES_VIEW));
    if (businessPanelIsOpen) {
      dispatch(businessPanelToggleAction(false));
    }
  };

  const handleBusinessPanelButtonClick = () => {
    const isAlreadyAtBusinessPanelView = isInSubPage() || currentMobileView === mobileViews.MOBILE_BUSINESS_PANEL_VIEW;

    const mobileView = isAlreadyAtBusinessPanelView
      ? mobileViews.MOBILE_MAP_VIEW
      : isInSubPage() || mobileViews.MOBILE_BUSINESS_PANEL_VIEW;

    dispatch(changeMobileViewAction(mobileView));
    dispatch(businessPanelToggleAction(!isAlreadyAtBusinessPanelView));
  };

  return (
    <FlexGrid className="mobile-tools mobile-tools--top">
      <Row className="mobile-tools__bar">
        <Block>
          <AppLogo appContext={appContext} logoTitle={appLogoTitle} />
        </Block>
        <Block className="right mobile-tools__button-row">
          <button
            type="button"
            title={t(translationkeys.maptoolsButtonTitle)}
            onClick={handleMapToolsButtonClick}
            className={selectButtonClasses}
            aria-label={t(translationkeys.maptoolsButtonTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_TOOLS_VIEW}
          >
            <Glyphicon glyph="karttatyokalut" alt={t(translationkeys.maptoolsButtonTitle)} />
          </button>

          <button
            type="button"
            title={t(translationkeys.mapLayersButtonTitle)}
            onClick={handleMapLayersButtonClick}
            className={layerButtonClasses}
            aria-label={t(translationkeys.mapLayersButtonTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_MAP_LAYERS_VIEW}
          >
            <Glyphicon glyph="karttatasot" alt={t(translationkeys.mapLayersButtonTitle)} />
          </button>

          <button
            type="button"
            title={t(translationkeys.searchButtonTitle)}
            onClick={handleSearchButtonClick}
            className={searchButtonClasses}
            aria-label={t(translationkeys.searchButtonTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_SEARCH_VIEW}
          >
            <Glyphicon glyph="hae" alt={t(translationkeys.searchButtonTitle)} />
          </button>

          <button
            type="button"
            title={t(translationkeys.featureToggleTitle)}
            onClick={handleMapFeatureToggleButtonClick}
            className={featuresButtonClasses}
            aria-label={t(translationkeys.featureToggleTitle)}
            aria-pressed={currentMobileView === mobileViews.MOBILE_FEATURES_VIEW}
          >
            <Glyphicon glyph="silma-kiinni" alt={t(translationkeys.featureToggleTitle)} />
          </button>

          <button
            type="button"
            title={glyphTitle}
            onClick={handleBusinessPanelButtonClick}
            className={menuButtonClasses}
            aria-label={glyphTitle}
            aria-controls="businessPanel"
            aria-expanded={isBusinessPanelOpen}
          >
            <Glyphicon glyph={glyph} alt={glyphTitle} />
          </button>
        </Block>
      </Row>
    </FlexGrid>
  );
};

export default MobileToolsComponent;
