import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { mapIsReady } from 'oskari/oskariModules/MapModule';
import { addClickFeatureWithCoordinatesListener, removeMapClickEventListener } from 'oskari/oskariModules/ClickFeature';
import { OSKARI_CHANNEL_STATUS_IDS, getOskariLayers } from 'oskari/OskariMap';
import { getFeatureForLayer } from 'oskari/oskariModules/ServicesMapModule/clickHandlers';
import { LAYER_BOUNDARY_MARKERS } from 'oskari/layers/VectorLayerIds';
import { MAP_TOOLS } from 'common/containers/MapToolbar/MapToolNames';

/**
 * @callback mapClickSelectCallback
 * @param {number} lon
 * @param {number} lat
 */

/**
 * @param {string} name
 * @param {mapClickSelectCallback} callback
 */
const useMapClickSelect = (name, callback) => {
  const zoom = useSelector(state => state.oskariIframe.zoom);
  const realEstatesLayerMinZoom = useSelector(
    state => state.mapLayers.find(layer => layer.id === getOskariLayers().kiinteistojaotus)?.minZoom
  );
  const channelReady =
    useSelector(state => state.layout.oskariChannelStatus) === OSKARI_CHANNEL_STATUS_IDS.channelReady;
  const isMapToolActive = useSelector(state => MAP_TOOLS.some(tool => state.mapToolbar[tool]?.active));

  if (!name) throw new Error('Event handler name is required.');

  useEffect(() => {
    if (channelReady) {
      mapIsReady().then(() => {
        removeMapClickEventListener(`${name}Location`); // Clear any previous handlers
        addClickFeatureWithCoordinatesListener(name, async (event, coords) => {
          // Clicking is not available, if using one of the map tools:
          if (isMapToolActive) return;

          // skip if clicking boundary markers
          const boundaryMark = getFeatureForLayer(event, LAYER_BOUNDARY_MARKERS);
          if (boundaryMark != null) return;
          // skip if zoom level is too high
          if (zoom < realEstatesLayerMinZoom) return;

          callback(coords.e, coords.n);
        });
      });

      return () => {
        removeMapClickEventListener(`${name}Location`);
      };
    }
    return () => {};
  }, [channelReady, name, realEstatesLayerMinZoom, zoom, isMapToolActive, callback]);
};

export default useMapClickSelect;
