import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Glyphicon } from 'common/components/Icon';

const ExternalLink = ({
  tabIndex = 0,
  children = undefined,
  className = '',
  href = '',
  text = '',
  defaultClassNames = 'external-link router-link',
  onClick = undefined,
}) => (
  <a
    tabIndex={tabIndex}
    target="_blank"
    rel="noopener noreferrer"
    onClick={onClick}
    className={cls(defaultClassNames, className)}
    href={href}
  >
    {text}
    {children}
    <Glyphicon glyph="avaa-uuteen-ikkunaan" />
  </a>
);

ExternalLink.propTypes = {
  tabIndex: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  defaultClassNames: PropTypes.string,
  onClick: PropTypes.func,
};

export default ExternalLink;
