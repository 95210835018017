import {
  addMonths,
  differenceInDays,
  format,
  formatISO,
  getYear,
  isAfter,
  isBefore,
  isFuture,
  isDate,
  isPast,
  isSameDay,
  isToday,
  isValid,
  lightFormat,
  parse,
  subDays,
  subMilliseconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as LOCALES from 'date-fns/locale';

const DATE_HUMAN_DMY = 'd.M.y';
const DATE_HUMAN_DDMMY = 'dd.MM.y';
const DATE_HUMAN_DMYYYY = 'd.M.yyyy';
const DATE_HUMAN_DDMMYYYY = 'dd.MM.yyyy';
const DATE_HUMAN_DDMMYYYY_DASHED = 'dd-MM-yyyy';
const DATE_HUMAN_YMMDD = 'y-MM-dd';
const DATE_HUMAN_YYYYMMDD_NONE = 'yyyyMMdd';
const DATE_HUMAN_DM = 'd.M.';
const TIME_HUMAN_HHMM = 'mm:ss';

const formatDateInEET = date => {
  return utcToZonedTime(date, 'Europe/Helsinki');
};

const formatDateDMY = date => {
  return lightFormat(formatDateInEET(date), DATE_HUMAN_DMY);
};

const formatDateDDMMY = date => {
  return lightFormat(formatDateInEET(date), DATE_HUMAN_DDMMY);
};

const formatDateDMYYYY = date => {
  return lightFormat(formatDateInEET(date), DATE_HUMAN_DMYYYY);
};

const formatDateDDMMYYYY = date => {
  return lightFormat(formatDateInEET(date), DATE_HUMAN_DDMMYYYY);
};

const formatDateYMMDD = date => {
  return lightFormat(formatDateInEET(date), DATE_HUMAN_YMMDD);
};

const formatDateDM = date => {
  return lightFormat(formatDateInEET(date), DATE_HUMAN_DM);
};

const formatDate = date => {
  const day = date ? date.getDate() : null;
  const month = date ? date.getMonth() + 1 : null;
  const year = date ? date.getFullYear() : null;
  return `${day}/${month}/${year}`;
};

const formatTime = date => {
  return lightFormat(formatDateInEET(date), TIME_HUMAN_HHMM);
};

const parseBase = (dateString, formatString) => parse(dateString, formatString, new Date());

const parseDatetimeDDMMYYYY = dateString => parseBase(dateString, DATE_HUMAN_DDMMYYYY);

const parseDatetimeDDMMYYYYDashed = dateString => parseBase(dateString, DATE_HUMAN_DDMMYYYY_DASHED);

const parseDatetimeYYYYMMDDNone = dateString => parseBase(dateString, DATE_HUMAN_YYYYMMDD_NONE);

export {
  addMonths,
  differenceInDays,
  format,
  formatISO,
  getYear,
  isAfter,
  isBefore,
  isDate,
  isPast,
  isFuture,
  isSameDay,
  isToday,
  isValid,
  lightFormat,
  parse,
  subDays,
  subMilliseconds,
  LOCALES,
  DATE_HUMAN_DMY,
  DATE_HUMAN_DDMMY,
  DATE_HUMAN_DMYYYY,
  DATE_HUMAN_DDMMYYYY,
  DATE_HUMAN_DDMMYYYY_DASHED,
  DATE_HUMAN_YMMDD,
  DATE_HUMAN_YYYYMMDD_NONE,
  DATE_HUMAN_DM,
  TIME_HUMAN_HHMM,
  formatDateInEET,
  formatDateDMY,
  formatDateDDMMY,
  formatDateDMYYYY,
  formatDateDDMMYYYY,
  formatDateYMMDD,
  formatDateDM,
  formatDate,
  formatTime,
  parseBase,
  parseDatetimeDDMMYYYY,
  parseDatetimeDDMMYYYYDashed,
  parseDatetimeYYYYMMDDNone,
};
