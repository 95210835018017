import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import disclaimerLogoBlue from 'styles/img/exclamation_mark.svg';
import disclaimerLogoOrange from 'styles/img/exclamation_mark-orange.svg';
import disclaimerLogoRed from 'styles/img/exclamation_mark-red.svg';

const ICONS = {
  info: disclaimerLogoBlue,
  warning: disclaimerLogoOrange,
  error: disclaimerLogoRed,
};

const InfoBox = ({ children, className = '', noIcon = false, level = 'info', ...rest }) => (
  <div className={cls('nls-infobox', className, level)} {...rest}>
    {!noIcon && (
      <span className="nls-infobox__icon">
        <img src={ICONS[level]} alt="" />
      </span>
    )}
    <div className="nls-infobox__content">{children}</div>
  </div>
);

InfoBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noIcon: PropTypes.bool,
  level: PropTypes.oneOf(['info', 'warning', 'error']),
};

export default InfoBox;
