import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const SubHeading = ({ level, className = '', dataTestId = '', children = null }) => {
  return (
    <div data-testid={dataTestId} className={cls('nls-subheading', `nls-heading-${level}`, className)}>
      {children}
    </div>
  );
};

SubHeading.propTypes = {
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};

export default SubHeading;
