// VIEW PRIVILEGES
export const SEARCH_REAL_ESTATES = 'search_real_estates';
export const SEARCH_HOUSING_COMPANIES = 'search_housing_companies';
export const LIST_OWN_REAL_ESTATES = 'list_own_real_estates';
export const LIST_OWN_ACTIVITIES = 'list_own_activities';
export const LIST_MORTGAGES = 'list_mortgages';
export const LIST_LEGAL_CONFIRMATIONS = 'list_legal_confirmations';
export const LIST_PROPRIETORS = 'list_proprietors';
export const LIST_USUFRUCTS_AND_RESTRICTIONS = 'list_usufructs_and_restrictions';
export const LIST_LEASEHOLDS = 'list_leaseholds';
export const FETCH_OWN_APARTMENTS = 'fetch_own_apartments';
export const FETCH_APARTMENTS_BY_SSN = 'fetch_apartments_by_ssn';
export const REALESTATES_BY_SSN = 'realestates_by_ssn';
export const LIST_OWN_ACTIVITIES_BY_SSN = 'list_own_activities_by_ssn';
export const REAL_ESTATE_TRANSFER_ANNOUNCEMENT = 'real_estate_transfer_announcement';
export const CADASTRAL_SURVEYS = 'cadastral_surveys';
export const GET_AVAILABILITY = 'get_availability';
export const FETCH_REAL_ESTATE_LEGAL_CONFIRMATION = 'fetch_real_estate_legal_confirmation';
export const ACTIVITY_REGISTRATION_DETAILS = 'activity_registration_details';
export const ACTIVITY_REGISTRATION_DETAILS_BY_SSN = 'activity_registration_details_by_ssn';
export const VIEW_GENERIC_LEGALCONFIRMATION = 'view_generic_legalconfirmation_form';
export const VIEW_PARTITIONING_LEGALCONFIRMATION = 'view_partitioning_legalconfirmation_form';
export const VIEW_LEASEHOLD_REGISTRATION_FORM = 'view_leasehold_registration_form';
export const LEGALCONFIRMATION_DETAILS = 'legalconfirmation_details';
export const MORTGAGE_DETAILS = 'mortgage_details';
export const LICENCED_DATA = 'licenced_data';
export const LIST_ACTIVITY_DECISIONS = 'list_activity_decisions';
export const NLS_USER = 'nls_user';
export const GET_APARTMENT_OWNER_BASIC_INFO = 'get_apartment_owner_basic_info';
export const GET_APARTMENT_OWNER_BASIC_INFO_WITH_SSN = 'get_apartment_owner_basic_info_with_ssn';
export const GET_APARTMENT_PLEDGES = 'get_apartment_pledges';
export const GET_APARTMENT_PLEDGES_WITH_SSN = 'get_apartment_pledges_with_ssn';
export const GET_SUPPLEMENT_REQUESTS = 'get_supplement_requests';
export const GET_APARTMENT_SHARE_GROUP_RESTRICTIONS = 'get_apartment_share_group_restrictions';
export const GET_APARTMENT_SHARE_GROUP_RESTRICTIONS_WITH_SSN = 'get_apartment_share_group_restrictions_with_ssn';
export const APARTMENT_DOCUMENTS_DIRECT_DOWNLOAD = 'apartment_documents_direct_download';
export const FETCH_RESOLVED_CADASTRAL_ACTIVITIES = 'fetch_resolved_cadastral_activities';
export const GET_APARTMENT_UNREGISTERED_OWNERSHIP = 'get_apartment_unregistered_ownership';
export const GENERAL_REGISTRATION_APPLICATION = 'general_registration_application';
export const SEND_TITLE_DEED_TO_KILU_ONLY = 'send_title_deed_to_kilu_only';
export const GET_APARTMENT_SHARE_GROUPS = 'get_apartment_share_groups';
export const GET_SHARE_GROUP_OWNER_ADDRESS = 'get_share_group_owner_address';
export const GET_SHARE_GROUP_OWNER_ADDRESS_WITH_SSN = 'get_share_group_owner_address_with_ssn';

// UPDATE PRIVILEGES
export const SUBMIT_CADASTRAL_FORM = 'submit_cadastral_form';
export const SUBMIT_LEGAL_CONFIRMATION = 'submit_legal_confirmation';
export const SUBMIT_REAL_ESTATE_TRANSFER_ANNOUNCEMENT = 'submit_real_estate_transfer_announcement';
export const SUBMIT_SUPPLEMENT_REGISTRATION = 'submit_supplement_registration';
export const SUBMIT_GENERIC_LEGALCONFIRMATION = 'submit_generic_legalconfirmation_form';
export const SUBMIT_LEASEHOLD_REGISTRATION = 'submit_leasehold_registration_form';
export const SUBMIT_LEASEHOLD_TRANSFER = 'submit_leasehold_registration_form';
export const SUBMIT_OWNERSHIP_REGISTRATION = 'submit_ownership_registration_form';

export const HANDLE_CERTIFICATE_OPENING = 'handleCertificateOpening';
export const HANDLE_REGISTRATION_MAP_OPENING = 'handleRegistrationMapDocumentOpening';
export const GET_REGISTRATION_MAP_XML = 'getRegistrationMapDocumentXml';
export const GET_CUSTOM_MAP_PDF_LINK = 'get_custom_map_pdf_link';
export const PRINT_FORM_SUMMARY_PAGE = 'print_form_summary_page';
