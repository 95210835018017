import React, { useCallback, useEffect, useId, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, useField, useForm } from 'react-final-form';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { mapIsReady, getOskariLayers } from 'oskari/OskariMap';
import { Glyphicon } from 'common/components';
import { RadioButtonBlock } from 'common/containers/Forms/Blocks';
import { zoomToThunk, toggleLoadingAnimationThunk } from 'common/containers/OskariMap/OskariMapActions';
import * as OskariMap from 'oskari/OskariMap';

import { ErrorBox } from 'realEstateSite/containers/Forms/InputValidationComponents';
import { toggleMapLayerVisibilityThunk } from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import mapSheetInfo from 'styles/img/map-sheet-info.svg';

import useOskari from 'common/hooks/useOskari';
import usePrevLang from 'common/hooks/usePrevLang';
import {
  AREA_SELECTION_TYPES,
  FIELD_NAMES,
  LATURI_MAP_LAYERS,
  YEAR_SELECTION_TYPES,
  YEAR_SELECTION_PRODUCTS,
  ORTHOPHOTO,
  BUILDINGS_3D,
  LASER_SCANNING_DATA_5P,
} from '../../FileDownloadServiceConstants';
import {
  clearMapLayersThunk,
  clearMapSheetSelectionThunk,
  setMapSheetLayerVisibilityThunk,
  startMapSheetInfoToolAction,
  startSheetSelectionByRectangleThunk,
  startSheetSelectionThunk,
  stopMapSheetInfoTool,
  stopSheetSelectionByRectangleThunk,
  stopSheetSelectionThunk,
} from '../../FileDownloadServiceAction';
import { getFeatureStyle, getMapLayerId, getProduct, useAreaSelectionTypes } from '../../FileDownloadServiceHelpers';
import { fetchCity3dMapSheets } from '../../FileDownloadServiceApi';
import useMapSheetAreas from './useMapSheetAreas';
import { LAYER_3D_BUILDINGS_SHEETS, LAYER_ORTHOPHOTO_SHEETS } from '../../../../../oskari/layers/VectorLayerIds';
import { clearMapSheetAvailabilityThunk } from '../../FileDownloadServiceAction/MapActions';
import SelectedMapSheetsComponent from './SelectedMapSheetsComponent';

const getCorrectProductLayer = (product, type, fileFormat, yearSelectionType) => {
  if (product.id === BUILDINGS_3D) return LAYER_3D_BUILDINGS_SHEETS;
  if (product.id === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.YEAR) return LAYER_ORTHOPHOTO_SHEETS;
  return getMapLayerId(product.mapLayers[type.value] || product.mapLayers[fileFormat.value]);
};

const MapSheetSelection = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { channelReady } = useOskari();
  const { change, getState, getFieldState } = useForm();
  const dispatch = useDispatch();
  const { prevLang } = usePrevLang();

  const areaSelectionTypes = useAreaSelectionTypes();

  const {
    input: { value: productId },
  } = useField(FIELD_NAMES.PRODUCT_ID);

  const {
    input: { value: type },
  } = useField(FIELD_NAMES.PRODUCT_TYPE);

  const fileFormat = getFieldState(FIELD_NAMES.FILE_FORMAT)?.value || '';

  const {
    input: { value: yearSelectionType },
  } = useField(FIELD_NAMES.YEAR_SELECTION_TYPE);

  const [activeButton, setActiveButton] = useState('');
  const [previousZoomLevel, setPreviousZoomLevel] = useState(null);

  const sheetSelectionErrorId = useId();

  const { meta: noMapSheets } = useField(FIELD_NAMES.NO_MAP_SHEETS);

  const toggleMapSheetAvailability = useMapSheetAreas();

  const product = getProduct(productId);

  const mapSheetLayer =
    product.mapLayers && getMapLayerId(product.mapLayers[type.value] || product.mapLayers[fileFormat.value]);

  const mapLayers = useSelector(state => state.mapLayers);

  const currentZoomLevel = useSelector(state => state.oskariIframe.zoom);

  const buttonClasses = classNames('button', 'button--icon', 'button--area-selection');

  const { submitFailed } = getState();
  const correctMapLayers =
    (product.id === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.YEAR) || productId === BUILDINGS_3D
      ? LATURI_MAP_LAYERS
      : mapLayers;
  const { minZoom } =
    (mapSheetLayer && correctMapLayers.find(layer => getMapLayerId(layer.id) === mapSheetLayer)) || {};
  const zoomMap = minZoom >= currentZoomLevel;

  const {
    input: { value: areaSelectionType },
  } = useField(FIELD_NAMES.AREA_SELECTION_TYPE);

  const setMapZoomLevel = useCallback(async () => {
    if (channelReady) {
      await mapIsReady();
      dispatch(zoomToThunk(minZoom));
    }
  }, [dispatch, minZoom, channelReady]);

  const startMapSheetClickSelect = useCallback(() => {
    setActiveButton('select-map-sheet-single');
    dispatch(stopSheetSelectionByRectangleThunk());
    dispatch(startSheetSelectionThunk());
    stopMapSheetInfoTool();
  }, [dispatch]);

  const startMapSheetRectangleSelect = useCallback(() => {
    setActiveButton('select-map-sheet-multiple');
    dispatch(stopSheetSelectionThunk());
    dispatch(startSheetSelectionByRectangleThunk());
    stopMapSheetInfoTool();
  }, [dispatch]);

  const startMapSheetInfoTool = useCallback(() => {
    setActiveButton('map-sheet-info');
    dispatch(stopSheetSelectionByRectangleThunk());
    dispatch(stopSheetSelectionThunk());
    dispatch(startMapSheetInfoToolAction(productId));
  }, [dispatch, productId]);

  const showMapSheetLayer = useCallback(() => {
    if (areaSelectionType !== AREA_SELECTION_TYPES.SELECT_SHEETS) return;

    if (product.id === ORTHOPHOTO && yearSelectionType === YEAR_SELECTION_TYPES.YEAR && zoomMap) {
      setMapZoomLevel();
      return;
    }
    // Possible if map sheet selection is configured to workers but not on frontend.
    if (!product.mapLayers) return;
    const productLayer = getCorrectProductLayer(product, type, fileFormat, yearSelectionType);

    dispatch(clearMapLayersThunk(productLayer));
    dispatch(setMapSheetLayerVisibilityThunk(productLayer, true));

    toggleMapSheetAvailability();

    if (zoomMap) setMapZoomLevel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, dispatch, type, fileFormat, setMapZoomLevel, areaSelectionType]);

  useEffect(() => {
    if (productId === BUILDINGS_3D && currentZoomLevel >= minZoom) {
      dispatch(toggleLoadingAnimationThunk(true));
      fetchCity3dMapSheets().then(data => {
        OskariMap.drawGeoJson(LAYER_3D_BUILDINGS_SHEETS, data, {
          featureStyle: getFeatureStyle(productId),
          clearPrevious: true,
        });
        dispatch(toggleLoadingAnimationThunk(false));
      });
    } else {
      OskariMap.clearLayer(LAYER_3D_BUILDINGS_SHEETS);
    }
  }, [currentZoomLevel, dispatch, minZoom, productId]);

  useEffect(() => {
    if (areaSelectionType) {
      if (areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS || language !== prevLang) {
        startMapSheetClickSelect();
      } else {
        dispatch(stopSheetSelectionThunk());
        dispatch(clearMapSheetSelectionThunk());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, areaSelectionType, startMapSheetClickSelect]);

  useEffect(() => {
    showMapSheetLayer();
    return () => dispatch(clearMapSheetAvailabilityThunk());
  }, [showMapSheetLayer, dispatch]);

  useEffect(() => {
    setTimeout(async () => {
      startMapSheetClickSelect();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, channelReady]);

  useEffect(() => {
    if (areaSelectionType !== AREA_SELECTION_TYPES.SELECT_SHEETS) {
      dispatch(clearMapLayersThunk());
    }
  }, [areaSelectionType, dispatch]);

  const oskariMapLayers = getOskariLayers();

  useEffect(() => {
    dispatch(toggleMapLayerVisibilityThunk(oskariMapLayers.taustakartta, true));
    return () => {
      dispatch(stopSheetSelectionThunk());
      dispatch(clearMapLayersThunk());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (areaSelectionTypes?.length === 1) {
      change(FIELD_NAMES.AREA_SELECTION_TYPE, AREA_SELECTION_TYPES.SELECT_SHEETS);
    }
  }, [change, areaSelectionTypes]);

  useEffect(() => {
    startMapSheetClickSelect();
  }, [yearSelectionType, startMapSheetClickSelect]);

  useEffect(() => {
    if (currentZoomLevel !== previousZoomLevel) {
      if (previousZoomLevel != null) {
        toggleMapSheetAvailability();
      }
      setPreviousZoomLevel(currentZoomLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZoomLevel, toggleMapSheetAvailability]);

  const setLabel = () => {
    if (productId === LASER_SCANNING_DATA_5P) {
      return t('fileDownloadService.areaSelection.selectSheets.label.laser5p');
    }
    if (productId === BUILDINGS_3D && yearSelectionType === YEAR_SELECTION_TYPES.YEAR) {
      return t('fileDownloadService.areaSelection.selectSheets.label.3d', {
        amount: '100',
      });
    }
    return t('fileDownloadService.areaSelection.selectSheets.label.basic', {
      amount: '100',
    });
  };

  return (
    <RadioButtonBlock
      id="mapSheetAreaSelection"
      value={AREA_SELECTION_TYPES.SELECT_SHEETS}
      name={FIELD_NAMES.AREA_SELECTION_TYPE}
      label={setLabel()}
      className="margin-b-1"
      errorId={submitFailed && noMapSheets?.error ? sheetSelectionErrorId : null}
      extraBlockContent={
        <>
          <button
            type="button"
            disabled={areaSelectionType !== AREA_SELECTION_TYPES.SELECT_SHEETS}
            onClick={startMapSheetClickSelect}
            className={classNames(buttonClasses, { active: activeButton === 'select-map-sheet-single' })}
            title={t('fileDownloadService.areaSelection.selectSheets.click')}
          >
            <Glyphicon glyph="select-map-sheet-single" />
          </button>
          {productId !== BUILDINGS_3D && yearSelectionType !== YEAR_SELECTION_TYPES.YEAR && (
            <button
              type="button"
              disabled={areaSelectionType !== AREA_SELECTION_TYPES.SELECT_SHEETS}
              onClick={startMapSheetRectangleSelect}
              className={classNames(buttonClasses, 'indent-block-1', {
                active: activeButton === 'select-map-sheet-multiple',
              })}
              title={t('fileDownloadService.areaSelection.selectSheets.rectangle')}
            >
              <Glyphicon glyph="select-map-sheet-multiple" />
            </button>
          )}
          {((productId === ORTHOPHOTO && YEAR_SELECTION_PRODUCTS.includes(productId)) ||
            productId === LASER_SCANNING_DATA_5P) && (
            <button
              type="button"
              disabled={areaSelectionType !== AREA_SELECTION_TYPES.SELECT_SHEETS}
              className={classNames(buttonClasses, 'indent-block-1', 'map-sheet-info', {
                active: activeButton === 'map-sheet-info',
              })}
              onClick={startMapSheetInfoTool}
              title={t('fileDownloadService.areaSelection.selectSheets.mapSheetInfo')}
            >
              <img src={mapSheetInfo} alt="" />
            </button>
          )}
          <SelectedMapSheetsComponent
            areaSelectionType={areaSelectionType}
            askUserToZoomMap={currentZoomLevel < minZoom && areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS}
            submitFailed={submitFailed}
          />
          <Field
            elementId={sheetSelectionErrorId}
            name={`${FIELD_NAMES.NO_MAP_SHEETS}`}
            component={ErrorBox}
            className="margin-b-1"
          />
        </>
      }
    />
  );
};

export default MapSheetSelection;
