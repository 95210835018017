import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import { t } from 'i18next';

const AmountSelector = ({ amount, className = '', decrease, increase, disabled = false }) => (
  <div className={cls('amount-selector__wrapper margin-b-2', className)}>
    <button
      type="button"
      aria-label={t('button.remove')}
      disabled={disabled}
      className="button--blue amount-selector__button"
      onClick={decrease}
    >
      <Glyphicon glyph="minus" />
    </button>
    <input
      aria-label={t('amountSelector.quantity')}
      type="text"
      className="text-input amount-selector__input"
      value={amount}
      required
      pattern="[0-9]*"
      name="quantity"
      readOnly
    />
    <button
      type="button"
      aria-label={t('button.add')}
      className="button--blue amount-selector__button-increase"
      onClick={increase}
    >
      <Glyphicon glyph="plus" />
    </button>
  </div>
);

AmountSelector.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
  decrease: PropTypes.func.isRequired,
  increase: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default AmountSelector;
