import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import cls from 'classnames';
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from 'common/components/Form/components/Error';
import { ACCEPTED_FILES_JPG_PDF } from './UploaderConstants';
import { Glyphicon } from '../Icon';
import UploaderContext from './UploaderContext';

const newFileId = () => uniqueId(['upload', new Date().getTime()].join('-'));

const UploaderInput = ({
  accept = ACCEPTED_FILES_JPG_PDF,
  disabled = false,
  name = 'file',
  maxSize = 20000000,
  isError = false,
  errorText = 'uploader.defaultFilesError',
  ariaDescribedby = null,
  errorId = null,
  fileRequired = false,
  id,
  setUploads,
}) => {
  const { t } = useTranslation();
  const { errorMessage, setErrorMessage } = useContext(UploaderContext);

  const onDropAccepted = useCallback(
    files => {
      setErrorMessage(null);
      setUploads(prevUploads => [...prevUploads, ...files.map(file => ({ id: newFileId(), file }))]);
    },
    [setErrorMessage, setUploads]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDropRejected = useCallback(() => setErrorMessage(t(errorText)), [errorText]);

  const wrapperStyles = useCallback(
    () =>
      cls('uploader__dropzone', {
        'file-input__error': isError,
        'file-input__disabled': disabled,
      }),
    [disabled, isError]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxSize,
    disabled,
    accept,
    onDropAccepted,
    onDropRejected,
  });

  return (
    <>
      {errorMessage && <ErrorMessage message={errorMessage} />}
      <div
        {...getRootProps({
          className: wrapperStyles(),
          // Note: This is needed so clicking doesn't trigger file selection twice when uploader is wrapped in a label
          onClick: evt => evt.preventDefault(),
        })}
        aria-describedby={errorId}
      >
        <input {...getInputProps({ id, name })} aria-describedby={ariaDescribedby} aria-invalid={isError} />
        <button tabIndex="-1" type="button" className="button button--link">
          {t('uploader.helpText1')}
        </button>
        &nbsp;{t('uploader.helpText2')}
        {fileRequired && <Glyphicon glyph="required" className="validation--required" />}
      </div>
    </>
  );
};

UploaderInput.propTypes = {
  id: PropTypes.string.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  maxSize: PropTypes.number,
  isError: PropTypes.bool,
  errorText: PropTypes.string,
  ariaDescribedby: PropTypes.string,
  setUploads: PropTypes.func.isRequired,
  errorId: PropTypes.string,
  fileRequired: PropTypes.bool,
};

export default UploaderInput;
