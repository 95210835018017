// Geometry Types (some also representing state slices)
export const OWN = 'own';
export const SEARCHED = 'searched';
export const OTHER = 'other';
export const LCAG = 'lcag';
export const LCA = 'lca';
export const DEATH_ESTATE_LCA = 'deathEstateLca';
export const PARTITIONING_LCA = 'partitioningLca';
export const LTA = 'lta';
export const LRA = 'lra';
export const OSRA = 'osra';
export const GRA = 'gra';
export const PRS = 'prs';
export const BD = 'bd';
export const ES = 'es';
export const OCS = 'ocs';
export const PSS = 'pss';
export const RAS = 'ras';
export const RETA = 'reta';
export const ACTIVITY_LOG = 'activityLog';
export const ALL = 'all';
export const SELECTED = 'selected';
export const USUFRUCT = 'usufruct';
export const JPUS = 'jpus';
export const ROOT_REALTY = 'rootRealty';
export const AOR = 'aor';
export const NEIGHBOUR_REAL_ESTATES = 'neighbourRealEstates';
export const MISSING = 'missingGeometries';

export const COMPLEX_GEOMETRY_POLYGON_POINTS_MINIMUM = 50000;

export const geometryTypes = [
  OWN,
  SEARCHED,
  OTHER,
  LCAG,
  LCA,
  DEATH_ESTATE_LCA,
  PARTITIONING_LCA,
  LTA,
  LRA,
  PRS,
  BD,
  ES,
  OCS,
  PSS,
  RAS,
  RETA,
  ACTIVITY_LOG,
  OSRA,
  ALL,
  SELECTED,
  USUFRUCT,
  JPUS,
  ROOT_REALTY,
  AOR,
  NEIGHBOUR_REAL_ESTATES,
  MISSING,
];
