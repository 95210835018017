export const USAGE_CODE = '05';
export const LOGGING_CODE = '35';
export const SOIL_CODE = '39';
export const USUFRUCT_CODE = '02';
export const PREMISE_USAGE_CODE = '13';
export const PREMISE_POSSESSION_CODE = '18';
export const SPLIT_CODE = '36';

export const OSRA_FIXED_TERM_DURATION_CODE = '1';

export const DURATIONS = [
  {
    code: OSRA_FIXED_TERM_DURATION_CODE, // Määräaikainen
    label: 'osra.step2.duration.option.fixedTerm.label',
  },
  {
    code: '2', // Toistaiseksi voimassa oleva
    label: 'osra.step2.duration.option.openEnded.label',
  },
  {
    code: '3', // Elinikäinen
    label: 'osra.step2.duration.option.lifetime.label',
  },
];

const CONTRACT_DAY_OF_SIGNING = 'osra.step2.contractDayOfSigning';
const DAY_OF_SIGNING = 'osra.step2.dayOfSigning';
const DATE = 'osra.step2.date';

export const PREMISE_TYPES = [
  {
    code: PREMISE_USAGE_CODE, // Käyttöoikeussopimus
    documentCode: '19',
    description: 'osra.step2.transferType.option.usageContract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: PREMISE_POSSESSION_CODE, // Hallinnanjakosopimus
    documentCode: '21',
    description: 'osra.step2.transferType.option.possessionContract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: '01', // Eläkeoikeussopimus
    documentCode: '12',
    description: 'osra.step2.transferType.option.pensionContract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: '28', // Metsänhakkuuoikeussopimus
    documentCode: '18',
    description: 'osra.step2.transferType.option.loggingContract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: '27', // Irrottamisoikeussopimus
    documentCode: '17',
    description: 'osra.step2.transferType.option.removalContract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: '06', // Sopimus
    documentCode: '13',
    description: 'osra.step2.transferType.option.contract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: '02', // Kauppakirja
    documentCode: '02',
    description: 'osra.step2.transferType.option.billOfContract.label',
    dateLabel: DAY_OF_SIGNING,
  },
  {
    code: '03', // Lahjakirja
    documentCode: '04',
    description: 'osra.step2.transferType.option.giftContract.label',
    dateLabel: DAY_OF_SIGNING,
  },
  {
    code: '05', // Perinnönjakokirja
    documentCode: '08',
    description: 'osra.step2.transferType.option.estateDistributionContract.label',
    dateLabel: DATE,
  },
  {
    code: '29', // Testamentti:
    documentCode: '20',
    description: 'osra.step2.transferType.option.willContract.label',
    dateLabel: DATE,
  },
  {
    code: '10', // Vuokrasopimus
    documentCode: '16',
    description: 'osra.step2.transferType.option.leaseContract.label',
    dateLabel: CONTRACT_DAY_OF_SIGNING,
  },
  {
    code: '32', // Muu asiakirja
    documentCode: '49',
    description: 'osra.step2.transferType.option.otherContract.label',
    dateLabel: DAY_OF_SIGNING,
  },
];

export const RIGHT_TYPES = [
  {
    code: USAGE_CODE, // Käyttö-, hallinta- tai asumisoikeus
    description: 'osra.step1.transferType.option.usage.label',
  },
  {
    code: SPLIT_CODE, // Kiinteistön hallinnan jakaminen
    description: 'osra.step1.transferType.option.split.label',
  },
  {
    code: '33', // Eläkeoikeus
    description: 'osra.step1.transferType.option.pension.label',
  },
  {
    code: LOGGING_CODE, // Metsänhakkuuoikeus
    description: 'osra.step1.transferType.option.logging.label',
  },
  {
    code: SOIL_CODE, // Oikeus ottaa maa-aineksia
    description: 'osra.step1.transferType.option.soil.label',
  },
  {
    code: '42', // Muu oikeus"
    description: 'osra.step1.transferType.option.other.label',
  },
];

export const USAGE_RIGHT_TYPES = [
  {
    code: USUFRUCT_CODE, // Käyttöoikeus
    description: 'osra.step1.transferType.option.usage.usufruct.label',
  },
  {
    code: '06', // Hallintaoikeus
    description: 'osra.step1.transferType.option.usage.possession.label',
  },
  {
    code: '09', // Hallinta- ja käyttöoikeus
    description: 'osra.step1.transferType.option.usage.usufructAndPossession.label',
  },
  {
    code: '07', // Asumisoikeus
    description: 'osra.step1.transferType.option.usage.occupancy.label',
  },
  {
    code: '08', // Asumis- ja käyttöoikeus
    description: 'osra.step1.transferType.option.usage.occupancyAndUsufruct.label',
  },
  {
    code: USAGE_CODE, // Hallinta-, asumis- ja käyttöoikeus
    description: 'osra.step1.transferType.option.usage.possessionAndOccupancyAndUsufruct.label',
  },
  {
    code: '12', // Asumis- ja käyttöoikeus kiinteistön rakennuksiin
    description: 'osra.step1.transferType.option.usage.occupancyAndUsufructForRealEstateBuildings.label',
  },
  {
    code: '11', // Käyttöoikeus kiinteistöllä oleviin rakennuksiin
    description: 'osra.step1.transferType.option.usage.usufructForRealEstateBuildings.label',
  },
  {
    code: '22', // Kaivon käyttöoikeus
    description: 'osra.step1.transferType.option.usage.wellUsufruct.label',
  },
  {
    code: '19', // Kulkuoikeus
    description: 'osra.step1.transferType.option.usage.passage.label',
  },
  {
    code: '28', // Vapaa-ajan asunnon käyttöoikeus
    description: 'osra.step1.transferType.option.usage.secondHomeUsufruct.label',
  },
  {
    code: '27', // Metsänkäyttöoikeus
    description: 'osra.step1.transferType.option.usage.forestUsufruct.label',
  },
  {
    code: '25', // Metsästysoikeus
    description: 'osra.step1.transferType.option.usage.huntingUsufruct.label',
  },
  {
    code: '15', // Rakentamis- ja käyttöoikeus tiehen
    description: 'osra.step1.transferType.option.usage.buildingAndUsufruct.label',
  },
  {
    code: '21', // Vedenjohto-oikeus
    description: 'osra.step1.transferType.option.usage.waterRedirectionUsufruct.label',
  },
];

export const FOREST_USUFRUCT_CATEGORY_TYPES = [
  {
    code: LOGGING_CODE, // Metsänhakkuuoikeus
    description: 'osra.step1.transferType.option.logging.label',
  },
  {
    code: '30', // Poltto- ja tarvepuun otto-oikeus
    description: 'osra.step1.transferType.option.usage.firewood.label',
  },
];

export const SOIL_CATEGORY_TYPES = [
  {
    code: '37', // Irrottamisoikeus
    description: 'osra.step1.transferType.option.usage.removal.label',
  },
  {
    code: SOIL_CODE, // Maa-ainesten irrottamisoikeus
    description: 'osra.step1.transferType.option.usage.soilRemoval.label',
  },
  {
    code: '38', // Soranotto-oikeus
    description: 'osra.step1.transferType.option.usage.gravel.label',
  },
];

export const OSRA_HANDLE_PRESUBMIT_TASKS_PAGE = 3;

export const OSRA_FIELDS = {
  OSRA_ATTACHMENTS: 'osraAttachments',
  OTHER_ATTACHMENTS: 'otherAttachments',
  TRANSFER_RIGHT: 'transferRight',
  PREMISE: 'premise',
  PREMISE_DATE: 'premise_date',
  STARTING_DATE: 'starting_date',
  DURATION: 'duration',
  FIXED_TERM_ENDING_DATE: 'fixed_term_ending_date',
  BUILDING_IN_AREA: 'buildingInArea',
  ALLOWED_TO_TRANSFER: 'allowedToTransfer',
  TRANSFER_RIGHT_CHOICE: 'transferRightChoice',
  FOREST_USUFRUCT_CHOICE: 'forestUsufructChoice',
  SOIL_CHOICE: 'soilChoice',
  TARGETS: 'targets',
  NOT_WHOLE_AREA: 'notWholeArea',
  RECIPIENTS: 'recipients',
  DONORS: 'donors',
};

export const OSRA_FIELD_VALUES = {
  PORTION_TYPE: {
    WHOLE_OR_PORTION: 'WHOLE_OR_PORTION_PORTION_TYPE',
    PROPERTY: 'PROPERTY_PORTION_TYPE',
    SHARED_AREA: 'SHARED_AREA_PORTION_TYPE',
  },
};
