import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Glyphicon } from 'common/components/Icon';
import { Field } from 'react-final-form';
import HeadingWithSteps from 'common/components/HeadingWithSteps';
import { Header } from 'common/components/Header';
import Ruler from 'common/components/Ruler';
import { Block, FlexGrid, Row } from 'common/components/Grid';
import RouterLink from 'common/components/Link/RouterLink';
import { t } from 'i18next';
import { ValidatedRadioButton } from 'common/containers/Forms/InputValidation/InputValidationComponents';
import InfoBox from 'common/components/InfoBox';
import MapButton from 'realEstateSite/containers/Realty/RealEstate/common/MapButtonComponent';
import { Heading, Paragraph } from 'common/components/index';
import { requestKeyboardFocusAction } from '../AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';

const HeadingRow = React.forwardRef(
  (
    {
      text,
      step = null,
      numberOfSteps = null,
      headingLevel = 'h1',
      className = '',
      headerClassName = '',
      subtitleText = undefined,
    },
    ref
  ) => (
    <div ref={ref} tabIndex={-1} className={cls('heading-row', className)}>
      <Row>
        <Block size={6}>
          {step ? (
            <HeadingWithSteps
              text={text}
              className={headerClassName}
              currentStep={step}
              numberOfSteps={numberOfSteps}
              subtitle={subtitleText}
            />
          ) : (
            <Header text={text} level={headingLevel} className={headerClassName} />
          )}
        </Block>
      </Row>
      <Ruler />
    </div>
  )
);

HeadingRow.propTypes = {
  text: PropTypes.string.isRequired,
  subtitleText: PropTypes.string,
  step: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  numberOfSteps: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  headingLevel: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
};

export const InfoRow = ({ title, text = null, text2 = null }) => (
  <Row>
    <Block size={6}>
      <Heading level="h2">{title}</Heading>
    </Block>
    {(text || text2) && (
      <Block size={6}>
        {text && <Paragraph>{text}</Paragraph>}
        {text2 && <Paragraph>{text2}</Paragraph>}
      </Block>
    )}
  </Row>
);

InfoRow.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  text2: PropTypes.string,
};

export const InfoRowWithMapButton = ({
  title,
  paragraph1 = null,
  paragraph2 = null,
  paragraph3 = null,
  Element = 'h2',
  className = '',
}) => (
  <Row className={className}>
    <Block size={6} className="info-row__with-button">
      <Heading level={Element}>{title}</Heading>
      <MapButton className="button--icon" />
    </Block>
    <Block size={6}>
      {paragraph1 && <p className="margin-b-1">{paragraph1}</p>}
      {paragraph2 && <p className="margin-b-1">{paragraph2}</p>}
      {paragraph3 && <p className="margin-b-0">{paragraph3}</p>}
    </Block>
  </Row>
);

InfoRowWithMapButton.propTypes = {
  title: PropTypes.string.isRequired,
  paragraph1: PropTypes.string,
  paragraph2: PropTypes.string,
  paragraph3: PropTypes.string,
  Element: PropTypes.string,
  className: PropTypes.string,
};

export const ContentRow = ({
  title,
  text = null,
  testId = null,
  descriptionId = null,
  className = '',
  children = null,
  h2 = false,
  additionalDesc = null,
}) => (
  <Row className={cls(className)}>
    <Block dataTestId={testId} size={6}>
      <Heading level={h2 ? 'h2' : 'h3'}>{title}</Heading>
      {text && <p id={descriptionId}>{text}</p>}
      {additionalDesc && <p className="margin-t-1">{additionalDesc}</p>}
      {children}
    </Block>
  </Row>
);

ContentRow.propTypes = {
  title: PropTypes.string.isRequired,
  testId: PropTypes.string,
  text: PropTypes.string,
  descriptionId: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  h2: PropTypes.bool,
  additionalDesc: PropTypes.string,
};

const getTextWithSuffix = (text, suffix) => {
  if (suffix === ' m2') {
    return <span> {text} m&sup2; </span>;
  }
  return `${text} ${suffix}`;
};

export const FormStaticContentBlock = ({ label = '', text, suffix = null }) => (
  <>
    {label && <p className="bold">{label}</p>}
    <p>{suffix ? getTextWithSuffix(text, suffix) : text}</p>
  </>
);

FormStaticContentBlock.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
  suffix: PropTypes.string,
};

const WrappedComponent = ({ isWrapped, wrapper, className = '', children }) =>
  isWrapped ? wrapper(children) : <span className={className}>{children}</span>;

WrappedComponent.propTypes = {
  isWrapped: PropTypes.bool.isRequired,
  wrapper: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const FormContentGroup = ({
  isLegendHidden = false,
  LegendElement = 'strong',
  legendClass = '',
  required = false,
  invalid = null,
  desc = null,
  desc2 = null,
  noMargin = false,
  className = '',
  hasCustomRequiredMarker = false,
  legend,
  children,
}) => (
  <fieldset
    aria-describedby={desc && `${legend.replace(/ /g, '')}-desc`}
    className={cls(className, { 'margin-b-1': !noMargin })}
    /* axe would report this as aria-allowed-attr violation, although it seems to be allowed with fieldset
       and screen reader is reading this as required group.
    */
    aria-invalid={invalid}
    {...(required ? { 'aria-required': true } : {})}
  >
    <legend
      className={cls('grid-block', 'grid-block-6', 'margin-b-1', {
        'visually-hidden': isLegendHidden,
      })}
    >
      <Heading level={LegendElement} className={cls(legendClass)}>
        {legend}
        {required && !hasCustomRequiredMarker && <Glyphicon glyph="required" className="validation--required" />}
      </Heading>
    </legend>
    {desc && (
      <Block size={6}>
        {desc2 && <Paragraph id={`${legend.replace(/ /g, '')}-desc2`}>{desc2}</Paragraph>}
        <Paragraph id={`${legend.replace(/ /g, '')}-desc`}>{desc}</Paragraph>
      </Block>
    )}
    {children}
  </fieldset>
);

FormContentGroup.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.string.isRequired,
  LegendElement: PropTypes.string,
  legendClass: PropTypes.string,
  isLegendHidden: PropTypes.bool,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  desc: PropTypes.string,
  desc2: PropTypes.string,
  noMargin: PropTypes.bool,
  className: PropTypes.string,
  hasCustomRequiredMarker: PropTypes.bool,
};

// Only accepts one child, else throws an error
export const FormContentBlock = ({
  isLabelHidden = false,
  LabelElement = 'strong',
  size = 6,
  desktopSize = null,
  desc = null,
  additionalDesc = null,
  additionalDesc2 = null,
  required = false,
  className = '',
  noMargin = false,
  additionalDescMargin = false,
  visualLabel = undefined,
  isVisualLabelHidden = true,
  heading = undefined,
  id,
  label,
  children,
}) => (
  <Block size={size} desktop={desktopSize || size} className={cls({ 'margin-b-1': !noMargin }, className)}>
    <div className="content-header">
      {visualLabel && (
        <LabelElement className={cls({ 'visually-hidden': isVisualLabelHidden })}>
          {visualLabel}
          {required && <Glyphicon glyph="required" className="validation--required" />}
        </LabelElement>
      )}
      <LabelElement className={cls({ 'visually-hidden': isLabelHidden })}>
        <label htmlFor={children.props?.id || id}>{label}</label>
        {required && <Glyphicon glyph="required" className="validation--required" />}
      </LabelElement>
      {additionalDesc2 && <Paragraph>{additionalDesc2}</Paragraph>}
      {additionalDesc && (
        <Paragraph className={(additionalDescMargin && `margin-b-2`) || ''}>{additionalDesc}</Paragraph>
      )}
      {desc && <Paragraph id={`${id}-desc`}>{desc}</Paragraph>}
      {heading && <strong>{heading}</strong>}
    </div>
    {React.cloneElement(React.Children.only(children), {
      id: children.props?.id || id,
      required,
      ...(typeof children.type === 'object'
        ? { ariaDescribedby: desc && `${id}-desc` } // children is Component
        : { 'aria-describedby': desc && `${id}-desc` }), // children is element
    })}
  </Block>
);

FormContentBlock.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  LabelElement: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLabelHidden: PropTypes.bool,
  size: PropTypes.number,
  desktopSize: PropTypes.number,
  desc: PropTypes.string,
  additionalDesc: PropTypes.string,
  additionalDesc2: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  additionalDescMargin: PropTypes.bool,
  visualLabel: PropTypes.string,
  isVisualLabelHidden: PropTypes.bool,
  heading: PropTypes.string,
};

export const RadioButtonBlock = React.forwardRef(
  (
    {
      onChange = () => null,
      onClick = () => null,
      disabled = false,
      extraBlockContent = null,
      desktopWidth = 6,
      className = '',
      errorId = null,
      dataCy = '',
      id,
      label,
      name,
      value,
      description,
      labelClassName,
    },
    ref
  ) => (
    <Block size={6} desktop={desktopWidth} className={className}>
      <Field
        {...{ id, name, label, value, onChange, onClick, disabled, extraBlockContent, labelClassName }}
        type="radio"
        component={ValidatedRadioButton}
        ref={ref}
        errorId={errorId}
        dataCy={dataCy}
      />
      {description && <div className="radio--description">{description}</div>}
    </Block>
  )
);

RadioButtonBlock.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  extraBlockContent: PropTypes.node,
  desktopWidth: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  errorId: PropTypes.string,
  dataCy: PropTypes.string,
  description: PropTypes.string,
};

export const NavigationBlock = ({
  disabled = false,
  isFirst = false,
  finalButtonText = null,
  onPrevious = null,
  abortButtonText = 'button.abort',
  isSubmit = false,
  previousSelector = '',
  abortSelector = '',
  onAbort,
  onSubmit,
  disableSubmittedUsingEnter,
}) => {
  const dispatch = useDispatch();
  const [submittedWithEnter, setSubmittedWithEnter] = useState(false);

  const onAbortKeyDown = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      if (abortSelector) {
        dispatch(requestKeyboardFocusAction({ selector: abortSelector }));
      }
      event.currentTarget.click();
    }
  };

  const onPreviousKeyDown = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      if (previousSelector) {
        dispatch(requestKeyboardFocusAction({ selector: previousSelector }));
      }
      event.currentTarget.click();
    }
  };

  const onSubmitKeyDown = event => {
    if (event?.key === 'Enter') {
      event.preventDefault();
      setSubmittedWithEnter(true);
      event.currentTarget.click();
    }
  };

  const onSubmitClick = event => {
    if (!submittedWithEnter) {
      disableSubmittedUsingEnter();
    }
    onSubmit(event);
  };

  return (
    <Row>
      <Block size={6}>
        <div className="float-left text-left">
          <button onClick={onAbort} onKeyDown={onAbortKeyDown} type="button" className="button button--light">
            {t(abortButtonText)}
          </button>
        </div>
        <div className="float-right text-right">
          {!isFirst && (
            <Block>
              <button onClick={onPrevious} onKeyDown={onPreviousKeyDown} type="button" className="button button--blue">
                {t('button.previous')}
              </button>
            </Block>
          )}

          {finalButtonText ? (
            <button
              className="button button--orange"
              disabled={disabled}
              onClick={onSubmitClick}
              onKeyDown={onSubmitKeyDown}
              type={isSubmit ? 'submit' : 'button'} // added for PaytrailForm
            >
              {finalButtonText}
            </button>
          ) : (
            <button
              type={isSubmit ? 'submit' : 'button'}
              className="button button--blue"
              disabled={disabled}
              onClick={onSubmit}
              onKeyDown={onSubmitKeyDown}
            >
              {t('button.next')}
            </button>
          )}
        </div>
      </Block>
    </Row>
  );
};

NavigationBlock.propTypes = {
  disabled: PropTypes.bool,
  finalButtonText: PropTypes.string,
  isFirst: PropTypes.bool,
  onAbort: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  abortButtonText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  isSubmit: PropTypes.bool,
  previousSelector: PropTypes.string,
  abortSelector: PropTypes.string,
  disableSubmittedUsingEnter: PropTypes.func.isRequired,
};

export const ContinueBrowsingBlock = ({ to, selector = undefined }) => (
  <Block>
    <Paragraph className="continue-browsing">
      <RouterLink to={to} selector={selector}>
        {t('button.continue')}
      </RouterLink>
      <Glyphicon glyph="nuoli-oikealle" />
    </Paragraph>
  </Block>
);

ContinueBrowsingBlock.propTypes = {
  to: PropTypes.string.isRequired,
  selector: PropTypes.string,
};

export const ErrorBlock = ({ onError }) => (
  <FlexGrid className="form__container-bg">
    <HeadingRow text={t('error.general.function')} />
    <Row>
      <Block size={6} className="margin-t-1">
        <button type="button" onClick={onError} className="button button--link bold">
          <Glyphicon glyph="nuoli-oikealle" />
          <span className="indent-block-1">{t('error.general.tryAgain')}</span>
        </button>
      </Block>
    </Row>
  </FlexGrid>
);

ErrorBlock.propTypes = {
  onError: PropTypes.func.isRequired,
};

export const DataWillBeNotSendingNoticeBox = () => (
  <Row className="margin-b-1">
    <Block size={6} className="">
      <InfoBox className="margin-t-1 margin-b-1">
        <div>
          <p id="data-will-not-be-sending">{t('realEstate.form.noticebox.dataWillNotBeSending')}</p>
        </div>
      </InfoBox>
    </Block>
  </Row>
);

export default HeadingRow;
