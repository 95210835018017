import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { t } from 'i18next';

import { Badge, Block, Glyphicon, Row } from 'common/components';
import { uniqueId } from 'lodash';

const DEFAULT_DURATION = 500;

const CollapsibleButton = ({
  id,
  onToggle,
  isOpen = true,
  isSubHeadingButton = false,
  headingOpen = null,
  headingClosed = null,
  openLabel = t('button.collapsible.open.title'),
  closeLabel = t('button.collapsible.close.title'),
  hideLabel = true,
  openIcon = headingClosed != null ? 'nuoli-alas' : 'open-section',
  closeIcon = headingOpen != null ? 'nuoli-ylos' : 'close-section',
  className = null,
}) => {
  const showHeading = headingOpen !== null && headingClosed !== null;

  const getTitle = () => {
    if (showHeading) {
      return isOpen ? headingOpen : headingClosed;
    }

    return isOpen ? closeLabel : openLabel;
  };

  return (
    <button
      type="button"
      onClick={onToggle}
      className={cls(className, {
        button__collapsible: !showHeading,
        'button__collapsible--inline': showHeading,
        'sub-heading-button': isSubHeadingButton,
        'no-label': true,
      })}
      title={getTitle()}
      aria-label={getTitle()}
      aria-expanded={isOpen}
      aria-controls={id}
    >
      {showHeading && <>{getTitle()}&nbsp;</>}
      <Glyphicon glyph={cls(isOpen ? closeIcon : openIcon)} />
      {!hideLabel && <>&nbsp;{isOpen ? closeLabel : openLabel}</>}
    </button>
  );
};

CollapsibleButton.propTypes = {
  id: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isSubHeadingButton: PropTypes.bool,
  headingOpen: PropTypes.string,
  headingClosed: PropTypes.string,
  openLabel: PropTypes.string,
  closeLabel: PropTypes.string,
  hideLabel: PropTypes.bool,
  openIcon: PropTypes.string,
  closeIcon: PropTypes.string,
  className: PropTypes.string,
};

const Collapsible = ({
  isOpen = false,
  unmountOnExit = true,
  customToggle = null,
  hideCollapsibleButton = false,
  hasSubHeading = false,
  subHeading = null,
  toggleButton = {},
  badgeValue = null,
  heading,
  children,
  className,
  showCalculator = true,
  isFilter = false,
}) => {
  const [isContentOpen, setIsContentOpen] = useState(isOpen);

  const toggle = () => setIsContentOpen(prev => !prev);
  const id = uniqueId('collapsible__content');

  // NOTE: handle Collapsible used as a controlled component
  useEffect(() => {
    setIsContentOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Row>
        <Block size={6} className={cls(className, 'collapsible-toggle')}>
          {heading && <span>{heading}</span>}
          {!hasSubHeading && !hideCollapsibleButton && (
            <CollapsibleButton id={id} onToggle={customToggle || toggle} isOpen={isContentOpen} {...toggleButton} />
          )}
        </Block>
        {hasSubHeading && (
          <Block
            size={6}
            className={cls('collapsible-toggle', 'margin-b-2', {
              'is-open': isContentOpen,
              'collapsible-filter': isFilter,
            })}
          >
            {showCalculator && subHeading && subHeading}
            {!hideCollapsibleButton && (
              <CollapsibleButton
                id={id}
                onToggle={customToggle || toggle}
                isOpen={isContentOpen}
                isSubHeadingButton
                {...toggleButton}
              />
            )}
            {badgeValue != null && <Badge value={badgeValue} />}
          </Block>
        )}
      </Row>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        in={isContentOpen}
        timeout={DEFAULT_DURATION}
        classNames="collapsible"
      >
        <div id={id} style={{ display: isContentOpen ? null : 'none' }} className="collapsible__content">
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

Collapsible.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  customToggle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hideCollapsibleButton: PropTypes.bool,
  hasSubHeading: PropTypes.bool,
  subHeading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  toggleButton: PropTypes.shape({
    hideLabel: PropTypes.bool,
    openLabel: PropTypes.string,
    closeLabel: PropTypes.string,
    openIcon: PropTypes.string,
    closeIcon: PropTypes.string,
  }),
  badgeValue: PropTypes.string,
  className: PropTypes.string,
  showCalculator: PropTypes.bool,
  isFilter: PropTypes.bool,
};

const InlineCollapsible = ({
  headingClosed,
  headingOpen,
  children,
  isOpen = false,
  unmountOnExit = true,
  customToggle = null,
  className = null,
}) => {
  const [isContentOpen, setIsContentOpen] = useState(isOpen);

  const toggle = () => setIsContentOpen(prev => !prev);
  const id = uniqueId('collapsible__content');

  // NOTE: handle Collapsible used as a controlled component
  useEffect(() => {
    setIsContentOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Row>
        <Block size={6} className="collapsible-toggle">
          <CollapsibleButton
            id={id}
            headingClosed={headingClosed}
            headingOpen={headingOpen}
            onToggle={customToggle || toggle}
            isOpen={isContentOpen}
            className={className}
          />
        </Block>
      </Row>
      <CSSTransition
        unmountOnExit={unmountOnExit}
        in={isContentOpen}
        timeout={DEFAULT_DURATION}
        classNames="collapsible"
      >
        <div id={id} style={{ display: isContentOpen ? null : 'none' }} className="collapsible__content">
          {children}
        </div>
      </CSSTransition>
    </>
  );
};

InlineCollapsible.propTypes = {
  headingClosed: PropTypes.string.isRequired,
  headingOpen: PropTypes.string.isRequired,
  customToggle: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isOpen: PropTypes.bool,
  unmountOnExit: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export { Collapsible, InlineCollapsible, CollapsibleButton };
