import React from 'react';
import PropTypes from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { uniqueId } from 'lodash';
import { FormContentBlock } from 'common/containers/Forms/Blocks';
import { FILTER_PARAMETER } from 'common/constants/FieldNames';
import { Row } from 'common/components';
import { reactToAsyncError } from 'common/components/Form/FormHelpers';
import { ValidatedSearchBar } from 'realEstateSite/containers/Forms/InputValidationComponents';
import { ErrorMessage } from 'common/components/Form/components/Error';

const FilterParametersField = ({ instructionText, addParameter, customError }) => {
  const { t } = useTranslation();
  const { reset } = useForm();

  const onSearch = value => {
    if (value != null) {
      addParameter(value);
      reset();
    }
  };

  return (
    <Row>
      <FormContentBlock
        id={uniqueId(FILTER_PARAMETER)}
        label={t('filter.parameter')}
        desc={instructionText}
        isLabelHidden
      >
        <>
          {customError && <ErrorMessage message={customError} />}
          <Field
            name={FILTER_PARAMETER}
            component={ValidatedSearchBar}
            search={onSearch}
            validate={reactToAsyncError}
            maxLength={60}
            noSearchOnBlur
          />
        </>
      </FormContentBlock>
    </Row>
  );
};

FilterParametersField.propTypes = {
  instructionText: PropTypes.string,
  addParameter: PropTypes.func,
  customError: PropTypes.string,
};

export default FilterParametersField;
