export const MAP_TOOL_ADDRESS = 'address';
export const MAP_TOOL_DISTANCE = 'distance';
export const MAP_TOOL_AREA = 'area';
export const MAP_TOOL_SELECTOR = 'selector';
export const MAP_TOOL_CUSTOM_MARKER = 'customMarker';
export const MAP_TOOL_CUSTOM_MARKER_WITHOUT_EDITOR = 'customMarkerWithoutEditor';
export const MAP_TOOL_FEEDBACK_MARKER = 'feedbackMarker';

export const MAP_TOOLS = [
  MAP_TOOL_ADDRESS,
  MAP_TOOL_DISTANCE,
  MAP_TOOL_AREA,
  MAP_TOOL_CUSTOM_MARKER,
  MAP_TOOL_FEEDBACK_MARKER,
];
