import React from 'react';
import { t } from 'i18next';
import MapStoreColumn from 'common/components/MapStoreColumn/MapStoreColumn';
import { ScrollerPanelPage } from 'common/components/ScrollerPanel/index';
import { MAP_STORE_TITLE } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { FocusableHeading } from 'common/components';

const MapStoreIndexComponent = () => {
  return (
    <ScrollerPanelPage>
      <FocusableHeading level="h1" className={MAP_STORE_TITLE}>
        {t('businessPanel.mapStore.title')}
      </FocusableHeading>
      <MapStoreColumn />
    </ScrollerPanelPage>
  );
};

export default MapStoreIndexComponent;
