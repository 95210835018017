import { isEmpty } from 'lodash';
import { FACILITY_TYPE, PROPERTY_TYPE, REGISTER_UNIT_TYPE } from 'realEstateSite/constants/RealEstate';
import * as Routes from 'realEstateSite/constants/Routes';
import * as GeometriesConstants from './geometriesConstants';

const ALL_TYPES = [
  GeometriesConstants.OWN,
  GeometriesConstants.SEARCHED,
  GeometriesConstants.OTHER,
  GeometriesConstants.LCAG,
  GeometriesConstants.LTA,
  GeometriesConstants.LRA,
  GeometriesConstants.OSRA,
  GeometriesConstants.GRA,
  GeometriesConstants.PRS,
  GeometriesConstants.BD,
  GeometriesConstants.ES,
  GeometriesConstants.OCS,
  GeometriesConstants.RAS,
  GeometriesConstants.AOR,
  GeometriesConstants.ACTIVITY_LOG,
  GeometriesConstants.RETA,
  GeometriesConstants.SELECTED,
  GeometriesConstants.USUFRUCT,
  GeometriesConstants.JPUS,
];

export const getCorrectGeometriesForType = (geometryType, geometryState, simplifyGeometries = false) => {
  const getCorrectGeometries = id => {
    if (simplifyGeometries && geometryState.simplifiedGeometriesById[id] != null) {
      return geometryState.simplifiedGeometriesById[id];
    }
    return geometryState.geometriesById[id];
  };

  if (Array.isArray(geometryType)) {
    return geometryType
      .reduce((acc, type) => [...acc, ...(geometryState[type] || [])], [])
      .map(getCorrectGeometries)
      .filter(Boolean);
  }
  if (geometryType === GeometriesConstants.ALL) {
    return ALL_TYPES.reduce((acc, type) => [...acc, ...(geometryState[type] || [])], [])
      .map(getCorrectGeometries)
      .filter(Boolean);
  }
  return (geometryState[geometryType] || []).map(getCorrectGeometries).filter(Boolean);
};

export const realestateHasGeometry = ({ geometry, realEstate }) => {
  if (!realEstate) return false;

  const geometries = Object.values(geometry.geometriesById);
  const geometryObjects = geometries.filter(g =>
    realEstate.type === FACILITY_TYPE
      ? g.features.length > 0 && g.features[0].properties && g.features[0].properties.facilityId === realEstate.id
      : g.id === realEstate.id
  );

  return geometryObjects.length > 0 && geometryObjects[0].features.length > 0;
};

export const countRealestateGeometries = ({ geometry, realEstate }) => {
  if (!realEstate) return false;

  const geometries = Object.values(geometry.geometriesById);
  const geometryObjects = geometries.filter(g =>
    realEstate.type === FACILITY_TYPE
      ? g.features.length > 0 && g.features[0].properties && g.features[0].properties.facilityId === realEstate.id
      : g.id === realEstate.id
  );

  return geometryObjects.reduce((count, geometryObject) => count + geometryObject.features.length, 0);
};

const addApartmentDataToRequest = realty => ({
  description: realty.description,
  apartmentId: realty.apartmentId,
  apartmentType: realty.apartmentType,
});

const ApartmentCodeToType = {
  A: 'parkingSpace',
  H: 'apartment',
  M: 'otherApartment',
};

const geometryRouteMap = {
  [Routes.SERVICES_REAL_ESTATE_LIST]: GeometriesConstants.OWN,
  [Routes.SERVICES_REAL_ESTATE_LIST_MOBILE]: GeometriesConstants.OWN,
  [Routes.SERVICES_FETCH_REAL_ESTATES]: GeometriesConstants.SEARCHED,
  [Routes.SERVICES_USUFRUCTS_AND_RESTRICTIONS]: [GeometriesConstants.ROOT_REALTY, GeometriesConstants.USUFRUCT],
  // lainhuutohakemukset
  [Routes.SERVICES_LCA]: GeometriesConstants.LCA,
  [Routes.SERVICES_LCAG]: GeometriesConstants.LCAG,
  [Routes.SERVICES_DEATH_ESTATE_LCA]: GeometriesConstants.DEATH_ESTATE_LCA,
  [Routes.SERVICES_LCA_PARTITIONING]: GeometriesConstants.PARTITIONING_LCA,
  // vuokra- ja erityisen oikeuden hakemukset
  [Routes.SERVICES_LEASEHOLD_REGISTRATION_APPLICATION]: GeometriesConstants.LRA,
  [Routes.SERVICES_LEASEHOLD_TRANSFER_APPLICATION]: GeometriesConstants.LTA,
  [Routes.SERVICES_OSRA]: GeometriesConstants.OSRA,
  [Routes.SERVICES_GRA]: GeometriesConstants.GRA,
  // maanmittaustoimitukset
  [Routes.SERVICES_PRIVATE_ROAD_SURVEY]: GeometriesConstants.PRS,
  [Routes.SERVICES_EASEMENT_SURVEY]: GeometriesConstants.ES,
  [Routes.SERVICES_BOUNDARY_DEMARCATION]: GeometriesConstants.BD,
  [Routes.SERVICES_PLOT_SUBDIVISION_SURVEY]: GeometriesConstants.PSS,
  [Routes.SERVICES_RELICTION_AREA]: GeometriesConstants.RAS,
  [Routes.SERVICES_AMALGAMATION_OF_REAL_ESTATES]: GeometriesConstants.AOR,
  [Routes.SERVICES_OTHER_CADASTRAL_SURVEY]: GeometriesConstants.OCS,
  // kiinteistönluovutusilmoitus
  [Routes.SERVICES_REAL_ESTATE_TRANSFER_ANNOUNCEMENT]: GeometriesConstants.RETA,
};

const getApartmentType = code => ApartmentCodeToType[code] || ApartmentCodeToType.H;

const prepareApartmentData = apartments =>
  apartments.reduce(
    (acc, apartment) =>
      acc.concat(
        apartment.apartmentLocations?.map(location => ({
          ...location,
          apartmentId: apartment.shareGroupId,
          description: apartment.companyName,
          apartmentType: getApartmentType(apartment.possessionTargetType?.code),
        }))
      ),
    []
  );

export function parseGeometryRequest(realties, isApartment) {
  const array = [];
  if (!realties) return array;
  const preparedRealties = isApartment ? prepareApartmentData(realties) : realties;

  preparedRealties.forEach(realty => {
    if (realty.type === REGISTER_UNIT_TYPE || realty.type === PROPERTY_TYPE) {
      array.push({
        id: realty.id,
        type: realty.type,
        ...(isApartment ? addApartmentDataToRequest(realty) : {}),
      });
    } else if (realty.type === FACILITY_TYPE && realty.locations && realty.locations.length > 0) {
      realty.locations.forEach(location => {
        array.push({
          id: location.id,
          locationType: location.type,
          type: FACILITY_TYPE,
          facilityId: realty.id,
          ...(isApartment ? addApartmentDataToRequest(realty) : {}),
        });
      });
    }
  });
  return array;
}

export function combineGeometries(data) {
  if (data.length > 1) {
    const mergedFeatures = data.map(item => item.features).flat();
    const uniqueFeatures = [...new Set(mergedFeatures)];
    return [{ id: data[0].id, type: data[0].type, features: uniqueFeatures }];
  }
  return data;
}

export function findGeometryFromState(geometries, realtyId) {
  return [geometries.geometriesById[realtyId]].filter(Boolean);
}

export function isMissingGeometry(geometries, realtyId) {
  return !isEmpty(geometries[GeometriesConstants.MISSING])
    ? Object.values(geometries[GeometriesConstants.MISSING]).some(g => realtyId && g === realtyId)
    : false;
}

const getRouteParts = route =>
  route
    .split('/')
    .filter(str => str.length >= 2)
    .reverse();

function isApplicationRoute(routeParts) {
  return (
    !routeParts.includes(Routes.SERVICES_REAL_ESTATE_LIST) && !routeParts.includes(Routes.SERVICES_FETCH_REAL_ESTATES)
  );
}

function isUsufructsRoute(routeParts) {
  return routeParts.includes(Routes.SERVICES_USUFRUCTS_AND_RESTRICTIONS);
}

export function isUsufructsMainRoute(route) {
  const routeParts = getRouteParts(route);
  return routeParts?.length === 4 && routeParts.includes(Routes.SERVICES_USUFRUCTS_AND_RESTRICTIONS);
}

export function getGeometryTypesForRoute(route) {
  const routeParts = getRouteParts(route);

  // application routes
  if (/\d/.test(routeParts[0]) && isApplicationRoute(routeParts)) return geometryRouteMap[routeParts[1]];
  // leasehold & osra section routes
  if (routeParts[0] === Routes.SERVICES_LEASEHOLD_TRANSFER_APPLICATION || routeParts[0] === Routes.SERVICES_OSRA)
    return GeometriesConstants.SELECTED;
  if (isUsufructsRoute(routeParts) && /\d/.test(routeParts[0]))
    return [GeometriesConstants.SELECTED, GeometriesConstants.ROOT_REALTY];
  // other routes
  return geometryRouteMap[routeParts[0]] || GeometriesConstants.SELECTED;
}

export function disableZoomForRoute(route) {
  const routeParts = getRouteParts(route);

  if (isUsufructsRoute(routeParts) && /^\d+$/.test(routeParts[0])) return true;

  return false;
}

export function getTotalPolygonPoints(features) {
  return features
    ?.filter(feature => feature?.geometry?.type === 'Polygon')
    .reduce((totalPoints, feature) => {
      if (feature.geometry != null && Array.isArray(feature.geometry.coordinates)) {
        return totalPoints + feature.geometry.coordinates.flat(Infinity).length / 2 || 0;
      }

      return totalPoints;
    }, 0);
}

export function simplifyGeometry(geometry) {
  if (geometry == null) return null;
  const { features, ...rest } = geometry;
  const totalPolygonPoints = getTotalPolygonPoints(features);
  if (totalPolygonPoints > GeometriesConstants.COMPLEX_GEOMETRY_POLYGON_POINTS_MINIMUM) {
    return {
      ...rest,
      features: features?.filter(feature => feature?.geometry?.type === 'Point'),
    };
  }

  return null;
}
