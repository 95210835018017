import { t } from 'i18next';

export const ACCEPTED_FILES_PDF = 'application/pdf';
export const ACCEPTED_FILES_JPG_PDF = 'application/pdf, image/jpeg';
export const TRANSITION_DURATION = 500;

export const APPLICATION_ATTACHMENT_TYPE_OPTIONS = () =>
  ['45', '39', '20', '51', '10', '15', '43', '09', '31', '27', '41', '23', '26', '49'].map(id => ({
    value: id,
    label: t(`attachment.type.${id}`),
  }));

export const SRA_ATTACHMENT_TYPE_OPTIONS = () =>
  [
    '02',
    '03',
    '04',
    '06',
    '08',
    '09',
    '10',
    '14',
    '15',
    '16',
    '20',
    '23',
    '25',
    '26',
    '27',
    '51',
    '31',
    '38',
    '39',
    '41',
    '43',
    '45',
    '49',
  ]
    .map(id => ({ value: id, label: t(`attachment.type.${id}`) }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

export const ORWA_ATTACHEMENT_TYPE_OPTIONS = () =>
  ['24', '35', '28', '48', '42', '41', '12', '20', '46', '23', '31'].map(id => ({
    value: id,
    label: t(`attachment.orwa.type.${id}`),
  }));

export const GRA_ATTACHMENT_TYPE_OPTIONS = () =>
  [
    '06',
    '43',
    '41',
    '21',
    '22',
    '02',
    '19',
    '04',
    '27',
    '49',
    '25',
    '15',
    '10',
    '14',
    '51',
    '45',
    '08',
    '09',
    '26',
    '13',
    '39',
    '20',
    '23',
    '03',
    '31',
    '38',
    '16',
  ]
    .map(id => ({ value: id, label: t(`attachment.type.${id}`) }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

export const LCA_ATTACHMENT_TYPE_OPTIONS = () =>
  ['08', '09', '10', '14', '15', '16', '20', '23', '26', '38', '39', '41', '43', '45', '49', '51']
    .map(id => ({ value: id, label: t(`attachment.type.${id}`) }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
