import * as routes from 'common/constants/Routes';
import { ROUTE_SERVICES_SITE_ID, ROUTE_MAP_SITE_ID } from 'common/constants/Routes';
import { getJson, postJson } from './AjaxJson';

// List of abortable / unique xhr events
let _activeNearestAddressXhr = null;

export const mapFeedback = feedback => postJson(routes.getRoute(routes.API_PATH_MAPFEEDBACK), feedback);

const buildPrintPdfParams = params => {
  return {
    width: params.width,
    height: params.height,
    scale: params.scale,
    centre: params.centre,
    layers: params.layers,
    fadeToWhite: params.fadeToWhite,
    borderColor: params.borderColor,
    header: params.header,
    lang: params.lang,
    hide: params.hide || [],
    borderThickness: params.borderThickness,
  };
};

export const getSnapshotPreviewPicture = params =>
  postJson(routes.getRoute(routes.API_PATH_GET_SNAPSHOT_PREVIEW_PIC), buildPrintPdfParams(params));

export default class MapSiteApi {
  static getPdfLink(params) {
    return postJson(routes.getRoute(routes.API_PATH_GET_PDF), buildPrintPdfParams(params));
  }

  static getAddress(sourceData) {
    return new Promise((resolve, reject) => {
      const controller = new AbortController();
      if (_activeNearestAddressXhr !== null) {
        controller.abort();
      }
      _activeNearestAddressXhr = postJson(
        routes.getRoute(routes.API_PATH_NEAREST_ADDRESS),
        { lat: sourceData.lat, lon: sourceData.lon },
        undefined,
        undefined,
        controller.signal,
        data => {
          resolve({ source: sourceData, destination: data });
        },
        XHR => {
          reject(XHR);
        }
      );
    });
  }

  static getAuthentication() {
    return getJson(routes.getRoute(routes.API_PATH_AUTHENTICATION));
  }
}

export function getLastNotification() {
  return getJson(routes.getRoute(routes.API_PATH_NOTIFICATIONS_LAST));
}

export function getDocumentPDFLinks(id, lang) {
  return getJson(`${routes.getRoute(routes.API_PATH_REGISTRATION_MAP_DOCUMENT)}/${id}/${lang}`);
}

export const createPayment = purchaseOrderContext =>
  postJson(routes.getRoute(routes.API_PATH_CREATE_PAYMENT), purchaseOrderContext);

export const fetchFileDownloadServiceProductParameters = () =>
  getJson(`${routes.getRoute(routes.API_PATH_SPATIAL_DATA_FILES)}/products`);

/**
 * @typedef FileOrder
 * @property {string} id
 * @property {string} status
 * @property {string} message
 * @property {object} results
 * @property {object} inputs
 */

/**
 * @typedef LicencedDataResults
 * @property {string} metadataFile
 * @property {string} zipFile
 */

/**
 * @typedef LicencedFileOrder
 * @property {string} id
 * @property {string} status
 * @property {string} message
 * @property {LicencedDataResults} licencedDataResults
 */

/**
 * @typedef SpatialFilerDownloadOrder
 * @property {string} orderNumber
 * @property {string} orderDate
 * @property {string} id
 * @property {Array<FileOrder>} files
 */

/**
 * @typedef FileDownloadOrder
 * @property {string} context
 * @property {boolean} delivered
 * @property {string} orderNumber
 * @property {Date} orderDate
 * @property {string} orderNo
 * @property {string} id
 * @property {Array} downloadableCertificates
 * @property {Array} downloadableMaps
 * @property {Array<SpatialFilerDownloadOrder>} spatialDataFilesOrderResponse
 */

/**
 * @typedef LicencedFileDownloadOrder
 * @property {string} orderNumber
 * @property {string} orderDate
 * @property {string} id
 * @property {string} lang
 * @property {string} licenceFile
 * @property {Array<LicencedFileOrder>} files
 */

/**
 *
 * @param orderId
 * @returns {Promise<FileDownloadOrder>}
 */
export const fetchFileDownloadOrder = orderId =>
  getJson(`${routes.buildRoute(ROUTE_MAP_SITE_ID, routes.API_PATH_PURCHASE_ORDER_DOWNLOADABLES)}/${orderId}`);

/**
 * @param orderId
 * @returns {Promise<LicencedFileDownloadOrder>}
 */
export const fetchLicencedFileDownloadOrder = orderId =>
  getJson(
    `${routes.buildRoute(ROUTE_SERVICES_SITE_ID, routes.API_PATH_SPATIAL_DATA_FILES_LICENCED_ORDERS)}/${orderId}`
  );

/**
 * @typedef EsbStatusResponse
 * @property {boolean} ok
 */

/**
 * @returns {Promise<EsbStatusResponse>}
 */
export const fetchEsbStatus = () => getJson(routes.getRoute(`${routes.API_PATH_ESB_STATUS_CHECK}`));

/**
 * @typedef ApplicationsStatusResponse
 * @property {boolean} ok
 */

export const fetchHtjEsbStatus = () => getJson(routes.getRoute(`${routes.API_PATH_HTJ_ESB_STATUS}`));

/**
 * @returns {Promise<ApplicationsStatusResponse>}
 */
export const fetchApplicationsStatus = purchaseOrderId =>
  getJson(`${routes.getRoute(routes.API_PATH_APPLICATIONS_STATUS_CHECK)}/${purchaseOrderId}`);

export const fetchLicencedDataStatus = purchaseOrderId =>
  getJson(`${routes.getRoute(routes.API_PATH_LICENCED_STATUS_CHECK)}/${purchaseOrderId}`);

export const fetchPublicRegisterUnitForPoint = (lat, lon) => {
  if (Number.isFinite(lat) && Number.isFinite(lon)) {
    return getJson(`${routes.getRoute(routes.API_PATH_PUBLIC_REGISTER_UNIT_FOR_POINT)}?lon=${lon}&lat=${lat}`);
  }
  return null;
};

export const fetchGeometries = realestates => postJson(routes.getRoute(routes.API_PATH_FETCH_GEOMETRY), realestates);
