import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const CheckBox = ({
  input,
  onClick,
  id,
  text = null,
  disabled = false,
  className = '',
  isError = false,
  required = false,
  ariaDescribedby = null,
}) => (
  <div className="checkbox-wrapper">
    <input
      className={cls({ error: isError })}
      {...input}
      onClick={onClick}
      type="checkbox"
      id={id}
      disabled={disabled}
      {...(required ? { 'aria-required': true } : {})}
      aria-describedby={ariaDescribedby}
    />
    <label className={cls('label', 'label--checkbox', { error: isError }, className)} htmlFor={id}>
      {text}
    </label>
  </div>
);

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.node,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isError: PropTypes.bool,
  required: PropTypes.bool,
  ariaDescribedby: PropTypes.string,
};

export default CheckBox;
