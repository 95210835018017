import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Glyphicon } from 'common/components/Icon';

const MapTool = ({ active, disabled, glyph, description, enable, onClick = () => null, showConfirmModal = false }) => {
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    }

    if (!disabled) {
      if (showConfirmModal) {
        showConfirmModal(() => {
          enable(!active);
        });
      } else {
        enable(!active);
      }
    }
  }, [active, disabled, enable, onClick, showConfirmModal]);

  const btnClass = classNames('button', 'button--icon', 'maptool__button', { active, disabled });

  return (
    <button
      type="button"
      className={btnClass}
      onClick={handleClick}
      title={description}
      aria-label={description}
      aria-pressed={active}
    >
      <Glyphicon glyph={glyph} alt={description} />
    </button>
  );
};

MapTool.propTypes = {
  description: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  enable: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  glyph: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  showConfirmModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
};

export default MapTool;
