import React from 'react';
import PropTypes from 'prop-types';
import { Glyphicon } from 'common/components/Icon';
import { t } from 'i18next';
import cls from 'classnames';

export const QuantityButtonTemplate = ({ onClick, glyph, text, ariaLabel, className = null, dataTestId = '' }) => (
  <button
    type="button"
    onClick={onClick}
    className={cls('button__quantity', className)}
    aria-label={ariaLabel}
    data-testid={dataTestId}
  >
    <Glyphicon glyph={glyph} />
    {text}
  </button>
);

QuantityButtonTemplate.propTypes = {
  onClick: PropTypes.func.isRequired,
  glyph: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

export const RemoveButton = ({ onClick, className = null, text }) => (
  <QuantityButtonTemplate glyph="poista" onClick={onClick} text={text} ariaLabel={text} className={className} />
);

RemoveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
};

export const AddButton = ({ onClick, className = null, text = 'button.add', dataTestId = '' }) => (
  <QuantityButtonTemplate
    glyph="lisaa"
    onClick={onClick}
    text={t(text)}
    ariaLabel={t(text)}
    className={className}
    dataTestId={dataTestId}
  />
);

AddButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  dataTestId: PropTypes.string,
};
