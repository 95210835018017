import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { EVENTS } from 'common/helpers/Analytics';
import { switchMapToolThunk } from 'common/containers/MapToolbar/MapToolbarActions';
import { ScrollerPanelPage, FocusableHeading } from 'common/components';
import { MAP_TOOL_CUSTOM_MARKER } from '../MapToolbar/MapToolNames';
import { SHARE_MAP_LINK_TITLE } from '../AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

const ShareMarkerIndexComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const switchMarkerToolOn = useCallback(isOn => dispatch(switchMapToolThunk(MAP_TOOL_CUSTOM_MARKER, isOn)), [
    dispatch,
  ]);

  const handleMarkerToolLinkClick = () => {
    EVENTS.trackCustomMarkerToolClick(false);
    switchMarkerToolOn(true);
  };

  useEffect(() => {
    return () => {
      EVENTS.trackCustomMarkerToolClick(true);
      switchMarkerToolOn(false);
    };
  }, [switchMarkerToolOn]);

  return (
    <ScrollerPanelPage>
      <FocusableHeading level="h1" className={SHARE_MAP_LINK_TITLE}>
        {t('share.title')}
      </FocusableHeading>
      <ol className="sharemarker-instruction-list">
        <li className="sharemarker-instruction-list__item sharemarker-instruction-list__item--with-button">
          <button type="button" onClick={handleMarkerToolLinkClick} className="button button--light">
            {t('share.index.instruction1')}
          </button>
        </li>
        <li className="sharemarker-instruction-list__item">{t('share.index.instruction2')}</li>
        <li className="sharemarker-instruction-list__item">{t('share.index.instruction3')}</li>
        <li className="sharemarker-instruction-list__item">{t('share.index.instruction4')}</li>
      </ol>
    </ScrollerPanelPage>
  );
};

export default ShareMarkerIndexComponent;
