import { LAYER_3D_BUILDINGS_SHEETS, LAYER_ORTHOPHOTO_SHEETS } from '../../../oskari/layers/VectorLayerIds';

export const FIELD_NAMES = {
  PRODUCT_ID: 'productId',
  PRODUCT_TYPE: 'productType',
  AREA_SELECTION_TYPE: 'areaSelectionType',
  AREA_SELECTION: 'areaSelection',
  VALID_AREA_SELECTION: 'areaSelectionValid',
  SELECTED_MUNICIPALITY: 'selectedMunicipality',
  VALID_MUNICIPALITY_SELECTION: 'municipalitySelectionValid',
  SELECTED_MAP_SHEETS: 'selectedMapSheets',
  FILE_FORMAT: 'fileFormat',
  THEME_FREE_SELECTION: 'freeSelectionTheme',
  THEME_MUNICIPALITY: 'municipalityTheme',
  THEME_ENTIRE_FINLAND: 'entireFinlandTheme',
  VALID_THEME_SELECTION: 'themeSelectionValid',
  FREE_SELECTION_TYPE: 'freeSelectionType',
  YEAR: 'year',
  CRS: 'crs',
  NO_MAP_SHEETS: 'noMapSheets',
  NO_PRODUCTION_AREAS: 'noProductionAreas',
  LEVEL_OF_DETAIL: 'levelOfDetail',
  YEAR_SELECTION_TYPE: 'yearSelectionType',
  SELECTED_PRODUCTION_AREA: 'selectedProductionArea',
};

/**
 * @typedef {string} AreaSelectionType
 */

/**
 * @enum {AreaSelectionType}
 * @property {string} SELECT_SHEETS   - Select downloaded area by selecting map sheets
 * @property {string} FREE_SELECTION  - Select downloaded area by drawing
 * @property {string} ENTIRE_FINLAND  - Select entire Finland for downloaded area
 * @property {string} MUNICIPALITY    - Select download area by municipality
 * @property {string} PRODUCTION_AREA - Select download area by production area
 */
export const AREA_SELECTION_TYPES = {
  SELECT_SHEETS: 'karttalehti',
  FREE_SELECTION: 'selectArea',
  ENTIRE_FINLAND: 'koko_suomi',
  MUNICIPALITY: 'kunta',
  PRODUCTION_AREA: 'productionArea',
};

export const YEAR_SELECTION_TYPES = {
  CURRENT: 'current',
  YEAR: 'year',
};

export const BBOX_FEATURE_ID = 'bboxOfFinland';

export const bboxForFinland = {
  type: 'FeatureCollection',
  crs: {
    type: 'name',
    properties: {
      name: 'EPSG:3067',
    },
  },
  features: [
    {
      type: 'Feature',
      properties: {
        id: BBOX_FEATURE_ID,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [60000, 6600000],
            [740000, 6599589],
            [740000, 7790000],
            [60000, 7790000],
            [60000, 6600000],
          ],
        ],
      },
    },
  ],
};

const LASER5P_MAP_SHEET_AVAILABILITY_STYLE = {
  fill: {
    area: {
      pattern: 0,
    },
  },
  stroke: {
    color: '#000',
    width: 3,
  },
};

export const LASER5P_MAP_SHEET_AVAILABILITY_OPTIONS = {
  featureStyle: LASER5P_MAP_SHEET_AVAILABILITY_STYLE,
  clearPrevious: true,
  opacity: 100,
  cursor: 'arrow',
};

export const PRODUCTION_AREA_STYLE = {
  featureStyle: {
    stroke: {
      color: 'rgba(0,0,0,0.5)',
      width: 2,
    },
    fill: {
      color: 'rgba(0,128,0,0.5)',
    },
    text: {
      labelProperty: 'nimi',
      font: 'bold 12px Arial',
      fill: {
        color: 'rgba(0,0,0,1)',
      },
      stroke: {
        color: 'rgba(255,255,255,0.8)',
        width: 2,
      },
    },
  },
};

export const FILE_FORMAT_OPTIONS = [
  { value: 'GPKG', label: 'GeoPackage' },
  { value: 'GeoPackage', label: 'GeoPackage' },
  { value: 'GeoJSON', label: 'GeoJSON' },
  { value: 'LAZ', label: 'LAZ' },
  { value: 'TIFF', label: 'GeoTIFF' },
  { value: 'GML', label: 'GML' },
  { value: 'ESRI shapefile', label: 'ESRI shapefile' },
  { value: 'kaikki', label: 'fileDownloadService.fileFormat.all' },
  { value: 'PNG', label: 'PNG' },
  { value: 'JPEG2000', label: 'JPEG2000' },
  { value: 'ASCII Grid', label: 'ASCII Grid' },
  { value: 'CityGML', label: 'CityGML' },
  { value: 'CityJSON', label: 'CityJSON' },
];

export const EPSG_CODE_NAMES = {
  'EPSG:3067': 'crs.epsg.3067',
  'EPSG:3046': 'ETRS-TM34',
  'EPSG:3047': 'crs.epsg.3047',
  'EPSG:3048': 'ETRS-TM36',
  'EPSG:3857': 'WGS 84 / Pseudo-Mercator',
  'EPSG:3873': 'ETRS-GK19',
  'EPSG:3874': 'ETRS-GK20',
  'EPSG:3875': 'ETRS-GK21',
  'EPSG:3876': 'ETRS-GK22',
  'EPSG:3877': 'ETRS-GK23',
  'EPSG:3878': 'ETRS-GK24',
  'EPSG:3879': 'ETRS-GK25',
  'EPSG:3880': 'ETRS-GK26',
  'EPSG:3881': 'ETRS-GK27',
  'EPSG:3882': 'ETRS-GK28',
  'EPSG:3883': 'ETRS-GK29',
  'EPSG:3884': 'ETRS-GK30',
  'EPSG:3885': 'ETRS-GK31',
  'EPSG:4258': 'crs.epsg.4258',
  'EPSG:4326': 'WGS84',
  'EPSG:3386': 'crs.epsg.3386',
  'EPSG:2391': 'crs.epsg.2391',
  'EPSG:2392': 'crs.epsg.2392',
  'EPSG:2394': 'crs.epsg.2394',
  CRS84: 'WGS 84',
};

/**
 * @typedef LaturiMapSheetLayer
 * @property {number} id - Oskari layer id
 * @property {string} name - Layer name
 * @property {number} sheetSize - Sheet size in km². See: https://www.maanmittauslaitos.fi/kartat-ja-paikkatieto/kartat/osta-kartta/nain-loydat-oikean-karttalehden
 */

/**
 * @enum {LaturiMapSheetLayer}
 */
export const LATURI_MAP_LAYERS = [
  { id: 'oskari.layers.mapSheet.5000', name: 'TM35-lehtijako (1:5 000)', sheetSize: 9 },
  { id: 'oskari.layers.mapSheet.10000', name: 'TM35-lehtijako (1:10 000)', sheetSize: 36, minZoom: 3 },
  {
    id: 'oskari.layers.mapSheet.laserScanning05p',
    name: 'TM35-lehtijako (laserkeilausaineisto 0,5p/m2)',
    sheetSize: 9,
  },
  { id: 'oskari.layers.mapSheet.25000', name: 'TM35-lehtijako (1:25 000)', sheetSize: 288 },
  { id: 'oskari.layers.mapSheet.25000H', name: 'TM35-lehtijako (1:25 000 puolilehti)', sheetSize: 288 },
  { id: 'oskari.layers.mapSheet.50000', name: 'TM35-lehtijako (1:50 000)', sheetSize: 1152 },
  { id: 'oskari.layers.mapSheet.100000', name: 'TM35-lehtijako (1:100 000)', sheetSize: 4608 },
  { id: 'oskari.layers.mapSheet.100000H', name: 'TM35-lehtijako (1:100 000 puolilehti)', sheetSize: 4608 },
  { id: 'oskari.layers.mapSheet.200000', name: 'TM35-lehtijako (1:200 000)', sheetSize: 18432 },
  { id: 'oskari.layers.mapSheet.200000H', name: 'TM35-lehtijako (1:200 000 puolilehti)', sheetSize: 18432 },
  { id: LAYER_ORTHOPHOTO_SHEETS, minZoom: 3 },
  { id: LAYER_3D_BUILDINGS_SHEETS, minZoom: 3 },
  { id: 'oskari.layers.mapSheet.laserScanning5p', name: 'TM35-lehtijako (laserkeilausaineisto 5p/m2)', minZoom: 5 },
];

// Product IDs
export const TOPOGRAPHIC_DATABASE = 'maastotietokanta';
export const CADASTRAL_INDEX_MAP_VECTOR = 'kiinteistorekisterikartta_vektori';
export const ELEVATION_MODEL = 'korkeusmalli';
export const DIVISION_INTO_ADMINISTRATIVE_AREAS_RASTER = 'hallinnolliset_aluejaot_rasteri';
export const ORTHOPHOTO = 'ortoilmakuva';
export const LASER_SCANNING_DATA_5P = 'laserkeilausaineisto_5p';
export const BUILDINGS_3D = '3d-rakennukset';

export const ELEVATION_THEME = 'maastotietokanta_korkeussuhteet';
export const ALL_EXCEPT_ELEVATION_THEME = 'maastotietokanta_kaikki_paitsi_korkeussuhteet';
export const POLYGON = 'polygon';
export const BOUNDING_BOX = 'bbox';

export const YEAR_SELECTION_PRODUCTS = [
  'hallinnolliset_aluejaot_rasteri',
  'hallinnolliset_aluejaot_vektori',
  'ortoilmakuva',
];

export const MAP_LAYERS = {
  '1:5000': 'oskari.layers.mapSheet.5000', // Karttalehtijako 1:5000, esim. M5321D1
  '1:10000': 'oskari.layers.mapSheet.10000', // Karttalehtijako 1:10 000, esim. M5321D
  '1:25000': 'oskari.layers.mapSheet.25000', // Karttalehtijako 1:25 000, esim. M5321
  '1:25000H': 'oskari.layers.mapSheet.25000H', // Karttalehtijako 1:25 000 puolilehti, esim. M5321L
  '1:50000': 'oskari.layers.mapSheet.50000', // Karttalehtijako 1:50 000, esim. M532
  '1:100000': 'oskari.layers.mapSheet.100000', // Karttalehtijako 1:100 000, esim. M53
  '1:100000H': 'oskari.layers.mapSheet.100000H', // Karttalehtijako 1:100 000 puolilehti, esim. M53L
  '1:200000': 'oskari.layers.mapSheet.200000', // Karttalehtijako 1:200 000, esim. M5
  '1:200000H': 'oskari.layers.mapSheet.200000H', // Karttalehtijako 1:200 000 puolilehti, esim. M5L
  laserScanning05p: 'oskari.layers.mapSheet.laserScanning05p',
  laserScanning5p: 'oskari.layers.mapSheet.laserScanning5p', // Karttalehtijako 1:5000 jaettuna 9 osaan, esim. Q5422F1_2
};

export const DEFAULT_CRS = 'EPSG:3067';

export const MAP_SHEET_SELECTION_PRODUCTS = [
  'ortoilmakuva',
  'kiinteistorekisterikartta_rasteri',
  'korkeusmalli',
  'korkeusvyohykkeet',
  'maastokartta_rasteri',
  'rinnevarjoje',
  'taustakartta_rasteri',
  'laserkeilausaineisto_05',
  'maastokartta_vektori',
  '3d-rakennukset',
  'laserkeilausaineisto_5p',
];
