import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Glyphicon } from 'common/components/Icon';
import cls from 'classnames';
import { FlexGrid, Row, Block } from 'common/components';
import {
  zoomInThunk,
  zoomOutThunk,
  toggleCoordinateToolThunk,
  trackUserLocationThunk,
} from 'common/containers/OskariMap/OskariMapActions';

const setPositionGlyph = 'gps-sijainti';
const zoomInGlyph = 'plus-pallo';
const zoomOutGlyph = 'miinus';
const coordsGlyph = 'koordinaatit';
const toInt = val => Math.round(parseFloat(val));

const getCurrentLocationTooltip = () => t('map.controls.automaticTracking');
const getZoomOutTooltip = () => t('map.controls.zoomout.tooltip');
const getZoomInTooltip = () => t('map.controls.zoomin.tooltip');

const ToolBlock = ({
  hidden,
  toggleTrackUserLocation,
  buttonClassNames,
  handleZoomIn,
  handleZoomOut,
  trackingUser,
}) => (
  <Block className="mobile-tools__button-row">
    <button
      type="button"
      tabIndex={hidden ? -1 : 0}
      onClick={toggleTrackUserLocation}
      className={cls(buttonClassNames, { 'dark-selected': trackingUser })}
      title={getCurrentLocationTooltip()}
      aria-label={getCurrentLocationTooltip()}
    >
      <Glyphicon glyph={setPositionGlyph} alt={getCurrentLocationTooltip()} />
    </button>
    <button
      type="button"
      tabIndex={hidden ? -1 : 0}
      onClick={handleZoomOut}
      className={buttonClassNames}
      title={getZoomOutTooltip()}
      aria-label={getZoomOutTooltip()}
    >
      <Glyphicon glyph={zoomOutGlyph} alt={getZoomOutTooltip()} />
    </button>
    <button
      type="button"
      tabIndex={hidden ? -1 : 0}
      onClick={handleZoomIn}
      className={buttonClassNames}
      title={getZoomInTooltip()}
      aria-label={getZoomInTooltip()}
    >
      <Glyphicon glyph={zoomInGlyph} alt={getZoomInTooltip()} />
    </button>
  </Block>
);

ToolBlock.propTypes = {
  hidden: PropTypes.bool.isRequired,
  buttonClassNames: PropTypes.string.isRequired,
  handleZoomIn: PropTypes.func.isRequired,
  handleZoomOut: PropTypes.func.isRequired,
  toggleTrackUserLocation: PropTypes.func.isRequired,
  trackingUser: PropTypes.bool.isRequired,
};

const MobileBottomToolbarComponent = ({ hidden }) => {
  const dispatch = useDispatch();
  const { trackingUser, coords } = useSelector(state => state.oskariIframe);

  const toggleTrackUserLocation = () => {
    return dispatch(trackUserLocationThunk(!trackingUser));
  };

  const toggleCoordinateConversionToolbar = () => {
    dispatch(toggleCoordinateToolThunk());
  };

  const buttonClassNames = cls('button--dark', 'button', 'button--icon');
  const N = toInt(coords.centerY);
  const E = toInt(coords.centerX);

  return (
    <FlexGrid className="mobile-tools mobile-tools--bottom">
      <Row className="mobile-tools__bar">
        <ToolBlock
          buttonClassNames={buttonClassNames}
          trackingUser={trackingUser}
          handleZoomIn={() => dispatch(zoomInThunk())}
          handleZoomOut={() => dispatch(zoomOutThunk())}
          hidden={hidden}
          toggleTrackUserLocation={toggleTrackUserLocation}
        />
        <Block className="mobile-tools-bottom__coordinates right">
          <label htmlFor="current-lon">N: {N}</label>
          <input type="text" id="current-lon" readOnly value={N} className="hidden" />
          <br />
          <label htmlFor="current-lat">E: {E}</label>
          <input type="text" id="current-lat" readOnly value={E} className="hidden" />
        </Block>
        <Block>
          <button
            type="button"
            tabIndex={hidden ? -1 : 0}
            onClick={toggleCoordinateConversionToolbar}
            title={t('map.controls.coordinates.tooltip')}
            className={buttonClassNames}
            aria-label={t('map.controls.coordinates.tooltip')}
          >
            <Glyphicon glyph={coordsGlyph} alt={t('map.controls.coordinates.tooltip')} />
          </button>
        </Block>
      </Row>
    </FlexGrid>
  );
};

MobileBottomToolbarComponent.propTypes = {
  hidden: PropTypes.bool.isRequired,
};

export default MobileBottomToolbarComponent;
