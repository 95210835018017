/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import firstBy from 'thenby';
import { t } from 'i18next';
import { isEmpty } from 'lodash';

import { compareRealEstatesById } from 'common/helpers/Sorting/Sorting';
import {
  POSSESSION_ROLE_EOVU,
  POSSESSION_ROLE_LHHS,
  POSSESSION_ROLE_LHHV,
  POSSESSION_ROLE_LHOS,
  POSSESSION_ROLE_LVSA,
} from 'realEstateSite/constants/Roles';
import { FACILITY_TYPE, PROPERTY_TYPE, REGISTER_UNIT_TYPE } from 'realEstateSite/constants/RealEstate';
import { getJson } from 'common/api/AjaxJson';
import * as routes from 'common/constants/Routes';
import { API_PATH_CERTIFICATE_CATALOG, SERVICES_REAL_ESTATE_LIST } from 'realEstateSite/constants/Routes';
import * as OskariActions from 'common/containers/OskariMap/OskariMapActions';
import ServicesApi from 'realEstateSite/api/ServicesApi';
import { formatDateDM, isToday } from 'utils/datetime-utils';
import { addErrorNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import { fetchGeometries } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import { OWN, OTHER } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import {
  GET_REGISTRATION_MAP_XML,
  HANDLE_CERTIFICATE_OPENING,
  HANDLE_REGISTRATION_MAP_OPENING,
} from 'realEstateSite/containers/Authorization/privileges';
import { getDocumentPDFLinks } from 'common/api/CommonApi';
import { error } from 'common/helpers/Error';
import { fetchTargetRealEstate } from '../CadastralSurveys/common/CadastralSurveyFormActions';
import { PAGE_UNIT_COUNT } from '../../RealtyConstants';

const POSSESSION_ROLE_ORDER = [
  POSSESSION_ROLE_LHHS,
  POSSESSION_ROLE_LHOS,
  POSSESSION_ROLE_LHHV,
  POSSESSION_ROLE_LVSA,
  POSSESSION_ROLE_EOVU,
];

const sortedPossessionRoles = realEstate =>
  realEstate.possessionRoles.filter(role => POSSESSION_ROLE_ORDER.includes(role));

const REAL_ESTATE_TYPE_ORDER = [FACILITY_TYPE, REGISTER_UNIT_TYPE, PROPERTY_TYPE];
const LAST_INDEX = 1000;

const getIndex = (order, value) => (order.indexOf(value) < 0 ? LAST_INDEX : order.indexOf(value));

const possessionRole = (realestate1, realestate2) =>
  getIndex(POSSESSION_ROLE_ORDER, sortedPossessionRoles(realestate1)[0]) -
  getIndex(POSSESSION_ROLE_ORDER, sortedPossessionRoles(realestate2)[0]);

const realEstateType = (realestate1, realestate2) =>
  getIndex(REAL_ESTATE_TYPE_ORDER, realestate1.type) - getIndex(REAL_ESTATE_TYPE_ORDER, realestate2.type);

const sortRealEstates = realestates =>
  realestates.sort(firstBy(possessionRole).thenBy(realEstateType).thenBy(compareRealEstatesById));

const initialState = {
  realEstates: [],
  productCatalog: {},
  productCatalogLoaded: false,
  realEstateLegalConfirmationLoading: false,
  realEstatesLoading: false,
  realEstatesLoaded: false,
  registrationMapDocumentPdfUrls: [],
  apiError: true,
  locationRealEstates: [],
  locationRealEstatesLoading: false,
  registerStateDate: null, // value remains null if register is up-to-date
  showAllRealEstates: true,
  realEstatePaginationLength: undefined,
  isManagingAuthoritySearch: false,
};

const realEstateListSlice = createSlice({
  name: 'realEstateList',
  initialState,
  reducers: {
    realEstatesCleared(state) {
      state.realEstates = [];
      state.locationRealEstates = [];
      state.realEstatesLoaded = false;
    },
    realEstateCreated(state, action) {
      state.realEstates = sortRealEstates(action.payload);
      state.realEstatesLoaded = true;
    },
    realEstateListApiError(state, action) {
      state.apiError = action.payload;
    },
    realEstateSelected(state, action) {
      state.selectedRealEstate = state.realEstates.find(r => r.id === action.payload);
    },
    realEstatesLoading(state, action) {
      if (!action.payload) {
        state.realEstatesLoading = false;
      } else {
        state.realEstatesLoading = action.payload;
      }
    },
    certificateSelected(state, action) {
      state.selectedCertificateType = action.payload;
    },
    productCatalogLoaded(state, action) {
      state.productCatalog = action.payload;
      state.productCatalogLoaded = true;
    },
    registrationMapDocumentLinksCreated(state, action) {
      state.registrationMapDocumentPdfUrls = action.payload;
    },
    registrationMapDocumentLinksCleared(state) {
      state.registrationMapDocumentPdfUrls = null;
    },
    locationRealEstateCreated(state, action) {
      const objectExists = state.locationRealEstates.find(r => r.id === action.payload.id);
      const realEstates = Array.from(state.locationRealEstates);
      if (!objectExists) {
        realEstates.push(action.payload);
        state.locationRealEstates = realEstates;
      }
    },
    locationRealEstateLoading(state, action) {
      if (!action.payload) {
        state.locationRealEstatesLoading = false;
      } else {
        state.locationRealEstatesLoading = action.payload;
      }
    },
    registerStateDateSet(state, action) {
      state.registerStateDate = action.payload;
    },
    showAllRealEstatesToggled(state, action) {
      state.showAllRealEstates = action.payload;
    },
    realEstatePaginationLengthUpdated(state, action) {
      state.realEstatePaginationLength = action.payload;
    },
    resetRealEstatePagination(state) {
      state.realEstatePaginationLength = undefined;
      state.showAllRealEstates = true;
    },
    isManagingAuthoritySearchToggled(state, action) {
      state.isManagingAuthoritySearch = action.payload;
    },
  },
});

export const {
  realEstatesCleared,
  realEstateCreated,
  realEstateListApiError,
  realEstateSelected,
  realEstatesLoading,
  certificateSelected,
  productCatalogLoaded,
  registrationMapDocumentLinksCreated,
  registrationMapDocumentLinksCleared,
  locationRealEstateCreated,
  locationRealEstateLoading,
  registerStateDateSet,
  showAllRealEstatesToggled,
  realEstatePaginationLengthUpdated,
  resetRealEstatePagination,
  isManagingAuthoritySearchToggled,
} = realEstateListSlice.actions;

export function loadProductCatalogThunk() {
  return dispatch => {
    return getJson(routes.getRoute(API_PATH_CERTIFICATE_CATALOG))
      .then(response => {
        dispatch(productCatalogLoaded(response));
      })
      .catch(err => {
        error(t('app.general.error'), err);
      });
  };
}

export function toggleMapAnimation(animationOn) {
  return dispatch => {
    dispatch(OskariActions.toggleLoadingAnimationThunk(animationOn));
  };
}

export const setRegisterState = (registerStateDate, reduxRegisterStateDate) => dispatch => {
  if (!registerStateDate) return;

  if (
    !isToday(new Date(registerStateDate)) &&
    (reduxRegisterStateDate === null || new Date(registerStateDate) < new Date(reduxRegisterStateDate))
  ) {
    dispatch(addErrorNotificationAction(t('error.registerState', { date: formatDateDM(registerStateDate) })));
    dispatch(registerStateDateSet(registerStateDate));
  }
};

export const getEarliestRegisterStateDate = realEstates => {
  const dates = realEstates?.map(r => r.registerStateDate);
  const earliest = dates.sort((a, b) => Date.parse(a) - Date.parse(b))[0];
  return !isEmpty(dates) ? earliest : null;
};

export function fetchOwnRealEstatesThunk(ssn, registerStateDate, isManagingAuthoritySearch, signal = null) {
  return async dispatch => {
    dispatch(realEstateListApiError(false));
    dispatch(realEstatesLoading(true));
    try {
      const realEstates = ssn
        ? await ServicesApi.fetchRealEstatesBySSN(ssn, isManagingAuthoritySearch)
        : await ServicesApi.fetchRealEstates(isManagingAuthoritySearch, signal);
      realEstates && dispatch(setRegisterState(getEarliestRegisterStateDate(realEstates), registerStateDate));
      dispatch(realEstateCreated(realEstates));
      return realEstates;
    } catch (err) {
      console.error(`Cannot fetch real estates: ${err}`);
      dispatch(toggleMapAnimation(false));
      dispatch(realEstateCreated([]));
      dispatch(realEstateListApiError(true));
      return null;
    } finally {
      dispatch(realEstatesLoading(false));
    }
  };
}

export const fetchRealEstatesAndGeometries = (
  ssn,
  registerStateDate,
  isManagingAuthoritySearch = false,
  signal = null
) => async (dispatch, getState) => {
  const realties = await dispatch(fetchOwnRealEstatesThunk(ssn, registerStateDate, isManagingAuthoritySearch, signal));

  if (!window.location.href.includes(SERVICES_REAL_ESTATE_LIST)) {
    return;
  }

  if (realties) {
    if (realties.length > PAGE_UNIT_COUNT) {
      dispatch(showAllRealEstatesToggled(false));
      if (!getState().realEstateList.realEstatePaginationLength) {
        dispatch(realEstatePaginationLengthUpdated(PAGE_UNIT_COUNT));
      }
      if (getState().realEstateList.realEstatePaginationLength >= realties.length) {
        dispatch(showAllRealEstatesToggled(true));
        dispatch(fetchGeometries(realties, OWN));
      }
    } else {
      dispatch(showAllRealEstatesToggled(true));
      dispatch(fetchGeometries(realties, OWN));
    }
  }
};

export const getDocumentPDFLinksThunk = (realEstateIdentifier, lang) => (dispatch, getState) => {
  const realEstate =
    getState().realEstateList.realEstates.find(r => r.id === realEstateIdentifier) ||
    getState().searchRealEstates.realEstates.find(r => r.id === realEstateIdentifier);

  dispatch(registrationMapDocumentLinksCleared());
  if (
    isAuthorized([HANDLE_CERTIFICATE_OPENING, HANDLE_REGISTRATION_MAP_OPENING, GET_REGISTRATION_MAP_XML]) &&
    realEstate?.type === REGISTER_UNIT_TYPE
  ) {
    getDocumentPDFLinks(realEstateIdentifier, lang)
      .then(urls => {
        dispatch(registrationMapDocumentLinksCreated(urls.documents));
      })
      .catch(reason => {
        console.error(`Cannot get PDF-urls for registration map document:  + ${reason}`);
      });
  }
};

export const searchLocationRealEstateThunk = realtyId => async dispatch => {
  const locationRealEstate = await dispatch(fetchTargetRealEstate(realtyId, OTHER, true));
  if (locationRealEstate) {
    dispatch(locationRealEstateCreated(locationRealEstate));
  }
  return locationRealEstate;
};

export const searchLocationRealEstateWithoutGeosThunk = realtyId => async dispatch => {
  const locationRealEstate = await dispatch(fetchTargetRealEstate(realtyId, OTHER, true, false, false));
  if (locationRealEstate) {
    dispatch(locationRealEstateCreated(locationRealEstate));
  }
  return locationRealEstate;
};

export default realEstateListSlice.reducer;
