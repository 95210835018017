import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { Glyphicon } from '../../components';

const MapFeatureGroup = ({
  name,
  isEmptyLayer,
  deleteFeatures,
  toggleFeatures = noop,
  isHidden,
  toggleable = true,
  disabled = false,
}) => {
  const { t } = useTranslation();
  if (isEmptyLayer) return null;

  return (
    <li className={cls('map-feature-toggle__li', { 'map-feature-toggle__li--disabled': disabled })}>
      {toggleable && (
        <button
          type="button"
          disabled={disabled}
          className={cls('map-feature-toggle__button', 'map-feature-toggle__visibility-btn', {
            'map-feature-toggle__visibility-btn--hidden': isHidden,
          })}
          onClick={toggleFeatures}
          title={isHidden ? t('sidebar.features.show') : t('sidebar.features.hide')}
        >
          <Glyphicon glyph={isHidden ? 'silma-kiinni' : 'silma-auki'} />
        </button>
      )}
      <span className={toggleable ? 'map-feature-toggle__title' : 'map-feature-toggle__title-without-remove'}>
        {name}
      </span>
      <button
        type="button"
        disabled={disabled}
        className="map-feature-toggle__button map-feature-toggle__remove-btn"
        onClick={deleteFeatures}
        title={t('button.remove')}
      >
        <Glyphicon glyph="roskakori" />
      </button>
    </li>
  );
};

MapFeatureGroup.propTypes = {
  name: PropTypes.string.isRequired,
  deleteFeatures: PropTypes.func.isRequired,
  isEmptyLayer: PropTypes.bool.isRequired,
  toggleFeatures: PropTypes.func,
  isHidden: PropTypes.bool.isRequired,
  toggleable: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MapFeatureGroup;
