import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MapSiteLinkBlock from 'mapSite/components/MapSiteIndex/MapSiteLinkBlockComponent';
import { ScrollerPanelList } from 'common/components';
import {
  FEEDBACK,
  FILE_DOWNLOAD_SERVICE,
  MAP_STORE_INDEX,
  SHARE_CUSTOM_MARKER,
  TO_REAL_ESTATE_SITE,
} from 'common/constants/Routes';
import {
  ACTIVE_MENU_ITEM,
  GEOSPATIAL_DATA_TITLE,
  MAP_STORE_TITLE,
  PROVIDE_FEEDBACK_TITLE,
  REAL_PROPERTY_TITLE,
  SHARE_MAP_LINK_TITLE,
} from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import {
  clearMapLayersThunk,
  removeBboxOfFinlandThunk,
} from 'common/containers/FileDownloadService/FileDownloadServiceAction';
import { START_PHASE_CHANNEL_READY } from 'common/containers/KasiAppMode/KasiAppModes';
import useLangQueryParam from 'common/hooks/useLangQueryParam';

const MapSiteIndexComponent = ({ hidden = false }) => {
  const { t } = useTranslation();
  useLangQueryParam();
  const dispatch = useDispatch();
  const mapReady = useSelector(state => state.appMode.startPhase >= START_PHASE_CHANNEL_READY);

  useEffect(() => {
    if (mapReady) {
      dispatch(removeBboxOfFinlandThunk());
      dispatch(clearMapLayersThunk());
    }
  }, [dispatch, mapReady]);

  return (
    <ScrollerPanelList odd>
      <MapSiteLinkBlock
        to={MAP_STORE_INDEX}
        glyph="ostoskarry"
        title={t('panelIndex.mapStoreTitle')}
        desc={t('panelIndex.mapStoreDesc')}
        hidden={hidden}
        selector={MAP_STORE_TITLE}
        className={ACTIVE_MENU_ITEM}
      />
      <MapSiteLinkBlock
        to={FILE_DOWNLOAD_SERVICE}
        glyph="download-open-data"
        title={t('panelIndex.fileDownloadServiceTitle')}
        desc={t('panelIndex.fileDownloadServiceText')}
        hidden={hidden}
        selector={GEOSPATIAL_DATA_TITLE}
      />
      <MapSiteLinkBlock
        to={TO_REAL_ESTATE_SITE}
        glyph="home"
        title={t('panelIndex.toRealEstateSiteTitle')}
        desc={t('panelIndex.toRealEstateSiteText')}
        hidden={hidden}
        selector={REAL_PROPERTY_TITLE}
      />
      <MapSiteLinkBlock
        to={SHARE_CUSTOM_MARKER}
        glyph="nayta-kartalla"
        title={t('share.title')}
        desc={t('panelIndex.shareText')}
        hidden={hidden}
        selector={SHARE_MAP_LINK_TITLE}
      />
      <MapSiteLinkBlock
        to={FEEDBACK}
        glyph="kirjekuori"
        title={t('panelIndex.feedbackTitle')}
        desc={t('panelIndex.feedbackText')}
        hidden={hidden}
        selector={PROVIDE_FEEDBACK_TITLE}
      />
    </ScrollerPanelList>
  );
};

MapSiteIndexComponent.propTypes = {
  hidden: PropTypes.bool,
};

export default MapSiteIndexComponent;
