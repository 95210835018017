import React from 'react';
import { t } from 'i18next';

import { InfoBox } from '../../components';

export default () => (
  <InfoBox level="error">
    <p>
      <strong>{t('error.general.function')}</strong>
    </p>
    <p>{t('error.general.tryAgainLater')}</p>
  </InfoBox>
);
