import React from 'react';
import PropTypes from 'prop-types';

const UnorderedItem = ({ children = null, dataTestId = null }) => (
  <li className="nls-unordered-item" data-testid={dataTestId}>
    {children}
  </li>
);

UnorderedItem.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

export default UnorderedItem;
