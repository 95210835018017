import { createSlice } from '@reduxjs/toolkit';

const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    closedSurveys: [],
  },
  reducers: {
    surveyClosed(state, action) {
      if (!state.closedSurveys.includes(action.payload.surveyId)) {
        state.closedSurveys.push(action.payload.surveyId);
      }
    },
  },
});

export const { surveyClosed } = surveySlice.actions;

export default surveySlice.reducer;
