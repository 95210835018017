import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FlexGrid, MainHeader } from '../../../components';
import PreviewImage from '../step1/PreviewImage';
import Laser5pComponent from './Laser5pComponent';
import { LASER_SCANNING_DATA_5P } from '../FileDownloadServiceConstants';

const RestrictedProductComponent = ({ productId }) => {
  const { t } = useTranslation();

  return (
    <FlexGrid className="form__container-bg">
      <section>
        <MainHeader level="h2" text={t(`fileDownloadService.product.${productId}.name`)} />
        <PreviewImage productId={productId} />

        {productId === LASER_SCANNING_DATA_5P && <Laser5pComponent />}
      </section>
    </FlexGrid>
  );
};

RestrictedProductComponent.propTypes = {
  productId: PropTypes.string.isRequired,
};

export default RestrictedProductComponent;
