import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const RadioButton = React.forwardRef(
  (
    {
      id,
      label,
      input,
      onClick = () => null,
      disabled = false,
      className = '',
      extraBlockContent = null,
      ariaDescribedby = null,
      dataCy = '',
    },
    ref
  ) => {
    let inputRef = useRef();
    if (ref != null) {
      inputRef = ref;
    }
    return (
      <div className="radio-wrapper">
        <input
          type="radio"
          {...input}
          onClick={onClick}
          id={id}
          disabled={disabled}
          aria-describedby={ariaDescribedby}
          ref={inputRef}
          data-cy={dataCy}
        />
        <label className={cls('label label--radio', className)} htmlFor={id}>
          {label}
        </label>
        {extraBlockContent && <div className="radio-wrapper__extra-block">{extraBlockContent}</div>}
      </div>
    );
  }
);

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  extraBlockContent: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  ariaDescribedby: PropTypes.string,
  dataCy: PropTypes.string,
};

export default RadioButton;
