import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Timeline = ({ className = '', children = null }) => (
  <dl className={cls('nls-timeline', className)}>{children}</dl>
);

Timeline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Timeline;
