import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';

import { Glyphicon } from 'common/components/Icon';
import Modal from 'common/components/Modal';
import { FlexGrid, Row, Block } from 'common/components/Grid';
import { hideServiceClosedModal } from 'common/containers/ServiceClosedModal/ServiceClosedModalActions';
import { Heading } from 'common/components/index';

const ServiceClosedModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(state => state.serviceClosed.isOpen);
  return (
    <Modal
      warn
      isOpen={isOpen}
      title={t('serviceclosed.title')}
      closeHandler={() => dispatch(hideServiceClosedModal())}
    >
      <FlexGrid className="text-center">
        <Row>
          <Block size="6">
            <Glyphicon glyph="huomio" className="service-closed-icon margin-t-3" />
            <Heading level="h3">
              <a
                onClick={() => {
                  dispatch(hideServiceClosedModal());
                  return true;
                }}
                href={window.location.href}
              >
                {t('serviceclosed.notice')}
              </a>
            </Heading>
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  );
};

export default ServiceClosedModal;
