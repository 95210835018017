import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Form } from 'react-final-form';
import { Chip, Collapsible } from 'common/components';
import { filterSettingsUpdated } from 'common/containers/FilterSettings/filterSettingsSlice';
import FilterParametersField from './FilterParametersField';

const Filter = ({
  type,
  subHeading = null,
  instructionText = null,
  badgeValue = null,
  dataTestId = '',
  customError = null,
  showCalculator = true,
}) => {
  const { t } = useTranslation();
  const [filterSettingUpdating, setFilterSettingUpdating] = useState(false);
  const dispatch = useDispatch();
  const parameters = useSelector(state => state.filterSettings[type]);

  /* We need to wait for the filter to be updated before showing the calculator */
  const addParameter = newParameter => {
    if (parameters.indexOf(newParameter) === -1) {
      setFilterSettingUpdating(true);
      dispatch(filterSettingsUpdated({ type, parameters: [...parameters, newParameter] }));
      setTimeout(() => setFilterSettingUpdating(false), 0);
    }
  };
  const removeParameter = deletedParameter => {
    setFilterSettingUpdating(true);
    dispatch(
      filterSettingsUpdated({ type, parameters: parameters.filter(parameter => parameter !== deletedParameter) })
    );
    setTimeout(() => setFilterSettingUpdating(false), 0);
  };

  const toggleButton = {
    openLabel: t('filter.openLabel'),
    closeLabel: t('filter.closeLabel'),
    hideLabel: false,
    openIcon: 'sliders-h',
    closeIcon: 'sliders-h',
  };

  return (
    <section className="nls-filter" data-testid={dataTestId}>
      <Collapsible
        hasSubHeading={subHeading != null}
        subHeading={subHeading}
        className="margin-b-2"
        toggleButton={toggleButton}
        badgeValue={badgeValue}
        showCalculator={showCalculator && !filterSettingUpdating}
        isFilter
      >
        <Form onSubmit={() => null}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FilterParametersField
                instructionText={instructionText}
                addParameter={addParameter}
                customError={customError}
              />
            </form>
          )}
        </Form>
        <div className="nls-chip-container margin-b-1">
          {parameters.map(parameter => (
            <Chip label={parameter} remove={removeParameter} />
          ))}
        </div>
      </Collapsible>
    </section>
  );
};

Filter.propTypes = {
  type: PropTypes.string.isRequired,
  subHeading: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  instructionText: PropTypes.string,
  badgeValue: PropTypes.string,
  dataTestId: PropTypes.string,
  customError: PropTypes.string,
  showCalculator: PropTypes.bool,
};

export default Filter;
