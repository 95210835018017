export const BUY_CERTIFICATES = 'buyCertificates';
export const REG_DOC = 'registrationDocument';
export const PROPERTY_DOC = 'propertyDocument';
export const REG_MAP_DOC = 'registrationMapDocument';
export const RENTAL_CERT = 'rentalCertificate';
export const STRAIN_CERT = 'strainCertificate';
export const LEGAL_CERT = 'legalCertificate';
export const MORTGAGES_LIST = 'mortgagesList';
export const LEGAL_CONFIRMATION = 'legalConfirmation';
export const PROPRIETOR_LIST = 'proprietorList';
export const CADASTRAL_SURVEY = 'cadastralSurvey';
export const LEASEHOLD = 'leasehold';
export const LEASEHOLDERS = 'leaseholders';
export const OSRA = 'osra';
export const USUFRUCTS_AND_RESTRICTIONS = 'usufructsAndRestrictions';
export const PLEDGES_LIST = 'pledgesList';
export const RESTRICTIONS_LIST = 'restrictionsList';
export const REG_APARTMENT_OWNERSHIP = 'registrationApartmentOwnership';
export const HOUSING_COMPANY_CONTACT_INFO = 'housingCompanyContactInfo';
