import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const DefinitionList = ({ className = '', children = null }) => (
  <dl className={cls('nls-definition-list', className)}>{children}</dl>
);

DefinitionList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default DefinitionList;
