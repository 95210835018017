// ROUTE_MAP_SITE_ID | ROUTE_SERVICES_SITE_ID | ROUTE_FILE_DOWNLOAD
let defaultSitePath = '';

// Magical APP_PUBLIC_PATH is defined in webpack config with WebpackDefinePlugin.
// eslint-disable-next-line no-undef
export const ROUTE_APP_ROOT = APP_PUBLIC_PATH;

function constructQueryString(input) {
  if (typeof input !== 'object') {
    return '';
  }
  return `?${Object.keys(input)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(input[key])}`)
    .join('&')}`;
}

const pathsJoinReturnParts = (parts, queryString) => {
  const firstPart = parts
    .map((part, i) => {
      if (i === 0) {
        return part.trim().replace(/[/]*$/g, '');
      }
      return part.trim().replace(/(^[/]*|[/]*$)/g, '');
    })
    .filter(x => x.length)
    .join('/');
  return {
    firstPart,
    queryString,
  };
};

export function pathsJoin(parts = [], queryString = undefined) {
  const pathParts = pathsJoinReturnParts(parts, queryString);
  return `${encodeURI(pathParts.firstPart)}${constructQueryString(pathParts.queryString)}`;
}

export function pathsJoinPreserveTrailingSlash(parts = [], queryString = undefined) {
  const pathParts = pathsJoinReturnParts(parts, queryString);
  return `${encodeURI(pathParts.firstPart)}/${constructQueryString(pathParts.queryString)}`;
}

export function buildRoute(sitePath, appRoutePath = '') {
  const possibleTrailingSlash = !appRoutePath ? '/' : '';
  return `/${pathsJoin([ROUTE_APP_ROOT, sitePath, appRoutePath])}${possibleTrailingSlash}`;
}

export const ROUTE_MAP_SITE_ID = 'karttapaikka';
export const ROUTE_SERVICES_SITE_ID = 'kiinteistoasiat';
export const ROUTE_FILE_DOWNLOAD = 'lataukset';

export const ROUTE_ERROR_GENERIC = 'errorGeneric.html';
export const ROUTE_MAP_SITE = buildRoute(ROUTE_MAP_SITE_ID);

export const MML_SITE_LOGOUT_URL =
  'https://www.maanmittauslaitos.fi/tietoa-maanmittauslaitoksesta/kiitos-kun-asioit-kanssamme';

export const MML_SITE_ESERVICE_URL = 'https://www.maanmittauslaitos.fi/asioi-verkossa';

export const setDefaultSitePath = newSitePath => {
  defaultSitePath = newSitePath;
};
export const buildMapSiteRoute = appRoutePath => buildRoute(ROUTE_MAP_SITE, appRoutePath);

export function getRoute(route = '') {
  return buildRoute(defaultSitePath, route);
}

/*
 * UI routes
 */
export const ONGOING_SESSION = 'istunto-voimassa';
export const EXPIRED_SESSION = 'istunto-paattynyt';
export const MAP_STORE_INDEX = 'karttakauppa';
export const SHOPPING_CART_CHECKOUT = 'kassa';
export const MAP_STORE_CUSTOM_MAPS = `${MAP_STORE_INDEX}/tee-oma-kartta`;
export const MAP_PRINT_PAGE = 'tilannetuloste';
export const FEEDBACK = 'palaute';
export const FEEDBACK_MAP = `${FEEDBACK}/karttapalaute`;
export const SHOPPING_CART = 'ostoskori';
export const SHARE_CUSTOM_MARKER = 'jaa-paikka';
export const TO_REAL_ESTATE_SITE = 'asiointipalveluun';
export const SHOPPING_CART_CONFIRMATION = 'tilausvahvistus';
export const SHOPPING_CART_PAYMENT_ERROR = 'maksuvirhe';
export const SHOPPING_CART_PAYMENT_CANCEL = 'maksunperuutus';
export const SHOPPING_CART_DELIVERY_ERROR = 'toimitusvirhe'; // Poistu
export const FORM_SUBMIT_SUCCESS = 'valmis';
export const FORM_SUBMIT_FAILED = 'epaonnistui';
export const FILE_DOWNLOAD_SERVICE = 'tiedostopalvelu';

/*
 * API Routes
 */

export const API_PATH_NEAREST_ADDRESS = 'api/nearestaddress';
export const API_PATH_GET_SNAPSHOT_PREVIEW_PIC = 'api/print/preview';
export const API_PATH_GET_PDF = 'api/get/pdflink';
export const API_PATH_MAPFEEDBACK = 'api/feedback';
export const API_PATH_CONFIGURATIONS = 'api/configurations';
export const API_PATH_CREATE_PAYMENT = 'api/createPayment/';
export const API_PATH_TITLES = 'api/titles';
export const API_PATH_AUTHENTICATION = 'api/authentication';
export const API_PATH_LOGOUT = 'api/authentication/logout';
export const API_PATH_AUTHORIZATION = 'api/authorization';
export const API_PATH_NOTIFICATIONS_LAST = 'api/notifications/last';
export const API_PATH_DISCOUNTS = 'api/discounts';
export const API_PATH_REGISTRATION_MAP_DOCUMENT = 'api/certificate/registrationmapdocumentxml';
export const API_PATH_SPATIAL_DATA_FILES = 'api/spatialDataFiles';
export const API_PATH_SPATIAL_DATA_FILES_ORDERS = 'api/spatialdatafilesorders';
export const API_PATH_SPATIAL_DATA_FILES_LICENCED_ORDERS = 'api/spatialdatafilesorders/licenced';
export const API_PATH_ESB_STATUS_CHECK = 'api/status/esb';
export const API_PATH_APPLICATIONS_STATUS_CHECK = 'api/status/applications';
export const API_PATH_LICENCED_STATUS_CHECK = 'api/status/licenced';
export const API_PATH_CHANGE_ROLE = 'api/authentication/changeRole';
export const API_PATH_PUBLIC_REGISTER_UNIT_FOR_POINT = 'api/publicpropertyinformation/point';
export const API_PATH_FETCH_GEOMETRY = 'api/geometry';
export const API_PATH_PURCHASE_ORDER_DOWNLOADABLES = 'api/purchaseorders/downloadables';
export const API_PATH_HTJ_ESB_STATUS = 'api/status/esb/htj';
