import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { Header } from '../Header';
import { SubHeading } from '../index';

const HeadingWithSteps = ({ text, currentStep, numberOfSteps, subtitle = undefined, className = '' }) => (
  <>
    <Header text={text} className={cls('flex-container', className)}>
      {currentStep}/{numberOfSteps}
    </Header>
    {subtitle && <SubHeading level="h5">{subtitle}</SubHeading>}
  </>
);

HeadingWithSteps.propTypes = {
  text: PropTypes.string.isRequired,
  currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  numberOfSteps: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  subtitle: PropTypes.string,
  className: PropTypes.string,
};

export default HeadingWithSteps;
