import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Field, useForm, useField } from 'react-final-form';

import { Glyphicon } from 'common/components';
import { ValidationErrorText } from 'common/containers/Forms/InputValidation/InputValidationComponents';
import { FIELD_NAMES, ORTHOPHOTO, MAP_SHEET_SELECTION_PRODUCTS } from '../../FileDownloadServiceConstants';
import { clearMapSheetSelectionThunk, drawSelectedMapSheetsThunk } from '../../FileDownloadServiceAction';

const SelectedMapSheetsComponent = ({ areaSelectionType, askUserToZoomMap, submitFailed }) => {
  const dispatch = useDispatch();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { change } = useForm();

  const { YEAR_SELECTION_TYPE, PRODUCT_ID, YEAR } = FIELD_NAMES;

  const {
    input: { value: productId },
  } = useField(PRODUCT_ID);

  const {
    input: { value: yearSelectionType },
  } = useField(YEAR_SELECTION_TYPE);

  const selectedMapSheets = useSelector(state => state.fileDownloadService.selectedMapSheets);

  useEffect(() => {
    if (productId === ORTHOPHOTO && yearSelectionType === YEAR) {
      dispatch(clearMapSheetSelectionThunk());
    }

    if (selectedMapSheets.length > 0) {
      dispatch(drawSelectedMapSheetsThunk(areaSelectionType));
    }

    // Teardown:
    return () => {
      change(
        FIELD_NAMES.SELECTED_MAP_SHEETS,
        selectedMapSheets.map(sheet => sheet.properties.mapSheetNumber ?? sheet.properties.karttalehtitunnus)
      );

      if (!MAP_SHEET_SELECTION_PRODUCTS.some(product => window.location.pathname.includes(product))) {
        dispatch(clearMapSheetSelectionThunk());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    change(
      FIELD_NAMES.SELECTED_MAP_SHEETS,
      selectedMapSheets.map(sheet => sheet.properties.mapSheetNumber ?? sheet.properties.karttalehtitunnus)
    );
  }, [change, selectedMapSheets]);

  return (
    <Field
      name={FIELD_NAMES.SELECTED_MAP_SHEETS}
      component={({ meta }) => (
        <>
          {askUserToZoomMap && (
            <div className="file-download-service__info-box margin-t-1">
              <span className="file-download-service__info-box__icon">
                <Glyphicon glyph="info" />
              </span>
              <p>{t(`fileDownloadService.notifications.zoom`)}</p>
            </div>
          )}
          {selectedMapSheets.length === 1 && (
            <p>
              {selectedMapSheets.length} {t(`fileDownloadService.areaSelection.selectSheets.selectedAmount.single`)}
            </p>
          )}
          {selectedMapSheets.length > 1 && (
            <p>
              {selectedMapSheets.length} {t(`fileDownloadService.areaSelection.selectSheets.selectedAmount.multiple`)}
            </p>
          )}
          {(submitFailed || meta.dirty) && meta.error ? (
            <ValidationErrorText className="margin-t-1" errorText={meta.error} />
          ) : null}
        </>
      )}
    />
  );
};

SelectedMapSheetsComponent.propTypes = {
  areaSelectionType: PropTypes.string.isRequired,
  askUserToZoomMap: PropTypes.bool.isRequired,
  submitFailed: PropTypes.bool.isRequired,
};

export default SelectedMapSheetsComponent;
