export const TRANSFER_TYPES = [
  {
    code: '13', // Perinnönjako
    attachmentType: '08',
    description: 'lcag.step1.transferType.option.inheritanceAgreement.label',
    dateLabel: 'lcag.step1.transferDate.option.inheritanceAgreement.label',
  },
  {
    code: '16', // Ositus ja perinnönjakosopimus
    attachmentType: '51',
    description: 'lcag.step1.transferType.option.split.label',
    dateLabel: 'lcag.step1.transferDate.option.split.label',
  },
  {
    code: '05', // testamentti
    attachmentType: '20',
    description: 'lcag.step1.transferType.option.willAndTestament.label',
    dateLabel: 'lcag.step1.transferDate.option.willAndTestament.label',
  },
  {
    code: '04', // Perukirja
    attachmentType: '45',
    description: 'lcag.step1.transferType.option.inheritance.label',
    fileLabel: 'lcag.step1.transferType.option.inheritance.fileLabel',
    dateLabel: 'lcag.step1.transferDate.option.inheritance.label',
    summaryLabel: 'lcag.step1.transferType.option.inheritance.summaryLabel',
  },
  {
    code: '06', // Ositussopimus
    attachmentType: '10',
    description: 'lcag.step1.transferType.option.splitAgreement.label',
    dateLabel: 'lcag.step1.transferDate.option.splitAgreement.label',
  },
  {
    code: '25', // Erottelusopimus
    attachmentType: '15',
    description: 'lcag.step1.transferType.option.separationAgreement.label',
    dateLabel: 'lcag.step1.transferDate.option.separationAgreement.label',
  },
  {
    code: '17', // Muu asiakirja
    attachmentType: '25',
    description: 'lcag.step1.transferType.option.otherDocument.label',
    dateLabel: 'lcag.step1.transferDate.option.otherDocument.label',
  },
];

export const INHERITANCE_TYPE = '04';

export const LCAG_FIELDS = {
  TRANSFER_TYPE: 'transferType',
  TRANSFER_DATE: 'transferDate',
  TARGETS: 'targets',
  RECIPIENTS: 'recipients',
  DONORS: 'donors',
  TITLE_DEED_ATTACHMENTS: 'titleDeedAttachments',
  OTHER_ATTACHMENTS: 'otherAttachments',
};

export const LCAG_FIELD_VALUES = {
  PORTION_TYPE: {
    WHOLE_OR_PORTION: 'WHOLE_OR_PORTION_PORTION_TYPE',
    PROPERTY: 'PROPERTY_PORTION_TYPE',
    SHARED_AREA: 'SHARED_AREA_PORTION_TYPE',
  },
};
