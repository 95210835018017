/* eslint-disable */
(function (root, factory) {
  if (typeof define === 'function' && define.amd) {
    define(['jschannel'], factory);
  } else if (typeof module === 'object' && module.exports) {
    module.exports = factory();
  } else {
    root.OskariRPC = factory();
  }
})(this, function () {
  return {
    VERSION: 'MOCK',
    connect: function (target, origin) {
      var RPC_API = {};
      RPC_API.enableDebug = function (blnEnabled) {};
      RPC_API.log = function () {};
      RPC_API.isReady = function () {
        return true;
      };
      RPC_API.onReady = function (cb) {
        if (typeof cb !== 'function') {
          return;
        }
        cb();
      };
      RPC_API.destroy = function () {};
      RPC_API.handleEvent = function (eventName, handler) {
        if (eventName === 'MapClickedEvent') {
          handler(0, 0);
        }
        if (eventName === 'SearchResultEvent') {
          handler({
            result: {
              locations: [
                {
                  id: 1,
                  lat: 0,
                  lon: 0,
                  name: 'Kaukovaara',
                  region: 'Oulu',
                  type: 'Kaupunginosa',
                  source: 'abradakabrada',
                },
                {
                  id: 2,
                  lat: 1,
                  lon: 1,
                  name: 'Lähivaara',
                  region: 'Vaasa',
                  type: 'huiskispuiskis',
                  source: 'abradakabrada',
                },
                {
                  id: 3,
                  lat: 1,
                  lon: 1,
                  name: 'Kaukovaara',
                  region: 'Oulu',
                  type: 'Talo',
                  source: 'abradakabrada',
                },
              ],
            },
          });
        }
      };
      RPC_API.unregisterEventHandler = function (eventName, handler) {};
      RPC_API.postRequest = function (request, params, error) {};
      RPC_API.isSupported = function (expectedOskariVersion, callback) {};
      RPC_API.getAllLayers = function (cb) {
        return cb([
          {
            id: 832,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (laserkeilausaineisto 5p/m2)',
            config: {},
            minZoom: 5,
          },
          {
            id: 769,
            opacity: 100,
            visible: false,
            name: 'Käyttöoikeusyksikön osan sijaintitiedot',
            config: {},
            minZoom: 7,
          },
          {
            id: 745,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:200 000, puolilehti)',
            config: {},
          },
          {
            id: 741,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:200 000)',
            config: {},
          },
          {
            id: 744,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:100 000, puolilehti)',
            config: {},
          },
          {
            id: 740,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:100 000)',
            config: {},
          },
          {
            id: 739,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:50 000)',
            config: {},
            minZoom: 2,
          },
          {
            id: 743,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:25 000, puolilehti)',
            config: {},
            minZoom: 2,
          },
          {
            id: 693,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:10 000)',
            config: {},
            minZoom: 3,
          },
          {
            id: 694,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (laserkeilausaineisto 0,5p/m2)',
            config: {},
            minZoom: 5,
          },
          {
            id: 738,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:25 000)',
            config: {},
            minZoom: 2,
          },
          {
            id: 691,
            opacity: 100,
            visible: false,
            name: 'TM35-lehtijako (1:5 000)',
            config: {},
            minZoom: 5,
          },
          {
            id: 819,
            opacity: 100,
            visible: false,
            name: 'Kiinteistötunnukset (vektoritiili / asiointi)',
            config: {},
            minZoom: 9,
          },
          {
            id: 623,
            opacity: 100,
            visible: false,
            name: 'Kiinteistöjaotus (vektoritiili)',
            config: {},
            minZoom: 9,
          },
          {
            id: 186,
            opacity: 50,
            visible: false,
            name: 'Korkeusmalli (harmaasavy, vinovalovarjoste)',
            config: {},
            metadataIdentifier: '1f247c72-4487-4d20-9595-985560343066',
          },
          {
            id: 3,
            opacity: 100,
            visible: false,
            name: 'Ortokuvat',
            config: {},
            metadataIdentifier: 'b20a360b-1734-41e5-a5b8-0e90dd9f2af3',
          },
          {
            id: 382,
            opacity: 100,
            visible: false,
            name: 'Selkokartta',
            config: {},
            metadataIdentifier: 'ec20cca8-36f4-45b3-9133-d8e62bae3123',
          },
          {
            id: 2,
            opacity: 100,
            visible: true,
            name: 'Maastokartta',
            config: {},
            metadataIdentifier: 'a2cd4d67-ee20-47b7-b899-a4d72e72bb2d',
          },
          {
            id: 1,
            opacity: 100,
            visible: false,
            name: 'Taustakartta',
            config: {},
            metadataIdentifier: 'c22da116-5095-4878-bb04-dd7db3a1a341',
          },
        ]);
      };
      RPC_API.getCurrentState = function (params, success, error) {};
      RPC_API.getFeatures = function (params, success, error) {};
      RPC_API.getInfo = function (params, success, error) {};
      RPC_API.getMapBbox = function (params, success, error) {};
      RPC_API.getMapPosition = function (cb) {
        cb({
          centerX: 0,
          centerY: 0,
          zoom: 4,
          scale: 362834,
          srsName: 'EPSG:3067',
        });
      };
      RPC_API.getPixelMeasuresInScale = function (params, success, error) {};
      RPC_API.getScreenshot = function (params, success, error) {};
      RPC_API.getSupportedEvents = function (params, success, error) {};
      RPC_API.getSupportedFunctions = function (params, success, error) {};
      RPC_API.getSupportedRequests = function (params, success, error) {};
      RPC_API.getVectorFeatures = function (params, success, error) {};
      RPC_API.getZoomRange = function (params, success, error) {};
      RPC_API.resetState = function (params, success, error) {};
      RPC_API.sendUIEvent = function (params, success, error) {};
      RPC_API.setCursorStyle = function (params, success, error) {};
      RPC_API.useState = function (params, success, error) {};
      RPC_API.zoomIn = function (params, success, error) {};
      RPC_API.zoomOut = function (params, success, error) {};
      RPC_API.zoomTo = function (params, success, error) {};

      return RPC_API;
    },
    synchronizerFactory: function (channel, handlers) {},
  };
});
/* eslint-enable */
