import React from 'react';
import PropTypes from 'prop-types';

import MapButton from 'realEstateSite/containers/Realty/RealEstate/common/MapButtonComponent';
import FocusableHeading from './FocusableHeading';

const HeadingWithMapButton = ({
  level = 'h1',
  visualLevel = 'h2',
  className = '',
  onMobile = false,
  hidden = false,
  showButton = true,
  withButtonText = false,
  children = null,
}) => {
  return (
    <div className="nls-heading__with-button">
      <FocusableHeading level={level} visualLevel={visualLevel} className={className}>
        {children}
      </FocusableHeading>
      {onMobile && showButton && <MapButton withText={withButtonText} tabIndex={hidden ? -1 : 0} />}
    </div>
  );
};

HeadingWithMapButton.propTypes = {
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  visualLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: PropTypes.string,
  onMobile: PropTypes.bool,
  showButton: PropTypes.bool,
  hidden: PropTypes.bool,
  withButtonText: PropTypes.bool,
  children: PropTypes.node,
};

export default HeadingWithMapButton;
