/**
 * @typedef {'MARKER' | 'GEOJSON'} GeometryType
 */
export const GEOMETRY_TYPES = {
  MARKER: 'MARKER',
  GUIDE: 'GUIDE',
  GEOJSON: 'GEOJSON',
};

/**
 * @typedef {string} FeatureType
 * @enum
 */
export const FEATURE_TYPES = {
  CUSTOM_MARKER: 'CUSTOM_MARKER',
  SEARCH_MARKER: 'SEARCH_MARKER',
  ADDRESS_MARKER: 'ADDRESS_MARKER',
  FEEDBACK_MARKER: 'FEEDBACK_MARKER',
  BOUNDARY_MARKER: 'rajamerkki',
  REAL_ESTATE_SEARCH_RESULT_MARKER: 'REAL_ESTATE_SEARCH_RESULT_MARKER',
  AREA_SELECTION: 'AREA_SELECTION',
  BBOX: 'BBOX',
  DISTANCE_MEASUREMENT: 'DISTANCE_MEASUREMENT',
  AREA_MEASUREMENT: 'AREA_MEASUREMENT',
  USUFRUCT_UNIT: 'USUFRUCT_UNIT',
  MAP_SHEET_AREAS: 'MAP_SHEET_AREAS',
};
