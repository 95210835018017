import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';

import { ExternalLink, Heading, Paragraph } from 'common/components';
import * as Routes from 'common/constants/Routes';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { MAP_FEEDBACK } from 'common/containers/Authorization/privileges';
import RouterLink from 'common/components/Link/RouterLink';
import { PROVIDE_FEEDBACK_ABOUT_MAP_TITLE } from '../AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

export const FEEDBACK_PAGE_URL = 'https://www.maanmittauslaitos.fi/karttoihin-liittyva-palaute';

const MapFeedbackLink = () => (
  <>
    <Heading level="h2">{t('feedback.mapfeedback.title')}</Heading>
    <Paragraph>{t('feedback.mapfeedback.description')}</Paragraph>
    <Paragraph>
      <RouterLink
        className="button button--light"
        to={Routes.getRoute(Routes.FEEDBACK_MAP)}
        selector={PROVIDE_FEEDBACK_ABOUT_MAP_TITLE}
      >
        {t('feedback.mapfeedback.linktext')}
      </RouterLink>
    </Paragraph>
  </>
);

// eslint-disable-next-line react/prop-types
const ApplicationFeedbackLink = ({ isRealEstateSite = false }) => (
  <>
    <Heading level="h2">
      {isRealEstateSite ? t('feedback.appfeedback.realEstate.title') : t('feedback.appfeedback.title')}
    </Heading>
    <Paragraph>
      <ExternalLink href={t('feedback.appfeedback.link')}>
        {isRealEstateSite ? t('feedback.appfeedback.realEstate.description') : t('feedback.appfeedback.description')}
      </ExternalLink>
    </Paragraph>
  </>
);

const FeedbackComponent = ({ isRealEstateSite }) => (
  <>
    {isAuthorized([MAP_FEEDBACK]) && <MapFeedbackLink />}
    <ApplicationFeedbackLink isRealEstateSite={isRealEstateSite} />
  </>
);

FeedbackComponent.propTypes = {
  isRealEstateSite: PropTypes.bool,
};

export default FeedbackComponent;
