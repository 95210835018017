import i18next from 'i18next';

import * as OskariMap from 'oskari/OskariMap';
import { addInfoBoxThunk } from 'common/geometries/geometriesActions';
import { dispatch } from 'common/helpers/ReduxAware';
import { isDesktop } from 'common/constants/Layout';
import { setRegisterState } from 'realEstateSite/containers/Realty/RealEstate/RealEstateList/realEstateListSlice';
import { infoBox } from '../InfoBox';
import { addClickFeatureWithCoordinatesListener, addFeatureListener } from '../ClickFeature';
import createBoundaryMarkerDetails from './BoundaryMarkers';
import { createParcelInfo, createMapSiteParcelInfo } from './ParcelInfo';
import {
  ADD_NEIGHBOUR_REAL_ESTATE_FEATURES,
  REMOVE_PARCEL,
  ZOOM_TO_PARCELS,
} from '../../../common/geometries/geometriesActionTypes';
import createUsufructDetails from './Usufruct';
import ServicesApi from '../../../realEstateSite/api/ServicesApi';
import { getReduxStore } from '../../../common/helpers/storage';
import { LAYER_BOUNDARY_MARKERS, LAYER_PARCELS, LAYER_USUFRUCTS } from '../../layers/VectorLayers';

let disableClickSelect;

export const initClickHandlers = () => {
  const store = getReduxStore();
  const appContext = store?.getState()?.application?.applicationContext;
  disableClickSelect = false;
  addBoundaryMarkersClickHandler();
  if (appContext === 'mapSite') addMapSiteParcelClickHandler(store?.getState);
  else {
    addUsufructClickHandler(store?.getState);
    addParcelClickHandler(store?.getState);
  }
};

export function setDisableClickSelect(value) {
  disableClickSelect = value;
}

export function getFeatureForLayer(event, layerId) {
  return event.features.filter(feature => feature.layerId === layerId).map(feature => feature.geojson.features[0])[0];
}

function addBoundaryMarkersClickHandler() {
  addFeatureListener('boundaryMarkers', event => {
    if (event.operation === 'click' && !disableClickSelect) {
      const geojson = getFeatureForLayer(event, LAYER_BOUNDARY_MARKERS);

      if (!geojson) return;

      const coordinates = { n: geojson.geometry.coordinates[1], e: geojson.geometry.coordinates[0] };
      const markerType = geojson.properties.rajamerkkilaji;

      dispatch(
        addInfoBoxThunk(
          {
            id: geojson.properties.id,
            title:
              markerType.code === '0'
                ? i18next.t('boundaryMarker.infobox.title')
                : markerType[i18next.language] || markerType.fi,
            desc: createBoundaryMarkerDetails(geojson.properties, coordinates),
            coords: coordinates,
            hideCoordinates: true,
          },
          'boundaryMarker'
        )
      );
    }
  });
}

function addParcelClickHandler(getState) {
  addClickFeatureWithCoordinatesListener('parcels', async (event, coords) => {
    if (event.operation === 'click' && !disableClickSelect) {
      // Do not let click propagate, if clicking boundary mark:
      const boundaryMark = getFeatureForLayer(event, LAYER_BOUNDARY_MARKERS);
      if (boundaryMark != null) return;

      // Do not let click propagate, if clicking usufruct:
      const usufruct = getFeatureForLayer(event, LAYER_USUFRUCTS);
      if (usufruct != null) return;

      const geojson = getFeatureForLayer(event, LAYER_PARCELS);

      if (!geojson || geojson.properties.name !== 'palsta') return;

      const { body, title, neighbourParcels } = await createParcelInfo(geojson.properties);

      infoBox(
        {
          id: geojson.properties.realtyIdentifier,
          title,
          desc: body,
          coords,
          hideCoordinates: true,
          actions: [
            {
              name: i18next.t('realEstate.neighbourRealEstates.showOnMap'),
              action: {
                actionId: ADD_NEIGHBOUR_REAL_ESTATE_FEATURES,
                neighbourParcels,
              },
            },
          ],
        },
        isDesktop(getState().layout.mode)
      );
    }
  });
}

function addMapSiteParcelClickHandler(getState) {
  addClickFeatureWithCoordinatesListener('parcels', async (event, coords) => {
    if (event.operation === 'click' && !disableClickSelect) {
      // Do not let click propagate, if clicking boundary mark:
      const boundaryMark = getFeatureForLayer(event, LAYER_BOUNDARY_MARKERS);
      if (boundaryMark != null) return;

      const geojson = getFeatureForLayer(event, LAYER_PARCELS);

      if (!geojson || geojson.properties.name !== 'palsta') return;

      const { body, title, numberOfParcels } = await createMapSiteParcelInfo(coords);

      const removeAction = {
        name: i18next.t('button.remove'),
        action: {
          actionId: REMOVE_PARCEL,
        },
        group: 1,
      };

      const actions =
        numberOfParcels > 1
          ? [
              {
                name: i18next.t('button.showOnMap'),
                action: {
                  actionId: ZOOM_TO_PARCELS,
                },
                group: 1,
              },
              removeAction,
            ]
          : [removeAction];

      infoBox(
        {
          id: geojson.properties.realtyIdentifier,
          title,
          desc: body,
          coords,
          hideCoordinates: true,
          actions,
        },
        isDesktop(getState().layout.mode)
      );
    }
  });
}

function addUsufructClickHandler(getState) {
  addClickFeatureWithCoordinatesListener('usufructs', async (event, coords) => {
    if (event.operation === 'click' && !disableClickSelect) {
      let feature = getFeatureForLayer(event, LAYER_USUFRUCTS);
      let ownFeature = true;

      if (!feature) {
        feature =
          getFeatureForLayer(event, OskariMap.getPrivateRoadsLayerId()) || getFeatureForLayer(event, LAYER_PARCELS);
        if (!feature || !feature.properties.kayttooikeusyksikkotunnus) return;

        ownFeature = false;
        try {
          const store = getReduxStore();
          const registerStateDate = store.getState().realEstateList?.registerStateDate;
          const properties = await ServicesApi.fetchPrivateRoadProperties(feature.properties.kayttooikeusyksikkotunnus);
          store.dispatch(setRegisterState(properties?.registerStateDate, registerStateDate));
          feature.properties = { ...feature.properties, ...properties };
        } catch (error) {
          console.error(error);
          return;
        }
      }

      infoBox(
        {
          id: feature.id,
          title: feature.properties.usufructTypeText[i18next.language],
          desc: createUsufructDetails(feature, ownFeature),
          coords,
          hideCoordinates: true,
        },
        isDesktop(getState().layout.mode)
      );
    }
  });
}
