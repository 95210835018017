import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const ScrollerPanelContext = React.createContext();

export const ScrollerPanelProvider = ({ children, activeColumnNumber, fullWidth = false, scrollerHeight }) => {
  const [activeMenuColumnNumber, setActiveMenuColumnNumber] = useState(null);
  const [activeMenuItemTop, setActiveMenuItemTop] = useState(null);
  const [previousMenuItemTop, setPreviousMenuItemTop] = useState(null);
  const [activeListButtonSelected, setActiveListButtonSelected] = useState({
    activityItems: false,
    realEstates: false,
    apartments: false,
  });

  const contextValues = useMemo(() => {
    return {
      fullWidth,
      activeColumnNumber,
      activeMenuColumnNumber,
      setActiveMenuColumnNumber,
      activeMenuItemTop,
      setActiveMenuItemTop,
      previousMenuItemTop,
      setPreviousMenuItemTop,
      scrollerHeight,
      activeListButtonSelected,
      setActiveListButtonSelected,
    };
  }, [
    fullWidth,
    activeColumnNumber,
    activeMenuColumnNumber,
    setActiveMenuColumnNumber,
    activeMenuItemTop,
    setActiveMenuItemTop,
    previousMenuItemTop,
    setPreviousMenuItemTop,
    scrollerHeight,
    activeListButtonSelected,
    setActiveListButtonSelected,
  ]);

  return <ScrollerPanelContext.Provider value={contextValues}>{children}</ScrollerPanelContext.Provider>;
};

ScrollerPanelProvider.propTypes = {
  children: PropTypes.node.isRequired,
  activeColumnNumber: PropTypes.number.isRequired,
  fullWidth: PropTypes.bool,
  scrollerHeight: PropTypes.number.isRequired,
};

export default ScrollerPanelContext;
