import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Routes as RouterRoutes, useLocation } from 'react-router-dom';
import { Provider, useSelector } from 'react-redux';

import * as Routes from 'common/constants/Routes';
import FrontPage from 'mapSite/pages/FrontPage';
import FeedbackIndexPage from 'mapSite/pages/FeedbackIndexPage';
import MapStoreIndexPage from 'mapSite/pages/MapStoreIndexPage';
import ShareMarkerPage from 'mapSite/pages/ShareMarkerPage';
import ToRealEstateSitePage from 'mapSite/pages/ToRealEstateSitePage';
import FileDownloadServicePage from 'mapSite/pages/FileDownloadServicePage';
import LayoutComponent from 'common/containers/Layout/LayoutComponent';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import * as Privileges from 'common/containers/Authorization/privileges';
import { isMobile } from 'common/constants/Layout';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';
import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import FileDownloadServiceFormComponent from 'common/containers/FileDownloadService/FileDownloadServiceFormComponent';
import { isProductDetailsPage } from 'common/containers/FileDownloadService/FileDownloadServiceHelpers';
import { LoaderSpinner, ScrollerWrapper } from 'common/components';
import CustomMaps from 'mapSite/pages/CustomMapsFormPage';
import ShoppingCart from 'mapSite/pages/ShoppingCartPage';
import Checkout from 'mapSite/pages/CheckoutPage';
import FeedbackMapForm from 'mapSite/pages/FeedbackMapFormPage';
import Confirmation from 'mapSite/pages/ConfirmationPage';
import PaymentError from 'mapSite/pages/PaymentErrorPage';
import PaymentCancel from 'mapSite/pages/PaymentCancelPage';
import DeliveryError from 'mapSite/pages/DeliveryErrorPage';
import NotFound from 'mapSite/pages/NotFoundPage';
import SessionExpired from 'mapSite/pages/SessionExpiredPage';

const FeedbackMapComponent = lazy(() => import('common/containers/FeedbackMap/FeedbackMapComponent'));
const DesktopMapPrintPage = lazy(() => import('common/containers/DesktopMapControls/DesktopMapPrintPage'));

const shouldShowCurtain = pathname =>
  pathname.includes(Routes.buildMapSiteRoute(Routes.SHARE_CUSTOM_MARKER)) || isProductDetailsPage(pathname);

const ScrollerRouting = ({ children }) => {
  const { pathname } = useLocation();
  const layoutMode = useSelector(state => state.layout.mode);

  return (
    <BusinessPanelComponent
      viewSize="full"
      showCurtain={shouldShowCurtain(pathname)}
      shouldScrollToTop={() => isMobile(layoutMode)}
      scrollTopTrigger={pathname}
    >
      {children}
      <Footer />
    </BusinessPanelComponent>
  );
};

ScrollerRouting.propTypes = {
  children: PropTypes.node,
};

const createRouter = store => {
  if (window.Cypress) window.store = store;
  return (
    <Provider store={store}>
      <Router>
        <LayoutComponent>
          <RouterRoutes>
            {/* SCROLLER MENU ROUTING ===================================== */}
            <Route path={Routes.ROUTE_MAP_SITE} element={<ScrollerRouting />}>
              <Route
                path=""
                element={<ScrollerWrapper fullWidth components={[{ columnNumber: 1, component: FrontPage }]} />}
              />
              {isAuthorized([Privileges.READY_MAP_STORE]) && (
                <Route
                  path={Routes.MAP_STORE_INDEX}
                  element={
                    <ScrollerWrapper
                      fullWidth
                      components={[
                        { columnNumber: 1, component: FrontPage, hidden: true },
                        { columnNumber: 2, component: MapStoreIndexPage },
                      ]}
                    />
                  }
                />
              )}
              {isAuthorized([Privileges.SHARE_MAP_LINK]) && (
                <Route
                  path={Routes.SHARE_CUSTOM_MARKER}
                  element={
                    <ScrollerWrapper
                      fullWidth
                      components={[
                        { columnNumber: 1, component: FrontPage, hidden: true },
                        { columnNumber: 2, component: ShareMarkerPage },
                      ]}
                    />
                  }
                />
              )}
              {isAuthorized([Privileges.FEEDBACK]) && (
                <Route
                  path={Routes.FEEDBACK}
                  element={
                    <ScrollerWrapper
                      fullWidth
                      components={[
                        { columnNumber: 1, component: FrontPage, hidden: true },
                        { columnNumber: 2, component: FeedbackIndexPage },
                      ]}
                    />
                  }
                />
              )}
              <Route
                path={Routes.TO_REAL_ESTATE_SITE}
                element={
                  <ScrollerWrapper
                    fullWidth
                    components={[
                      { columnNumber: 1, component: FrontPage, hidden: true },
                      { columnNumber: 2, component: ToRealEstateSitePage },
                    ]}
                  />
                }
              />
              {isAuthorized([Privileges.VIEW_DOWNLOAD_SERVICE]) && (
                <Route
                  exact
                  strict
                  path={`${Routes.FILE_DOWNLOAD_SERVICE}`}
                  element={
                    <ScrollerWrapper
                      fullWidth
                      components={[
                        { columnNumber: 1, component: FrontPage, hidden: true },
                        { columnNumber: 2, component: FileDownloadServicePage },
                      ]}
                    />
                  }
                />
              )}
              {isAuthorized([Privileges.VIEW_DOWNLOAD_SERVICE]) && (
                <Route
                  path={`${Routes.FILE_DOWNLOAD_SERVICE}/:id/*`}
                  element={
                    <ScrollerWrapper
                      fullWidth
                      components={[
                        { columnNumber: 1, component: FrontPage, hidden: true },
                        { columnNumber: 2, component: FileDownloadServicePage, hidden: true },
                        { columnNumber: 3, component: FileDownloadServiceFormComponent },
                      ]}
                    />
                  }
                />
              )}
            </Route>

            {/* SEPARATE PAGES =========================================================== */}
            {/* Desktop Map Print Page */}
            <Route element={<DesktopMapPrintPage />} path={`karttapaikka/${Routes.MAP_PRINT_PAGE}`} />
            {isAuthorized([Privileges.CUSTOM_MAP_STORE]) && (
              <Route path={Routes.getRoute(Routes.MAP_STORE_CUSTOM_MAPS).concat('/*')} element={<CustomMaps />} />
            )}
            {isAuthorized([Privileges.MAP_FEEDBACK]) && (
              <Route path={`${Routes.getRoute(Routes.FEEDBACK_MAP)}/*`} element={<FeedbackMapForm />}>
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
                      <FeedbackMapComponent />
                    </Suspense>
                  }
                />
              </Route>
            )}
            {isAuthorized([Privileges.CHECKOUT]) && (
              <>
                <Route path={Routes.getRoute(Routes.SHOPPING_CART)} element={<ShoppingCart />} />
                <Route path={`${Routes.getRoute(Routes.SHOPPING_CART_CHECKOUT)}/*`} element={<Checkout />} />
                <Route path={Routes.getRoute(Routes.SHOPPING_CART_CONFIRMATION)} element={<Confirmation />} />
                <Route path={Routes.getRoute(Routes.SHOPPING_CART_PAYMENT_ERROR)} element={<PaymentError />} />
                <Route path={Routes.getRoute(Routes.SHOPPING_CART_PAYMENT_CANCEL)} element={<PaymentCancel />} />
                <Route path={Routes.getRoute(Routes.SHOPPING_CART_DELIVERY_ERROR)} element={<DeliveryError />} />
              </>
            )}
            <Route path={Routes.getRoute(Routes.EXPIRED_SESSION)} element={<SessionExpired />} />
            <Route path="*" element={<NotFound />} />
          </RouterRoutes>
        </LayoutComponent>
      </Router>
    </Provider>
  );
};

export default createRouter;
