import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import FocusableHeading from './FocusableHeading';
import Glyphicon from '../Icon/Glyphicon';
import { Ruler } from '../index';

const HeadingWithIcon = ({
  glyph,
  level = 'h1',
  visualLevel = 'h2',
  noRuler = false,
  longTitle = false,
  className = '',
  children = null,
}) => {
  return (
    <div className={cls('nls-heading__with-icon', { 'no-margin': noRuler })}>
      <Glyphicon glyph={glyph} className={cls('glyphicon', `glyphicon__${visualLevel}`)} />
      <div>
        <FocusableHeading
          level={level}
          visualLevel={visualLevel}
          className={cls(className, { 'long-title': longTitle })}
        >
          {children}
        </FocusableHeading>
        {!noRuler && <Ruler />}
      </div>
    </div>
  );
};

HeadingWithIcon.propTypes = {
  glyph: PropTypes.string.isRequired,
  level: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  visualLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  longTitle: PropTypes.bool,
  noRuler: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default HeadingWithIcon;
