import { MAP_CLICKED_LISTENER_CUSTOM_MARKER } from 'common/constants/EventListenerIds';
import * as OskariMap from 'oskari/OskariMap';
import { pathsJoinPreserveTrailingSlash } from 'common/constants/Routes';
import i18next from 'i18next';
import {
  getParamValue,
  getUrlParam,
  getUrlParamAsNumber,
  getEncodedUrlParamAsJSON,
  encodeJSON,
} from 'common/helpers/Url/Url';
import {
  SHARED_MARKER_LAYERS_SCHEMA,
  SHARED_MARKER_DEFAULT_LAYERS,
  shareOpenAction,
} from 'common/containers/Share/ShareActions';
import {
  INFO_BOX_ACTION_ID_SHARE_CUSTOM_MARKER,
  INFO_BOX_ACTION_ID_REMOVE_CUSTOM_MARKER,
  INFO_BOX_ACTION_ID_EDIT_CUSTOM_MARKER,
} from 'common/constants/InfoBoxActionIds';
import { error } from 'common/helpers/Error';
import { getConfiguration } from 'common/helpers/Configurations/Configurations';
import validateJSON from 'common/helpers/JSONValidator';
import {
  toggleMapLayerVisibilityThunk,
  toggleMapLayerOpacityThunk,
} from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import * as Routes from 'common/constants/Routes';
import { FEATURE_TYPES } from 'common/constants/MapConstants';
import { getSelectableLayers } from 'oskari/OskariMap';

import markerSvg from 'styles/markers/CustomMarker.svg';

import { MAP_TOOL_CUSTOM_MARKER_OPEN_EDITOR, MAP_TOOL_CUSTOM_MARKER_CLOSE_EDITOR } from './MapToolbarActionTypes';
import { addMarkerFeature, removeFeatureThunk } from '../../geometries/geometriesActions';
import {
  CUSTOM_MARKER_TITLE_MAX_LENGTH,
  CUSTOM_MARKER_SHOW_MAX_LENGTH,
  CUSTOM_MARKER_PRIORITY,
} from './CustomMarkerConstants';
import { isDesktop } from '../../constants/Layout';
import { LAYER_CUSTOM_MARKERS } from '../../../oskari/layers/VectorLayers';

const marker = {
  svg: markerSvg,
  centerX: 16,
  centerY: 1,
  layer: LAYER_CUSTOM_MARKERS,
};

const openEditorAction = item => ({
  type: MAP_TOOL_CUSTOM_MARKER_OPEN_EDITOR,
  item,
});

const closeEditorAction = item => ({
  type: MAP_TOOL_CUSTOM_MARKER_CLOSE_EDITOR,
  item,
});

const formattedCustomMarkerData = data => ({
  ...data,
  id: `CustomMarker_${Date.now()}`,
});

const toMapFeature = customMarker => ({
  ...customMarker,
  name: customMarker.title,
  featureType: FEATURE_TYPES.CUSTOM_MARKER,
});

export function turnCustomMarkerOn(dispatch) {
  OskariMap.setCursorStyle('crosshair');
  OskariMap.clicked(MAP_CLICKED_LISTENER_CUSTOM_MARKER, data => {
    const formattedData = {
      ...formattedCustomMarkerData(data),
      modalIsOpen: true,
    };
    dispatch(addMarkerFeature(toMapFeature(formattedData), marker, CUSTOM_MARKER_PRIORITY));
    dispatch(openEditorAction(formattedData));
  });
}

export function turnCustomMarkerOff() {
  OskariMap.setCursorStyle('default');
  OskariMap.removeClicked(MAP_CLICKED_LISTENER_CUSTOM_MARKER);
}

export function showCustomMarkerInfoBox(disableMobileBreakpoint) {
  return item => {
    const removeAction = {
      name: i18next.t('button.remove'),
      action: {
        itemId: item.id,
        actionId: INFO_BOX_ACTION_ID_REMOVE_CUSTOM_MARKER,
      },
    };

    const editAction = {
      name: i18next.t('button.edit'),
      action: {
        itemId: item.id,
        actionId: INFO_BOX_ACTION_ID_EDIT_CUSTOM_MARKER,
      },
    };

    const actions =
      Routes.getRoute().indexOf(Routes.ROUTE_MAP_SITE_ID) > -1
        ? [
            {
              name: i18next.t('share.title'),
              action: {
                itemId: item.id,
                actionId: INFO_BOX_ACTION_ID_SHARE_CUSTOM_MARKER,
              },
            },
            editAction,
            removeAction,
          ]
        : [editAction, removeAction];

    OskariMap.infoBox(
      {
        id: `${item.id}_infobox`,
        title: item.title,
        desc: item.desc,
        coords: { n: item.lat, e: item.lon },
        actions,
      },
      disableMobileBreakpoint
    );
  };
}

const pattern = Routes.buildMapSiteRoute();

export const createShareUrl = (windowLocation, item, zoom, layers) =>
  pathsJoinPreserveTrailingSlash(
    [windowLocation.href.slice(0, windowLocation.href.indexOf(pattern) + pattern.length)],
    {
      lang: i18next.language,
      share: 'customMarker',
      n: item.lat,
      e: item.lon,
      title: item.title,
      desc: item.desc,
      zoom,
      layers: encodeJSON(layers),
    }
  );

export function updateItemThunk(item) {
  return (dispatch, getState) => {
    dispatch(closeEditorAction());
    dispatch(addMarkerFeature(toMapFeature(item), marker, CUSTOM_MARKER_PRIORITY));
    showCustomMarkerInfoBox(isDesktop(getState().layout.mode))(item);
  };
}

export function removeItemThunk(id, onlyCloseEditor = false) {
  return dispatch => {
    if (!onlyCloseEditor) {
      dispatch(removeFeatureThunk(id));
    }
    dispatch(closeEditorAction());
  };
}

export function removeCustomMarkerThunk(item) {
  return dispatch => {
    dispatch(removeFeatureThunk(item.id));
  };
}

export function shareCustomMarkerThunk(item) {
  return (dispatch, getState) => {
    OskariMap.getCurrentMapPosition(positionData => {
      const { zoom } = positionData;
      const state = getState();
      const selectableLayers = getSelectableLayers();
      const layers = state.mapLayers
        .filter(layer => selectableLayers.includes(layer.id) && layer.visible)
        .map(layer => ({ id: layer.id, opacity: layer.opacity }));
      const url = createShareUrl(window.location, item, zoom, layers);
      dispatch(shareOpenAction(url));
    });
  };
}

export function editCustomMarkerThunk(item) {
  return dispatch => {
    dispatch(openEditorAction(item));
  };
}

export function handleCustomMarkerInfoBoxActionsThunk(actionId, item) {
  return dispatch => {
    if (actionId === INFO_BOX_ACTION_ID_SHARE_CUSTOM_MARKER) {
      dispatch(shareCustomMarkerThunk(item));
    } else if (actionId === INFO_BOX_ACTION_ID_REMOVE_CUSTOM_MARKER) {
      dispatch(removeCustomMarkerThunk(item));
    } else if (actionId === INFO_BOX_ACTION_ID_EDIT_CUSTOM_MARKER) {
      dispatch(editCustomMarkerThunk(item));
    }
  };
}

export function showSharedCustomMarkerThunk(data) {
  return (dispatch, getState) => {
    const formattedData = formattedCustomMarkerData(data);
    dispatch(addMarkerFeature(toMapFeature(formattedData), marker, CUSTOM_MARKER_PRIORITY));
    // pois päältä kaikki karttatasot
    Object.values(OskariMap.getOskariLayers()).forEach(id => {
      const selectedLayer = formattedData.layers.find(layer => layer.id === id);
      if (selectedLayer) {
        dispatch(toggleMapLayerVisibilityThunk(selectedLayer.id, true));
        dispatch(toggleMapLayerOpacityThunk(selectedLayer.id, selectedLayer.opacity));
      } else {
        dispatch(toggleMapLayerVisibilityThunk(id, false));
      }
    });
    OskariMap.moveMap(formattedData.lon, formattedData.lat, formattedData.zoom);
    showCustomMarkerInfoBox(isDesktop(getState().layout.mode))(formattedData);
  };
}

export function processSharedCustomMarkerThunk(windowLocation) {
  return dispatch => {
    // TAKE PARAMS
    const n = getUrlParamAsNumber(windowLocation, 'n', null);
    const e = getUrlParamAsNumber(windowLocation, 'e', null);
    const title = getUrlParam(windowLocation, 'title', '');
    const desc = getUrlParam(windowLocation, 'desc', '');
    const zoom = getUrlParamAsNumber(windowLocation, 'zoom', 11);
    const layers = getEncodedUrlParamAsJSON(windowLocation, 'layers', SHARED_MARKER_DEFAULT_LAYERS);

    // finland boundaries
    const minN = getConfiguration('finland.boundingbox.n.min');
    const maxN = getConfiguration('finland.boundingbox.n.max');
    const minE = getConfiguration('finland.boundingbox.e.min');
    const maxE = getConfiguration('finland.boundingbox.e.max');

    // VALIDATE
    const valid =
      !!n &&
      !!e &&
      !Number.isNaN(n) &&
      !Number.isNaN(e) &&
      n >= minN &&
      n <= maxN &&
      e >= minE &&
      e <= maxE &&
      title.length <= CUSTOM_MARKER_TITLE_MAX_LENGTH &&
      desc.length <= CUSTOM_MARKER_SHOW_MAX_LENGTH &&
      !Number.isNaN(zoom) &&
      zoom >= OskariMap.ZOOM_MIN &&
      zoom <= OskariMap.ZOOM_MAX &&
      validateJSON(SHARED_MARKER_LAYERS_SCHEMA, layers);

    // DISPATCH
    if (valid) {
      dispatch(
        showSharedCustomMarkerThunk({
          lon: e,
          lat: n,
          title,
          desc,
          zoom,
          layers,
        })
      );
    } else {
      error(i18next.t('share.invalidUrl'), new Error('Invalid URL.'));
    }
  };
}

export function processSharedLinksThunk(windowLocation) {
  return dispatch => {
    const shareParam = getParamValue(windowLocation, 'share');

    switch (shareParam) {
      case 'customMarker':
        return dispatch(processSharedCustomMarkerThunk(windowLocation));
      default:
        return Promise.resolve();
    }
  };
}
