import { t } from 'i18next';

export const TRANSFER_TYPE = 'transferType';

export const VALID_TRANSFER_TYPES = [
  '01', // Kauppa
  '02', // Vaihto
  '03', // Lahja
  '15', // Jakosopimus
  '21', // Esisopimus
  '08', // Apportti
  '23', // Saantokirjan korjaus
  '20', // Luovutuksen purkaminen
];

export const TARGET_USAGE = 'targetUsage';
export const OTHER_USAGE = 'otherUsage';
export const OTHER_TARGET_USAGE = 'otherTargetUsage';
export const WHOLE_TARGET_PORTION_TYPE = 'wholeTarget';
export const NEW_PROPERTY_PORTION_TYPE = 'newProperty';
export const SHARED_AREA_PORTION_TYPE = 'sharedArea';
export const PORTION_PORTION_TYPE = 'portion';

export const DEED_OF_TRANSFER_ATTACHMENTS = 'deedOfTransferAttachments';
export const PURCHASE_RECEIPT_ATTACHMENTS = 'purchaseReceiptAttachments';
export const OTHER_ATTACHMENTS = 'otherAttachments';

export const TRANSFER_CONDITION_PURCHASE_PRICE = 'purchasePrice';
export const TRANSFER_CONDITION_OTHER_COUNTER_VALUE = 'otherCountervalue';
export const TRANSFER_CONDITION_MOVABLE_VALUE = 'movableValue';
export const NUMBER_OF_PAGES = '7';

export const CURRENCY_AND_AREA_INPUT_MAX_LENGTH = 15; // 12, added 3 for formatting
export const ADDRESS_FIELD_MAX_LENGTH = 50;
export const TEXT_FIELD_MAX_LENGTH = 150;

export const SQUARE_METRE = ' m²';
export const SQUARE_METRE_IN_BRACKETS = ' (m²)';
export const EURO_SIGN = ' €';
export const EURO_SIGN_IN_BRACKETS = ' (€)';

// lca types for legal confirmation application
export const PORTION_TYPES = {
  [WHOLE_TARGET_PORTION_TYPE]: {
    value: WHOLE_TARGET_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.wholeTarget',
    lca: 'KK',
  },
  [PORTION_PORTION_TYPE]: {
    value: PORTION_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.portion',
    lca: 'MO',
  },
  [NEW_PROPERTY_PORTION_TYPE]: {
    value: NEW_PROPERTY_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.newProperty',
    lca: 'MA',
  },
  [SHARED_AREA_PORTION_TYPE]: {
    value: SHARED_AREA_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.sharedArea',
    lca: 'MP',
  },
};

export const TRANSFER_CONDITIONS = () => [
  {
    value: TRANSFER_CONDITION_PURCHASE_PRICE,
    label: t('realEstate.realEstateTransferAnnouncement.step3.transferConditions.purchasePrice'),
    suffix: EURO_SIGN,
  },
  {
    value: TRANSFER_CONDITION_OTHER_COUNTER_VALUE,
    label: t('realEstate.realEstateTransferAnnouncement.step3.transferConditions.otherCountervalue'),
  },
  {
    value: TRANSFER_CONDITION_MOVABLE_VALUE,
    label: t('realEstate.realEstateTransferAnnouncement.step3.transferConditions.movableValue'),
    suffix: EURO_SIGN,
  },
];

export const TRANSFER_OTHER_CONDITIONS = () => [
  {
    value: 'conditionalPropertyRights',
    label: t(
      'realEstate.realEstateTransferAnnouncement.step3.transferConditions.otherConditions.conditionalPropertyRights'
    ),
  },
  {
    value: 'dissemblingCondition',
    label: t('realEstate.realEstateTransferAnnouncement.step3.transferConditions.otherConditions.dissemblingCondition'),
  },
  {
    value: 'sustainedPrivilege',
    label: t('realEstate.realEstateTransferAnnouncement.step3.transferConditions.otherConditions.sustainedPrivilege'),
  },
];

export const TRANSFER_TARGET_USAGES = () => [
  {
    value: 'residence',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.targetUsages.residence'),
  },
  {
    value: 'vacation',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.targetUsages.vacation'),
  },
  {
    value: 'other',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.targetUsages.other'),
  },
  {
    value: 'farming',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.targetUsages.farming'),
  },
  {
    value: 'raw',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.targetUsages.raw'),
  },
  {
    value: OTHER_USAGE,
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.targetUsages.otherUsage'),
  },
];

export const TRANSFER_PLAN_INFOS = () => [
  {
    value: 'station',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.planInfos.station'),
  },
  {
    value: 'coastalStation',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.planInfos.coastalStation'),
  },
  {
    value: 'general',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.planInfos.general'),
  },
  {
    value: 'noPlan',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.planInfos.noPlan'),
  },
];

export const TRANSFER_RELATIVE_TRANSFERENCE = () => ({
  value: 'transferRelativeTransference',
  label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.relativeTransference'),
});

export const TRANSFER_REAL_ESTATE_EXTRA_AREA = () => ({
  value: 'realEstateExtraArea',
  label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.realEstateExtraArea'),
});

export const TRANSFER_COASTAL_TARGET = () => ({
  value: 'coastalTarget',
  label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.coastalTarget'),
});

export const TRANSFER_BUILDINGS = () => [
  {
    value: 'residence',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.residence'),
  },
  {
    value: 'vacation',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.vacation'),
  },
  {
    value: 'economy',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.economy'),
  },
  {
    value: 'industry',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.industry'),
  },
  {
    value: 'other',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.other'),
  },
  {
    value: 'noBuildings',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.noBuildings'),
  },
  {
    value: 'worthless',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.worthless'),
  },
  {
    value: 'outsideTransfer',
    label: t('realEstate.realEstateTransferAnnouncement.step3.otherInformation.buildings.outsideTransfer'),
  },
];

export const LAND_AREAS = () => [
  {
    value: 'farmLand',
    label: t('realEstate.realEstateTransferAnnouncement.step3.areas.farmLand'),
    suffix: SQUARE_METRE,
  },
  {
    value: 'forestLand',
    label: t('realEstate.realEstateTransferAnnouncement.step3.areas.forestLand'),
    suffix: SQUARE_METRE,
  },
  {
    value: 'constructionLand',
    label: t('realEstate.realEstateTransferAnnouncement.step3.areas.constructionLand'),
    suffix: SQUARE_METRE,
  },
  {
    value: 'otherLand',
    label: t('realEstate.realEstateTransferAnnouncement.step3.areas.otherLand'),
    suffix: SQUARE_METRE,
  },
];

export const TOTAL_AREA_OF_LAND = () => ({
  value: 'totalAreaOfLand',
  label: t('realEstate.realEstateTransferAnnouncement.step3.areas.totalLandArea'),
  suffix: SQUARE_METRE,
});

export const WATER_AREA = () => ({
  value: 'waterArea',
  label: t('realEstate.realEstateTransferAnnouncement.step3.areas.waterArea'),
  suffix: SQUARE_METRE,
});

export const BUSINESS_NAME_MAX_CHAR = 50;
