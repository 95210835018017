import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import { RemoveButton } from '../Button';
import { TRANSITION_DURATION } from './UploaderConstants';

const UploadedFilesList = ({ uploadedFiles = [], onRemove, showType, isOrwa = false }) => {
  const { t } = useTranslation();
  return (
    <TransitionGroup component={null}>
      {uploadedFiles &&
        uploadedFiles.map((file, index) => (
          <CSSTransition
            key={`uploaded-file-${file.responseFilename}`}
            classNames="uploaded-list"
            timeout={TRANSITION_DURATION}
          >
            <div className="removable-data margin-b-1" data-testid="filelist">
              <div className="flex-column uploaded-list__wrapper">
                {showType && file.attachmentType && (
                  <span>
                    {t(
                      isOrwa ? `attachment.orwa.type.${file.attachmentType}` : `attachment.type.${file.attachmentType}`
                    )}
                  </span>
                )}
                <strong className="uploaded-list-file-name truncated-text-ellipsis">{file.filename}</strong>
              </div>
              <RemoveButton onClick={() => onRemove(index, file.filename)} text={t('button.remove')} />
            </div>
          </CSSTransition>
        ))}
    </TransitionGroup>
  );
};

UploadedFilesList.propTypes = {
  uploadedFiles: PropTypes.arrayOf(
    PropTypes.shape({ responseFilename: PropTypes.string.isRequired, filename: PropTypes.string.isRequired })
  ),
  onRemove: PropTypes.func.isRequired,
  showType: PropTypes.bool.isRequired,
  isOrwa: PropTypes.bool,
};

export default UploadedFilesList;
