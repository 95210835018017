import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { isFullOfNothing } from 'common/helpers/isEmpty';
import { isReceived, resolveFormPageCount } from '../FormHelpers';

const redirectFromReceivedPage = (page, state, navigate) => {
  if (isReceived(page)) {
    // Allow reload on "received" page if user is there legitimately
    if (state?.form?.isLegitSubmitPage) return false;
    navigate('../', { replace: true, state: { form: { isLegitSubmitPage: false } } });
  }
  return true;
};

const redirectToValidStep = (values, navigate) => (validator, i) => {
  if (!validator) return false;
  const errors = validator(values);
  if (isFullOfNothing(errors)) return false;
  navigate(`../${i + 1}`, { replace: true, state: { form: { isLegitSubmitPage: false } } });
  return true;
};

const RedirectToFirstValidStep = ({ page, pageComponents = [] }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { getState } = useForm();

  const navigateToFirstFailingStep = () => {
    // NOTE: navigate to first page every time user tries to directly navigate past submit
    const shouldValidateFurther = redirectFromReceivedPage(page, state, navigate);
    if (!shouldValidateFurther) return;

    // NOTE: navigate to the highest step possible where all previous steps are valid
    pageComponents
      .slice(0, (isReceived(page) ? resolveFormPageCount(pageComponents) : page) - 1)
      .map(element => element?.props?.validate)
      .some(redirectToValidStep(getState().values, navigate));
  };

  useEffect(() => {
    navigateToFirstFailingStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectToFirstValidStep;

RedirectToFirstValidStep.propTypes = {
  page: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageComponents: PropTypes.array,
};
