import { t } from 'i18next';
import * as OskariMap from 'oskari/OskariMap';
import { toggleLoadingAnimationThunk } from 'common/containers/OskariMap/OskariMapActions';
import { isEmpty } from 'lodash';
import {
  addDrawnFeature,
  addGeoJsonFeature,
  removeFeature,
  removeFeaturesByTypeAndLayerThunk,
  removeFeatureThunk,
} from '../../../geometries/geometriesActions';
import { FEATURE_TYPES } from '../../../constants/MapConstants';
import { fetchFileDownloadServiceProductParameters } from '../../../api';
import {
  ADD_SELECTED_MAP_SHEETS,
  REMOVE_SELECTED_MAP_SHEET,
  SET_ACTIVE_AREA_SELECTION,
  SET_FILE_PRODUCT_PARAMETERS,
  SET_FILE_PRODUCT_PARAMETERS_ERROR,
  SET_FILE_PRODUCT_PARAMETERS_LOADED,
  SET_MAP_SHEET_LAYER,
  SET_SELECTED_MAP_SHEETS,
  SET_ADMINISTRATIVE_AREA_YEARS,
  SET_SELECTED_PRODUCTION_AREA,
  SET_LASER5P_PRODUCTION_AREAS,
} from '../FileDownloadServiceActionTypes';
import {
  BBOX_FEATURE_ID,
  bboxForFinland,
  LASER5P_MAP_SHEET_AVAILABILITY_OPTIONS,
  LASER_SCANNING_DATA_5P,
  LATURI_MAP_LAYERS,
  AREA_SELECTION_TYPES,
} from '../FileDownloadServiceConstants';
import { toggleMapLayerVisibilityThunk } from '../../MapLayerSelector/MapLayerSelectorActions';
import { createYearOfPhotographyInfoBox } from '../YearOfPhotographyInfoBox';
import {
  fetchLaser5pMapSheetInfo,
  fetchLaser5pProductionAreas,
  fetchYearOfPhotography,
} from '../FileDownloadServiceApi';
import { formatProductionAreaName, getMapLayerId, multiPolygonToMultiLineString } from '../FileDownloadServiceHelpers';
import { createLaser5pInfoBox } from '../Laser5pInfoBox';
import {
  LAYER_LASER5P_MAP_SHEET_AVAILABILITY,
  LAYER_LASER5P_PRODUCTION_AREAS,
  LAYER_MAP_SELECTION,
} from '../../../../oskari/layers/VectorLayerIds';

const SHEET_SELECT_EVENT_HANDLER_NAME = 'MapSheetSelect';
const SHEET_INFO_SELECT_EVENT_HANDLER_NAME = 'MapSheetInfoSelect';
const PRODUCTION_AREA_SELECT_HANDLER_NAME = 'ProductionAreaSelect';

export const DOWNLOAD_AREA_SELECTION_ID = 'fileDownloadServiceAreaSelection';

export const stopAreaSelectionThunk = () => {
  OskariMap.stopAreaSelection();
};

export const resetAreaSelectionThunk = () => dispatch => {
  dispatch(removeFeature(DOWNLOAD_AREA_SELECTION_ID));
  OskariMap.stopAreaSelection(true);
};

export const clearAreaSelectionThunk = () => dispatch => {
  dispatch(removeFeature(DOWNLOAD_AREA_SELECTION_ID));
  OskariMap.clearAreaSelection();
};

export const setActiveAreaSelectionType = tool => ({ type: SET_ACTIVE_AREA_SELECTION, tool });

/**
 * @param {Shape} selectionType
 * @param {number|null} maxArea
 */
export const startAreaSelectionThunk = (selectionType, maxArea) => dispatch => {
  const handleDrawFinished = event => {
    if (event.isFinished) {
      if (event.geojson.features.length > 0) {
        dispatch(addDrawnFeature(DOWNLOAD_AREA_SELECTION_ID, FEATURE_TYPES.AREA_SELECTION, event.geojson));
        dispatch({ type: SET_ACTIVE_AREA_SELECTION, tool: '' });
      }
    }
  };
  OskariMap.startAreaSelection(handleDrawFinished, { selectionType, maxArea });
};

export const fetchProductPropertiesThunk = () => dispatch => {
  fetchFileDownloadServiceProductParameters()
    .then(parameters => dispatch({ type: SET_FILE_PRODUCT_PARAMETERS, parameters }))
    .catch(() => dispatch({ type: SET_FILE_PRODUCT_PARAMETERS_ERROR, isError: true }))
    .finally(() => dispatch({ type: SET_FILE_PRODUCT_PARAMETERS_LOADED, isLoaded: true }));
};

export const drawBboxOfFinlandThunk = () => dispatch => {
  dispatch(addGeoJsonFeature(BBOX_FEATURE_ID, FEATURE_TYPES.BBOX, bboxForFinland, { layer: LAYER_MAP_SELECTION }));
};

export const removeBboxOfFinlandThunk = () => dispatch => {
  dispatch(removeFeatureThunk(BBOX_FEATURE_ID));
};

const SELECTED_SHEET_STYLE = {
  fill: {
    color: 'rgba(100, 100, 100, 0.5)',
  },
};

/**
 * @param {Array.<String>} mapSheets
 */
const addMapSheets = mapSheets => ({
  type: ADD_SELECTED_MAP_SHEETS,
  mapSheets,
});

const removeSelectedMapSheet = mapSheet => ({
  type: REMOVE_SELECTED_MAP_SHEET,
  mapSheet,
});

const setSelectedMapSheets = mapSheets => ({
  type: SET_SELECTED_MAP_SHEETS,
  mapSheets,
});

export const startSheetSelectionThunk = () => (dispatch, getState) => {
  const mapSheetLayers = LATURI_MAP_LAYERS.map(({ id }) => getMapLayerId(id));
  const handleMapSheetSelect = event => {
    if (event.operation !== 'click') return;
    const { selectedMapSheets } = getState().fileDownloadService;
    const features = event.features[0];
    const selectedSheet = features?.geojson?.features[0];

    if (features.layerId === LAYER_MAP_SELECTION || selectedMapSheets.includes(selectedSheet?.id)) {
      const mapSheetNumber = selectedSheet.properties.mapSheetNumber ?? selectedSheet.properties.karttalehtitunnus;
      OskariMap.removeFeaturesByProperty('mapSheetNumber', mapSheetNumber, LAYER_MAP_SELECTION);
      OskariMap.removeFeaturesByProperty('karttalehtitunnus', mapSheetNumber, LAYER_MAP_SELECTION);
      dispatch(removeSelectedMapSheet(selectedSheet));
    }

    if (mapSheetLayers.includes(features.layerId) && !selectedMapSheets.includes(features?.geojson?.features[0]?.id)) {
      OskariMap.drawGeoJson(LAYER_MAP_SELECTION, features.geojson, { featureStyle: SELECTED_SHEET_STYLE });
      dispatch(addMapSheets([selectedSheet]));
    }
  };

  OskariMap.removeFeatureListener(SHEET_SELECT_EVENT_HANDLER_NAME);
  OskariMap.addFeatureListener(SHEET_SELECT_EVENT_HANDLER_NAME, handleMapSheetSelect);
};

export const drawSelectedMapSheetsThunk = areaSelectionType => async (_, getState) => {
  const { selectedMapSheets } = getState().fileDownloadService;
  if (!isEmpty(selectedMapSheets) && areaSelectionType === AREA_SELECTION_TYPES.SELECT_SHEETS) {
    setTimeout(
      () =>
        OskariMap.drawGeoJson(
          LAYER_MAP_SELECTION,
          {
            type: 'FeatureCollection',
            features: selectedMapSheets,
          },
          { featureStyle: SELECTED_SHEET_STYLE }
        ),
      2500
    );
  }
};

export const startSheetSelectionByRectangleThunk = () => (dispatch, getState) => {
  const areaSelectionOptions = {
    selectionType: 'Box',
    validateSelfIntersection: false,
    showMeasureOnMap: false,
  };

  const handleDrawFinished = async event => {
    if (event.isFinished && event.geojson.features.length > 0) {
      const feature = event.geojson.features[0];

      // If user draws an invalid feature (e.g. double clicks tha map), remove it and restart selection tool.
      if (feature.properties.valid === false || !feature.properties.area) {
        OskariMap.stopAreaSelection(true);
        dispatch(startSheetSelectionByRectangleThunk());
        return;
      }

      const layerId = getState().fileDownloadService.mapSheetLayer;
      const featuresByLayer = await OskariMap.getVectorFeatures(feature, layerId);
      const { features } = featuresByLayer[layerId];

      OskariMap.drawGeoJson(
        LAYER_MAP_SELECTION,
        { type: 'FeatureCollection', features },
        { featureStyle: SELECTED_SHEET_STYLE }
      );

      const { selectedMapSheets } = getState().fileDownloadService;
      const newSheets = features.filter(
        newSheet => selectedMapSheets.findIndex(oldSheet => oldSheet.id === newSheet.id) === -1
      );

      dispatch(addMapSheets(newSheets));

      // Clear drawn area but keep the drawing tool active.
      // It's not possible to clear drawn area without turning drawing off.
      OskariMap.stopAreaSelection(true);
      OskariMap.startAreaSelection(handleDrawFinished, areaSelectionOptions);
    }
  };

  OskariMap.startAreaSelection(handleDrawFinished, areaSelectionOptions);
};

export const stopSheetSelectionByRectangleThunk = () => () => {
  OskariMap.stopAreaSelection(true);
};

export const stopSheetSelectionThunk = () => () => {
  OskariMap.removeFeatureListener(SHEET_SELECT_EVENT_HANDLER_NAME);
};

export const clearMapSheetSelectionThunk = () => dispatch => {
  dispatch(setSelectedMapSheets([]));
  OskariMap.clearLayer(LAYER_MAP_SELECTION);
};

export const setMapSheetLayerVisibilityThunk = (id, visibility) => dispatch => {
  if (visibility === true && id) dispatch({ type: SET_MAP_SHEET_LAYER, id });
  dispatch(toggleMapLayerVisibilityThunk(id, visibility));
};

export const clearMapLayersThunk = layerToKeep => dispatch => {
  LATURI_MAP_LAYERS.map(({ id }) => getMapLayerId(id))
    .filter(id => id !== layerToKeep)
    .forEach(id => dispatch(setMapSheetLayerVisibilityThunk(id, false)));
};

export const setAdministrativeAreaYears = years => ({
  type: SET_ADMINISTRATIVE_AREA_YEARS,
  years,
});

export const stopMapSheetInfoTool = () => {
  OskariMap.removeFeatureListener(SHEET_INFO_SELECT_EVENT_HANDLER_NAME);
  OskariMap.removeFeatureListener(`${SHEET_INFO_SELECT_EVENT_HANDLER_NAME}Location`);
};

export const startMapSheetInfoToolAction = productId => dispatch => {
  const mapSheetLayers = LATURI_MAP_LAYERS.map(({ id }) => getMapLayerId(id));

  stopMapSheetInfoTool();

  OskariMap.addClickFeatureWithCoordinatesListener(SHEET_INFO_SELECT_EVENT_HANDLER_NAME, (event, coords) => {
    if (event.operation !== 'click') return;

    const feature = event.features.filter(({ layerId }) => layerId !== LAYER_MAP_SELECTION)[0];
    if (!feature || !mapSheetLayers.includes(feature.layerId)) return;
    if (productId === LASER_SCANNING_DATA_5P) {
      dispatch(toggleLoadingAnimationThunk(true));
      const { karttalehtitunnus } = feature.geojson.features[0].properties;
      fetchLaser5pMapSheetInfo(karttalehtitunnus)
        .then(({ features }) => {
          return fetchLaser5pProductionAreas().then(productionAreas => {
            const newestScan = features.reduce((a, b) =>
              a.properties.keilausPvm.localeCompare(b.properties.keilausPvm) > 0 ? a : b
            );

            const productionArea = productionAreas.features?.filter(
              area => area.properties.tuotantoalueId === newestScan.properties.tuotantoalueId
            );

            return { ...newestScan.properties, tuotantoalue: formatProductionAreaName(productionArea[0]) };
          });
        })
        .then(createLaser5pInfoBox)
        .then(content =>
          OskariMap.infoBox(
            {
              id: 'mapSheetInfoBox',
              desc: content,
              coords,
              title: `${t('fileDownloadService.mapSheetInfo.mapSheetNumber')} ${karttalehtitunnus}`,
              hideCoordinates: true,
            },
            true
          )
        )
        .catch(console.error)
        .finally(() => dispatch(toggleLoadingAnimationThunk(false)));
    } else {
      dispatch(toggleLoadingAnimationThunk(true));
      const { mapSheetNumber } = feature.geojson.features[0].properties;
      fetchYearOfPhotography(mapSheetNumber)
        .then(({ features }) => features.map(({ properties }) => properties.yearOfPhotography))
        .then(createYearOfPhotographyInfoBox)
        .then(content =>
          OskariMap.infoBox(
            {
              id: 'mapSheetInfoBox',
              desc: content,
              coords,
              title: `${t('fileDownloadService.mapSheetInfo.mapSheetNumber')} ${mapSheetNumber}`,
              hideCoordinates: true,
            },
            true
          )
        )
        .catch(console.error)
        .finally(() => dispatch(toggleLoadingAnimationThunk(false)));
    }
  });
};

export const addMapSheetAreas = (areas, id) => dispatch => {
  dispatch(addGeoJsonFeature(id, FEATURE_TYPES.MAP_SHEET_AREAS, areas));
};

export const removeMapSheetAreas = id => dispatch => {
  dispatch(removeFeaturesByTypeAndLayerThunk(FEATURE_TYPES.MAP_SHEET_AREAS, id));
};

/**
 * @param {Array.<String>} productionArea
 */

const setProductionArea = productionArea => ({
  type: SET_SELECTED_PRODUCTION_AREA,
  productionArea,
});

export const addProductionAreas = productionAreas => ({
  type: SET_LASER5P_PRODUCTION_AREAS,
  productionAreas,
});

export const changeSelectedProductionAreaThunk = productionArea => dispatch => {
  const productionAreaId = productionArea[0]?.properties.tuotantoalueId;
  const features = { type: 'FeatureCollection', features: productionArea };
  OskariMap.clearLayer(LAYER_MAP_SELECTION);
  OskariMap.drawGeoJson(LAYER_MAP_SELECTION, features, { featureStyle: SELECTED_SHEET_STYLE });
  dispatch(setProductionArea(productionAreaId));
};

export const startProductionAreaSelectionThunk = areaSelectionType => (dispatch, getState) => {
  const handleProductionAreaSelect = event => {
    if (event.operation !== 'click') return;
    const { selectedProductionArea } = getState().fileDownloadService;
    const features = event.features[0];

    if (
      selectedProductionArea !== features?.geojson?.features[0]?.properties?.tuotantoalueId &&
      areaSelectionType !== AREA_SELECTION_TYPES.SELECT_SHEETS
    ) {
      const productionAreaId = features.geojson.features[0].properties.tuotantoalueId;
      OskariMap.clearLayer(LAYER_MAP_SELECTION);
      OskariMap.drawGeoJson(LAYER_MAP_SELECTION, features.geojson, { featureStyle: SELECTED_SHEET_STYLE });
      dispatch(setProductionArea(productionAreaId));
    }
  };

  OskariMap.removeFeatureListener(PRODUCTION_AREA_SELECT_HANDLER_NAME);
  OskariMap.addFeatureListener(PRODUCTION_AREA_SELECT_HANDLER_NAME, handleProductionAreaSelect);
};

export const stopProductionAreaSelectionThunk = () => () => {
  OskariMap.removeFeatureListener(PRODUCTION_AREA_SELECT_HANDLER_NAME);
};

export const hideProductionAreasThunk = () => dispatch => {
  dispatch(setProductionArea(''));
  dispatch(toggleMapLayerVisibilityThunk(LAYER_LASER5P_PRODUCTION_AREAS, false));
};

export const clearProductionAreaSelectionThunk = () => dispatch => {
  dispatch(setProductionArea(''));
  OskariMap.clearLayer(LAYER_MAP_SELECTION);
};

export const setMapSheetAvailabilityThunk = (laser5pProductionAreas, outlinesOnly = false) => async () => {
  const productionAreas = outlinesOnly
    ? {
        type: 'FeatureCollection',
        features: laser5pProductionAreas?.features?.map(f => multiPolygonToMultiLineString(f?.geometry)),
      }
    : laser5pProductionAreas;
  OskariMap.clearLayer(LAYER_LASER5P_MAP_SHEET_AVAILABILITY);
  OskariMap.drawGeoJson(LAYER_LASER5P_MAP_SHEET_AVAILABILITY, productionAreas, LASER5P_MAP_SHEET_AVAILABILITY_OPTIONS);
};

export const clearMapSheetAvailabilityThunk = () => () => {
  OskariMap.clearLayer(LAYER_LASER5P_MAP_SHEET_AVAILABILITY);
};
