import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import usePageTitle from 'common/hooks/usePageTitle';
import Footer from 'mapSite/containers/Footer/MapSiteFooter';
import * as Routes from 'common/constants/Routes';
import { LoaderSpinner } from 'common/components';
import BusinessPanelComponent from 'common/containers/BusinessPanel/BusinessPanelComponent';

const ShoppingCart = lazy(() => import('common/containers/ShoppingCart/ShoppingCartComponent/ShoppingCartComponent'));

function ShoppingCartPage() {
  usePageTitle({
    title: 'shoppingCart.title',
    template: 'app.documentTitle.template',
  });
  const { t } = useTranslation();
  const isOpen = useSelector(state => state.businessPanel.isOpen);
  const layoutMode = useSelector(state => state.layout.mode);

  return (
    <BusinessPanelComponent viewSize="full" layoutMode={layoutMode} isOpen={isOpen}>
      <Suspense fallback={<LoaderSpinner size="s" className="full-panel-loader margin-t-2" />}>
        <ShoppingCart
          buyProductsText={t('shoppingCart.noItems.mapStoreLink')}
          buyProductsLink={Routes.getRoute(Routes.MAP_STORE_INDEX)}
        >
          <Footer />
        </ShoppingCart>
      </Suspense>
    </BusinessPanelComponent>
  );
}

export default ShoppingCartPage;
