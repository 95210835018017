import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import { activitiesNeedsToBeLoaded } from 'realEstateSite/containers/Realty/RealEstate/Activity/ActivityComponent/activityLogSlice';
import { geometryTypeCleared } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import { isMobile } from 'common/constants/Layout';
import { buildMapSiteRoute, getRoute } from 'common/constants/Routes';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import scrollTop from 'common/helpers/Scroller/Scroller';
import { Glyphicon } from 'common/components/Icon';
import { Block } from 'common/components/Grid';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import { SELECTED } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import {
  buildServicesSiteRoute,
  SERVICES_REAL_ESTATE_LIST,
  SERVICES_FETCH_REAL_ESTATES,
  LICENCED_DATA_INDEX,
  SERVICES_REAL_ESTATE_LIST_MOBILE,
} from 'realEstateSite/constants/Routes';
import { LICENCED_DATA, LIST_OWN_REAL_ESTATES, NLS_USER } from 'realEstateSite/containers/Authorization/privileges';

export const removeTrailingSlash = str => (str.endsWith('/') ? str.slice(0, -1) : str);

export const homeRouteRE = onMobile => {
  if (onMobile) return buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST_MOBILE);
  if (isAuthorized([LIST_OWN_REAL_ESTATES])) {
    return buildServicesSiteRoute(SERVICES_REAL_ESTATE_LIST);
  }
  if (isAuthorized([LICENCED_DATA]) && !isAuthorized([NLS_USER])) {
    return buildServicesSiteRoute(LICENCED_DATA_INDEX);
  }
  return buildServicesSiteRoute(SERVICES_FETCH_REAL_ESTATES);
};

export const getHomeUrl = onMobile => {
  const rootUrl = getRoute();
  return rootUrl === buildServicesSiteRoute() ? homeRouteRE(onMobile) : rootUrl;
};

// Trailing slash has to be removed so isActive matches exactly
export const showHome = ({ matchPath, pathname, onMobile }) =>
  !matchPath(pathname, removeTrailingSlash(homeRouteRE(onMobile))) &&
  !matchPath(pathname, removeTrailingSlash(buildMapSiteRoute()));

const UserMenuHomeButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onMobile = useSelector(state => isMobile(state.layout.mode));
  const homeUrl = getHomeUrl(onMobile);

  const clearGeometries = useCallback(() => {
    dispatch(geometryTypeCleared(SELECTED));
  }, [dispatch]);

  const setLoadActivities = useCallback(() => {
    dispatch(activitiesNeedsToBeLoaded());
  }, [dispatch]);

  const onKeyDown = event => {
    if (event.key === 'Enter') {
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <Link
      to={homeUrl}
      onKeyDown={onKeyDown}
      onClick={() => {
        setLoadActivities();
        clearGeometries();
        setTimeout(scrollTop, 0);
      }}
      className={cls('button', 'button--flexible', 'user-menu__item-block')}
    >
      <Block className="item-block__content">
        <Glyphicon glyph="home" />
        <span className={cls('indent-block-1', { 'visually-hidden': onMobile })}>{t('usermenu.frontpageButton')}</span>
      </Block>
    </Link>
  );
};

export default UserMenuHomeButton;
