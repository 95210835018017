import React from 'react';
import { useTranslation } from 'react-i18next';

import { Glyphicon } from 'common/components/Icon';
import { ScrollerPanelPage, ExternalLink, FocusableHeading, Heading, Paragraph } from 'common/components';
import { REAL_PROPERTY_TITLE } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import {
  trackIndividualCustomerLogin,
  trackOrganisationalCustomerLogin,
} from 'common/helpers/Analytics/Analytics_eventmap';

const ToRealEstateSiteComponent = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <ScrollerPanelPage>
      <FocusableHeading level="h1" className={REAL_PROPERTY_TITLE}>
        {t('toRealEstateSiteSection.title')}
      </FocusableHeading>
      <Heading level="h2">{t('toRealEstateSiteSection.naturalPerson.title')}</Heading>
      <Paragraph>
        {t('toRealEstateSiteSection.naturalPerson.desc1')}{' '}
        <ExternalLink href={t('toRealEstateSiteSection.readMore.url')}>
          {t('toRealEstateSiteSection.readMore.text')}
        </ExternalLink>
      </Paragraph>
      <Paragraph>{t('toRealEstateSiteSection.naturalPerson.desc2')}</Paragraph>
      {language === 'en' && <Paragraph>{t('toRealEstateSiteSection.naturalPerson.desc3')}</Paragraph>}

      <a
        target="_blank"
        href={t('toRealEstateSiteSection.naturalPerson.link.url')}
        rel="noopener noreferrer"
        className="button button--blue button--narrow margin-t-2 authentication-link"
        onClick={trackIndividualCustomerLogin}
      >
        <Glyphicon className="glyphicon-lock" glyph="lukko-auki" /> &nbsp;
        {t('toRealEstateSiteSection.naturalPerson.link.text')}
        <Glyphicon glyph="avaa-uuteen-ikkunaan" />
      </a>
      <a
        target="_blank"
        href={t('toRealEstateSiteSection.organization.link.url')}
        rel="noopener noreferrer"
        className="button button--blue button--narrow margin-t-2 authentication-link"
        onClick={trackOrganisationalCustomerLogin}
      >
        <Glyphicon className="glyphicon-lock" glyph="lukko-auki" /> &nbsp;
        {t('toRealEstateSiteSection.organization.link.text')}
        <Glyphicon glyph="avaa-uuteen-ikkunaan" />
      </a>
    </ScrollerPanelPage>
  );
};

export default ToRealEstateSiteComponent;
