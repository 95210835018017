import i18next, { t } from 'i18next';
import firstBy from 'thenby';
import { uniqBy, isEmpty, head } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

import { formatDateDMYYYY } from 'utils/datetime-utils';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { GET_APARTMENT_UNREGISTERED_OWNERSHIP } from 'realEstateSite/containers/Authorization/privileges';
import {
  APARTMENT_TYPE_CODE_PARKING_SPACE,
  APARTMENT_TYPE_ORDER,
  PENDING_STATUS,
  PROVISIONAL_STATUS,
  RESTING_STATUS,
  REJECTED_NOT_BINDING_STATUS,
  APPROVED_NOT_BINDING_STATUS,
} from './ApartmentConstants';
import shareGroupNameComparison from './shareGroupNameComparison';
import { formatDobString, formatDodString } from '../RealEstate/RealEstateHelpers';

const formatAddress = address =>
  address.foreignAddress
    ? address.foreignAddress
    : `${address.streetName[i18next.language] || ''} ${address.streetNumber}`;

export const formattedAddress = (address, shareGroupName) =>
  shareGroupName ? `${formatAddress(address)} ${shareGroupName}` : formatAddress(address);

export const sortByAddress = address =>
  address.streetName ? address.streetName[i18next.language] : address.foreignAddress;

const apartmentAddress = apartment => {
  const address = apartment.shareGroupAddress || apartment.address;
  return address
    ? (address.foreignAddress || !isEmpty(address.streetName)) && formattedAddress(address, apartment.shareGroupName)
    : '';
};

const apartmentMunicipality = apartment => {
  const address = apartment.shareGroupAddress || apartment.address;
  return address && !address.foreignAddress && address.municipality ? address.municipality[i18next.language] : '';
};

export const apartmentTitle = apartment =>
  apartment.shareGroupName
    ? `${apartment.possessionTargetType?.[i18next.language] || ''} ${apartment.shareGroupName}`
    : apartment.possessionTargetType?.[i18next.language] || '';

const apartmentShareType = shareTypeElectronic =>
  shareTypeElectronic ? i18next.t('apartment.shareTypeElectronic') : i18next.t('apartment.shareTypeCertificate');

export const formattedApartment = apartment =>
  apartment && {
    id: apartment.shareGroupId,
    title: apartmentTitle(apartment),
    companyId: apartment.companyId,
    companyName: apartment.companyName,
    address: apartmentAddress(apartment),
    mainUsage:
      apartment.possessionTargetType?.code !== APARTMENT_TYPE_CODE_PARKING_SPACE &&
      apartment.mainUsage &&
      apartment.mainUsage[i18next.language],
    municipality: apartmentMunicipality(apartment),
    partTimePosession: apartment.partTimePosession,
    shares: apartment.shares,
    shareCount: apartment.shareCount,
    shareType: apartmentShareType(apartment.shareTypeElectronic),
    isParkingSpace: apartment.possessionTargetType?.code === APARTMENT_TYPE_CODE_PARKING_SPACE,
    code: apartment.possessionTargetType?.code,
    electronicPossessionMark: apartment.electronicPossessionMark,
    apartmentLocations: apartment.apartmentLocations,
    shareGroupId: apartment.shareGroupId,
    shareGroupAddress: apartment.shareGroupAddress,
    shareGroupName: apartment.shareGroupName,
    keywordsFi: apartment.keywordsFi,
    keywordsSv: apartment.keywordsSv,
    possessionTargetType: apartment.possessionTargetType,
  };

export const formattedApartments = apartments =>
  apartments && apartments.map(apartment => formattedApartment(apartment));

// this can be removed when TIPAS-18 Epic is done
export const filterUnregisteredOwnership = apartments => {
  if (isAuthorized([GET_APARTMENT_UNREGISTERED_OWNERSHIP])) {
    return apartments.map(apartment => formattedApartment(apartment));
  }
  return apartments.filter(a => a.electronicPossessionMark).map(apartment => formattedApartment(apartment));
};

const LAST_INDEX = 1000;
export const getIndex = (order, value) => (order.indexOf(value) < 0 ? LAST_INDEX : order.indexOf(value));

export const apartmentType = (apartment1, apartment2) =>
  getIndex(APARTMENT_TYPE_ORDER, apartment1.possessionTargetType.code) -
  getIndex(APARTMENT_TYPE_ORDER, apartment2.possessionTargetType.code);

export const sortedApartments = apartments => {
  return (
    apartments &&
    uniqBy(
      apartments.sort(
        firstBy(a => (a.companyMunicipality?.municipality ? a.companyMunicipality.municipality[i18next.language] : ''))
          .thenBy('companyName')
          .thenBy(apartmentType)
          .thenBy(a => (a.shareGroupAddress ? sortByAddress(a.shareGroupAddress) : ''))
          .thenBy(a => (a.shareGroupAddress?.streetNumber ? Number(a.shareGroupAddress.streetNumber) : null))
          .thenBy(shareGroupNameComparison)
      ),
      'shareGroupId'
    )
  );
};

export const isOwnApartment = (apartments, realtyIdentifier) =>
  !!apartments.find(r => r.shareGroupId === realtyIdentifier);

export const hasDeathEstateInOldFormat = person => person.deathestate && isEmpty(person.deathEstateShareholders);

export const formatActivityDescription = (activity, isListView = false) => {
  if (activity?.status.code === PENDING_STATUS) {
    return {
      fi: `${t('ownership.description.pending', { lng: 'fi' })} ${formatDateDMYYYY(
        new Date(activity.activityStartDate)
      )}`,
      sv: `${t('ownership.description.pending', { lng: 'sv' })} ${formatDateDMYYYY(
        new Date(activity.activityStartDate)
      )}`,
    };
  }
  if ([PROVISIONAL_STATUS, RESTING_STATUS].includes(activity?.status.code)) {
    return {
      fi: `${activity.status.values.fi}`,
      sv: `${activity.status.values.sv}`,
    };
  }
  if (activity?.status.code === REJECTED_NOT_BINDING_STATUS) {
    return {
      fi: `${t('ownership.description.rejected_not_binding', { lng: 'fi' })}`,
      sv: `${t('ownership.description.rejected_not_binding', { lng: 'sv' })}`,
    };
  }
  if (activity?.status.code === APPROVED_NOT_BINDING_STATUS && !isListView) {
    return {
      fi: `${t('ownership.description.approved_not_binding', { lng: 'fi' })}`,
      sv: `${t('ownership.description.approved_not_binding', { lng: 'sv' })}`,
    };
  }
  return null;
};

const createCommaSeparatedString = (...args) => args.filter(arg => arg != null).join(', ');

const prefix = (
  { firstname, lastname, companyId, companyName, companyHomeNation, companyHomeLocation, registerNumber, register },
  detailedView
) => {
  const homeNation = companyHomeNation != null ? companyHomeNation.values[i18next.language] : null;
  const finnishCompanyName = detailedView ? createCommaSeparatedString(companyName, companyId) : companyName;
  const foreignCompanyName = detailedView
    ? createCommaSeparatedString(companyName, registerNumber, register, companyHomeLocation, homeNation)
    : createCommaSeparatedString(companyName, companyHomeLocation, homeNation);

  // Finnish company
  if (companyId) return finnishCompanyName;
  // foreign company
  if (!companyId && companyName) return foreignCompanyName;
  // natural person
  return createCommaSeparatedString(lastname, firstname);
};

const infix = ({ dateOfBirth, dateOfDeath }, detailedView) => {
  if (!detailedView) return null;
  if (dateOfBirth && dateOfDeath)
    return createCommaSeparatedString(formatDobString(dateOfBirth), formatDodString(dateOfDeath));
  if (dateOfBirth) return formatDobString(dateOfBirth);
  return null;
};

const postfix = ({ deathestate, dateOfDeath }, detailedView, pledgeListItemView) => {
  if (detailedView) return null;
  if (deathestate && !pledgeListItemView) return t('ownership.ownerPeople.deathEstateShareholders');
  if (!deathestate && dateOfDeath) return t('realEstate.legalConfirmation.estateOfDeceasedPerson');
  return null;
};

export const formatActivityPersonName = (activityPerson, detailedView = false, pledgeListItemView = false) => {
  // Estate in old format:
  if (hasDeathEstateInOldFormat(activityPerson)) {
    return createCommaSeparatedString(activityPerson.lastname, formatDobString(activityPerson.dateOfBirth));
  }

  const mainString = createCommaSeparatedString(
    prefix(activityPerson, detailedView),
    infix(activityPerson, detailedView)
  );
  const postfixString = postfix(activityPerson, detailedView, pledgeListItemView);
  return postfixString != null ? `${mainString} ${postfixString}` : mainString;
};

export const selectApartment = createSelector(
  state => state.apartmentList.apartments,
  (state, selected) => selected,
  (apartments, selected) => apartments.find(apartment => apartment.shareGroupId === selected)
);

export const withPendingOwnerships = createSelector(
  state => state.apartmentList.apartmentsWithPendingOwnerships,
  (_, selected) => selected,
  (apartmentsWithPendingOwnerships, selected) => apartmentsWithPendingOwnerships.includes(selected)
);

export const withBlockingRestrictions = createSelector(
  state => state.apartmentList.apartmentsWithBlockingRestrictions,
  (_, selected) => selected,
  (apartmentsWithBlockingRestrictions, selected) => apartmentsWithBlockingRestrictions.includes(selected)
);

export const isSomeShareGroupAlreadyRegistered = createSelector(
  state => state.apartmentList.apartmentShareGroups,
  apartmentShareGroups => apartmentShareGroups.some(group => group.electronicPossessionMark === true)
);

export const getApartmentIdentifier = (apartment, isShareList = false) => {
  return isShareList ? apartment.companyName : `${apartmentTitle(apartment)}, ${apartment.companyName}`;
};

export const sortOldestActivity = activities => {
  if (isEmpty(activities)) return undefined;
  return head(activities.sort(firstBy('activityStartDate').thenBy('activityIdentifier')));
};

export const hasNonDisclosureForSafetyReasons = (
  isOrganizationRepresentative,
  isSupportUser,
  nonDisclosureForSafetyReasons,
  client
) => {
  if (isOrganizationRepresentative) return false;
  if (isSupportUser) {
    return client.organizationRepresentative ? false : client.nonDisclosureForSafetyReasons;
  }
  return nonDisclosureForSafetyReasons;
};

export const userHasAddressInHTJ = contactInfo => {
  const address = { ...contactInfo, email: null };
  return !Object.values(address).every(x => x === null || x === '');
};

export const getHousingCompanyContactInfoName = (
  isOrganizationRepresentative,
  isSupportUser,
  authentication,
  client
) => {
  if (isSupportUser) {
    return client.organizationRepresentative ? client.organizationName : `${client.firstName} ${client.lastName}`;
  }
  return isOrganizationRepresentative
    ? authentication.organizationName
    : `${authentication.firstName} ${authentication.lastName}`;
};

export const formatStreetAddress = address => {
  if (!address) return '';

  const [streetPart, ...postalParts] = address.split(',');
  const postalInfo = postalParts.join(',').trim();
  const streetWords = streetPart.trim().split(' ');

  // Find all numbers in the address
  const numberIndices = streetWords
    .map((word, index) => (!Number.isNaN(Number(word)) ? index : -1))
    .filter(index => index !== -1);

  // Check if we have two consecutive numbers (only words between them are spaces)
  if (numberIndices.length >= 2) {
    const firstNumberIndex = numberIndices[0];
    const secondNumberIndex = numberIndices[1];

    // Check if there are any non-space words between the numbers
    const hasWordsInBetween = streetWords
      .slice(firstNumberIndex + 1, secondNumberIndex)
      .some(word => word.trim().length > 0);

    if (!hasWordsInBetween) {
      const beforeSecondNumber = streetWords.slice(0, secondNumberIndex).join(' ');
      const afterSecondNumber = streetWords.slice(secondNumberIndex).join(' ');

      return postalInfo
        ? `${beforeSecondNumber} ${i18next.t('apartment.abbreviation')} ${afterSecondNumber}, ${postalInfo}`
        : `${beforeSecondNumber} ${i18next.t('apartment.abbreviation')} ${afterSecondNumber}`;
    }
  }

  return address;
};

export const getHousingCompanyContactInfoAddress = (
  isOrganizationRepresentative,
  personAddress,
  client,
  organizationAddress
) => {
  const foreignAddressParts = [
    organizationAddress?.addressPart1,
    organizationAddress?.addressPart2,
    organizationAddress?.addressPart3,
    organizationAddress?.addressPart4,
  ];

  if (isOrganizationRepresentative || client?.organizationId)
    return {
      careOf: organizationAddress.careOf,
      streetAddress: formatStreetAddress(organizationAddress.streetAddress),
      foreignStreetAddress: foreignAddressParts,
      postOfficeBox: organizationAddress.postOfficeBox,
      postalCode: organizationAddress.postalCode,
      city: organizationAddress.city,
      country: organizationAddress.country,
    };

  return {
    streetAddress: formatStreetAddress(personAddress.streetAddress),
    postalCode: personAddress.postalCode,
    city: personAddress.city,
    country: personAddress.country,
  };
};
