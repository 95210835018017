import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { RotatingLines } from 'react-loader-spinner';

export const LOADER_SIZES = {
  xs: 25,
  s: 45,
  m: 70,
  l: 100,
  xl: 125,
};

export const LOADER_COLORS = {
  lightBlue: '#63b8d4',
  white: '#fff',
  gray: '#aaa',
  darkBlue: '#266B7F',
};

export const APP_LOADER_CONFIG = {
  length: 18,
  width: 10,
  radius: 28,
  opacity: 0.45,
  trail: 30,
  color: LOADER_COLORS.lightBlue,
};

function LoaderSpinner({ size = 'm', color = 'darkBlue', className = '' }) {
  return (
    <span className={cls('spinner__block', className)} aria-busy>
      <div aria-hidden>
        <RotatingLines
          ariaLabel="loading-indicator"
          strokeWidth={4}
          strokeColor={LOADER_COLORS[color]}
          animationDuration="1"
          width={LOADER_SIZES[size]}
        />
      </div>
    </span>
  );
}

LoaderSpinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default LoaderSpinner;
