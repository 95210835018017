import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import { ScrollerPanelListItem as ListItem } from 'common/components/ScrollerPanel';
import LoaderSpinner from './LoaderSpinner';

const ScrollerPanelListLoader = ({
  color = 'darkBlue',
  size = 's',
  text1 = '',
  text2 = '',
  fullWidth = false,
  hideDivider = false,
  className = '',
}) => (
  <ListItem hideDivider={hideDivider} className={cls('loader', { 'full-width': fullWidth }, className)}>
    <LoaderSpinner color={color} size={size} />
    {(text1 || text2) && (
      <div>
        {text1}
        <br />
        {text2}
      </div>
    )}
  </ListItem>
);

ScrollerPanelListLoader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  fullWidth: PropTypes.bool,
  hideDivider: PropTypes.bool,
  className: PropTypes.string,
};

export default ScrollerPanelListLoader;
