import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { t } from 'i18next';

import { getClipboardContents } from 'utils/navigator-utils';
import { FlexGrid, Row, Block, Modal } from 'common/components';
import { shareCloseAction } from './ShareActions';

const ShareComponent = () => {
  const [copied, setCopied] = useState(false);
  const [clicked, setClicked] = useState(false);
  const urlRef = useRef(null);
  const dispatch = useDispatch();

  const url = useSelector(state => state.share.url);

  const isDone = clicked && copied;
  const isFailed = clicked && !copied;

  const handleClose = () => {
    setCopied(false);
    setClicked(false);
    dispatch(shareCloseAction());
  };

  const selectUrlField = () => urlRef.current.setSelectionRange(0, urlRef?.current.value.length);

  const copyToClipboard = () => {
    const clipboardContents = getClipboardContents();
    if (clipboardContents == null) {
      urlRef.current.focus();
      selectUrlField();
      setCopied(document.execCommand('copy'));
    } else {
      clipboardContents
        .writeText(urlRef.current.value)
        .then(() => setCopied(true))
        .catch(() => setCopied(false));
    }

    setClicked(true);
  };

  useEffect(() => {
    return () => {
      setCopied(false);
      setClicked(false);
    };
  }, []);

  return url ? (
    <Modal isOpen title={t('share.title')} closeHandler={handleClose}>
      <FlexGrid className="custom-marker-link">
        <Row>
          <Block size={6}>
            <label htmlFor="share-url-input">
              {!copied && <span>{t('share.desc')}</span>}
              {isDone && <span>{t('share.copySuccess')}</span>}
              {copied && isFailed && <span>{t('share.copyFailed')}</span>}
            </label>
            <input
              ref={urlRef}
              id="share-url-input"
              className="input-text margin-t-2"
              type="text"
              readOnly
              value={url || ''}
              onClick={selectUrlField}
            />
          </Block>
        </Row>
        <Row className="margin-t-2">
          <Block size={6}>
            <button type="button" onClick={copyToClipboard} className="button button--orange button--full-width">
              {t('share.copyToClipboard')}
            </button>
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  ) : null;
};

export default ShareComponent;
