import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from 'common/components/Modal';
import { FlexGrid, Row, Block } from 'common/components/Grid';
import { Heading } from '../index';

const RoleChangeModal = ({ changeRole, cancelRoleChange, goToShoppingCart }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen closeHandler={cancelRoleChange} title={t('roleChangeModal.title')}>
      <FlexGrid className="text-center">
        <Row className="margin-t-2">
          <Block size="6">
            <Heading level="h3">{t('roleChangeModal.text.title')}</Heading>
            <p className="margin-t-1">{t('roleChangeModal.text.info')}</p>
          </Block>
        </Row>
        <Row className="margin-t-2 margin-b-3">
          <Block size="6">
            <button
              type="button"
              className="button button--light"
              onClick={() => {
                goToShoppingCart();
              }}
            >
              {t('roleChangeModal.button.goToShoppingCart')}
            </button>
            <button
              type="button"
              className="button button--orange indent-block-2"
              onClick={() => {
                changeRole();
              }}
            >
              {t('roleChangeModal.button.changeRole')}
            </button>
          </Block>
        </Row>
      </FlexGrid>
    </Modal>
  );
};

RoleChangeModal.propTypes = {
  changeRole: PropTypes.func.isRequired,
  cancelRoleChange: PropTypes.func.isRequired,
  goToShoppingCart: PropTypes.func.isRequired,
};

export default RoleChangeModal;
