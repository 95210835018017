import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const UnorderedList = ({ className = '', showBullets = false, dataTestId = '', children = null }) => (
  <ul
    className={cls('nls-unordered-list', { 'nls-unordered-list--bullets': showBullets }, className)}
    data-testid={dataTestId}
  >
    {children}
  </ul>
);

UnorderedList.propTypes = {
  className: PropTypes.string,
  showBullets: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};

export default UnorderedList;
