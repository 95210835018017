import { INFO_BOX_ACTION_ID_REMOVE_ADDRESS } from 'common/constants/InfoBoxActionIds';
import { REST_CALL_STATUS_STARTED, REST_CALL_STATUS_FINISHED } from 'common/constants/StatusCodes';
import { MAP_CLICKED_LISTENER_ADDRESS } from 'common/constants/EventListenerIds';
import * as OskariMap from 'oskari/OskariMap';
import MapSiteApi from 'common/api/CommonApi';
import { error } from 'common/helpers/Error';
import { addInfoNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import i18next from 'i18next';
import { toggleLoadingAnimationThunk } from 'common/containers/OskariMap/OskariMapActions';
import markerSvg from 'styles/markers/AddressMarker.svg';
import { MAP_TOOL_ADDRESS_REST_CALL_STATUS } from './MapToolbarActionTypes';
import { addMarkerFeature, removeFeatureThunk, setLayerVisibilityThunk } from '../../geometries/geometriesActions';
import { FEATURE_TYPES, GEOMETRY_TYPES } from '../../constants/MapConstants';
import { accessibleNotificationAdded } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from '../AccessibleNotifications/AccessibleNotificationsConstants';
import { isDesktop } from '../../constants/Layout';
import { LAYER_ADDRESSES } from '../../../oskari/layers/VectorLayers';
import { switchMapToolThunk } from './MapToolbarActions';
import { MAP_TOOL_ADDRESS } from './MapToolNames';

const ADDRESS_MARKER_PRIORITY = 4;

const marker = {
  svg: markerSvg,
  centerX: 16,
  centerY: 1,
  layer: LAYER_ADDRESSES,
};

const showDestination = (id, dispatch, getState, { lat, lon }) => data => {
  dispatch(mapToolAddressRestCallStatus(REST_CALL_STATUS_FINISHED));

  if (getState().geometries?.layerVisibility[LAYER_ADDRESSES] === false)
    dispatch(setLayerVisibilityThunk(LAYER_ADDRESSES, true));
  if (data.destination) {
    const inputData = {
      id,
      lat: data.destination.lat,
      lon: data.destination.lon,
      featureType: FEATURE_TYPES.ADDRESS_MARKER,
      name: data.destination.address[i18next.language] || data.destination.address.fi || data.destination.address.sv,
      destination: data.destination,
    };
    dispatch(
      addMarkerFeature(inputData, marker, ADDRESS_MARKER_PRIORITY, {
        id: `guide-${id}`,
        lon0: lon,
        lat0: lat,
        lon1: data.destination.lon,
        lat1: data.destination.lat,
        featureType: FEATURE_TYPES.ADDRESS_MARKER,
        geometryType: GEOMETRY_TYPES.GUIDE,
        layer: LAYER_ADDRESSES,
      })
    );
    showAddressInfoBox(isDesktop(getState().layout.mode))(inputData);
    dispatch(switchMapToolThunk(MAP_TOOL_ADDRESS, false));
  } else {
    error('NearestAddress could not resolve destination');
  }
  dispatch(toggleLoadingAnimationThunk(false));
};

export function turnAddressOn(dispatch, getState) {
  OskariMap.setCursorStyle('crosshair');
  OskariMap.clicked(MAP_CLICKED_LISTENER_ADDRESS, sourceData => {
    const id = `NearestAddress_${Date.now()}`;
    dispatch(toggleLoadingAnimationThunk(true));
    dispatch(mapToolAddressRestCallStatus(REST_CALL_STATUS_STARTED));
    MapSiteApi.getAddress(sourceData)
      .then(showDestination(id, dispatch, getState, sourceData))
      .catch(err => {
        if (err.status === 422) {
          dispatch(addInfoNotificationAction(i18next.t('maptoolbar.address.notification.notfound')));

          dispatch(
            accessibleNotificationAdded({
              text: i18next.t('maptoolbar.address.notification.notfound'),
              type: ASSERTIVE,
            })
          );
        } else {
          error(i18next.t('maptoolbar.address.notification.apierror'), err);
        }
        dispatch(toggleLoadingAnimationThunk(false));
      });
  });
}

export function turnAddressOff() {
  OskariMap.setCursorStyle('default');
  OskariMap.removeClicked(MAP_CLICKED_LISTENER_ADDRESS);
}

export function mapToolAddressRestCallStatus(status) {
  return {
    type: MAP_TOOL_ADDRESS_REST_CALL_STATUS,
    status,
    timestamp: Date.now(),
  };
}

const showCorrectDescription = place => {
  if (place.destination?.postOffice) return place.destination.postOffice[i18next.language];
  if (place.municipality) return place?.municipality[i18next.language];
  return '';
};

export function showAddressInfoBox(disableMobileBreakpoint) {
  return place => {
    // Address markers can be created with nearest address search or by
    // searching address names. These objects have some difference in properties
    const title = place?.destination?.address[i18next.language] || place.name;

    const coords = place.destination
      ? { n: place.destination.lat, e: place.destination.lon }
      : { n: place.lat, e: place.lon };

    OskariMap.infoBox(
      {
        id: `${place.id}_infobox`,
        title,
        desc: showCorrectDescription(place),
        coords,
        actions: [
          {
            name: i18next.t('button.remove'),
            action: {
              itemId: place.id,
              actionId: INFO_BOX_ACTION_ID_REMOVE_ADDRESS,
            },
          },
        ],
      },
      disableMobileBreakpoint
    );
  };
}

export function handleAddressInfoBoxActionsThunk(actionId, item) {
  return dispatch => {
    if (actionId === INFO_BOX_ACTION_ID_REMOVE_ADDRESS) {
      dispatch(removeFeatureThunk(item.id));
      dispatch(removeFeatureThunk(`guide-${item.id}`));
    }
  };
}
