import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Emphasized = ({ className = '', children = null, dataTestId = null }) => (
  <em className={cls('nls-emphasized', className)} data-testid={dataTestId}>
    {children}
  </em>
);

Emphasized.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

export default Emphasized;
