import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { t } from 'i18next';

import CartNotificationBubble from 'common/components/UserMenu/CartNotificationBubble';
import { accessibleNotificationsCleared } from '../AccessibleNotifications/accessibleNotificationsSlice';
import { toggleCartNotificationBubble } from '../ShoppingCart/ShoppingCartActions';
import { POLITE } from '../AccessibleNotifications/AccessibleNotificationsConstants';

const CartNotificationBubbleComponent = () => {
  const dispatch = useDispatch();
  const [showCartBubble, setShowCartBubble] = useState(true);
  const [timeouts, setTimeouts] = useState([]);

  const cartBubbleType = useSelector(state => state.shoppingCart.notificationBubble.type);
  const resetTimeouts = useSelector(state => state.shoppingCart.notificationBubble.reset);

  const cartBubbleText = type => t(`togglableUserMenuComponent.${type}`);

  const closeCartBubble = () => {
    dispatch(toggleCartNotificationBubble());
    dispatch(accessibleNotificationsCleared(POLITE));
    setShowCartBubble(false);
  };

  const fadeOut = () => {
    setTimeouts([
      ...timeouts,
      setTimeout(() => setShowCartBubble(false), 6000),
      setTimeout(() => {
        dispatch(toggleCartNotificationBubble());
        dispatch(accessibleNotificationsCleared(POLITE));
      }, 7000),
    ]);
  };

  useEffect(() => {
    fadeOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (resetTimeouts === true) {
      timeouts.forEach(clearTimeout);
      fadeOut();
      dispatch(toggleCartNotificationBubble(cartBubbleType, true));
    }

    return () => timeouts.forEach(clearTimeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetTimeouts]);

  return (
    <CartNotificationBubble
      cartBubbleText={cartBubbleText(cartBubbleType)}
      trigger={showCartBubble}
      close={closeCartBubble}
    />
  );
};

export default CartNotificationBubbleComponent;
