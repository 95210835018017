import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';

const SimpleTitle = ({ id = null, title, TagName = 'h2', className = '' }) => {
  const dispatch = useDispatch();
  const titleRef = useRef(null);

  const focusHandler = () => titleRef?.current?.focus();

  useAutomaticKeyboardFocus(className, focusHandler);

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  return (
    <TagName id={id} onKeyDown={onKeyDown} className={className}>
      <span ref={titleRef} tabIndex="-1">
        {title}
      </span>
    </TagName>
  );
};

SimpleTitle.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  TagName: PropTypes.string,
  className: PropTypes.string,
};

export default SimpleTitle;
