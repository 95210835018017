import * as OskariMap from 'oskari/OskariMap';
import { getJson } from 'common/api/AjaxJson';
import { OSKARI_CHANNEL_STATUS_IDS } from 'oskari/OskariMap';
import { LAYER_MAP_SHEETS, LAYER_SELECTED_MAP_SHEET } from 'oskari/layers/VectorLayerIds';
import * as routes from 'common/constants/Routes';
import { initGrid } from 'common/containers/MapStore/MapStoreActions';
import { error } from 'common/helpers/Error';
import { t } from 'i18next';
import {
  CHANGE_BASE_MAP_LAYER_OPACITY,
  CHANGE_BASE_MAP_LAYER_VISIBILITY,
  DISABLE_ALL_LAYERS,
  DISABLE_ALL_OTHER_LAYERS,
  DISABLE_BASE_MAP_LAYER,
  ENABLE_ALL_BASE_MAP_LAYERS,
  LAYERS,
} from './MapLayerSelectorActionTypes';

export function initializeLayers(layers) {
  return {
    type: LAYERS,
    layers,
  };
}

// Handles only 2sasi ui component base map layer opacity slider state
export function changeBaseMapLayerOpacity(mapId, opacity) {
  return {
    type: CHANGE_BASE_MAP_LAYER_OPACITY,
    mapId,
    opacity,
  };
}

// Handles only 2sasi ui component base map layer visibility
export function changeBaseMapLayerVisibility(mapId, isVisible) {
  return {
    type: CHANGE_BASE_MAP_LAYER_VISIBILITY,
    mapId,
    isVisible,
  };
}

export function initializeLayersThunk() {
  return dispatch =>
    new Promise(resolve => {
      OskariMap.getAllLayers(layers => {
        dispatch(initializeLayers(layers.reverse()));
        resolve();
      });
    });
}

/**
 * Handles map layer opacity change on 2sasi ui component and oskari rpc
 * @param {number} layerId
 * @param {boolean} opacity
 */
export function toggleMapLayerOpacityThunk(layerId, opacity) {
  return dispatch => {
    OskariMap.changeMapLayerOpacity(layerId, opacity);
    return dispatch(changeBaseMapLayerOpacity(layerId, opacity));
  };
}

/**
 * Handles map layer visibility toggle on 2sasi ui component and oskari rpc
 * @param {number|String} layerId
 * @param {boolean} visible
 */
export function toggleMapLayerVisibilityThunk(layerId, visible) {
  return dispatch => {
    OskariMap.changeMapLayerVisibility(layerId, visible);
    return dispatch(changeBaseMapLayerVisibility(layerId, visible));
  };
}

function disableBaseMapLayer(mapId, leaveVisible) {
  const isVisible = typeof leaveVisible === 'boolean' ? leaveVisible : false;
  return {
    type: DISABLE_BASE_MAP_LAYER,
    mapId,
    leaveVisible: isVisible,
  };
}

export function enableAllBaseMapLayers() {
  return {
    type: ENABLE_ALL_BASE_MAP_LAYERS,
  };
}

export function enableAllBaseMapLayersThunk() {
  return dispatch => {
    // Send Oskari RPC map layer visibility requests:
    const layers = OskariMap.getOskariLayers();
    Object.keys(layers).forEach(layerName => {
      const layerId = layers[layerName];
      dispatch(toggleMapLayerVisibilityThunk(layerId, true));
    });
  };
}

export function disableBaseMapLayerThunk(mapId, leaveVisible) {
  return dispatch => {
    const isVisible = typeof leaveVisible === 'boolean' ? leaveVisible : false;

    // Handle Oskari RPC map visibility state:
    dispatch(toggleMapLayerVisibilityThunk(mapId, isVisible));

    // Handle 2sasi local map layer disable state:
    dispatch(disableBaseMapLayer(mapId, isVisible));
  };
}

function disableAllOtherLayers(layerId) {
  return {
    type: DISABLE_ALL_OTHER_LAYERS,
    layerId,
  };
}

export function disableAllButMaastokarttaLayer() {
  return dispatch => {
    dispatch(disableAllOtherLayers(OskariMap.getOskariLayers().maastokartta));
  };
}

export function disableAllLayers() {
  return {
    type: DISABLE_ALL_LAYERS,
  };
}

export function hideExtraLayersThunk() {
  return async (dispatch, getState) => {
    if (getState().layout.oskariChannelStatus !== OSKARI_CHANNEL_STATUS_IDS.channelReady) {
      return;
    }

    await OskariMap.mapIsReady();
    OskariMap.getAllLayers(layers => {
      const configuredLayerIds = Object.values(OskariMap.getOskariLayers());

      layers
        .filter(layer => Number.isInteger(layer.id)) // Don't apply to vector layers
        .forEach(layer => {
          if (!configuredLayerIds.includes(layer.id)) dispatch(toggleMapLayerVisibilityThunk(layer.id, false));
        });
    });
  };
}

export function setRealEstateLayerDefaultStyleThunk() {
  return async () => {
    await OskariMap.mapIsReady();

    const layers = OskariMap.getOskariLayers();
    OskariMap.changeMapLayerStyle(layers.kiinteistojaotus, 'KIINTEISTORAJAT');
    OskariMap.changeMapLayerStyle(layers.kiinteistotunnukset, 'KIINTEISTOTUNNUKSET');
  };
}

export function setLayerDefaultVisibilitiesThunk() {
  return async dispatch => {
    // Send map layer visibility requests to Oskari RPC:
    const layerVisibilities = OskariMap.getMapLayerVisibilities();
    const layers = OskariMap.getOskariLayers();

    Object.keys(layers).forEach(layerName => {
      dispatch(toggleMapLayerVisibilityThunk(layers[layerName], layerVisibilities[layerName]));
    });

    // Hide layers that are not configured for layer list
    dispatch(hideExtraLayersThunk());
  };
}

function _cleanAllReadyMapLayers() {
  OskariMap.clearLayer(LAYER_MAP_SHEETS);
  OskariMap.clearLayer(LAYER_SELECTED_MAP_SHEET);
}

export function cleanMapThunk() {
  return dispatch => {
    dispatch(() => {
      _cleanAllReadyMapLayers();
      OskariMap.removeClicked('gridClick');
    });
  };
}

export function getCustomMapsSheetsThunk() {
  return async dispatch => {
    try {
      const response = await getJson(routes.getRoute(routes.API_PATH_TITLES));
      dispatch(initGrid(response));
    } catch (err) {
      error(t('mapStoreIndex.customMaps.mapSheetsLoadError'), err);
    }
  };
}
