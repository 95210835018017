import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Slider from 'rc-slider';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';
import { Glyphicon } from 'common/components/Icon';
import { getOskariLayers } from 'oskari/OskariMap';
import { toggleMapLayerVisibilityThunk } from './MapLayerSelectorActions';
import { addBoundaryInaccuracyNotification } from '../AppNotifications/AppNotificationsActions';
import { enableIframeEvents } from '../OskariMap/OskariMapActions';

const onToggleLayer = (mapId, visible) => {
  return dispatch => {
    const layers = getOskariLayers();

    if (visible && (mapId === layers.kiinteistojaotus.toString() || mapId === layers.kiinteistotunnukset.toString())) {
      dispatch(addBoundaryInaccuracyNotification());
    }
    dispatch(toggleMapLayerVisibilityThunk(mapId, visible));
  };
};

const LayerControls = ({
  id,
  visible,
  disabled = false,
  opacity,
  minZoom = 0,
  maxZoom = 13,
  zoomLevel,
  onMapLayerOpacityChange,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const toggleVisibility = () => {
    dispatch(onToggleLayer(id, !visible));
  };

  const handleSliderChange = opac => {
    dispatch(enableIframeEvents(false));
    onMapLayerOpacityChange(opac);
  };

  const layerNotInRange = () => !(zoomLevel >= minZoom && zoomLevel <= maxZoom);

  return (
    <li className="layer-controls__li">
      <button
        type="button"
        className={cls('layer-controls__button', { visible })}
        onClick={toggleVisibility}
        title={visible ? t('mapLayerSelector.toggle.tooltip.close') : t('mapLayerSelector.toggle.tooltip.open')}
        disabled={disabled}
      >
        <Glyphicon glyph={visible ? 'silma-auki' : 'silma-kiinni'} />
        <span className="layer-controls__name">{t(`mapLayerSelector.layer${id}.name`)}</span>
      </button>
      {visible && (
        <div className="layer-controls__slider">
          {layerNotInRange() ? (
            <div className="layer-controls__not-in-range">{t('mapLayerSelector.layerNotInRange')}</div>
          ) : (
            <Slider
              value={opacity}
              onChange={handleSliderChange}
              onAfterChange={() => dispatch(enableIframeEvents(true))}
              disabled={disabled}
              ariaLabelForHandle={`${t(`mapLayerSelector.layer${id}.name`)} ${t('mapLayerSelector.slider.label')}`}
            />
          )}
        </div>
      )}
    </li>
  );
};

LayerControls.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.number.isRequired,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  onMapLayerOpacityChange: PropTypes.func.isRequired,
  opacity: PropTypes.number.isRequired,
  visible: PropTypes.bool.isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

export default LayerControls;
