import React from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';

const LinkHeading = ({ className = '', children }) => {
  return <p className={cls('nls-link-heading', className)}>{children}</p>;
};

LinkHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default LinkHeading;
