import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import ScrollerColumnContentPositioner from './ScrollerColumnContentPositioner';
import ScrollerPanelContext from './ScrollerPanelContext';
import { ScrollerColumnProvider } from './ScrollerColumnContext';

const ScrollerColumn = ({ columnNumber, two = false, lastColumn = false, children = null }) => {
  const { activeColumnNumber, fullWidth } = useContext(ScrollerPanelContext);
  const hiddenColumn =
    (!fullWidth && (columnNumber < activeColumnNumber - 1 || columnNumber > activeColumnNumber)) ||
    (fullWidth && columnNumber !== activeColumnNumber);
  const columnId = `scroller-panel-column-${columnNumber}`;
  const panelClasses = cls('scroller-panel__column', {
    'hidden-column': hiddenColumn,
    'scroller-panel__column--single': !two,
    'two-columns': !!two,
    'last-column': lastColumn && columnNumber !== 2,
  });

  return (
    <div className={panelClasses} id={columnId} data-cy={columnId}>
      <ScrollerColumnProvider targetColumnNumber={columnNumber} hiddenColumn={hiddenColumn}>
        <ScrollerColumnContentPositioner columnNumber={columnNumber}>{children}</ScrollerColumnContentPositioner>
      </ScrollerColumnProvider>
    </div>
  );
};

ScrollerColumn.propTypes = {
  columnNumber: PropTypes.number.isRequired,
  two: PropTypes.bool,
  lastColumn: PropTypes.bool,
  children: PropTypes.node,
};

export default ScrollerColumn;
