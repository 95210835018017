import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const Heading = ({ level, visualLevel = null, className = '', children = null }) => {
  const Tag = level;
  return <Tag className={cls(`nls-heading-${visualLevel || level}`, className)}>{children}</Tag>;
};

Heading.propTypes = {
  level: PropTypes.PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong']).isRequired,
  visualLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Heading;
