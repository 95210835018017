/**
 * ASIALAJIT
 */
export const OTHER_SPECIAL_RIGHT = 'EO';
export const MORTGAGE = 'KI';
export const REMINDER = 'MU';
export const LEGAL_CONFIRMATION = 'LH';
export const PRIORITIZATION = 'KI_2';

export const ISSUE_TYPES = [
  {
    code: OTHER_SPECIAL_RIGHT, // Erityinen oikeus
    name: 'gra.step1.issueType.types.otherSpecialRight.name',
    name_organization: 'gra.step1.issueType.types.otherSpecialRight.name',
    desc: 'gra.step1.issueType.types.otherSpecialRight.desc',
  },
  {
    code: MORTGAGE, // Kiinnitys
    name: 'gra.step1.issueType.types.mortgage.name',
    name_organization: 'gra.step1.issueType.types.mortgage.name',
  },
  {
    code: REMINDER, // Muistutus tai vallintarajoitus
    name: 'gra.step1.issueType.types.reminder.name',
    name_organization: 'gra.step1.issueType.types.reminder.name',
    desc: 'gra.step1.issueType.types.reminder.desc',
  },
  {
    code: LEGAL_CONFIRMATION, // Lainhuuto
    name: 'gra.step1.issueType.types.legalConfirmation.name',
    name_organization: 'gra.step1.issueType.types.legalConfirmation.name',
  },
  {
    code: PRIORITIZATION, // Etusijajärjestyksen muuttaminen
    name: 'gra.step1.issueType.types.prioritization.name',
    name_organization: 'gra.step1.issueType.types.prioritization.name',
    desc: 'gra.step1.issueType.types.prioritization.desc',
  },
];

/**
 * ASIALAADUT
 */

export const OSR_CHANGE = 'EO02';
export const OSR_REMOVAL = 'EO04';
export const OSR_MOVING = 'EO03';
export const OSR_UPDATE = 'EO06';
export const OSR_FIX = 'KOEO';

export const MORTGAGE_NEW = 'KI01';
export const MORTGAGE_CHANGE = 'KI22';
export const MORTGAGE_REMOVAL = 'KI12';
export const MORTGAGE_MOVING = 'KI27';
export const MORTGAGE_TRANSFER = 'KS';
export const MORTGAGE_PARTIAL_REMOVAL = 'YP00';
export const MORTGAGE_FIX = 'KOKI';

export const REMINDER_NEW = 'MU01';
export const REMINDER_REMOVAL = 'MU02';
export const REMINDER_CHANGE = 'MU05';
export const REMINDER_FIX = 'KOMU';

export const LC_NEW = 'LH03';
export const LC_UPDATE = 'LH04';
export const LC_FIX = 'KOLH';

export const PRIO_CHANGE = 'KI21';
export const PRIO_FIX = 'KOEJ';

export const OTHER_SPECIAL_RIGHT_QUALITIES = [
  {
    code: OSR_CHANGE, // Muuttaminen
    name: 'gra.step1.issueType.qualities.otherSpecialRight.change',
    name_organization: 'gra.step1.issueType.qualities.otherSpecialRight.change',
    desc: 'gra.step1.issueType.qualities.otherSpecialRight.changeDesc',
    productCode: '7430107434',
  },
  {
    code: OSR_REMOVAL, // Poistaminen
    name: 'gra.step1.issueType.qualities.otherSpecialRight.removal',
    name_organization: 'gra.step1.issueType.qualities.otherSpecialRight.removal',
    desc: 'gra.step1.issueType.qualities.otherSpecialRight.removalDesc',
    productCode: '7430107433',
  },
  {
    code: OSR_MOVING, // Siirtäminen
    name: 'gra.step1.issueType.qualities.otherSpecialRight.moving',
    name_organization: 'gra.step1.issueType.qualities.otherSpecialRight.moving',
    desc: 'gra.step1.issueType.qualities.otherSpecialRight.movingDesc',
    productCode: '7430107432',
  },
  {
    code: OSR_UPDATE, // Selventäminen
    name: 'gra.step1.issueType.qualities.otherSpecialRight.update',
    name_organization: 'gra.step1.issueType.qualities.otherSpecialRight.update',
    desc: 'gra.step1.issueType.qualities.otherSpecialRight.updateDesc',
    productCode: '7430107436',
  },
  {
    code: OSR_FIX, // Korjaaminen
    name: 'gra.step1.issueType.qualities.otherSpecialRight.fix',
    name_organization: 'gra.step1.issueType.qualities.otherSpecialRight.fix',
    desc: 'gra.step1.issueType.qualities.otherSpecialRight.fixDesc',
    productCode: 'maksuton',
  },
];

export const MORTGAGE_QUALITIES = [
  {
    code: MORTGAGE_CHANGE, // Muuttaminen
    name: 'gra.step1.issueType.qualities.mortgage.change',
    desc: 'gra.step1.issueType.qualities.mortgage.changeDesc',
    productCode: '7420207460',
  },
  {
    code: MORTGAGE_PARTIAL_REMOVAL, // Purkaminen
    name: 'gra.step1.issueType.qualities.mortgage.partialRemoval',
    desc: 'gra.step1.issueType.qualities.mortgage.partialRemovalDesc',
    productCode: '7420207452',
  },
  {
    code: MORTGAGE_REMOVAL, // Kuolettaminen
    name: 'gra.step1.issueType.qualities.mortgage.removal',
    desc: 'gra.step1.issueType.qualities.mortgage.removalDesc',
    productCode: '7420107425',
  },
  {
    code: MORTGAGE_MOVING, // Sähköisen panttikirjan siirtäminen
    name: 'gra.step1.issueType.qualities.mortgage.moving',
    desc: 'gra.step1.issueType.qualities.mortgage.movingDesc',
    productCode: '7420207458',
  },
  {
    code: MORTGAGE_TRANSFER, // Paperisen panttikirjan muuttaminen sähköiseks
    name: 'gra.step1.issueType.qualities.mortgage.transfer',
    productCode: '7420207457',
  },
  {
    code: MORTGAGE_NEW, // Vahvistaminen
    name: 'gra.step1.issueType.qualities.mortgage.new',
    desc: 'gra.step1.issueType.qualities.mortgage.newDesc',
    productCode: '7420207453',
  },
  {
    code: MORTGAGE_FIX, // Korjaaminen
    name: 'gra.step1.issueType.qualities.mortgage.fix',
    desc: 'gra.step1.issueType.qualities.mortgage.fixDesc',
    productCode: 'maksuton',
  },
];

export const REMINDER_QUALITIES = [
  {
    code: REMINDER_NEW, // Merkitseminen
    name: 'gra.step1.issueType.qualities.reminder.new',
    desc: 'gra.step1.issueType.qualities.reminder.newDesc',
    productCode: 'maksuton',
  },
  {
    code: REMINDER_REMOVAL, // Poistaminen
    name: 'gra.step1.issueType.qualities.reminder.removal',
    desc: 'gra.step1.issueType.qualities.reminder.removalDesc',
    productCode: 'maksuton',
  },
  {
    code: REMINDER_CHANGE, // Muuttaminen
    name: 'gra.step1.issueType.qualities.reminder.change',
    desc: 'gra.step1.issueType.qualities.reminder.changeDesc',
    productCode: 'maksuton',
  },
  {
    code: REMINDER_FIX, // Korjaaminen
    name: 'gra.step1.issueType.qualities.reminder.fix',
    desc: 'gra.step1.issueType.qualities.reminder.fixDesc',
    productCode: 'maksuton',
  },
];

export const LEGAL_CONFIRMATION_QUALITIES = [
  {
    code: LC_FIX, // Korjaaminen
    name: 'gra.step1.issueType.qualities.legalConfirmation.fix',
    desc: 'gra.step1.issueType.qualities.legalConfirmation.fixDesc',
    productCode: 'maksuton',
  },
];

export const LEGAL_CONFIRMATION_ORGANIZATION_QUALITIES = [
  {
    code: LC_FIX, // Korjaaminen
    name: 'gra.step1.issueType.qualities.legalConfirmation.fix',
    desc: 'gra.step1.issueType.qualities.legalConfirmation.fixDesc',
    productCode: 'maksuton',
  },
  {
    code: LC_NEW, // Rekisteröinti
    name: 'gra.step1.issueType.qualities.legalConfirmationOrganization.new',
    desc: 'gra.step1.issueType.qualities.legalConfirmationOrganization.newDesc',
    productCode: 'maksuton',
  },
  {
    code: LC_UPDATE, // Hallinnoivan viranomaisen merkitseminen
    name: 'gra.step1.issueType.qualities.legalConfirmationOrganization.update',
    desc: 'gra.step1.issueType.qualities.legalConfirmationOrganization.updateDesc',
    productCode: 'maksuton',
  },
];

export const PRIORITIZATION_QUALITIES = [
  {
    code: PRIO_CHANGE, // Muuttaminen
    name: 'gra.step1.issueType.qualities.prioritization.change',
    desc: 'gra.step1.issueType.qualities.prioritization.changeDesc',
    productCode: 'maksuton',
  },
  {
    code: PRIO_FIX, // Korjaaminen
    name: 'gra.step1.issueType.qualities.prioritization.fix',
    desc: 'gra.step1.issueType.qualities.prioritization.fixDesc',
    productCode: 'maksuton',
  },
];

export const FIX_QUALITIES = [OSR_FIX, MORTGAGE_FIX, REMINDER_FIX, LC_FIX, PRIO_FIX, LC_FIX];

export const SEARCH_ACTIVITY_QUALITIES = [
  OSR_CHANGE,
  OSR_REMOVAL,
  OSR_UPDATE,
  OSR_MOVING,
  MORTGAGE_REMOVAL,
  MORTGAGE_CHANGE,
  MORTGAGE_MOVING,
  MORTGAGE_TRANSFER,
  MORTGAGE_PARTIAL_REMOVAL,
  REMINDER_REMOVAL,
  REMINDER_CHANGE,
  OSR_FIX,
  MORTGAGE_FIX,
  REMINDER_FIX,
];

export const SEARCH_ACTIVITY_FACILITIES = [OSR_CHANGE, OSR_REMOVAL, OSR_UPDATE, OSR_MOVING, OSR_FIX];

export const CHANGING_ACTIVITY_QUALITIES = [
  OSR_CHANGE,
  OSR_MOVING,
  OSR_UPDATE,
  OSR_FIX,
  MORTGAGE_FIX,
  REMINDER_FIX,
  LC_FIX,
  PRIO_FIX,
  MORTGAGE_CHANGE,
  MORTGAGE_MOVING,
  MORTGAGE_PARTIAL_REMOVAL,
  MORTGAGE_NEW,
  MORTGAGE_TRANSFER,
  REMINDER_CHANGE,
  LC_NEW,
  LC_UPDATE,
  PRIO_CHANGE,
];

export const FREE_PRODUCTS = [
  LC_NEW,
  LC_UPDATE,
  LC_FIX,
  REMINDER_NEW,
  REMINDER_REMOVAL,
  PRIO_FIX,
  MORTGAGE_FIX,
  REMINDER_FIX,
  REMINDER_CHANGE,
  OSR_FIX,
  PRIO_CHANGE,
];

export const SHOPPING_CART_INFO_PRODUCTS = [
  OSR_UPDATE,
  OSR_FIX,
  MORTGAGE_FIX,
  REMINDER_CHANGE,
  REMINDER_FIX,
  REMINDER_NEW,
  REMINDER_REMOVAL,
  LC_NEW,
  LC_UPDATE,
  LC_FIX,
  PRIO_FIX,
];

/**
 * FIELDS
 */
export const GRA_FIELDS = {
  TARGET: 'target',
  ISSUE_TYPE: 'issueType',
  LC_QUALITY: 'legalConfirmationQuality',
  MORTGAGE_QUALITY: 'mortgageQuality',
  OSR_QUALITY: 'otherSpecialRightQuality',
  PRIO_QUALITY: 'prioritizationQuality',
  REMINDER_QUALITY: 'reminderQuality',
  OSR_SUBTYPE: 'osrSubtype',
  REMINDER_SUBTYPE: 'reminderSubtype',
  DESCRIPTION: 'issueDescription',
  DESCRIPTION_RECEIVERS: 'issueDescriptionReceivers',
  ADDITIONAL_INFORMATION: 'additionalInformation',
  OTHER_ATTACHMENTS: 'otherAttachments',
  OSR_DEATH_ESTATE_ATTACHMENTS: 'osrDeathEstateAttachments',
  MORTGAGE_TRANSFER_ATTACHMENTS: 'mortgageTransferAttachments',
};
