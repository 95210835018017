import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import FileSender from './FileSender';
import { TRANSITION_DURATION } from './UploaderConstants';

const noop = () => null;

const UploadProgress = ({
  uploads,
  onBeforeSend = noop,
  onUploadError = noop,
  onDone = noop,
  removeUpload,
  type = '',
  formId,
  url,
  errorText,
}) => (
  <TransitionGroup component={null}>
    {uploads.map(upload => (
      <CSSTransition
        key={`upload-${upload.id}`}
        classNames="uploader__progress"
        timeout={{
          enter: TRANSITION_DURATION,
          exit: TRANSITION_DURATION * 3,
        }}
      >
        <FileSender
          upload={upload}
          onBeforeSend={onBeforeSend}
          onUploadError={onUploadError}
          onDone={onDone}
          removeUpload={removeUpload}
          type={type}
          formId={formId}
          url={url}
          fileTypeErrorText={errorText}
        >
          {(loaded, total, isError) => (
            <div className="uploader-progress__wrapper">
              <progress
                max={total}
                className={cls('uploader__progressbar', {
                  'uploader__progress--ongoing': loaded < total,
                  'uploader__progress--done': loaded >= total,
                  'uploader__progress--error': isError,
                })}
                value={loaded}
              />
              <i className="truncated-text-ellipsis">{upload.file.name}</i>
            </div>
          )}
        </FileSender>
      </CSSTransition>
    ))}
  </TransitionGroup>
);

UploadProgress.propTypes = {
  uploads: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      file: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  url: PropTypes.string.isRequired,
  onBeforeSend: PropTypes.func,
  onUploadError: PropTypes.func,
  onDone: PropTypes.func,
  removeUpload: PropTypes.func.isRequired,
  errorText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default UploadProgress;
