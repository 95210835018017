/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import firstBy from 'thenby';
import { head, isEmpty, sortBy } from 'lodash';

import ServicesApi from 'realEstateSite/api/ServicesApi';
import { formatDateYMMDD } from 'utils/datetime-utils';
import { PAGE_UNIT_COUNT } from 'realEstateSite/containers/Realty/RealtyConstants';
import ActivityObject from '../ActivityObject';
import { setRegisterState } from '../../RealEstateList/realEstateListSlice';
import { showSupplementRequest } from '../ActivityHelpers';
import { SECRET_DOCUMENT } from '../CadastralActivityConstants';

const initialState = {
  activityItems: [],
  activitiesLoaded: false,
  apiError: false,
  cadastralSurveysActivityApiFailed: false,
  registrationIssuesActivityApiFailed: false,
  activitiesNeedsToBeLoaded: true,
  activitiesLoading: false,
  selectedActivityDecisionId: null,
  selectedActivityDecisions: [],
  selectedActivityDecisionsLoading: false,
  activityDecisionApiError: false,
  cadastralDocumentsLoading: false,
  cadastralDocumentsApiError: false,
  cadastralDocuments: [],
  hasMoreCadastralActivityRealties: false,
  hasAllCadastralActivityRealtiesShown: false,
  hasFetchedAllCadastralActivityGeos: false,
  showAllActivities: true,
  activityPaginationLength: undefined,
  activityGeometriesLoading: false,
};

const compareNewestSupplementRequest = (a, b) => {
  if (showSupplementRequest(a)) {
    if (showSupplementRequest(b)) {
      const newestRequestA = head(sortBy(a.supplementRequests, ['submissionTime', 'supplementRequestId']).reverse());
      const newestRequestB = head(sortBy(b.supplementRequests, ['submissionTime', 'supplementRequestId']).reverse());

      const submissionTimesComparison = newestRequestB.submissionTime?.localeCompare(newestRequestA?.submissionTime);
      return submissionTimesComparison === 0
        ? newestRequestB?.supplementRequestId?.localeCompare(newestRequestA?.supplementRequestId)
        : submissionTimesComparison;
    }
    return -1;
  }
  if (!isEmpty(b.supplementRequests) && showSupplementRequest(b)) {
    return 1;
  }
  return 0;
};

const activityLogSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    activityItemsCreated(state, action) {
      state.activityItems =
        action?.payload?.activities != null
          ? action.payload.activities.map(ActivityObject).sort(
              firstBy((a, b) => b.hasSupplementRequests - a.hasSupplementRequests)
                .thenBy((a, b) => compareNewestSupplementRequest(a, b))
                .thenBy((a, b) => b.isActiveApplication - a.isActiveApplication)
                .thenBy(
                  (a, b) =>
                    new Date(b.solutionDate || b.lastPhaseDate || b.activityStartDate) -
                    new Date(a.solutionDate || a.lastPhaseDate || a.activityStartDate)
                )
            )
          : [];
      state.activitiesLoaded = true;
      state.cadastralSurveysActivityApiFailed = action.payload.cadastralSurveysActivityApiFailed;
      state.registrationIssuesActivityApiFailed = action.payload.registrationIssuesActivityApiFailed;
      state.activitiesNeedsToBeLoaded = false;
      state.apiError = false;
    },
    activitiesCleared(state) {
      state.activityItems = [];
      state.activitiesLoaded = false;
      state.apiError = false;
      state.cadastralSurveysActivityApiFailed = false;
      state.registrationIssuesActivityApiFailed = false;
      state.activitiesNeedsToBeLoaded = true;
      state.activitiesLoading = false;
      state.selectedActivityDecisions = [];
      state.selectedActivityDecisionsLoading = false;
      state.activityDecisionApiError = false;
    },
    activityApiError(state, action) {
      state.apiError = action.payload;
    },
    activitiesNeedsToBeLoaded(state) {
      state.activitiesNeedsToBeLoaded = true;
    },
    activitiesLoading(state, action) {
      state.activitiesLoading = action.payload;
    },
    selectedActivityDecisionsLoading(state, action) {
      state.selectedActivityDecisionsLoading = action.payload;
    },
    activityDecisionApiError(state, action) {
      state.activityDecisionApiError = action.payload;
    },
    activityDecisionsCreated(state, action) {
      state.selectedActivityDecisions = action.payload.sort((a, b) => b.documentId - a.documentId);
    },
    activityDecisionsCleared(state) {
      state.selectedActivityDecisions = [];
    },
    activityDecisionIdSelected(state, action) {
      state.selectedActivityDecisionId = action.payload;
    },
    cadastralDocumentsLoading(state, action) {
      state.cadastralDocumentsLoading = action.payload;
    },
    cadastralDocumentsApiError(state, action) {
      state.cadastralDocumentsApiError = action.payload;
    },
    cadastralDocumentsCreated: (state, action) => {
      state.cadastralDocuments = action.payload.map(item => ({
        ...item,
        documentDate: formatDateYMMDD(new Date(item.documentDate)),
        surveyDate: formatDateYMMDD(new Date(item.surveyDate)),
      }));
    },
    cadastralDocumentsCleared(state) {
      state.cadastralDocuments = [];
    },
    hasMoreCadastralActivityRealtiesToggled(state, action) {
      state.hasMoreCadastralActivityRealties = action.payload;
    },
    hasAllCadastralActivityRealtiesShownToggled(state, action) {
      state.hasAllCadastralActivityRealtiesShown = action.payload;
    },
    hasFetchedAllCadastralActivityGeosToggled(state, action) {
      state.hasFetchedAllCadastralActivityGeos = action.payload;
    },
    showAllActivitiesToggled(state, action) {
      state.showAllActivities = action.payload;
    },
    activityPaginationLengthUpdated(state, action) {
      state.activityPaginationLength = action.payload;
    },
    resetActivityListPagination(state) {
      state.activityPaginationLength = undefined;
      state.showAllActivities = true;
    },
    activityGeometriesLoading(state, action) {
      state.activityGeometriesLoading = action.payload;
    },
  },
});

export const {
  activityItemsCreated,
  activitiesCleared,
  activityApiError,
  activitiesNeedsToBeLoaded,
  activitiesLoading,
  selectedActivityDecisionsLoading,
  activityDecisionApiError,
  activityDecisionsCreated,
  activityDecisionsCleared,
  activityDecisionIdSelected,
  cadastralDocumentsLoading,
  cadastralDocumentsApiError,
  cadastralDocumentsCreated,
  cadastralDocumentsCleared,
  hasMoreCadastralActivityRealtiesToggled,
  hasAllCadastralActivityRealtiesShownToggled,
  hasFetchedAllCadastralActivityGeosToggled,
  showAllActivitiesToggled,
  activityPaginationLengthUpdated,
  resetActivityListPagination,
  activityGeometriesLoading,
} = activityLogSlice.actions;

export const fetchActivityLogThunk = (registerStateDate, signal = null) => async (dispatch, getState) => {
  dispatch(activitiesLoading(true));
  dispatch(activityApiError(false));
  try {
    const response = await ServicesApi.getActivityLog(signal);
    if (response.cadastralSurveysActivityApiFailed) {
      console.error('Toimitusasioiden haku epäonnistui');
    }
    if (response.registrationIssuesActivityApiFailed) {
      console.error('Rekisteröintiasioiden haku epäonnistui');
    }
    if (response.cadastralSurveysActivityApiFailed && response.registrationIssuesActivityApiFailed) {
      throw new Error('Käynnissä olevien asioiden haku epäonnistui');
    }
    dispatch(activityItemsCreated(response));
    if (!isEmpty(response.activities)) {
      const registrationActivity = response.activities.find(a => a.registerStateDate != null);
      if (registrationActivity) {
        dispatch(setRegisterState(registrationActivity?.registerStateDate, registerStateDate));
        dispatch(setRegisterState(registrationActivity?.activityRegistration?.registerStateDate, registerStateDate));
      }

      if (response.activities.length > PAGE_UNIT_COUNT) {
        dispatch(showAllActivitiesToggled(false));
        if (!getState().activities.activityPaginationLength) {
          dispatch(activityPaginationLengthUpdated(PAGE_UNIT_COUNT));
        }
        if (getState().activities.activityPaginationLength >= response.activities.length) {
          dispatch(showAllActivitiesToggled(true));
        }
      } else {
        dispatch(showAllActivitiesToggled(true));
      }
    }
  } catch (reason) {
    console.error(`Cannot fetch activity log: `, reason);
    dispatch(activityApiError(true));
  } finally {
    dispatch(activitiesLoading(false));
  }
};

export const fetchActivityDecisionsThunk = activityId => async dispatch => {
  dispatch(selectedActivityDecisionsLoading(true));
  try {
    const response = await ServicesApi.getActivityDecisions(activityId);
    if (!isEmpty(response.documents)) {
      dispatch(activityDecisionsCreated(response.documents));
    }
  } catch (reason) {
    console.error(`Cannot fetch activity decisions: `, reason);
    dispatch(activityDecisionApiError(true));
  } finally {
    dispatch(selectedActivityDecisionsLoading(false));
  }
};

export const fetchCadastralDocumentsThunk = (activityId, ssn) => async dispatch => {
  dispatch(cadastralDocumentsLoading(true));
  try {
    const response = ssn
      ? await ServicesApi.getCadastralActivityDocumentsBySSN(ssn, activityId)
      : await ServicesApi.getCadastralActivityDocuments(activityId);
    const publicityResponse = await ServicesApi.getActivityDecisions(activityId, true);

    if (!isEmpty(response.surveyDocuments)) {
      const uniqueDocuments = response.surveyDocuments.reduce((documentList, document) => {
        if (!documentList.some(item => item.documentNumber === document.documentNumber)) {
          const publicityDocument = publicityResponse.documents.find(
            pubDoc => pubDoc.documentId === document.documentNumber.toString()
          );

          // skip damaged documents from the list
          if (!publicityDocument) {
            return documentList;
          }
          document.isPublic = publicityDocument.publicityClass !== SECRET_DOCUMENT;

          documentList.push(document);
        }
        return documentList;
      }, []);
      dispatch(cadastralDocumentsCreated(uniqueDocuments.sort((a, b) => b.documentDate - a.documentDate)));
    }
  } catch (reason) {
    console.error(`Cannot fetch cadastral documents: `, reason);
    dispatch(cadastralDocumentsApiError(true));
  } finally {
    dispatch(cadastralDocumentsLoading(false));
  }
};

export default activityLogSlice.reducer;
