/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const filterSettingsSlice = createSlice({
  name: 'filterSettings',
  initialState: {
    ownRealtiesFilter: [],
    ownApartmentsFilter: [],
    searchedApartmentsFilter: [],
  },
  reducers: {
    // Can "mutate" state thanks to Immer!
    filterSettingsUpdated(slice, action) {
      const { type, parameters } = action.payload;
      slice[type] = parameters;
    },
    filterSettingsCleared(slice, action) {
      const { type } = action.payload;
      slice[type] = [];
    },
  },
});

export const { filterSettingsUpdated, filterSettingsCleared } = filterSettingsSlice.actions;

export default filterSettingsSlice.reducer;
/* eslint-enable no-param-reassign */
