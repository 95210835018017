import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { postFile } from 'common/api/index';
import { t } from 'i18next';
import UploaderContext from './UploaderContext';

const noop = () => null;

const getErrorMessage = errorMessage => (errorMessage ? errorMessage[0] : '');

const appendFormData = ({ file, id, type, formId }) => {
  const data = new FormData();
  data.append('file', file);
  data.append('guid', id);
  data.append('type', type);
  data.append('formId', formId);
  return data;
};

const FileSender = ({
  upload,
  formId,
  url,
  fileTypeErrorText,
  removeUpload,
  children,
  onDone = noop,
  onBeforeSend = noop,
  onUploadError = noop,
  type = '',
}) => {
  const [progress, setProgress] = useState({ loaded: 0, total: 0 });
  const [isError, setIsError] = useState(false);

  const { setErrorMessage } = useContext(UploaderContext);

  const handleDone = (data, statusParam, request) => {
    const filename = upload.file.name;
    const responseFilename = data.filename;
    const attachmentType = type;
    const mimetype = upload.file.type;

    onDone({
      responseFilename,
      filename,
      attachmentType,
      mimetype,
      statusParam,
      xhr: request,
      id: upload.id,
    });
  };

  const handleError = request => {
    const errorMessage = request.response ? getErrorMessage(JSON.parse(request.response).globalErrors) : '';
    if (errorMessage === 'file type not supported') {
      setErrorMessage(t(fileTypeErrorText));
    } else if (errorMessage === 'file is infected') {
      setErrorMessage(t('uploader.fileInfectedError'));
    } else {
      setErrorMessage(t('uploader.defaultUploadError'));
    }
    setIsError(true);
    onUploadError(upload.id);
  };

  const handleProgress = throttle(newProgress => {
    setProgress({ loaded: newProgress.loaded, total: newProgress.total });
  }, 200);

  const handleBeforeSend = (request, settings) => {
    request.upload.addEventListener('progress', handleProgress);
    onBeforeSend(request, settings, upload);
  };

  const handleFileUpload = () => {
    setErrorMessage(null);
    const data = appendFormData({ file: upload.file, id: upload.id, type, formId });
    postFile({ data, url, beforeSend: handleBeforeSend })
      .done(handleDone)
      .fail(handleError)
      .always(() => removeUpload(upload));
  };

  useEffect(() => {
    handleFileUpload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children(progress.loaded, progress.total, isError);
};

FileSender.propTypes = {
  upload: PropTypes.shape({
    id: PropTypes.string.isRequired,
    file: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  url: PropTypes.string.isRequired,
  onBeforeSend: PropTypes.func,
  onUploadError: PropTypes.func,
  onDone: PropTypes.func,
  removeUpload: PropTypes.func.isRequired,
  fileTypeErrorText: PropTypes.string.isRequired,
  formId: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default FileSender;
