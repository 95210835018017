import { isEmpty } from 'lodash';

// When/if one day in future ASREK data contains also  shareGroupName string sources, these sorting methods can be simplified.
// Ticket in ASREK backlog: https://jira.nls.fi/browse/ASREK-8403

const findLeadingString = value => value && value.match(/^[^\d]+/) && value.match(/^[^\d]+/)[0];

const findNumberInString = value => value && value.match(/\d+/) && Number(value.match(/\d+/)[0]);

const compareValues = (a, b) => {
  if (a === b) {
    return 0;
  }
  return a > b ? 1 : -1;
};

const shareGroupNameComparison = (a, b) => {
  const aLeadStr = findLeadingString(a.shareGroupName);
  const bLeadStr = findLeadingString(b.shareGroupName);
  const aNum = findNumberInString(a.shareGroupName);
  const bNum = findNumberInString(b.shareGroupName);

  if (isEmpty(a.shareGroupName) && isEmpty(b.shareGroupName)) {
    return 0;
  }
  if (isEmpty(b.shareGroupName)) {
    return 1;
  }
  if (isEmpty(a.shareGroupName)) {
    return -1;
  }
  if (aLeadStr && bLeadStr && aNum && bNum) {
    if (aLeadStr === bLeadStr) {
      return compareValues(aNum, bNum);
    }
    return compareValues(aLeadStr, bLeadStr);
  }
  if (aLeadStr && bLeadStr) {
    return compareValues(aLeadStr, bLeadStr);
  }
  if (aNum && bNum) {
    return compareValues(aNum, bNum);
  }
  return compareValues(a, b);
};

export default shareGroupNameComparison;
