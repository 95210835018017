import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import TimelineItemTypes from './TimelineItemTypes';
import TimelineIcon from './TimelineIcon';
import { Heading } from '../index';

const getClassName = type => {
  switch (type) {
    case TimelineItemTypes.Past:
      return 'nls-timeline-item--past';
    case TimelineItemTypes.Present:
      return 'nls-timeline-item--present';
    case TimelineItemTypes.Future:
      return 'nls-timeline-item--future';
    case TimelineItemTypes.Cancelled:
      return 'nls-timeline-item--cancelled';
    default:
      return '';
  }
};

const TimelineItem = ({ type = TimelineItemTypes.Past, title = null, children = null }) => (
  <div className={cls('nls-timeline-item', getClassName(type))}>
    <div>
      <dt className="nls-timeline-icon-container">
        <TimelineIcon type={type} />
      </dt>
      <dd className="nls-timeline-text-container">
        <section>
          {title && <Heading level="h4">{title}</Heading>}
          {children}
        </section>
      </dd>
    </div>
  </div>
);

TimelineItem.propTypes = {
  type: PropTypes.oneOf([
    TimelineItemTypes.Past,
    TimelineItemTypes.Present,
    TimelineItemTypes.Future,
    TimelineItemTypes.Cancelled,
  ]),
  title: PropTypes.string,
  children: PropTypes.node,
};

export default TimelineItem;
