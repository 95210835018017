/**
 * Checks whether Playwright is running.
 */
const isPlaywrightDriven = () => navigator.userAgent.includes('Playwright');

/**
 * Gets clipboard contents.
 */
const getClipboardContents = () => navigator.clipboard;

export { isPlaywrightDriven, getClipboardContents };
