import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

const ScrollerPanelContent = ({ children = null, className = '', panelWithLink = false }) => {
  return (
    <div className={cls('panel-list__wrapper', { 'scroller-panel__content': !panelWithLink }, className)}>
      {children}
    </div>
  );
};

ScrollerPanelContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  panelWithLink: PropTypes.bool,
};

export default ScrollerPanelContent;
