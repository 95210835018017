import React from 'react';
import PropTypes from 'prop-types';
import { uniq, uniqueId } from 'lodash';
import { t } from 'i18next';
import cls from 'classnames';

import RemovableTargetTag from 'common/containers/Forms/Components/RemovableTargetTag';
import { LRA_FORM_ID, OSRA_FORM_ID, WITH_ACQUISITION_SHARE_RETURN } from 'realEstateSite/constants/FormIds';
import { priceInEuro, formatTotalPrice } from 'common/helpers/moneyHelpers';
import UnorderedList, { UnorderedItem } from 'common/components/UnorderedList';
import { InlineCollapsible } from '../index';
import AmountSelector from '../AmountSelector';
import Ruler from '../Ruler';
import { Block, Row } from '../Grid';
import { isLaser5pProduct } from '../../containers/ShoppingCart/Checkout/CheckoutHelpers';

const ShoppingCartPriceComponent = ({ price }) =>
  price.discountPrice ? (
    <span>
      <span className="shopping-cart__invalid-price-text">{priceInEuro(price.unitPrice)}</span>
      <span className="indent-block-2">{price.discount}</span>
      <b className="indent-block-2">{priceInEuro(price.discountPrice)}</b>
    </span>
  ) : (
    <b>{priceInEuro(price.unitPrice)}</b>
  );

const pricePropType = PropTypes.shape({
  unitPrice: PropTypes.number.isRequired,
  totalPrice: PropTypes.number,
  discountPrice: PropTypes.number,
  discount: PropTypes.string,
});

ShoppingCartPriceComponent.propTypes = {
  price: pricePropType.isRequired,
};

const CollapsibleMapSheets = ({ item }) => {
  const { mapSheets, fileFormat, size } = item;
  return (
    <>
      {t('shoppingCart.checkout.mapSheets.heading', {
        amount: mapSheets.length,
        fileFormat,
      })}
      <br />
      {size}
      <InlineCollapsible
        headingClosed={t('shoppingCart.checkout.mapSheets.button.closed')}
        headingOpen={t('shoppingCart.checkout.mapSheets.button.open')}
      >
        {mapSheets.map((mapsheet, index) => (
          <span key={uniqueId(mapsheet)}>
            {index ? ', ' : ''} {mapsheet}
          </span>
        ))}
      </InlineCollapsible>
    </>
  );
};

CollapsibleMapSheets.propTypes = {
  item: PropTypes.shape({
    fileFormat: PropTypes.string,
    size: PropTypes.string,
    mapSheets: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const ShoppingCartItemRow = ({
  remove = () => null,
  increase = () => null,
  decrease = () => null,
  amount = undefined,
  summary = false,
  item,
  price,
}) => {
  const collapseMapSheets = item.mapSheets && item.mapSheets.length > 1;
  return (
    <>
      <div className="shopping-cart__item-row" data-testid="ShoppingCartItemRow">
        <Block size={6} className="margin-b-1">
          <RemovableTargetTag
            targetIdentifier={item.name}
            onClick={remove}
            isRemovable={!summary && item.applicationType !== WITH_ACQUISITION_SHARE_RETURN}
            className="absolute top-right"
          >
            <div className={cls('description-container', { 'no-button': summary })}>
              <strong>{item.name}</strong>
              {collapseMapSheets && <CollapsibleMapSheets item={item} />}
              {!collapseMapSheets &&
                item?.description?.length &&
                (Array.isArray(item.description) ? (
                  <>
                    {item.description.map((desc, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <span key={i} className="description">
                        {desc}
                      </span>
                    ))}
                  </>
                ) : (
                  <span className="description">{item.description}</span>
                ))}
              {item.customMapName && <span className="description">{item.customMapName}</span>}
            </div>
            {item.realEstateIdentifications && (
              <div className="id-container">
                {item.realEstateIdentifications &&
                  item.realEstateIdentifications.map(realEstateIdentification => (
                    <div key={realEstateIdentification}>
                      <span>{realEstateIdentification}</span>
                    </div>
                  ))}
              </div>
            )}
            <div className="price-container">
              {item.language && <span>{item.language}</span>}
              {amount && !item.realEstateIdentifications && !item.apartmentIdentification && (
                <>
                  {summary ? (
                    <div>
                      {amount} {t('shoppingCart.checkout.summary.product.amount')}
                    </div>
                  ) : (
                    <AmountSelector {...{ amount, increase, decrease }} disabled={amount <= 1} />
                  )}
                  <ShoppingCartPriceComponent price={price} />
                </>
              )}
              {!amount && price.discountPrice && <ShoppingCartPriceComponent price={price} />}
              {item.applicationType !== LRA_FORM_ID ||
                item.applicationType !== OSRA_FORM_ID ||
                (item.realEstateIdentifications?.length > 1 && (
                  <div className="float-left">
                    <b>
                      {formatTotalPrice({
                        itemCount: uniq(item.realEstateIdentifications).length,
                        price: price.totalPrice / (uniq(item.realEstateIdentifications).length || 1),
                        vat: 0,
                        excludeTotalIfManyItems: true,
                        excludeVAT: true,
                      })}
                    </b>
                  </div>
                ))}
              {item.contentSettings && (
                <InlineCollapsible
                  headingClosed={t('fileDownload.maps.details')}
                  headingOpen={t('fileDownload.maps.details')}
                >
                  <UnorderedList className="map-details-list">
                    {item.contentSettings.map(setting => {
                      return <UnorderedItem key={uniqueId(setting)}>{t(`fileDownload.maps.${setting}`)}</UnorderedItem>;
                    })}
                  </UnorderedList>
                </InlineCollapsible>
              )}
              <div className="float-right">
                <b className="shopping-cart__price-text">
                  {isLaser5pProduct(item.name) ? '' : priceInEuro(price.totalPrice || price.unitPrice)}
                </b>
              </div>
            </div>
          </RemovableTargetTag>
        </Block>
      </div>
      <Row className="margin-t-1">
        <Block size={6}>
          <Ruler />
        </Block>
      </Row>
    </>
  );
};

ShoppingCartItemRow.propTypes = {
  remove: PropTypes.func,
  increase: PropTypes.func,
  decrease: PropTypes.func,
  item: PropTypes.shape({
    name: PropTypes.string,
    fileFormat: PropTypes.string,
    size: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    mapSheets: PropTypes.arrayOf(PropTypes.string),
    language: PropTypes.string,
    realEstateIdentifications: PropTypes.arrayOf(PropTypes.string),
    apartmentIdentification: PropTypes.string,
    applicationType: PropTypes.string,
    contentSettings: PropTypes.arrayOf(PropTypes.string),
    customMapName: PropTypes.string,
  }).isRequired,
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  price: pricePropType.isRequired,
  summary: PropTypes.bool,
};

const ShoppingCartHeaderRow = () => (
  <div className="shopping-cart__main-row margin-t-1 bold" data-testid="ShoppingCartHeaderRow">
    <Block size={6}>{t('shoppingCart.items.label.products')}</Block>
  </div>
);

const ShoppingCartTotalRow = ({ price, discountPrice = undefined }) => (
  <div className="shopping-cart__total-row" data-testid="ShoppingCartTotalRow">
    <Block size={6}>
      <b>{t('shoppingCart.items.label.total')}</b>
      <div className="float-right">
        {discountPrice ? (
          <span>
            <span className="shopping-cart__invalid-price-text">{priceInEuro(price)}</span>
            <b className="shopping-cart__total-price-text indent-block-4">{priceInEuro(discountPrice)}</b>
          </span>
        ) : (
          <b className="shopping-cart__total-price-text">{priceInEuro(price)}</b>
        )}
      </div>
    </Block>
  </div>
);

ShoppingCartTotalRow.propTypes = {
  price: PropTypes.number.isRequired,
  discountPrice: PropTypes.number,
};

export { ShoppingCartItemRow, ShoppingCartTotalRow, ShoppingCartHeaderRow, ShoppingCartPriceComponent };
