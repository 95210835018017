import { isEmpty, isEqual } from 'lodash';

import { toggleMapLayerVisibilityThunk } from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import { layersOnMap } from 'oskari/oskariModules/ServicesMapModule/index';
import { isDesktop } from 'common/constants/Layout';
import { FEATURE_TYPES, GEOMETRY_TYPES } from '../constants/MapConstants';
import * as ActionTypes from './geometriesActionTypes';
import * as OskariMap from '../../oskari/OskariMap';
import {
  LAYER_ADDRESSES,
  LAYER_BOUNDARY_MARKERS,
  LAYER_CUSTOM_MARKERS,
  LAYER_MEASUREMENTS,
  LAYER_PARCELS,
  LAYER_PLACE_NAMES,
  LAYER_REAL_ESTATE_IDS,
  LAYER_REAL_ESTATE_BORDERS,
} from '../../oskari/layers/VectorLayers';

const { MARKER, GUIDE, GEOJSON } = GEOMETRY_TYPES;
const { REAL_ESTATES, BOUNDARY_MARKERS, APARTMENTS } = ActionTypes.REAL_ESTATE_SITE_FEATURE_TYPES;
const { DISTANCE_MEASUREMENT, AREA_MEASUREMENT, ADDRESS_MARKER, CUSTOM_MARKER, SEARCH_MARKER } = FEATURE_TYPES;

const drawMarkerFeature = ({ id, lat, lon, name, priority, marker }, guide = {}) => {
  OskariMap.markerWithSvg(lon, lat, id, marker, guide, priority, name);
};

const removeFeatureFromMap = feature => {
  if (feature?.featureType === DISTANCE_MEASUREMENT || feature?.featureType === AREA_MEASUREMENT) {
    return;
  }

  if (feature?.geometryType === MARKER) OskariMap.removeMarkerFeature(feature.id, feature.marker.layer);
  if (feature?.geometryType === GUIDE) OskariMap.removeGuideFeature(feature.id, feature.layer);
  if (feature?.geometryType === GEOJSON) OskariMap.removeFeature(feature.id, feature.layer);
};

const forEachFeature = (getState, featureType, callback) =>
  getState()
    .geometries.features.filter(feature => feature.featureType === featureType)
    .forEach(callback);

export const removeFeaturesByType = featureType => ({ type: ActionTypes.REMOVE_FEATURES_BY_TYPE, featureType });

const setLayerVisibility = (layer, isVisible) => ({ type: ActionTypes.SET_LAYER_VISIBILITY, layer, isVisible });

export const reloadMarker = (geometry, guide) => drawMarkerFeature(geometry, guide);

export const addFeature = feature => ({ type: ActionTypes.ADD_FEATURE, feature });

export const removeFeature = id => ({ type: ActionTypes.REMOVE_FEATURE, id });

export const setInfobox = (infoBox, featureType) => ({ type: ActionTypes.SET_INFOBOX, infoBox, featureType });

export const removeSearchFeatureThunk = id => (dispatch, getState) => {
  const feature = getState().geometries.features.filter(geom => geom.name === id);
  feature.forEach(f => {
    removeFeatureFromMap(f);
    dispatch(removeFeature(f.id));
  });
};

/**
 * Removes feature from map
 * @param id Id of the feature to remove.
 */
export const removeFeatureThunk = id => (dispatch, getState) => {
  const feature = getState().geometries.features.find(geom => geom.id === id);
  dispatch(removeFeature(id));
  removeFeatureFromMap(feature);
};

/**
 * Draw marker feature to map.
 *
 * If feature with same id exists on map it will be replaced.
 *
 * @param featureToAdd
 * @param marker
 * @param {number} priority
 */
export const addMarkerFeature = (featureToAdd, marker, priority, guide = {}) => (dispatch, getState) => {
  const feature = { ...featureToAdd, priority, marker, geometryType: MARKER };

  if (!feature.featureType) {
    console.warn('Feature should have featureType property', feature);
  }

  // If feature with same id exists, remove old feature
  const oldFeature = getState().geometries.features.find(geom => geom.id === feature.id);
  if (oldFeature) dispatch(removeFeatureThunk(oldFeature.id));
  dispatch(addFeature(feature));

  // If guide with same id exists, remove old guide
  if (!isEmpty(guide)) {
    const oldGuide = getState().geometries.features.find(geom => geom.id === `guide-${feature.id}`);
    if (oldGuide) dispatch(removeFeatureThunk(oldGuide.id));
    dispatch(addFeature(guide));
  }

  drawMarkerFeature(feature, guide);
};

export const removeAllFeaturesThunk = () => (dispatch, getState) => {
  getState().geometries.features.forEach(removeFeatureFromMap);
  dispatch({ type: ActionTypes.REMOVE_ALL_FEATURES });
};

export const removeFeaturesByTypeThunk = featureType => (dispatch, getState) => {
  forEachFeature(getState, featureType, removeFeatureFromMap);
  dispatch(removeFeaturesByType(featureType));
};

export const addInfoBoxThunk = (infobox, type) => (dispatch, getState) => {
  dispatch(setInfobox(infobox.id, type));
  OskariMap.infoBox(infobox, isDesktop(getState().layout.mode));
};

const getInfoBoxIdsByType = (features, featureType) =>
  features.filter(feature => feature.featureType === featureType).map(feature => `${feature.id}_infobox`);

const hideInfoBoxesForLayer = (getState, layer) => {
  const { features } = getState().geometries;

  if (layer === LAYER_PLACE_NAMES) OskariMap.hideInfobox(getInfoBoxIdsByType(features, FEATURE_TYPES.SEARCH_MARKER));

  if (layer === LAYER_ADDRESSES) OskariMap.hideInfobox(getInfoBoxIdsByType(features, FEATURE_TYPES.ADDRESS_MARKER));
  if (layer === LAYER_CUSTOM_MARKERS) OskariMap.hideInfobox(getInfoBoxIdsByType(features, FEATURE_TYPES.CUSTOM_MARKER));
};

export const setLayerVisibilityThunk = (layer, isVisible) => (dispatch, getState) => {
  dispatch(setLayerVisibility(layer, isVisible));
  hideInfoBoxesForLayer(getState, layer);
  OskariMap.changeMapLayerVisibility(layer, isVisible);
};

export const setRealEstateLayerVisibilities = isVisible => dispatch => {
  dispatch(setLayerVisibilityThunk(LAYER_REAL_ESTATE_IDS, isVisible));
  dispatch(setLayerVisibilityThunk(LAYER_PARCELS, isVisible));
  dispatch(setLayerVisibilityThunk(LAYER_BOUNDARY_MARKERS, isVisible));
  dispatch(setLayerVisibilityThunk(LAYER_REAL_ESTATE_BORDERS, isVisible));
};

export const setMeasurementsLayerVisibility = isVisible => dispatch => {
  dispatch(setLayerVisibility(LAYER_MEASUREMENTS, isVisible));
};

const setRealEstateSiteFeaturesRemoved = (featureType, isRemoved) => ({
  type: ActionTypes.SET_REAL_ESTATE_SITE_FEATURES_REMOVED,
  featureType,
  isRemoved,
});

/**
 * @param {FeatureType} featureType
 */
export const removeRealEstateSiteFeaturesThunk = featureType => dispatch => {
  dispatch(setRealEstateSiteFeaturesRemoved(featureType, true));

  if (featureType === APARTMENTS) OskariMap.clearMapApartmentsLayer();
  if (featureType === BOUNDARY_MARKERS) OskariMap.clearMapBoundaryMarkersLayer();
  if (featureType === REAL_ESTATES) OskariMap.clearMapRealEstates();
};

export const resetRealEstateSiteFeaturesRemoved = () => ({ type: ActionTypes.RESET_REAL_ESTATE_SITE_FEATURES_REMOVED });

export const removeAllNonLaturiGeometriesFromMapThunk = () => dispatch => {
  const removables = {};
  const nonLaturiFeatures = [ADDRESS_MARKER, CUSTOM_MARKER, SEARCH_MARKER];

  nonLaturiFeatures.forEach(featureType => dispatch(removeFeaturesByTypeThunk(featureType)));
  OskariMap.hideAllInfoboxes();
  OskariMap.removeAllNonLaturiMarkers(removables);
};

/**
 * Stores drawn feature.
 * @param {string} id
 * @param {FeatureType} featureType
 * @param {Object} geoJson
 */
export const addDrawnFeature = (id, featureType, geoJson) => (dispatch, getState) => {
  const feature = { id, featureType, geoJson, geometryType: GEOJSON };

  // If different feature with same id exists, remove old feature
  const oldFeature = getState().geometries.features.find(geom => geom.id === feature.id);

  if (oldFeature && isEqual(oldFeature, feature)) {
    return;
  }

  if (oldFeature) dispatch(removeFeatureThunk(oldFeature.id));

  dispatch(addFeature(feature));
};

/**
 * Draw GeoJSON to map.
 * @param {string} id
 * @param {FeatureType} featureType
 * @param {object} geoJson
 * @param {DrawGeoJSONOptions} options
 */
export const addGeoJsonFeature = (id, featureType, geoJson, options = {}, setLayerVisible = true) => (
  dispatch,
  getState
) => {
  const feature = { id, featureType, geoJson, layer: options.layer || id, geometryType: GEOJSON };

  // skip this step for laser5pMapSheetAreas as it comes in several parts
  if (id !== 'laser5pMapSheetAreas') {
    // If feature with same id exists, remove old feature
    const oldFeature = getState().geometries.features.find(geom => geom.id === feature.id);
    if (oldFeature) dispatch(removeFeatureThunk(oldFeature.id));
  }

  dispatch(addFeature(feature));
  OskariMap.drawGeoJson(id, geoJson, options);
  if (setLayerVisible) {
    dispatch(toggleMapLayerVisibilityThunk(feature.layer, true));
  }
};

export const getFeaturesByLayer = async layerId => {
  const featuresByLayers = await layersOnMap(true);
  return featuresByLayers[layerId] || {};
};

export const displayFeatureOnMap = (id, featureType, geoJson, options = {}) => async dispatch => {
  const feature = { id, featureType, geoJson, layer: options.layer || id, geometryType: GEOJSON };

  getFeaturesByLayer(feature.layer).then(data => {
    if (!data.features || data.features.length === 0) {
      dispatch(addFeature(feature));
      OskariMap.drawGeoJson(id, geoJson, options);
    }
  });
  dispatch(toggleMapLayerVisibilityThunk(feature.layer, true));
};

/**
 * Removes all features with given feature type from the store
 * and clears map layer with given layer id.
 *
 * This can be used if all features with the same type are drawn to same Oskari layer.
 * @param {FeatureType} featureType
 * @param {String|Number} layerId
 */
export const removeFeaturesByTypeAndLayerThunk = (featureType, layerId) => dispatch => {
  dispatch(removeFeaturesByType(featureType));
  OskariMap.clearLayer(layerId);
};
