import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';

import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';
import { Row, Block } from '../Grid';
import Ruler from '../Ruler';
import { Heading } from '../index';

const Header = ({ text, level = 'h1', children = undefined, className = '' }) => {
  const titleRef = useRef(null);

  const focusHandler = () => titleRef?.current?.focus();

  useAutomaticKeyboardFocus(className, focusHandler, { clearFocusRequest: true });

  return (
    <Heading level={level} visualLevel="h1" className={cls('header-title', 'margin-b-1', className)}>
      <span className="header-title__text margin-r-auto" ref={titleRef} tabIndex="-1">
        {text}
      </span>
      {children && <span className="step__indicator">{children}</span>}
    </Heading>
  );
};

Header.propTypes = {
  level: PropTypes.string,
  children: PropTypes.node,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const Subtitle = ({ text = undefined, className = '' }) => {
  if (text) {
    return <h3 className={cls(className)}>{text}</h3>;
  }
  return null;
};

Subtitle.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

const MainHeader = ({ text, level = 'h1', className = '', headerClassName = '', children = null }) => (
  <Row className={className}>
    <Block size="6">
      <Header text={text} level={level} className={headerClassName}>
        {children}
      </Header>
      <Ruler large blue />
    </Block>
  </Row>
);

MainHeader.propTypes = {
  text: PropTypes.string.isRequired,
  level: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  children: PropTypes.node,
};

export default Header;
export { MainHeader, Subtitle };
