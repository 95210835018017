import ServicesApi from 'realEstateSite/api/ServicesApi';
import { addGeoJsonFeature, removeFeaturesByTypeAndLayerThunk } from 'common/geometries/geometriesActions';
import { FEATURE_TYPES } from 'common/constants/MapConstants';
import * as OskariMap from 'oskari/OskariMap';
import { t } from 'i18next';
import { addInfoNotificationAction } from 'common/containers/AppNotifications/AppNotificationsActions';
import { accessibleNotificationAdded } from 'common/containers/AccessibleNotifications/accessibleNotificationsSlice';
import { ASSERTIVE } from 'common/containers/AccessibleNotifications/AccessibleNotificationsConstants';
import { isEmpty } from 'lodash';
import { toggleMapLayerVisibilityThunk } from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import { setRegisterState } from 'realEstateSite/containers/Realty/RealEstate/RealEstateList/realEstateListSlice';
import { LAYER_PARCELS, LAYER_USUFRUCTS } from '../../../../../../oskari/layers/VectorLayers';
import {
  LINE_STYLE,
  POINT_STYLE,
  POLYGON_STYLE,
  UNLEGALIZED_LINE_STYLE,
  UNLEGALIZED_POINT_STYLE,
  UNLEGALIZED_POLYGON_STYLE,
} from './UsufructUnitConstans';
import { formatFeatures } from './UsufructUnitHelpers';

const { USUFRUCT_UNIT } = FEATURE_TYPES;

export const STORE_USUFRUCT_UNITS = 'STORE_USUFRUCT_UNITS';
export const SET_USUFRUCT_UNITS_LOADING = 'SET_USUFRUCT_UNITS_LOADING';
export const SET_USUFRUCT_UNITS_API_ERROR = 'SET_USUFRUCT_UNITS_API_ERROR';
export const SET_USUFRUCT_UNIT_GEOMETRIES_READY = 'SET_USUFRUCT_UNIT_GEOMETRIES_READY';
export const ADD_CONTACT_PERSON_INFO_TO_USUFRUCT_UNIT = 'ADD_CONTACT_PERSON_INFO_TO_USUFRUCT_UNIT';

export const storeUsufructUnits = (usufructUnits, realtyId) => ({
  type: STORE_USUFRUCT_UNITS,
  usufructUnits,
  realtyId,
});

export const addContactPersonInfoToUsufructUnit = (id, hasContactPersonWithAddress) => ({
  type: ADD_CONTACT_PERSON_INFO_TO_USUFRUCT_UNIT,
  id,
  hasContactPersonWithAddress,
});

export const setUsufructUnitsLoading = isLoading => ({ type: SET_USUFRUCT_UNITS_LOADING, isLoading });

export const setUsufructUnitApiError = apiError => ({ type: SET_USUFRUCT_UNITS_API_ERROR, apiError });

export const setUsufructUnitGeometriesReady = isReady => ({ type: SET_USUFRUCT_UNIT_GEOMETRIES_READY, isReady });

export const fetchUsufructUnitsThunk = (realtyId, registerStateDate) => async dispatch => {
  dispatch(setUsufructUnitsLoading(true));
  dispatch(setUsufructUnitApiError(false));
  try {
    const usufructUnits = await ServicesApi.fetchUsufructUnits(realtyId);
    !isEmpty(usufructUnits) && dispatch(setRegisterState(usufructUnits[0].registerStateDate, registerStateDate));
    dispatch(storeUsufructUnits(usufructUnits, realtyId));
  } catch (error) {
    console.error(error);
    dispatch(setUsufructUnitApiError(true));
    dispatch(addInfoNotificationAction(t('app.general.error')));
    dispatch(accessibleNotificationAdded({ text: t('app.general.error'), type: ASSERTIVE }));
  } finally {
    dispatch(setUsufructUnitsLoading(false));
  }
};

export const toFeatureCollection = featureArray => {
  const features = formatFeatures(featureArray);
  return {
    type: 'FeatureCollection',
    features,
  };
};

export const zoomToUsufructs = async () => {
  await OskariMap.mapIsReady();
  OskariMap.zoomToFeatures([], [LAYER_USUFRUCTS, LAYER_PARCELS]);
};

export const zoomToUsufruct = async ids => {
  await OskariMap.mapIsReady();
  OskariMap.zoomToFeatures(ids, [LAYER_USUFRUCTS]);
};

export const zoomToUsufructLayer = async () => zoomToUsufruct();

const POINT_LAYER_OPTIONS = {
  featureStyle: POINT_STYLE,
  layer: LAYER_USUFRUCTS,
  prio: 1,
  optionalStyles: UNLEGALIZED_POINT_STYLE,
};
const LINE_LAYER_OPTIONS = {
  featureStyle: LINE_STYLE,
  layer: LAYER_USUFRUCTS,
  prio: 2,
  optionalStyles: UNLEGALIZED_LINE_STYLE,
};
const POLYGON_LAYER_OPTIONS = {
  featureStyle: POLYGON_STYLE,
  layer: LAYER_USUFRUCTS,
  prio: 3,
  optionalStyles: UNLEGALIZED_POLYGON_STYLE,
};

export const LAYER_OPTIONS = {
  Point: POINT_LAYER_OPTIONS,
  LineString: LINE_LAYER_OPTIONS,
  Polygon: POLYGON_LAYER_OPTIONS,
};

/**
 * Add usufruct unit geometries to map
 * @param {Array} geometries
 * @param {Boolean} zoom
 */
export const addGeometriesToMapThunk = geometries => (dispatch, getState) => {
  const idsOnMap = getState().geometries.features.map(({ id }) => id);
  const targetLayers = [];
  geometries
    .flatMap(collection => collection.features)
    .filter(feature => !idsOnMap.includes(feature.id))
    .forEach(feature => {
      if (!targetLayers.includes(feature.layer)) {
        targetLayers.push(feature.layer);
      }

      dispatch(
        addGeoJsonFeature(
          feature.id,
          USUFRUCT_UNIT,
          toFeatureCollection([feature]),
          LAYER_OPTIONS[feature.geometry.type],
          false
        )
      );
    });
  targetLayers.forEach(layer => dispatch(toggleMapLayerVisibilityThunk(layer, true)));
  dispatch(setUsufructUnitGeometriesReady(true));
};

export const removeUsufructFeaturesThunk = () => dispatch => {
  dispatch(removeFeaturesByTypeAndLayerThunk(FEATURE_TYPES.USUFRUCT_UNIT, LAYER_USUFRUCTS));
};
