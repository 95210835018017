import { useEffect, useState } from 'react';

const useClickOutside = () => {
  const [target, setTarget] = useState(null);

  const handleClickOutside = event => setTarget(event.target);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return { target };
};

export default useClickOutside;
