import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { KARTTAPAIKKA_LANGUAGES, ASIOINTI_LANGUAGES } from 'common/components/UserMenu/LanguageSelector';

const usePrevLang = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const prevLang = localStorage.getItem('prevLang');

  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const languages = isMapSite ? KARTTAPAIKKA_LANGUAGES : ASIOINTI_LANGUAGES;

  useEffect(() => {
    const currentLang = language;
    return () => {
      if (prevLang !== currentLang) {
        if (!prevLang) {
          localStorage.setItem(
            'prevLang',
            languages.find(lang => lang !== currentLang)
          );
        } else {
          localStorage.setItem('prevLang', currentLang);
        }
      }
    };
  }, [language, prevLang, languages]);

  return { prevLang };
};

export default usePrevLang;
