import {
  disableBaseMapLayerThunk,
  enableAllBaseMapLayers,
  disableAllLayers,
  setLayerDefaultVisibilitiesThunk,
  toggleMapLayerVisibilityThunk,
  cleanMapThunk,
} from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import { enableSnapshotArea } from 'common/containers/SnapshotPreviewArea/SnapshotPreviewAreaActions';
import { getOskariLayers, mapIsReady } from 'oskari/OskariMap';
import disableAllMapToolsThunk from 'common/containers/MapToolbar/DisableMapToolActions';
import { switchAllMapToolsThunk } from 'common/containers/MapToolbar/MapToolbarActions';
import { CHANGE_APP_MODE, CHANGE_START_PHASE } from './KasiAppModeActionTypes';
import * as Modes from './KasiAppModes';
import { LAYER_MAP_SHEETS } from '../../../oskari/layers/VectorLayerIds';

export function changeToPhase(startPhase) {
  return {
    type: CHANGE_START_PHASE,
    startPhase,
  };
}

export function changeToPrerenderReadyPhase() {
  return changeToPhase(Modes.START_PHASE_PRERENDER_READY);
}
export function changeToFirstRenderReadyPhase() {
  return changeToPhase(Modes.START_PHASE_FIRST_RENDER_READY);
}
export function changeToChannelReadyPhase() {
  return changeToPhase(Modes.START_PHASE_CHANNEL_READY);
}
export function changeToPostRenderReadyPhase() {
  return changeToPhase(Modes.START_PHASE_POST_RENDER_READY);
}
export function changeToUiVisiblePhase() {
  return changeToPhase(Modes.START_PHASE_UI_VISIBLE);
}
export function changeToInitPhase() {
  return changeToPhase(Modes.START_PHASE_INIT);
}

export function changeToBgTasksReadyPhase() {
  return changeToPhase(Modes.START_PHASE_BG_TASKS_READY);
}

export function changeToCustomMapsMode() {
  return {
    type: CHANGE_APP_MODE,
    mode: Modes.APP_MODE_CUSTOM_MAPS,
    layers: Modes.APP_MODE_CUSTOM_MAPS_LAYERS,
  };
}

export function changeToNormalMode() {
  return {
    type: CHANGE_APP_MODE,
    mode: Modes.APP_MODE_NORMAL,
  };
}

export function setMapLayerInitialVisibilitiesThunk() {
  return dispatch => dispatch(setLayerDefaultVisibilitiesThunk());
}

const revertToNormalMode = () => dispatch => {
  dispatch(toggleMapLayerVisibilityThunk(LAYER_MAP_SHEETS, false));
  dispatch(enableAllBaseMapLayers());
  dispatch(setMapLayerInitialVisibilitiesThunk());
  dispatch(disableAllMapToolsThunk(false));
};

export const changeToNormalModeThunk = () => dispatch => {
  dispatch(cleanMapThunk());
  dispatch(enableSnapshotArea(false));
  dispatch(revertToNormalMode());
  dispatch(changeToNormalMode());
};

const changeToMapFocusMode = () => dispatch => {
  dispatch(setLayerDefaultVisibilitiesThunk());
  dispatch(disableAllLayers());
  dispatch(switchAllMapToolsThunk(false));
  dispatch(disableAllMapToolsThunk(true));
  dispatch(toggleMapLayerVisibilityThunk(LAYER_MAP_SHEETS, true));
};

const disableBaseMapLayers = (dispatch, getState) => {
  const layers = getOskariLayers(getState);

  dispatch(disableBaseMapLayerThunk(layers.maastokartta, true));
  dispatch(disableBaseMapLayerThunk(layers.kiinteistojaotus, true));
  dispatch(disableBaseMapLayerThunk(layers.kiinteistotunnukset, true));
};

export const changeToCustomMapsModeThunk = () => async dispatch => {
  await mapIsReady();
  dispatch(enableSnapshotArea(true));
  dispatch(changeToMapFocusMode());
  dispatch(disableBaseMapLayers);
  dispatch(changeToCustomMapsMode());
};

export function resetCustomMapsToNormalModeThunk() {
  return async (dispatch, getState) => {
    await mapIsReady();
    if (getState().appMode.mode === Modes.APP_MODE_CUSTOM_MAPS) dispatch(changeToNormalModeThunk());
  };
}
