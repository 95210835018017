import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getCustomMapsSheetsThunk } from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import * as Routes from 'common/constants/Routes';
import { ExternalLink, Paragraph } from 'common/components';
import { YOUR_MAP_TITLE_1 } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';
import RouterLink from '../Link/RouterLink';

const MapStoreColumn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const sheets = useSelector(state => state.mapStore.sheets || [], shallowEqual);

  useEffect(() => {
    if (sheets.length === 0) dispatch(getCustomMapsSheetsThunk(sheets.length));
  }, [dispatch, sheets.length]);

  return (
    <>
      <Paragraph>{t('mapStoreIndex.customMapsDesc')}</Paragraph>
      <Paragraph>
        <ExternalLink href={t('customMapsForm.prices.href')} text={t('customMapsForm.prices.title')} />
        <br />
        <ExternalLink href={t('customMapsForm.examples.href')} text={t('customMapsForm.examples.title')} />
      </Paragraph>
      <RouterLink
        className="button button--blue bold margin-b-2"
        to={Routes.getRoute(Routes.MAP_STORE_CUSTOM_MAPS)}
        selector={YOUR_MAP_TITLE_1}
      >
        {t('mapStoreIndex.customMaps.linkText')}
      </RouterLink>
    </>
  );
};

export default MapStoreColumn;
