import React from 'react';
import PropTypes from 'prop-types';

const Calculator = ({ className = '', shownItemsLength = 0, fullListLength = 0, dataTestId = '' }) => {
  return (
    <div className={className} data-testid={dataTestId}>
      <strong className="list-item__calculator">{`${shownItemsLength} / ${fullListLength}`}</strong>
    </div>
  );
};

Calculator.propTypes = {
  className: PropTypes.string,
  shownItemsLength: PropTypes.number,
  fullListLength: PropTypes.number,
  dataTestId: PropTypes.string,
};

export default Calculator;
