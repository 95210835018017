import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getRoute } from 'common/constants/Routes';
import { MainHeader, FlexGrid, Block, Row, ErrorBoundary } from 'common/components';

const MainContentErrorBoundary = ({ isMobile = false, children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // TODO: https://jira.nls.fi/browse/ASI-6907 - reset appropriate state with this.
  const resetState = () => navigate(getRoute());

  return (
    <ErrorBoundary
      resetState={resetState}
      fallbackView={reset => (
        <FlexGrid className="form__container-bg relative">
          <MainHeader text={t('notFound.pageHeader')} className={cls({ 'margin-t-4': isMobile })} />
          <Row>
            <Block size={6} className="notfound-text">
              50<span className="error-fallen">0</span>
            </Block>
          </Row>
          <Row className="margin-t-4">
            <Block>
              <button type="button" className="button button--blue" onClick={reset}>
                {t('usermenu.frontpageButton')}
              </button>
            </Block>
          </Row>
        </FlexGrid>
      )}
    >
      {children}
    </ErrorBoundary>
  );
};

MainContentErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
};

export default MainContentErrorBoundary;
