import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { useTranslation } from 'react-i18next';

import SnapshotScreenLayer from 'common/containers/SnapshotScreenLayer/SnapshotScreenLayerComponent';
import { getConfiguration } from 'common/helpers/Configurations/Configurations';
import { Glyphicon } from 'common/components/Icon';
import { businessPanelToggleAction } from 'common/containers/BusinessPanel/BusinessPanelActions';
import { isMobile } from 'common/constants/Layout';
import { resetCustomMapsToNormalModeThunk } from 'common/containers/KasiAppMode/KasiAppModeActions';
import {
  toggleMapBackButtonVisibility,
  addMapMovedListenerThunk,
  addUserLocationListenerThunk,
  showCrosshairThunk,
  setInitialViewFromLinkThunk,
} from 'common/containers/OskariMap/OskariMapActions';
import {
  destroyChannel,
  init as initMap,
  waitForElement,
  MAP_DOM_ID,
  OSKARI_CHANNEL_STATUS_IDS,
  channelIsReady,
} from 'oskari/OskariMap';
import {
  hideExtraLayersThunk,
  setRealEstateLayerDefaultStyleThunk,
} from 'common/containers/MapLayerSelector/MapLayerSelectorActions';
import { reloadOskariState } from 'common/helpers/AppStarter/Reload';
import { processSharedLinksThunk } from 'common/containers/MapToolbar/CustomMarkerActions';
import { addInfoboxActionListenerThunk } from 'common/containers/MapToolbar/SelectorActions';
import { drawReloadedGeometriesThunk } from 'realEstateSite/containers/Realty/common/geometries/geometriesSlice';
import { ALL, SEARCHED } from 'realEstateSite/containers/Realty/common/geometries/geometriesConstants';
import { SERVICES_USUFRUCTS_AND_RESTRICTIONS, SERVICES_FETCH_REAL_ESTATES } from 'realEstateSite/constants/Routes';
import { ROUTE_SERVICES_SITE_ID, MAP_STORE_INDEX, FEEDBACK } from 'common/constants/Routes';
import usePrevLang from 'common/hooks/usePrevLang';
import { isPlaywrightDriven } from 'utils/navigator-utils';
import AppNotificationsComponent from '../AppNotifications/AppNotificationsComponent';
import { setMeasurementsLayerVisibility } from '../../geometries/geometriesActions';
import { processSearchLinksThunk } from '../SidebarSearch/SearchActions';
import { updateOskariChannelStatus } from '../KasiApp/KasiAppActions';
import OskariMapVeilComponent from './OskariMapVeilComponent';
import useHideInfoBox from './useHideInfoBox';

const INITIAL_LAT = 445171;
const INITIAL_LON = 7196225;
const INITIAL_ZOOM_LEVEL = 1;

const coordsForMap = `?coord=${INITIAL_LAT}_${INITIAL_LON}&zoomLevel=${INITIAL_ZOOM_LEVEL}`;

const generateOskariPath = (basePath, additionalPathParams, publishId, language) =>
  [
    basePath || getConfiguration('oskari.path'),
    additionalPathParams || 'hkp',
    'published',
    language,
    publishId || getConfiguration('oskari.publishId'),
    coordsForMap,
  ].join('/');

const isMapStorePage = () => window.location.href.includes(MAP_STORE_INDEX);

const OskariMapComponent = ({ basePath = null, additionalPathParams = null, publishId = null }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const usufructUnitRealtyId =
    window.location.pathname.includes(SERVICES_USUFRUCTS_AND_RESTRICTIONS) &&
    window.location.pathname.includes(ROUTE_SERVICES_SITE_ID) &&
    window.location.pathname.split('/').filter(Boolean)[2];
  const usufructUnitId =
    window.location.pathname.includes(SERVICES_USUFRUCTS_AND_RESTRICTIONS) &&
    window.location.pathname.includes(ROUTE_SERVICES_SITE_ID) &&
    window.location.pathname.split('/').filter(Boolean)[4];
  const dispatch = useDispatch();
  const businessPanelIsOpen = useSelector(state => state.businessPanel.isOpen);
  const isMobileMode = useSelector(state => isMobile(state.layout.mode));
  const eventsEnabled = useSelector(state => state.oskariIframe.eventsEnabled);
  const isMapBackButtonVisible = useSelector(state => state.oskariIframe.backButtonVisible);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const oskariMap = useRef();
  const isMounted = useRef(false);
  const applicationContext = useSelector(state => state.application.applicationContext);
  const realEstateSiteReload = applicationContext === 'realEstateSite';
  const channelStatus = useSelector(state => state.layout.oskariChannelStatus);
  const channelReady = channelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady;
  const channelNotReady = channelStatus === OSKARI_CHANNEL_STATUS_IDS.channelNotReady;

  const iframeHasLoaded = () => {
    setIsIframeLoaded(true);
  };

  const backButtonClickHandler = () => {
    dispatch(toggleMapBackButtonVisibility(false));
    dispatch(businessPanelToggleAction());
  };

  useEffect(() => {
    if (channelIsReady() && channelReady && !isMounted.current) {
      dispatch(hideExtraLayersThunk());
      dispatch(setMeasurementsLayerVisibility(false));
      dispatch(setRealEstateLayerDefaultStyleThunk());
      if (!isMapStorePage()) dispatch(resetCustomMapsToNormalModeThunk());
    }
  }, [dispatch, channelReady]);

  usePrevLang();

  const InitializeOskariMap = useCallback(async () => {
    try {
      dispatch(updateOskariChannelStatus(OSKARI_CHANNEL_STATUS_IDS.startingChannel));
      await waitForElement(MAP_DOM_ID);
      const oskariChannelStatus = await initMap(applicationContext);
      if (oskariChannelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady) {
        dispatch(reloadOskariState(realEstateSiteReload));
        await Promise.all([
          dispatch(addMapMovedListenerThunk()),
          dispatch(addUserLocationListenerThunk()),
          dispatch(addInfoboxActionListenerThunk()),
          dispatch(showCrosshairThunk()),
        ]);
        // override layer and zoom settings, so it must be last.
        dispatch(processSharedLinksThunk(window.location));
        dispatch(processSearchLinksThunk(window.location));
        dispatch(setInitialViewFromLinkThunk(window.location));
      }
      dispatch(updateOskariChannelStatus(oskariChannelStatus));
      return oskariChannelStatus;
    } catch (e) {
      throw new Error(`post render failed: ${e}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    async function reloadOskariMap() {
      destroyChannel();
      const oskariChannelStatus = await InitializeOskariMap();
      if (
        realEstateSiteReload &&
        oskariChannelStatus === OSKARI_CHANNEL_STATUS_IDS.channelReady &&
        !window.location.pathname.includes(MAP_STORE_INDEX) &&
        !window.location.pathname.includes(FEEDBACK)
      ) {
        const correctGeoType = window.location.pathname.includes(SERVICES_FETCH_REAL_ESTATES) ? SEARCHED : ALL;
        dispatch(drawReloadedGeometriesThunk(correctGeoType, usufructUnitRealtyId, usufructUnitId, language));
      }
    }

    // we run reload only after first render
    if (isMounted.current) {
      reloadOskariMap();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useHideInfoBox({ channelReady });

  return (
    <div
      className={cls('map-container', {
        loading: !isIframeLoaded,
      })}
    >
      {!isPlaywrightDriven() && (
        <iframe
          allow="geolocation"
          ref={oskariMap}
          title={t('map.title')}
          onLoad={iframeHasLoaded}
          id="js-oskari-iframe"
          className={cls('map-iframe', {
            'disable-events': !eventsEnabled,
          })}
          src={generateOskariPath(basePath, additionalPathParams, publishId, language)}
          tabIndex={businessPanelIsOpen && isMobileMode ? -1 : 0}
        />
      )}
      {isPlaywrightDriven() && (
        <iframe id="js-oskari-iframe" className="mock-map" title={t('map.title')}>
          <div>MAP</div>
        </iframe>
      )}
      <SnapshotScreenLayer />
      <AppNotificationsComponent />
      {channelNotReady && <OskariMapVeilComponent title={t('map.error.title')} message={t('map.error.message')} />}
      <button
        type="button"
        className={cls('back-button', 'button', 'button--blue', 'button--large', 'button--icon-and-text', {
          hidden: !isMapBackButtonVisible,
        })}
        onClick={backButtonClickHandler}
      >
        <Glyphicon glyph="takaisin-kartalta" />
        {t('button.back')}
      </button>
    </div>
  );
};

OskariMapComponent.propTypes = {
  additionalPathParams: PropTypes.string,
  basePath: PropTypes.string,
  publishId: PropTypes.string,
};

export default OskariMapComponent;
