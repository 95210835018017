import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const UploaderContext = createContext();

export function UploaderProvider({ children }) {
  const [errorMessage, setErrorMessage] = useState(null);

  const contextValues = useMemo(() => {
    return {
      errorMessage,
      setErrorMessage,
    };
  }, [errorMessage, setErrorMessage]);

  return <UploaderContext.Provider value={contextValues}>{children}</UploaderContext.Provider>;
}

UploaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UploaderContext;
