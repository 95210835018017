import React from 'react';
import PropTypes from 'prop-types';
import cls from 'classnames';
import { FlexGrid, Row, Block } from 'common/components';
import { useTranslation } from 'react-i18next';
import FooterBlock from './FooterBlock';

const FooterComponent = ({
  isSticky = false,
  leftBlockLinks = null,
  rightBlockLinks = null,
  leftHeading,
  rightHeading = '',
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <footer className={cls('footer', { sticky: isSticky })}>
      <FlexGrid className={cls('footer__link-box', { 'single-column': !rightBlockLinks })}>
        <Row>
          {leftBlockLinks && <FooterBlock links={leftBlockLinks} heading={leftHeading} />}
          {rightBlockLinks && <FooterBlock links={rightBlockLinks} heading={rightHeading} />}
        </Row>
      </FlexGrid>
      <FlexGrid>
        <Row className={cls('footer__slogan', { 'single-column': !rightBlockLinks })}>
          <Block>
            <div className={`footer__logo footer__logo--${language}`} />
          </Block>
          <Block className="slogan__block">
            <p>{t('footer.slogan')}</p>
          </Block>
        </Row>
      </FlexGrid>
    </footer>
  );
};

FooterComponent.propTypes = {
  isSticky: PropTypes.bool,
  leftBlockLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      disabledLangs: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  rightBlockLinks: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      disabledLangs: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  leftHeading: PropTypes.string.isRequired,
  rightHeading: PropTypes.string,
};

export default FooterComponent;
