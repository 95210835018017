import React from 'react';
import PropTypes from 'prop-types';

const SearchResultToggleWrapper = ({ children = null }) => <div className="search-results">{children}</div>;

SearchResultToggleWrapper.propTypes = {
  children: PropTypes.node,
};

export default SearchResultToggleWrapper;
