export const APARTMENT_TYPE_CODE_PARKING_SPACE = 'A';
export const APARTMENT_TYPE_APARTMENT = 'apartment';
export const APARTMENT_TYPE_PARKING_SPACE = 'parkingSpace';
export const APARTMENT_TYPE_CODE_APARTMENT = 'H';
export const APARTMENT_TYPE_CODE_OTHER = 'M';
export const APARTMENT_TYPE_OTHER = 'otherApartment';

export const APARTMENT_TYPE_ORDER = [
  APARTMENT_TYPE_CODE_APARTMENT,
  APARTMENT_TYPE_CODE_PARKING_SPACE,
  APARTMENT_TYPE_CODE_OTHER,
];

export const APARTMENT_TYPES = [APARTMENT_TYPE_APARTMENT, APARTMENT_TYPE_PARKING_SPACE, APARTMENT_TYPE_OTHER];

export const PENDING_STATUS = '1';
export const PROVISIONAL_STATUS = '3';
export const APPROVED_NOT_BINDING_STATUS = '4';
export const REJECTED_NOT_BINDING_STATUS = '5';
export const APPROVED_STATUS = '6';
export const RESTING_STATUS = '10';

export const ACQUISITION_TYPE = 'SAANTOLAJI';
export const BASIS_TYPE = 'PERUSTELAJI';

export const PLEDGE_REPAIRING_CODE = '5';
export const PLEDGE_CHANGE_STATUS = '7';
export const PLEDGE_REMOVE_STATUS = '8';

export const RESTRICTION_SET_CODE = '14';
export const RESTRICTIONS_REPAIRING_CODE = '4';

export const RESTRICTION_TYPE_SECURITY_DOCUMENTS_NOTIFICATION = '3';
export const RESTRICTION_TYPE_SEIZURE = '6';
export const RESTRICTION_TYPE_SECURITY_MEASURE = '7';
export const RESTRICTION_TYPE_TEMPORARY_SECURITY_MEASURE = '8';
export const RESTRICTION_TYPE_CONFISCATION = '9';
export const RESTRICTION_TYPE_SECURITY_CONFISCATION = '10';
export const RESTRICTION_TYPE_TEMPORARY_SECURITY_CONFISCATION = '11';
export const RESTRICTION_TYPE_TEMPORARY_MEASURE = '12';
export const RESTRICTION_TYPE_BANKRUPTCY = '13';
export const RESTRICTION_TYPE_CORPORATE_RESTRUCTURING = '14';
export const RESTRICTION_TYPE_SEIZURE_OF_ESTATE_SHARE = '15';
export const RESTRICTION_TYPE_SEIZURE_OF_COMPANY_SHARE = '16';
export const RESTRICTION_TYPE_OTHER_RESTRICTION_OF_PROPERTY_RIGHTS = '26';
