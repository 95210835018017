import React, { useCallback } from 'react';
import cls from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { ROUTE_SERVICES_SITE_ID, getRoute, SHOPPING_CART, API_PATH_CHANGE_ROLE } from 'common/constants/Routes';
import CartNotificationBubbleComponent from 'common/containers/UserMenu/CartNotificationBubbleComponent';
import { showBack } from 'common/containers/UserMenu/UserMenuBackButtonContainer';
import { clearCart, toggleCartNotificationBubble } from 'common/containers/ShoppingCart/ShoppingCartActions';
import { isMobile } from 'common/constants/Layout';
import { isAuthorized } from 'common/containers/Authorization/AuthorizationComponent';
import { CHECKOUT } from 'common/containers/Authorization/privileges';
import getCartCount from 'common/containers/UserMenu/cartItemCounter';
import { logoutThunk } from 'common/helpers/Authentication/authenticationSlice';
import { clearFormDataFromSessionStorage } from 'common/components/Form/FormHelpers';
import UserMenuHomeButton, { showHome } from './UserMenuHomeButton';
import UserMenuBackButton from './UserMenuBackButton';
import { FlexGrid, Row } from '../Grid';
import UserMenuDropDown from './UserMenuDropDown';
import LanguageSelector from './LanguageSelector';
import CartButton from './CartButton';

const UserMenu = () => {
  const { pathname } = useLocation();
  const authentication = useSelector(state => state.authentication);
  const openBubble = useSelector(state => state.shoppingCart.notificationBubble.isOpen);
  const onMobile = useSelector(state => isMobile(state.layout.mode));
  const rootUrl = getRoute();
  const showCartButton = isAuthorized([CHECKOUT]);
  const cartItemCount = useSelector(state => getCartCount(state.shoppingCart.items));

  const backShown = showBack({ pathname, onMobile });
  const homeShown = showHome({ matchPath, pathname, onMobile });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goToShoppingCart = useCallback(() => {
    navigate(getRoute(SHOPPING_CART));
  }, [navigate]);

  const changeRole = useCallback(() => {
    dispatch(clearCart());
    dispatch(toggleCartNotificationBubble());
    clearFormDataFromSessionStorage();
    window.location.assign(getRoute(API_PATH_CHANGE_ROLE));
  }, [dispatch]);

  return (
    <FlexGrid className={cls('user-menu__wrapper', backShown ? 'back' : '')}>
      <Row className="user-menu">
        {backShown && <UserMenuBackButton isMobile={onMobile} />}
        {backShown && <div className="user-menu__item-divider" />}
        {homeShown && <UserMenuHomeButton />}
        {homeShown && <div className="user-menu__item-divider" />}
        <LanguageSelector className="user-menu__item-block no-hover" />
        <div className="user-menu__item-divider" />
        {authentication?.uid && (
          <UserMenuDropDown
            authentication={authentication}
            onLogout={() => dispatch(logoutThunk())}
            floatRight={rootUrl.includes(ROUTE_SERVICES_SITE_ID)}
            isMobile={onMobile}
            cartItemCount={cartItemCount}
            goToShoppingCart={goToShoppingCart}
            changeRole={changeRole}
          />
        )}
        {authentication?.uid && <div className="user-menu__item-divider" />}
        {showCartButton && (
          <CartButton cartItemCount={cartItemCount} shoppingCartUrl={getRoute(SHOPPING_CART)} rootUrl={rootUrl}>
            {openBubble && <CartNotificationBubbleComponent />}
          </CartButton>
        )}
      </Row>
    </FlexGrid>
  );
};

export default UserMenu;
