import {
  NEW_PROPERTY_PORTION_TYPE,
  PORTION_PORTION_TYPE,
  SHARED_AREA_PORTION_TYPE,
  WHOLE_TARGET_PORTION_TYPE,
} from '../../RealEstateTransferAnnouncement/common/RealEstateTransferAnnouncementConstants';
import { LCAG_FIELD_VALUES } from '../LCAG/LCAGConstants';

export const LCA_SOURCE_TYPE_E_SERVICES = 'SA';
export const LCA_SOURCE_TYPE_PUBLIC_PURCHASE_WITNESSING = 'KV';
export const NO_PRICE = 0.0;
export const LCA_WHOLE_TARGET_PORTION_TYPE = 'KK';
export const LCA_PORTION_PORTION_TYPE = 'MO';
export const LCA_NEW_PROPERTY_PORTION_TYPE = 'MA';
export const LCA_PROPERTY_RESERVED_PORTION_TYPE = 'MP';

export const DEATH_ESTATE_TYPE = 'deathEstate';

// lca types for legal confirmation application
export const PORTION_TYPES = {
  [WHOLE_TARGET_PORTION_TYPE]: {
    value: WHOLE_TARGET_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.wholeTarget',
    lca: LCA_WHOLE_TARGET_PORTION_TYPE,
  },
  [PORTION_PORTION_TYPE]: {
    value: PORTION_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.portion',
    lca: LCA_PORTION_PORTION_TYPE,
  },
  [NEW_PROPERTY_PORTION_TYPE]: {
    value: NEW_PROPERTY_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.newProperty',
    lca: LCA_NEW_PROPERTY_PORTION_TYPE,
  },
  [SHARED_AREA_PORTION_TYPE]: {
    value: SHARED_AREA_PORTION_TYPE,
    text: 'realEstate.realEstateTransferAnnouncement.step2.transferTarget.sharedArea',
    lca: LCA_NEW_PROPERTY_PORTION_TYPE,
  },
  [LCAG_FIELD_VALUES.PORTION_TYPE.WHOLE_OR_PORTION]: {
    value: LCAG_FIELD_VALUES.WHOLE_OR_PORTION_PORTION_TYPE,
    lca: LCA_WHOLE_TARGET_PORTION_TYPE,
  },
  [LCAG_FIELD_VALUES.PORTION_TYPE.PROPERTY]: {
    value: LCAG_FIELD_VALUES.PROPERTY_PORTION_TYPE,
    lca: LCA_NEW_PROPERTY_PORTION_TYPE,
  },
  [LCAG_FIELD_VALUES.PORTION_TYPE.SHARED_AREA]: {
    value: LCAG_FIELD_VALUES.SHARED_AREA_PORTION_TYPE,
    lca: LCA_NEW_PROPERTY_PORTION_TYPE,
  },
};
