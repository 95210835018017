import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { createSelector } from 'reselect';

import * as viewNames from 'common/containers/MobileTools/MobileViewNames';
import MainToolbarHeaderComponent from 'common/containers/Sidebar/MainToolbarHeaderComponent';
import { ExternalLink, SearchBar } from 'common/components';

import { mapIsReady } from 'oskari/OskariMap';
import useMapClickSelect from 'realEstateSite/helpers/useMapClickSelect';
import { searchRealEstatesOnClickErrorToggled } from 'realEstateSite/containers/Realty/RealEstate/Search/searchRealEstatesSlice';
import { fetchPublicRegisterUnitForPoint } from 'common/api/CommonApi';
import { removeSearchFeatureThunk } from 'common/geometries/geometriesActions';
import useDrawPrevRealtyMarkers from 'common/hooks/useDrawPrevRealtyMarkers';
import { clearSearchThunk, handleRealEstateDrawing, searchThunk } from './SearchActions';
import getLayerVisibility from '../MapLayerSelector/MapLayerSelectorSelectors';

export const SEARCH_MAX_CHARS = 50;

const getFeatureIds = createSelector(
  state => state.geometries?.features,
  features => features?.map(feature => feature.name)
);

const SearchBarComponent = ({ activePanel = null }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const searchInput = useRef(null);
  const dispatch = useDispatch();
  const prevLang = localStorage.getItem('prevLang');

  const { params: searchParams } = useSelector(state => state.search);
  const currentMobileView = useSelector(state => state.mobileView.currentMobileView);
  const sideBarIsOpen = useSelector(state => state.layout.desktop.sidebar.isOpen);
  const isMapSite = useSelector(state => state.application.applicationContext) === 'mapSite';
  const isPropertyBoundaryVisible = useSelector(state => getLayerVisibility(state, 623));
  const RealEstateMarkerIds = useSelector(getFeatureIds);
  const searchedGeometries = useSelector(state => state.geometry.searched);

  useMapClickSelect('mapSiteSelect', async (lon, lat) => {
    if (!isMapSite) return;
    if (isPropertyBoundaryVisible) {
      dispatch(searchRealEstatesOnClickErrorToggled(false));
      try {
        const registerUnit = await fetchPublicRegisterUnitForPoint(lat, lon);
        if (registerUnit != null) {
          if (!isEmpty(searchedGeometries) && RealEstateMarkerIds.includes(registerUnit.id)) {
            dispatch(removeSearchFeatureThunk(registerUnit.id));
          }
          dispatch(handleRealEstateDrawing(registerUnit));
        }
      } catch (e) {
        console.error(e);
      }
    }
  });

  useDrawPrevRealtyMarkers();

  useEffect(() => {
    if (searchInput.current !== null) {
      if (currentMobileView === viewNames.MOBILE_SEARCH_VIEW || activePanel === 'search-panel' || sideBarIsOpen) {
        searchInput.current.focus();
      }
    }
  }, [activePanel, sideBarIsOpen, currentMobileView]);

  const getSearchValuesFromDOM = () => {
    return {
      searchString: searchInput.current?.value,
    };
  };

  const handleSearchButtonClick = () => {
    const search = getSearchValuesFromDOM();
    const { searchString } = search;
    if (!search.searchString || searchString.length === 0) {
      return false;
    }

    return dispatch(searchThunk(search, true, false));
  };

  const handleClearSearchClick = () => {
    dispatch(clearSearchThunk());
  };

  const handleSearchParamsChanged = useCallback(() => {
    dispatch(searchThunk(getSearchValuesFromDOM(), false, false, isMapSite));
  }, [dispatch, isMapSite]);

  useEffect(() => {
    const fetchSearchThunk = async () => {
      if (prevLang && prevLang !== language && searchInput.current.value !== null) {
        await mapIsReady();
        await dispatch(searchThunk(searchParams, false, false, isMapSite));
      }
    };
    fetchSearchThunk();
  }, [dispatch, language, prevLang, searchParams, isMapSite]);

  return (
    <div className="sidebar-search">
      <MainToolbarHeaderComponent title={t('search.title')} />
      <div className="search-bar__field">
        <label htmlFor="sideBarSearch" className="search-bar__label">
          <span>{t('search.label.text')}&nbsp;</span>
          <ExternalLink href={t('search.label.link.url')}>{t('search.label.link.text')}</ExternalLink>
        </label>
        <SearchBar
          id="sideBarSearch"
          name="sideBarSearch"
          ref={searchInput}
          maxLength={SEARCH_MAX_CHARS}
          value={searchParams.searchString}
          onChange={handleSearchParamsChanged}
          onReset={handleClearSearchClick}
          onSubmit={handleSearchButtonClick}
          noSearchOnBlur
        />
      </div>
    </div>
  );
};

SearchBarComponent.propTypes = {
  activePanel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default SearchBarComponent;
