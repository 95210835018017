import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cls from 'classnames';

import useAutomaticKeyboardFocus from 'common/containers/AutomaticKeyboardFocus/useAutomaticKeyboardFocus';
import { requestKeyboardFocusAction } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusActions';
import { ACTIVE_MENU_ITEM } from 'common/containers/AutomaticKeyboardFocus/AutomaticKeyboardFocusConstants';

const FocusableHeading = ({
  level,
  visualLevel = null,
  className = '',
  excludeDefaultClass = false,
  delayFocus = false,
  focusPending = false,
  children = null,
}) => {
  const titleRef = useRef(null);
  const dispatch = useDispatch();

  const focusHandler = () => titleRef?.current?.focus();

  useAutomaticKeyboardFocus(className, focusHandler, { delayFocus, focusPending });

  const onKeyDown = event => {
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      dispatch(requestKeyboardFocusAction({ selector: ACTIVE_MENU_ITEM }));
    }
  };

  const Tag = level;
  return (
    <Tag
      onKeyDown={onKeyDown}
      className={cls(excludeDefaultClass ? null : `nls-heading-${visualLevel || level}`, className)}
    >
      <span ref={titleRef} tabIndex="-1">
        {children}
      </span>
    </Tag>
  );
};

FocusableHeading.propTypes = {
  level: PropTypes.PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong']).isRequired,
  visualLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  className: PropTypes.string,
  excludeDefaultClass: PropTypes.bool,
  delayFocus: PropTypes.bool,
  focusPending: PropTypes.bool,
  children: PropTypes.node,
};

export default FocusableHeading;
