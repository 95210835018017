import React from 'react';
import PropTypes from 'prop-types';
import i18next, { t } from 'i18next';

import defaultLogo from 'styles/img/mml-logo-fi.svg';
import swedishRELogo from 'styles/img/mml-logo-sv.svg';
import englishRELogo from 'styles/img/mml-logo-en.svg';
import swedishMSLogo from 'styles/img/kp-logo-sv.svg';
import englishMSLogo from 'styles/img/kp-logo-en.svg';
import finnishMSLogo from 'styles/img/kp-logo-fi.svg';

const resolveLogoSource = appContext => {
  if (appContext === 'mapSite') {
    if (i18next.language === 'sv') {
      return swedishMSLogo;
    }
    if (i18next.language === 'en') {
      return englishMSLogo;
    }
    return finnishMSLogo;
  }

  if (i18next.language === 'sv') {
    return swedishRELogo;
  }
  if (i18next.language === 'en') {
    return englishRELogo;
  }
  return defaultLogo;
};

const AppLogoComponent = ({ appContext = 'realEstateSite', logoTitle = t('app.logo.title') }) => (
  <div className="logo__container" data-testid="AppLogoComponent">
    <a href={t('app.link.nlsHome')} target="_blank" rel="noopener noreferrer" className="logo__link">
      <img src={resolveLogoSource(appContext)} alt={logoTitle} className="logo__image" />
    </a>
  </div>
);

AppLogoComponent.propTypes = {
  appContext: PropTypes.string,
  logoTitle: PropTypes.string,
};

export default AppLogoComponent;
export { resolveLogoSource };
