export const CERTIFICATE_FORM_ID = 'certificate';
export const LCA_FORM_ID = 'lca'; // legal confirmation application
export const LCAG_FORM_ID = 'lcag'; // legal confirmation application for inheritence (generic)
export const DEATH_ESTATE_LCA_FORM_ID = 'deathEstateLca'; // legal confirmation application for inheritence (generic)
export const PARTITIONING_LCA_FORM_ID = 'plca'; // legal confirmation application for partitioning
export const RETA_FORM_ID = 'reta'; // real estate transfer announcement
export const SEARCH_REAL_ESTATES_FORM_ID = 'searchRealEstates';
export const PRS_FORM_ID = 'prs'; // private road survey
export const PSS_FORM_ID = 'pss'; // plot subdivision survey
export const OCS_FORM_ID = 'ocs'; // other cadastral survey
export const ES_FORM_ID = 'es'; // easement survey
export const BD_FORM_ID = 'bd'; // boundary demarcation
export const LTA_FORM_ID = 'lta'; // leasehold transfer application
export const LRA_FORM_ID = 'lra'; // leasehold registration application
export const OSRA_FORM_ID = 'osra'; // other special right application
export const GRA_FORM_ID = 'ylermi'; // general registration application
export const RAS_FORM_ID = 'ras'; // reliction area survey
export const AOR_FORM_ID = 'aor'; // amalgamation of real estates
export const WITH_CERTIFICATE_FORM_ID = 'orwsc'; // ownership registration with a share certificate
export const WITH_ACQUISITION_FORM_ID = 'orwa'; // ownership registration with an acquisition
export const WITH_ACQUISITION_SHARE_RETURN = 'orwa_sharereturn';
